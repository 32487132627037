// General
import "./add-payment-method-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
// Services
import {
  useLazyGetPaymentGatewayListQuery,
  useInitXenopayPaymentMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  resetCreditCardDetails,
  updatePaymentMethodSelection,
} from "../../../../redux/store/paymentStore";
import { updateAddPaymentMethodDialog } from "../../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// Material UI
import {
  Dialog,
  Slide,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useSecurionCreateCardFlow from "../../../utility/custom-hooks/useSecurionCreateCardFlow-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../elements/spinner/spinner";
import PaymentMethodSelection from "../../payment/payment-method-selection/payment-method-selection";
import CardDetails from "../../../shared/payment/card-details/card-details";
import AddCardLoadingDialog from "../../../shared/dialog-content/add-card-loading-dialog/add-card-loading-dialog";

const AddPaymentMethodDialog = () => {
  // API variables
  const [
    getPaymentGatewayList,
    {
      data: paymentGatewayListData,
      error: paymentGatewayListErrorData,
      isFetching: paymentGatewayListFetching,
      isLoading: paymentGatewayListLoading,
      isSuccess: paymentGatewayListSuccess,
      isError: paymentGatewayListError,
    },
  ] = useLazyGetPaymentGatewayListQuery();
  const [
    initXenopayPayment,
    {
      data: initXenopayPaymentData,
      error: initXenopayPaymentErrorData,
      isLoading: initXenopayPaymentLoading,
      isSuccess: initXenopayPaymentSuccess,
      isError: initXenopayPaymentError,
    },
  ] = useInitXenopayPaymentMutation();

  // General variables
  const [tnc, setTnc] = useState(false);

  // Redux variables
  const addPaymentMethodDialog = useSelector(
    (state) => state.dialog.addPaymentMethodDialog
  );
  const selectedCoinPackage = useSelector(
    (state) => state.products.selectedCoinPackage
  );
  const paymentMethodSelection = useSelector(
    (state) => state.payment.paymentMethodSelection
  );
  const isFormValid = useSelector((state) => state.payment.isFormValid);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const securionCreateCardFlow = useSecurionCreateCardFlow();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!addPaymentMethodDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-AddPaymentMethod-Dialog",
      },
    });

    getPaymentGatewayList("?product_type=coin", true);

    dispatch(resetCreditCardDetails());
    setTnc(false);
  }, [addPaymentMethodDialog]);

  // Lifecycle | Check for update | Init XenoPay API Response
  useEffect(() => {
    if (initXenopayPaymentLoading) {
    } else if (initXenopayPaymentSuccess) {
      window.open(initXenopayPaymentData?.data?.url, "_self");
    } else if (initXenopayPaymentError) {
      const toastObj = {
        message: initXenopayPaymentErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));
    }
  }, [
    initXenopayPaymentLoading,
    initXenopayPaymentSuccess,
    initXenopayPaymentError,
  ]);

  // Event Handlers | Button
  const onAddCard = () => {
    switch (paymentMethodSelection) {
      case "securionpay":
        if (!isFormValid || !tnc) return;

        securionCreateCardFlow();
        break;
      case "xenopay":
        const xenopayObj = {
          product_hash_id: selectedCoinPackage?.product_hash_id,
          product_type: selectedCoinPackage?.product_name,
          redirect_url: `${window.origin}${routeConst.payment.redirect.path}`,
        };
        initXenopayPayment(xenopayObj);
        break;
      default:
        break;
    }
  };

  // Event Handlers | MUI Checkbox
  const onCheckboxChange = (event) => {
    setTnc(event.target.checked);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updatePaymentMethodSelection(null));
    dispatch(updateAddPaymentMethodDialog(false));
  };

  // Utility Functions
  const returnShowSpinner = () => {
    switch (paymentMethodSelection) {
      case "securionpay":
        return false;
      case "xenopay":
        return initXenopayPaymentLoading;
      default:
        return false;
    }
  };

  return (
    <Dialog
      className={
        isMobile ? "custom-bottom-sheet-no-radius-fullscreen-dialog" : ""
      }
      fullScreen={isMobile ? true : false}
      open={addPaymentMethodDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="add-payment-method-dialog">
        <div className="dialog-header">
          <div className="close-button-container" onClick={onCloseDialog}>
            <CloseIcon className="close-button" />
          </div>
        </div>

        <div className="padding-container">
          <Divider />

          <div className="coin-package-selected-container">
            <div className="sb-coin-icon-container">
              {getIcon("sbCoinIcon", "sb-coin-icon")}
            </div>

            <div className="coin-amount">
              {selectedCoinPackage?.description}
            </div>

            <div className="payment-amount">
              {selectedCoinPackage?.display_currency}
              {selectedCoinPackage?.discounted_amount?.toLocaleString() ??
                selectedCoinPackage?.amount?.toLocaleString()}
            </div>
          </div>

          <Divider />

          <PaymentMethodSelection
            availableGateways={selectedCoinPackage?.gateways}
          />

          {paymentMethodSelection === "securionpay" && <CardDetails />}

          {paymentMethodSelection === "securionpay" && (
            <div className="we-accept-label">{t("purchase.we_accept")}:</div>
          )}

          {paymentMethodSelection === "securionpay" && (
            <div className="credit-card-company-image-container">
              {getIcon("visaLogo", "visa-logo")}
              {getIcon("mastercardLogo", "mastercard-logo")}
              {getIcon("maestroLogo", "maestro-logo")}
              {getIcon("visaElectronLogo", "electron-logo")}
            </div>
          )}

          {paymentMethodSelection && (
            <div className="agreement-checkbox-container">
              <FormGroup className="checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      className="custom-checkbox"
                      onChange={onCheckboxChange}
                    />
                  }
                  label={t("purchase.i_accept_tnc")}
                />
              </FormGroup>
            </div>
          )}

          {paymentMethodSelection && (
            <div className="discretion-container">
              <div className="discretion-head">
                {paymentGatewayListData?.data?.copywriting?.descriptor?.head}
              </div>
              <div className="discretion-body">
                {paymentGatewayListData?.data?.copywriting?.descriptor?.body?.join(
                  ", "
                )}
              </div>
            </div>
          )}

          {paymentMethodSelection && (
            <div
              className={`pay-button ${
                isFormValid && tnc ? "" : "disabled-button"
              }`}
              onClick={onAddCard}
            >
              {returnShowSpinner() ? (
                <Spinner size={20} isPadding={false} color={"white-spinner"} />
              ) : (
                t("common.continue")
              )}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default AddPaymentMethodDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
