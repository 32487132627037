// General
import "./profile-info.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// i18next
import { useTranslation } from "react-i18next";
// Material UI
import { Skeleton } from "@mui/material";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import useGetMembershipBadge from "../../../utility/custom-hooks/useGetMembershipBadge-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../shared/elements/custom-avatar/custom-avatar";
import VerifiedProfileBadge from "../../../shared/elements/verified-profile-badge/verified-profile-badge";

const ProfileInfo = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getMembershipBadge = useGetMembershipBadge();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  if (getProfileSuccess || getProfileData) {
    return (
      <div id="overview-profile-info-subcomponent">
        <div className="profile-photo-container">
          <CustomAvatar
            className="profile-photo"
            src={
              getProfileData?.data?.profile_photo?.photo_status !== "REJECTED"
                ? getProfileData?.data?.profile_photo?.original_photo
                : null
            }
            role={getProfileData?.data?.role}
            profileBorder={
              getProfileData?.data?.profile_decorations?.profile_border_url
            }
          />
        </div>

        <div className="username-container">
          <div className="name">
            {getProfileData?.data?.username || t("common.new_user")}
          </div>

          {/* Verified Profile */}
          <div className="verified-profile-badge-container">
            <VerifiedProfileBadge
              verifiedProfile={getProfileData?.data?.is_verified_profile}
              className={"verified-profile-badge"}
            />
          </div>

          {/* Premium Mini Badge */}
          {(getProfileData?.data?.membership_type ===
            userConst.membershipType.premium ||
            getProfileData?.data?.membership_type ===
              userConst.membershipType.freePremium) &&
            getIcon("premiumMiniBadge", "premium-mini-badge")}
        </div>

        <div className="badge-container">
          {/* Premium Badge */}
          {(getProfileData?.data?.membership_type ===
            userConst.membershipType.premium ||
            getProfileData?.data?.membership_type ===
              userConst.membershipType.freePremium) && (
            <div className="premium-badge-container">
              <img
                className="premium-badge"
                src={getMembershipBadge(getProfileData?.data?.membership_type)}
              />
            </div>
          )}

          {/* Levelling */}
          {getProfileData?.data?.levelling_profile?.badge && (
            <div className="levelling-badge-container">
              <img
                className="levelling-badge"
                src={getProfileData?.data?.levelling_profile?.badge}
              />
            </div>
          )}
        </div>
      </div>
    );
  } else if (getProfileFetching || getProfileLoading) {
    return (
      <div id="overview-profile-info-subcomponent">
        <div className="profile-photo-container">
          <Skeleton className="profile-photo" variant="circular" />
        </div>

        <div className="username-container">
          <Skeleton className="username-skeleton" variant="rounded" />
        </div>

        <div className="badge-container">
          <Skeleton className="badge-skeleton" variant="rounded" />
        </div>
      </div>
    );
  } else if (getProfileError) {
    if (getProfileErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default ProfileInfo;
