// General
import "./last-stream-top-gifters.scss";
import { useEffect } from "react";
// Services
import { useLazyGetLastStreamResultQuery } from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../../redux/store/userCardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";
import Spinner from "../../../../shared/elements/spinner/spinner";

const LastStreamTopGifters = () => {
  // API variables
  const [
    getLastStreamSummary,
    {
      data: getLastStreamSummaryData,
      error: getLastStreamSummaryErrorData,
      isFetching: getLastStreamSummaryFetching,
      isLoading: getLastStreamSummaryLoading,
      isSuccess: getLastStreamSummarySuccess,
      isError: getLastStreamSummaryError,
    },
  ] = useLazyGetLastStreamResultQuery();

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getLastStreamSummary(null, true);
  }, []);

  // Event Handlers | Button
  const onViewProfile = (gifter) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-28-28.1-Button",
      },
    });

    // User View
    dispatch(updateId(gifter?.id));
    dispatch(updateIdInt(gifter?.id_int));
    dispatch(updateUsername(gifter?.username));
    dispatch(updateAge(gifter?.appearance?.age));
    dispatch(updateLocation(gifter?.location?.city || gifter?.location?.state));
    dispatch(updateMembershipType(gifter?.membership_type));
    dispatch(updateVerifiedProfile(gifter?.is_verified_profile));
    dispatch(updateVerifiedCaller(gifter?.verified_caller));
    dispatch(updateLevellingBadge(gifter?.levelling_profile?.badge));
    dispatch(
      updateLivestreamingAchievementBadge(
        gifter?.live_streaming_achievements?.chat_badges_set
      )
    );
    dispatch(updateProfilePhoto(gifter?.profile_photo?.original_photo));
    dispatch(
      updatePublicPhotos(
        gifter?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
          };
        })
      )
    );
    dispatch(
      updatePrivatePhotos(
        gifter?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            thumbnailData: photo?.thumbnail_data,
          };
        })
      )
    );
    dispatch(updateTorTags(gifter?.tor_tags));
    dispatch(
      updateProfileAchievementBadge(
        gifter?.live_streaming_achievements?.profile_badges
      )
    );
    dispatch(updateAboutMe(gifter?.aboutme));
    dispatch(updateLookingFor(gifter?.lookingfor));
    dispatch(updateOccupation(gifter?.occupation?.occupation));
    dispatch(updateLifestyle(gifter?.occupation?.lifestyle_budget));
    dispatch(updateEducation(gifter?.occupation?.education));
    dispatch(updateRelationship(gifter?.relationship?.relationship_status));
    dispatch(updateSmoking(gifter?.habit?.smoking));
    dispatch(updateDrinking(gifter?.habit?.drinking));
    dispatch(updateHeight(gifter?.appearance?.height));
    dispatch(updateBodyType(gifter?.appearance?.body_type));
    dispatch(updateEthnicity(gifter?.appearance?.ethnicity));
    dispatch(updateEyeColor(gifter?.appearance?.eye_color));
    dispatch(updateHairColor(gifter?.appearance?.hair_color));
    dispatch(updateChildren(gifter?.relationship?.children));
    // User Utility
    dispatch(updateShouldCallApi(false));
    dispatch(
      updatePrivatePhotoRequested(gifter?.interaction?.private_photo_requested)
    );
    dispatch(
      updatePrivatePhotoApproval(gifter?.interaction?.private_photo_approval)
    );
    dispatch(
      updateRequestedMyPrivatePhoto(
        gifter?.interaction?.requested_my_private_photo
      )
    );
    dispatch(
      updateApprovedMyPrivatePhoto(
        gifter?.interaction?.approved_my_private_photo
      )
    );
    dispatch(updateIsBlocked(gifter?.interaction?.is_blocked ? true : false));
    dispatch(
      updateIsSilentInteraction(gifter?.interaction?.should_silent_interactions)
    );
    dispatch(updateIsFavorited(gifter?.interaction?.is_favorited));
    dispatch(
      updateIsCallEnabled(
        gifter?.private_call_settings?.enable_calls &&
          gifter?.private_call_settings?.system_private_calls_enabled
      )
    );
    dispatch(
      updateIsSugarbaby(
        gifter?.role === userConst.userRole.sugarbaby ||
          gifter?.role === userConst.userRole.maleSugarbaby
      )
    );
    dispatch(updateRole(gifter?.role));

    onNavigate(`${routeConst.profile.view.clearPath}${gifter?.id}`);
  };

  if (getLastStreamSummaryFetching || getLastStreamSummaryLoading) {
    return <Spinner />;
  } else if (getLastStreamSummarySuccess) {
    if (getLastStreamSummaryData?.status === 1) {
      if (getLastStreamSummaryData?.data?.top_gifters?.length > 0) {
        return (
          <div id="last-stream-summary-last-stream-top-gifters-subcomponent">
            <div className="padding-container">
              <div className="top-gifters-label">
                {t("leaderboard.top_gifters")}
              </div>

              <div className="top-gifters-container">
                {getLastStreamSummaryData?.data?.top_gifters?.map(
                  (gifter, index) => (
                    <div className="gifter-container" key={index}>
                      <CustomAvatar
                        className="gifter-photo"
                        src={gifter?.profile_photo?.original_photo}
                        role={gifter?.role}
                        profileBorder={
                          gifter?.profile_decorations?.profile_border_url
                        }
                        onClick={() => onViewProfile(gifter)}
                      />

                      <div className="gifter-position">
                        {index === 0 &&
                          getIcon("leaderboardRank1", "badge-ranking")}
                        {index === 1 &&
                          getIcon("leaderboardRank2", "badge-ranking")}
                        {index === 2 &&
                          getIcon("leaderboardRank3", "badge-ranking")}
                      </div>

                      <div className="gifter-username">{gifter?.username}</div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        );
      }
    }
  } else if (getLastStreamSummaryError) {
  }
};

export default LastStreamTopGifters;
