// General
import "./gifters-tab.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetLifetimeGiftersListQuery,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateGiftersList,
  resetGiftersList,
} from "../../../../../redux/store/userStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import EmptyList from "../empty-list/empty-list";
import FollowerUserList from "../../../../shared/profile/follower-user-list/follower-user-list";

const GiftersTab = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getLifetimeGiftersList,
    {
      data: getLifetimeGiftersListData,
      error: getLifetimeGiftersListErrorData,
      isFetching: getLifetimeGiftersListFetching,
      isLoading: getLifetimeGiftersListLoading,
      isSuccess: getLifetimeGiftersListSuccess,
      isError: getLifetimeGiftersListError,
    },
  ] = useLazyGetLifetimeGiftersListQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);

  // Render variables
  let giftersListView = <div></div>;

  // Redux variables
  const giftersList = useSelector((state) => state.user.giftersList);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    dispatch(resetGiftersList());
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      getLifetimeGiftersList(`?user_id=${getProfileData?.data?.id}`, true);
    } else if (getProfileError) {
      if (getProfileErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Get Lifetime Gifters List API Response
  useEffect(() => {
    if (getLifetimeGiftersListFetching || getLifetimeGiftersListLoading) {
    } else if (getLifetimeGiftersListSuccess) {
      if (getLifetimeGiftersListData?.status === 1) {
        setFirstLoad(false);

        if (getLifetimeGiftersListData?.data?.users?.length > 0) {
          dispatch(updateGiftersList(getLifetimeGiftersListData?.data?.users));
        }
      }
    } else if (getLifetimeGiftersListError) {
      if (getLifetimeGiftersListErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLifetimeGiftersListFetching,
    getLifetimeGiftersListLoading,
    getLifetimeGiftersListSuccess,
    getLifetimeGiftersListError,
  ]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (getLifetimeGiftersListData?.data?.pagination?.next_cursor) {
      setTimeout(() => {
        getLifetimeGiftersList(
          `?user_id=${getProfileData?.data?.id}&pagination_token=${getLifetimeGiftersListData?.data?.pagination?.next_cursor}`
        );
      }, 1000);
    }
  };

  // Render Functions
  if (giftersList?.length > 0) {
    giftersListView = (
      <div className="infinite-scroller-container">
        {giftersList?.map((gifter, index) => (
          <FollowerUserList
            // User View
            id={gifter?.id}
            idInt={gifter?.id_int}
            username={gifter?.username}
            age={gifter?.appearance?.age}
            location={
              gifter?.location?.display_address ||
              gifter?.location?.city ||
              gifter?.location?.state
            }
            membershipType={gifter?.membership_type}
            verifiedProfile={gifter?.is_verified_profile}
            verifiedCaller={gifter?.verified_caller}
            levellingBadge={gifter?.levelling_profile?.badge}
            livestreamingAchievementBadge={
              gifter?.live_streaming_achievements?.chat_badges_set
            }
            profilePhoto={gifter?.profile_photo?.original_photo}
            publicPhotos={gifter?.public_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
              };
            })}
            privatePhotos={gifter?.private_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
                thumbnailData: photo?.thumbnail_data,
              };
            })}
            torTags={gifter?.tor_tags}
            profileAchievementBadge={
              gifter?.live_streaming_achievements?.profile_badges
            }
            aboutMe={gifter?.aboutme}
            lookingFor={gifter?.lookingfor}
            tagline={gifter?.tagline}
            occupation={gifter?.occupation?.occupation}
            lifestyle={gifter?.occupation?.lifestyle_budget}
            education={gifter?.occupation?.education}
            relationship={gifter?.relationship?.relationship_status}
            smoking={gifter?.habit?.smoking}
            drinking={gifter?.habit?.drinking}
            height={gifter?.appearance?.height}
            bodyType={gifter?.appearance?.body_type}
            ethnicity={gifter?.appearance?.ethnicity}
            eyeColor={gifter?.appearance?.eye_color}
            hairColor={gifter?.appearance?.hair_color}
            children={gifter?.relationship?.children}
            // User Utility
            shouldCallApi={false}
            privatePhotoAbleToRequest={
              gifter?.interaction?.private_photo_requested === false
                ? true
                : false
            }
            privatePhotoRequested={gifter?.interaction?.private_photo_requested}
            privatePhotoApproval={gifter?.interaction?.private_photo_approval}
            requestedMyPrivatePhoto={
              gifter?.interaction?.requested_my_private_photo
            }
            approvedMyPrivatePhoto={
              gifter?.interaction?.approved_my_private_photo
            }
            isBlocked={gifter?.interaction?.is_blocked}
            isSilentInteraction={
              gifter?.interaction?.should_silent_interactions
            }
            isFavorited={gifter?.interaction?.is_favorited}
            isCallEnabled={
              gifter?.private_call_settings?.enable_calls &&
              gifter?.private_call_settings?.system_private_calls_enabled
            }
            isSugarbaby={
              gifter?.role === userConst.userRole.sugarbaby ||
              gifter?.role === userConst.userRole.maleSugarbaby
            }
            role={gifter?.role}
            // Livestream
            channelId={gifter?.live_stream?.channel_id}
            livestreamerId={gifter?.id}
            followerCount={gifter?.live_streaming_meta?.follower_count}
            viewerCount={gifter?.live_stream?.viewer_count}
            diamondCount={gifter?.live_streaming_meta?.lifetime_diamonds_earned}
            isFollowing={gifter?.live_streaming_meta?.is_following}
            type={"gifters"}
            key={index}
          />
        ))}
      </div>
    );
  } else if (
    firstLoad &&
    (getLifetimeGiftersListFetching || getLifetimeGiftersListLoading)
  ) {
    giftersListView = <Spinner />;
  } else {
    return <EmptyList />;
  }

  return (
    <div id="follows-gifters-tab-subcomponent">
      <div className="gifters-list-container">
        <InfiniteScroll
          pageStart={0}
          loadMore={infiniteScrollFunc}
          hasMore={
            getLifetimeGiftersListData?.data?.pagination?.next_cursor
              ? true
              : false
          }
          loader={<Spinner key={0} />}
          useWindow={false}
        >
          {giftersListView}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default GiftersTab;
