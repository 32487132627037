// General
import "./incomplete-profile.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileCheckQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const IncompleteProfile = () => {
  // API variables
  const [
    getProfileCheck,
    {
      data: getProfileCheckData,
      error: getProfileCheckErrorData,
      isFetching: getProfileCheckFetching,
      isLoading: getProfileCheckLoading,
      isSuccess: getProfileCheckSuccess,
      isError: getProfileCheckError,
    },
  ] = useLazyGetProfileCheckQuery();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-7-7.3",
      },
    });

    getProfileCheck(null, false);
  }, []);

  // Render Functions | API Response
  if (getProfileCheckFetching || getProfileCheckLoading) {
  } else if (getProfileCheckSuccess && getProfileCheckData) {
    if (getProfileCheckData?.status === 0) {
      if (getProfileCheckData?.payload?.percent < 100) {
        return (
          <div
            id="search-incomplete-profile-subcomponent"
            onClick={() => onNavigate(routeConst.profile.edit.path)}
          >
            <div className="left-container">
              <div className="profile-icon-container">
                {getIcon("userProfileCompleteIcon", "profile-icon")}
              </div>
            </div>

            <div className="description-container">
              <div className="subheader">{t("signup.complete_profile")}</div>
              <div className="text">{t("signup.complete_profile_desc")}</div>
              <div className="complete-container">
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{
                      width: `${getProfileCheckData?.payload?.percent}%`,
                    }}
                  ></div>
                </div>

                <div className="percentage">
                  {getProfileCheckData?.payload?.percent}%
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  } else if (getProfileCheckError) {
    if (getProfileCheckErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default IncompleteProfile;
