// General
import "./advanced-filters.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import searchConst from "../../../../../const/searchConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import LifestyleFilterDialog from "../../../../shared/dialog-content/advanced-filters-dialog/lifestyle-filter-dialog/lifestyle-filter-dialog";
import BodyTypeFilterDialog from "../../../../shared/dialog-content/advanced-filters-dialog/body-type-filter-dialog/body-type-filter-dialog";
import EthnicityFilterDialog from "../../../../shared/dialog-content/advanced-filters-dialog/ethnicity-filter-dialog/ethnicity-filter-dialog";
import EyeColorFilterDialog from "../../../../shared/dialog-content/advanced-filters-dialog/eye-color-filter-dialog/eye-color-filter-dialog";
import HairColorFilterDialog from "../../../../shared/dialog-content/advanced-filters-dialog/hair-color-filter-dialog/hair-color-filter-dialog";
import SmokingFilterDialog from "../../../../shared/dialog-content/advanced-filters-dialog/smoking-filter-dialog/smoking-filter-dialog";
import DrinkingFilterDialog from "../../../../shared/dialog-content/advanced-filters-dialog/drinking-filter-dialog/drinking-filter-dialog";
import RelationshipStatusFilterDialog from "../../../../shared/dialog-content/advanced-filters-dialog/relationship-status-filter-dialog/relationship-status-filter-dialog";
import EducationFilterDialog from "../../../../shared/dialog-content/advanced-filters-dialog/education-filter-dialog/education-filter-dialog";
import {
  updateBodyTypeFilterDialog,
  updateDrinkingFilterDialog,
  updateEducationFilterDialog,
  updateEthnicityFilterDialog,
  updateEyeColorFilterDialog,
  updateHairColorFilterDialog,
  updateLifestyleFilterDialog,
  updateRelationshipStatusFilterDialog,
  updateSmokingFilterDialog,
} from "../../../../../redux/store/dialogStore";
import LifestyleFilter from "../../../elements/filters/lifestyle-filter/lifestyle-filter";
import BodyTypeFilter from "../../../elements/filters/body-type-filter/body-type-filter";
import EthnicityFilter from "../../../elements/filters/ethnicity-filter/ethnicity-filter";
import EyeColorFilter from "../../../elements/filters/eye-color-filter/eye-color-filter";
import HairColorFilter from "../../../elements/filters/hair-color-filter/hair-color-filter";
import SmokingFilter from "../../../elements/filters/smoking-filter/smoking-filter";
import DrinkingFilter from "../../../elements/filters/drinking-filter/drinking-filter";
import RelationshipStatusFilter from "../../../elements/filters/relationship-status-filter/relationship-status-filter";
import EducationFilter from "../../../elements/filters/education-filter/education-filter";
import PremiumLock from "../../../elements/premium-lock/premium-lock";

const AdvancedFilters = (props) => {
  const { type = searchConst.searchFilterType.dialog } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Constant variables
  const advancedFiltersList = searchConst.filters.advancedFilters;

  // General variables
  const [open, setOpen] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  // Render variables
  let [dialogView, setDialogView] = useState();

  // Redux variables
  const lifestyleSelectedCount = useSelector(
    (state) => state.searchFilter.lifestyleSelectedCount
  );
  const bodyTypeSelectedCount = useSelector(
    (state) => state.searchFilter.bodyTypeSelectedCount
  );
  const ethnicitySelectedCount = useSelector(
    (state) => state.searchFilter.ethnicitySelectedCount
  );
  const eyeColorSelectedCount = useSelector(
    (state) => state.searchFilter.eyeColorSelectedCount
  );
  const hairColorSelectedCount = useSelector(
    (state) => state.searchFilter.hairColorSelectedCount
  );
  const smokingSelectedCount = useSelector(
    (state) => state.searchFilter.smokingSelectedCount
  );
  const drinkingSelectedCount = useSelector(
    (state) => state.searchFilter.drinkingSelectedCount
  );
  const relationshipStatusSelectedCount = useSelector(
    (state) => state.searchFilter.relationshipStatusSelectedCount
  );
  const educationSelectedCount = useSelector(
    (state) => state.searchFilter.educationSelectedCount
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Event Handlers | MUI Dialog
  const onOpenDialog = (state, index) => {
    if (shouldShowPremiumLock()) return;

    // Control List View
    setOpen((prev) => {
      let temp = [...prev];
      temp[index] = !temp[index];
      return temp;
    });

    // Control Dialog View
    if (type === searchConst.searchFilterType.dialog) {
      switch (state) {
        case "lifestyle":
          setDialogView(<LifestyleFilterDialog />);
          dispatch(updateLifestyleFilterDialog(true));
          break;
        case "bodyType":
          setDialogView(<BodyTypeFilterDialog />);
          dispatch(updateBodyTypeFilterDialog(true));
          break;
        case "ethnicity":
          setDialogView(<EthnicityFilterDialog />);
          dispatch(updateEthnicityFilterDialog(true));
          break;
        case "eyeColor":
          setDialogView(<EyeColorFilterDialog />);
          dispatch(updateEyeColorFilterDialog(true));
          break;
        case "hairColor":
          setDialogView(<HairColorFilterDialog />);
          dispatch(updateHairColorFilterDialog(true));
          break;
        case "smoking":
          setDialogView(<SmokingFilterDialog />);
          dispatch(updateSmokingFilterDialog(true));
          break;
        case "drinking":
          setDialogView(<DrinkingFilterDialog />);
          dispatch(updateDrinkingFilterDialog(true));
          break;
        case "relationshipStatus":
          setDialogView(<RelationshipStatusFilterDialog />);
          dispatch(updateRelationshipStatusFilterDialog(true));
          break;
        case "education":
          setDialogView(<EducationFilterDialog />);
          dispatch(updateEducationFilterDialog(true));
          break;
        default:
          break;
      }
    }
  };

  // Utiltiy Functions
  const getAdvancedFilterSelectedCount = (state) => {
    switch (state) {
      case "lifestyle":
        return lifestyleSelectedCount;
      case "bodyType":
        return bodyTypeSelectedCount;
      case "ethnicity":
        return ethnicitySelectedCount;
      case "eyeColor":
        return eyeColorSelectedCount;
      case "hairColor":
        return hairColorSelectedCount;
      case "smoking":
        return smokingSelectedCount;
      case "drinking":
        return drinkingSelectedCount;
      case "relationshipStatus":
        return relationshipStatusSelectedCount;
      case "education":
        return educationSelectedCount;
      default:
        break;
    }
  };
  const getItemViewList = (state) => {
    switch (state) {
      case "lifestyle":
        return <LifestyleFilter type={type} enableTitle={false} />;
      case "bodyType":
        return <BodyTypeFilter type={type} enableTitle={false} />;
      case "ethnicity":
        return <EthnicityFilter type={type} enableTitle={false} />;
      case "eyeColor":
        return <EyeColorFilter type={type} enableTitle={false} />;
      case "hairColor":
        return <HairColorFilter type={type} enableTitle={false} />;
      case "smoking":
        return <SmokingFilter type={type} enableTitle={false} />;
      case "drinking":
        return <DrinkingFilter type={type} enableTitle={false} />;
      case "relationshipStatus":
        return <RelationshipStatusFilter type={type} enableTitle={false} />;
      case "education":
        return <EducationFilter type={type} enableTitle={false} />;
      default:
        break;
    }
  };
  const shouldShowPremiumLock = () => {
    if (
      isDaddy &&
      getProfileData?.data?.membership_type === userConst.membershipType.free
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div id="search-filter-advanced-filters-subcomponent">
      <div className="filter-padding-container">
        <div className="subheader">{t("filter.advanced_filters")}</div>

        <div className="buttons-container">
          {advancedFiltersList?.map((item, index) => (
            <div key={index}>
              <div
                className={`button-container ${
                  type === searchConst.searchFilterType.dialog
                    ? "dialog-button-height"
                    : "page-button-height"
                }`}
                onClick={() => onOpenDialog(item?.value, index)}
              >
                <div className="button-label-container">
                  <div className="button-label">{t(`${item?.jsonTitle}`)}</div>
                  {shouldShowPremiumLock() && <PremiumLock />}
                </div>

                <div className="icon-container">
                  {getAdvancedFilterSelectedCount(item?.value) > 0 && (
                    <div className="selected-label">
                      {t("common.n_selected", {
                        n: getAdvancedFilterSelectedCount(item?.value),
                      })}
                    </div>
                  )}
                  {open[index]
                    ? getIcon("keyboardArrowDownIcon", "icon")
                    : getIcon("chevronRightIcon", "icon")}
                </div>
              </div>

              {/* List View */}
              {type === searchConst.searchFilterType.full &&
                open[index] &&
                getItemViewList(item?.value)}
            </div>
          ))}
        </div>
      </div>

      {dialogView}
    </div>
  );
};

export default AdvancedFilters;
