// General
import "./upgrade-status.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  usePostPaymentTagMutation,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateUpgradeStatus } from "../../../redux/store/upgradeStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";

const UpgradeStatus = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    postPaymentTag,
    {
      data: postPaymentTagData,
      error: postPaymentTagErrorData,
      isLoading: postPaymentTagLoading,
      isSuccess: postPaymentTagSuccess,
      isError: postPaymentTagError,
    },
  ] = usePostPaymentTagMutation();

  // Redux variables
  const upgradeStatus = useSelector((state) => state.upgrade.upgradeStatus);
  const premiumSubscribeInfo = useSelector(
    (state) => state.upgrade.premiumSubscribeInfo
  );
  const paymentTag = useSelector((state) => state.payment.paymentTag);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted & Unmounted
  useEffect(() => {
    if (!isDetailValid()) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-56",
        },
      });

      onNavigate(routeConst.upgrade.path);
    }

    return () => {
      dispatch(updateUpgradeStatus(null));
    };
  }, []);

  // Lifecycle | Check for update | Payment Tag API Response
  useEffect(() => {
    if (postPaymentTagLoading) {
    } else if (postPaymentTagSuccess) {
      if (postPaymentTagData?.status >= 0) {
        onNavigate(routeConst.search.path);
      }
    } else if (postPaymentTagError) {
    }
  }, [postPaymentTagLoading, postPaymentTagSuccess, postPaymentTagError]);

  // Event Handlers | Button
  const onProceedToMain = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-56-To-56.1-Button",
      },
    });

    const obj = {
      payment_tag: paymentTag,
      trans_id: premiumSubscribeInfo?.transaction_reference,
    };

    postPaymentTag(obj);
  };
  const onProceedToUpgrade = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-56-To-56.2-Button",
      },
    });

    onNavigate(routeConst.upgrade.path);
  };

  // Utility Functions
  const isDetailValid = () => {
    if (upgradeStatus === "success") {
      if (premiumSubscribeInfo) {
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-Upgrade-Success", // Do not change this, it will affect GTM and Insider
            user_id: "",
            email: "",
            username: "",
            role: "",
            is_premium: true,
            signup_date: "",
            order_id: premiumSubscribeInfo?.transaction_reference,
            amount: premiumSubscribeInfo?.amount,
            currency: premiumSubscribeInfo?.currency,
            product_name: premiumSubscribeInfo?.package,
          },
        });

        return true;
      } else {
        return false;
      }
    } else if (upgradeStatus === "error") {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Upgrade-Error",
        },
      });

      return true;
    }
  };

  return (
    <div id="upgrade-status-page">
      <div className="max-width-container">
        {upgradeStatus === "success" && (
          <div className="padding-container upgrade-success">
            <div className="thank-you-label">
              {t("upgrade.thank_you_for_purchase")}
            </div>
            <div className="welcome-premium-label">
              {t("upgrade.welcome_to_premium")}
            </div>
            <div className="your-purchase-label">
              {t("upgrade.your_purchase")}
            </div>

            <div className="premium-container">
              <div className="icon-container">
                <div className="star-icon-container">
                  {getIcon("upgradeStarIcon", "star-icon")}
                </div>
              </div>

              <div className="premium-description">
                <div className="months-premium-label">
                  {premiumSubscribeInfo?.package}
                </div>
                <div className="total-billed">
                  {t("upgrade.total_bill_today", {
                    amount: `${premiumSubscribeInfo?.amount}${premiumSubscribeInfo?.currency}`,
                  })}
                </div>
              </div>
            </div>

            <div className="transaction-reference">
              {t("upgrade.transaction_reference")}:{" "}
              {premiumSubscribeInfo?.transaction_reference}
            </div>

            <div className="discrete-note">
              {t("upgrade.note")}: {premiumSubscribeInfo?.note}
            </div>

            <div className="continue-button" onClick={onProceedToMain}>
              {t("common.continue")}
            </div>
          </div>
        )}

        {upgradeStatus === "error" && (
          <div className="padding-container upgrade-error">
            <div className="payment-error-icon-container">
              {getIcon("paymentErrorAltIcon", "payment-error-icon")}
            </div>

            <div className="payment-unsuccessful-label">
              {t("upgrade.payment_unsuccessful")}
            </div>

            <div
              className="payment-error-text"
              dangerouslySetInnerHTML={{
                __html: t("upgrade.payment_unsuccessful_desc"),
              }}
            ></div>

            <div className="try-again-button" onClick={onProceedToUpgrade}>
              {t("upgrade.try_again")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpgradeStatus;
