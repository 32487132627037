// General
import "./empty-list.scss";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const EmptyList = (props) => {
  const { type } = props;

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  if (type === "casino") {
    return (
      <div id="leaderboard-section-casino-empty-list-subcomponent">
        <div className="casino-empty-list-title">
          {t("leaderboard.casino_empty_list_title")}
        </div>

        <div className="casino-empty-list-body">
          {t("leaderboard.casino_empty_list_body")}
        </div>
      </div>
    );
  } else {
    return (
      <div
        id="leaderboard-section-empty-list-subcomponent"
        className="empty-list-container"
      >
        <div className="message-icon-container">
          {getIcon("viewedMeOutlineIcon", "message-icon")}
        </div>
        <div className="no-message-text">
          {t("leaderboard.casino_empty_list")}
        </div>
      </div>
    );
  }
};

export default EmptyList;
