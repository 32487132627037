// General
import "./default-tag.scss";

const DefaultTag = (props) => {
  const { torName, blur, match } = props;

  return (
    <div
      id="tor-tag-default-tag-subcomponent"
      className={`${blur ? "blur" : ""} ${match ? "default-matched" : "default-unmatched"}`}
    >
      <div className="tor-name">{torName}</div>
    </div>
  );
};

export default DefaultTag;
