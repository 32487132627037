// General
import "./viewed-me-tab.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetViewedMeListQuery,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
import userConst from "../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateViewedMeLists,
  updateViewedMePagination,
  resetViewedMeLists,
} from "../../../redux/store/interestStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import UserList from "../../shared/interests/user-list/user-list";
import EmptyList from "../../shared/interests/empty-list/empty-list";
import Spinner from "../../shared/elements/spinner/spinner";

const ViewedMeTab = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getViewedMeList,
    {
      data: getViewedMeListData,
      error: getViewedMeListErrorData,
      isFetching: getViewedMeListFetching,
      isLoading: getViewedMeListLoading,
      isSuccess: getViewedMeListSuccess,
      isError: getViewedMeListError,
    },
  ] = useLazyGetViewedMeListQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);

  // Render variables
  let viewedMeListView = <div></div>;

  // Redux variables
  const viewedMeLists = useSelector((state) => state.interest.viewedMeLists);
  const viewedMePagination = useSelector(
    (state) => state.interest.viewedMePagination
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-9-Viewed-Me-Tab",
      },
    });

    getProfile(null, true);

    if (viewedMeLists?.length <= 0) {
      getViewedMeList(null, true);
    }
  }, []);

  // Lifecycle | Check for update | Viewed Me List API Response
  useEffect(() => {
    if (getViewedMeListFetching || getViewedMeListLoading) {
    } else if (getViewedMeListSuccess) {
      setFirstLoad(false);

      // Pagination
      dispatch(
        updateViewedMePagination(
          getViewedMeListData?.data?.pagination?.next_cursor
        )
      );

      // User List
      if (getViewedMeListData?.data?.results?.length > 0) {
        dispatch(updateViewedMeLists(getViewedMeListData?.data?.results));
      }
    } else if (getViewedMeListError) {
      if (getViewedMeListErrorData?.status == 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getViewedMeListFetching,
    getViewedMeListLoading,
    getViewedMeListSuccess,
    getViewedMeListError,
  ]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (!viewedMePagination) return;

    // Delay to allow User Meta to have a chance to load
    setTimeout(() => {
      getViewedMeList(`?pagination_token=${viewedMePagination}`, true);
    }, 1000);
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetViewedMeLists());
    dispatch(updateViewedMePagination(null));
    setFirstLoad(true);

    return new Promise((resolve) => {
      resolve(getViewedMeList(null, false));
    });
  };

  // Render Functions | Viewed Me Data
  if (viewedMeLists?.length > 0) {
    viewedMeListView = (
      <div className="infinite-scroller-container">
        {viewedMeLists?.map((user, index) => {
          return (
            <UserList
              // User View
              id={user?.user?.id}
              idInt={user?.user?.id_int}
              username={user?.user?.username}
              age={user?.user?.appearance?.age}
              location={
                user?.user?.location?.display_address ||
                user?.user?.location?.city ||
                user?.user?.location?.state
              }
              membershipType={user?.user?.membership_type}
              verifiedProfile={user?.user?.is_verified_profile}
              verifiedCaller={user?.user?.verified_caller}
              levellingBadge={user?.user?.levelling_profile?.badge}
              livestreamingAchievementBadge={
                user?.user?.live_streaming_achievements?.chat_badges_set
              }
              profilePhoto={user?.user?.profile_photo?.original_photo}
              profileDecorations={user?.user?.profile_decorations}
              publicPhotos={user?.user?.public_photos?.map((photo) => {
                return {
                  photoUrl: photo?.original_photo,
                };
              })}
              privatePhotos={user?.user?.private_photos.map((photo) => {
                return {
                  photoUrl: photo?.original_photo,
                };
              })}
              torTags={user?.user?.tor_tags}
              profileAchievementBadge={
                user?.user?.live_streaming_achievements?.profile_badges
              }
              aboutMe={user?.user?.aboutme}
              lookingFor={user?.user?.lookingfor}
              tagline={user?.user?.tagline}
              annualIncome={user?.user?.occupation?.annual_income}
              networth={user?.user?.occupation?.net_worth}
              occupation={user?.user?.occupation?.occupation}
              lifestyle={user?.user?.occupation?.lifestyle_budget}
              education={user?.user?.occupation?.education}
              relationship={user?.user?.relationship?.relationship_status}
              smoking={user?.user?.habit?.smoking}
              drinking={user?.user?.habit?.drinking}
              height={user?.user?.appearance?.height}
              bodyType={user?.user?.appearance?.body_type}
              ethnicity={user?.user?.appearance?.ethnicity}
              eyeColor={user?.user?.appearance?.eye_color}
              hairColor={user?.user?.appearance?.hair_color}
              children={user?.user?.relationship?.children}
              timestamp={user?.viewed_me_timestamp}
              isBlur={
                isDaddy &&
                getProfileData?.data?.membership_type ===
                  userConst.membershipType.free
              }
              // User Utility
              shouldCallApi={true}
              privatePhotoRequested={
                user?.user?.interaction?.private_photo_requested
              }
              privatePhotoApproval={
                user?.user?.interaction?.private_photo_approval
              }
              isBlocked={user?.user?.interaction?.is_blocked}
              isSilentInteraction={
                user?.user?.interaction?.should_silent_interactions
              }
              isFavorited={user?.user?.interaction?.is_favorited}
              isSugarbaby={isDaddy}
              role={user?.user?.role}
              key={index}
            />
          );
        })}
      </div>
    );
  } else if (
    firstLoad &&
    (getViewedMeListFetching || getViewedMeListFetching)
  ) {
    viewedMeListView = <Spinner />;
  } else {
    viewedMeListView = <EmptyList />;
  }

  return (
    <div id="interests-viewed-me-tab-subcomponent">
      <PullToRefresh
        className="pull-to-refresh-container"
        onRefresh={pullToRefreshFunc}
        refreshingContent={t("common.release")}
        pullingContent={t("common.pull_to_refresh")}
        isPullable={!getViewedMeListFetching && !getViewedMeListFetching}
      >
        <div className="viewed-me-list-container">
          <InfiniteScroll
            pageStart={0}
            loadMore={infiniteScrollFunc}
            hasMore={viewedMePagination ? true : false}
            loader={<Spinner key={0} />}
            useWindow={false}
          >
            {viewedMeListView}
          </InfiniteScroll>
        </div>
      </PullToRefresh>
    </div>
  );
};

export default ViewedMeTab;
