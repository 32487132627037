// General
import "./user-profile-dialog.scss";
import { useEffect, forwardRef } from "react";
import { useSearchParams } from "react-router-dom";
// Services
import {
  useLazyGetUserProfileQuery,
  useLazyGetSearchUserProfileQuery,
} from "../../../../services/data.service";
// Static Data
import userConst from "../../../../const/userConst";
import profileConst from "../../../../const/profileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateTagline,
  updateAnnualIncome,
  updateNetworth,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  updateShowLastActive,
  updateLastActive,
  updateShowMemberSince,
  updateMemberSince,

  // User Utility
  updateProfileStatus,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsOnline,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../redux/store/userCardStore";
import {
  updateUserProfileDialog,
  updateDialogSearchParam,
  resetDialogSearchParam,
  updateUserIsLiveDialog,
} from "../../../../redux/store/dialogStore";
import { updateUserIsLiveData } from "../../../../redux/store/livestreamingStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Divider, useMediaQuery } from "@mui/material";
// Moment
import moment from "moment";
// Custom Hooks
import useGetUserProfileStatus from "../../../utility/custom-hooks/useGetUserProfileStatus-hook";
// Components
import Spinner from "../../elements/spinner/spinner";
import ComponentNavbar from "../../elements/component-navbar/component-navbar";
import TopUserContainer from "../../../profile/view/top-user-container/top-user-container";
import BottomUserContainer from "../../../profile/view/bottom-user-container/bottom-user-container";
import PhotoInfo from "../../elements/profile/photo-info/photo-info";
import BasicInfo from "../../elements/profile/basic-info/basic-info";
import TermsOfRelationship from "../../elements/profile/terms-of-relationship/terms-of-relationship";
import ProfileAchievements from "../../elements/profile/achievements-info/achievements-info";
import AboutMeInfo from "../../elements/profile/about-me-info/about-me-info";
import LookingForInfo from "../../elements/profile/looking-for-info/looking-for-info";
import IndepthInfo from "../../elements/profile/indepth-info/indepth-info";
import ProfileActionButtons from "../../elements/profile/profile-action-buttons/profile-action-buttons";

const UserProfileDialog = () => {
  // API variables
  const [
    getUserProfile,
    {
      data: getUserProfileData,
      error: getUserProfileErrorData,
      isFetching: getUserProfileFetching,
      isLoading: getUserProfileLoading,
      isSuccess: getUserProfileSuccess,
      isError: getUserProfileError,
    },
  ] = useLazyGetUserProfileQuery();
  const [
    getSearchUserProfile,
    {
      data: getSearchUserProfileData,
      error: getSearchUserProfileErrorData,
      isFetching: getSearchUserProfileFetching,
      isLoading: getSearchUserProfileLoading,
      isSuccess: getSearchUserProfileSuccess,
      isError: getSearchUserProfileError,
    },
  ] = useLazyGetSearchUserProfileQuery();

  // Redux variables
  const userProfileDialog = useSelector(
    (state) => state.dialog.userProfileDialog
  );
  const id = useSelector((state) => state.userCard.id);
  const idInt = useSelector((state) => state.userCard.idInt);
  const torTags = useSelector((state) => state.userCard.torTags);
  const shouldCallApi = useSelector((state) => state.userCard.shouldCallApi);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Router variables
  const [searchParams, setSearchParams] = useSearchParams();

  // Custom Hooks Functions
  const getProfileStatus = useGetUserProfileStatus();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!userProfileDialog || !id || !shouldCallApi) return;

    getUserProfile(id);
    // getSearchUserProfile({ user_id: idInt }); // Deprecated
  }, [userProfileDialog, shouldCallApi]);

  // Lifecycle | Mounted
  useEffect(() => {
    if (userProfileDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-UserProfile-Dialog",
        },
      });

      // Testing
      // dispatch(
      //   updateDialogSearchParam({
      //     key: "userProfileDialog=true",
      //     value: true,
      //   })
      // );
    } else {
      // Testing
      // dispatch(
      //   updateDialogSearchParam({
      //     key: "userProfileDialog=true",
      //     value: false,
      //   })
      // );
    }
  }, [userProfileDialog]);

  // Lifecycle | Check for update | Get User Profile API Response
  useEffect(() => {
    if (getUserProfileFetching || getUserProfileLoading) {
    } else if (getUserProfileSuccess) {
      switch (getUserProfileData?.status) {
        case 0:
        case 1:
          dispatch(updateId(getUserProfileData?.data?.id));
          dispatch(updateIdInt(getUserProfileData?.data?.id_int));
          dispatch(updateUsername(getUserProfileData?.data?.username));
          dispatch(updateAge(getUserProfileData?.data?.appearance?.age));
          dispatch(updateLocation(getUserProfileData?.data?.location?.city));
          dispatch(
            updateMembershipType(getUserProfileData?.data?.membership_type)
          );
          dispatch(
            updateVerifiedProfile(getUserProfileData?.data?.is_verified_profile)
          );
          dispatch(
            updateVerifiedCaller(getUserProfileData?.data?.verified_caller)
          );
          dispatch(
            updateLevellingBadge(
              getUserProfileData?.data?.levelling_profile?.badge
            )
          );
          dispatch(
            updateLivestreamingAchievementBadge(
              getUserProfileData?.data?.live_streaming_achievements
                ?.chat_badges_set
            )
          );
          dispatch(
            updateProfilePhoto(
              getUserProfileData?.data?.profile_photo?.photo_status ===
                "REJECTED"
                ? null
                : getUserProfileData?.data?.profile_photo?.original_photo
            )
          );
          dispatch(
            updatePublicPhotos(
              getUserProfileData?.data?.public_photos?.map((photo) => {
                return {
                  photoUrl: photo?.original_photo,
                };
              })
            )
          );
          dispatch(
            updatePrivatePhotos(
              getUserProfileData?.data?.private_photos?.map((photo) => {
                return {
                  photoUrl: photo?.original_photo,
                  thumbnailData: photo?.thumbnail_data,
                };
              })
            )
          );
          dispatch(updateTorTags(getUserProfileData?.data?.tor_tags));
          dispatch(
            updateProfileAchievementBadge(
              getUserProfileData?.data?.live_streaming_achievements
                ?.profile_badges
            )
          );
          dispatch(updateAboutMe(getUserProfileData?.data?.aboutme));
          dispatch(updateLookingFor(getUserProfileData?.data?.lookingfor));
          dispatch(updateTagline(getUserProfileData?.data?.tagline));
          dispatch(
            updateAnnualIncome(
              getUserProfileData?.data?.occupation?.annual_income
            )
          );
          dispatch(
            updateNetworth(getUserProfileData?.data?.occupation?.net_worth)
          );
          dispatch(
            updateOccupation(getUserProfileData?.data?.occupation?.occupation)
          );
          dispatch(
            updateLifestyle(
              getUserProfileData?.data?.occupation?.lifestyle_budget
            )
          );
          dispatch(
            updateEducation(getUserProfileData?.data?.occupation?.education)
          );
          dispatch(
            updateRelationship(
              getUserProfileData?.data?.relationship?.relationship_status
            )
          );
          dispatch(updateSmoking(getUserProfileData?.data?.habit?.smoking));
          dispatch(updateDrinking(getUserProfileData?.data?.habit?.drinking));
          dispatch(updateHeight(getUserProfileData?.data?.appearance?.height));
          dispatch(
            updateBodyType(getUserProfileData?.data?.appearance?.body_type)
          );
          dispatch(
            updateEthnicity(getUserProfileData?.data?.appearance?.ethnicity)
          );
          dispatch(
            updateEyeColor(getUserProfileData?.data?.appearance?.eye_color)
          );
          dispatch(
            updateHairColor(getUserProfileData?.data?.appearance?.hair_color)
          );
          dispatch(
            updateChildren(getUserProfileData?.data?.relationship?.children)
          );
          dispatch(
            updateShowLastActive(
              getUserProfileData?.data?.activity?.activity_display
            )
          );
          dispatch(
            updateLastActive(
              getUserProfileData?.data?.activity?.last_seen_timestamp
            )
          );
          dispatch(
            updateShowMemberSince(
              getUserProfileData?.data?.activity?.join_date_display
            )
          );
          dispatch(
            updateMemberSince(
              getUserProfileData?.data?.activity?.joined_timestamp
            )
          );
          dispatch(
            updateProfileStatus(getProfileStatus(getUserProfileData?.data))
          );
          dispatch(
            updatePrivatePhotoRequested(
              getUserProfileData?.data?.interaction?.private_photo_requested
            )
          );
          dispatch(
            updatePrivatePhotoApproval(
              getUserProfileData?.data?.interaction?.private_photo_approval
            )
          );
          dispatch(
            updateRequestedMyPrivatePhoto(
              getUserProfileData?.data?.interaction?.requested_my_private_photo
            )
          );
          dispatch(
            updateApprovedMyPrivatePhoto(
              getUserProfileData?.data?.interaction?.approved_my_private_photo
            )
          );
          dispatch(
            updateIsOnline(getUserProfileData?.data?.activity?.is_online)
          );
          dispatch(
            updateIsBlocked(
              getUserProfileData?.data?.interaction?.is_blocked ? true : false
            )
          );
          dispatch(
            updateIsSilentInteraction(
              getUserProfileData?.data?.interaction?.should_silent_interactions
            )
          );
          dispatch(
            updateIsFavorited(
              getUserProfileData?.data?.interaction?.is_favorited
            )
          );
          dispatch(
            updateIsCallEnabled(
              getUserProfileData?.data?.private_call_settings?.enable_calls &&
                getUserProfileData?.data?.private_call_settings
                  ?.system_private_calls_enabled
            )
          );
          dispatch(
            updateIsSugarbaby(
              getUserProfileData?.data?.role?.toUpperCase() ===
                userConst.userRole.sugarbaby ||
                getUserProfileData?.data?.role?.toUpperCase() ===
                  userConst.userRole.maleSugarbaby
            )
          );
          dispatch(updateRole(getUserProfileData?.data?.role));

          // Not needed in dialog
          // if (getUserProfileData?.data?.live_stream?.live_status === "live") {
          //   const liveObj = {
          //     id: getUserProfileData?.data?.id,
          //     idInt: getUserProfileData?.data?.id_int,
          //     username: getUserProfileData?.data?.username,
          //     channelId: getUserProfileData?.data?.live_stream?.channel_id,
          //   };
          //   dispatch(updateUserIsLiveData(liveObj));
          //   dispatch(updateUserIsLiveDialog(true));
          // }
          break;
        case -1:
          break;
        default:
          break;
      }
    } else if (getUserProfileError) {
      dispatch(updateUserProfileDialog(false));
    }
  }, [
    getUserProfileFetching,
    getUserProfileLoading,
    getUserProfileSuccess,
    getUserProfileError,
  ]);

  // Lifecycle | Check for update | Get Search User Profile API Response | Deprecated
  useEffect(() => {
    if (getSearchUserProfileFetching || getSearchUserProfileLoading) {
    } else if (getSearchUserProfileSuccess) {
      switch (getSearchUserProfileData?.status) {
        case 0:
          dispatch(
            updateId(
              getSearchUserProfileData?.payload?.message?.livestream_metadata
                ?.hashed_user_id
            )
          );
          dispatch(
            updateIdInt(getSearchUserProfileData?.payload?.message?.profile?.id)
          );
          dispatch(
            updateUsername(
              getSearchUserProfileData?.payload?.message?.profile?.username
            )
          );
          dispatch(
            updateAge(
              calculateAge(
                getSearchUserProfileData?.payload?.message?.profile?.birthyear,
                getSearchUserProfileData?.payload?.message?.profile?.birthmonth,
                getSearchUserProfileData?.payload?.message?.profile?.birthday
              )
            )
          );
          dispatch(
            updateLocation(
              getSearchUserProfileData?.payload?.message?.location
                ?.currentcity ||
                getSearchUserProfileData?.payload?.message?.location
                  ?.currentstate
            )
          );
          dispatch(
            updateMembershipType(
              getSearchUserProfileData?.payload?.message?.profile
                ?.membershiptype
            )
          );
          dispatch(
            updateVerifiedProfile(
              getSearchUserProfileData?.payload?.message?.is_verified
            )
          );
          dispatch(
            updateVerifiedCaller(
              getSearchUserProfileData?.payload?.message?.profile
                ?.verified_caller
            )
          );
          dispatch(
            updateLevellingBadge(
              getSearchUserProfileData?.payload?.message?.levelling_profile
                ?.badge
            )
          );
          dispatch(
            updateLivestreamingAchievementBadge(
              getSearchUserProfileData?.payload?.message
                ?.live_streaming_achievements?.chat_badges_set
            )
          );
          dispatch(
            updateProfilePhoto(
              getSearchUserProfileData?.payload?.message?.profile
                ?.profilephoto_opt_fullpath
            )
          );
          dispatch(
            updatePublicPhotos(
              getSearchUserProfileData?.payload?.message?.publicphotos?.map(
                (photo) => {
                  return {
                    photoUrl: photo?.photomainid_full,
                  };
                }
              )
            )
          );
          dispatch(
            updatePrivatePhotos(
              getSearchUserProfileData?.payload?.message?.privatephotos?.map(
                (photo) => {
                  return {
                    photoUrl: photo?.photomainid_full,
                  };
                }
              )
            )
          );
          dispatch(
            updateTorTags(getSearchUserProfileData?.payload?.message?.tor_tags)
          );
          dispatch(
            updateProfileAchievementBadge(
              getSearchUserProfileData?.payload?.message
                ?.live_streaming_achievements?.profile_badges
            )
          );
          dispatch(
            updateAboutMe(
              getSearchUserProfileData?.payload?.message?.profile?.aboutme
            )
          );
          dispatch(
            updateLookingFor(
              getSearchUserProfileData?.payload?.message?.profile
                ?.lookingfordetails
            )
          );
          dispatch(
            updateAnnualIncome(
              getSearchUserProfileData?.payload?.message?.profile?.annualincome
            )
          );
          dispatch(
            updateNetworth(
              getSearchUserProfileData?.payload?.message?.profile?.networth
            )
          );
          dispatch(
            updateOccupation(
              getSearchUserProfileData?.payload?.message?.profiledetails
                ?.occupation
            )
          );
          dispatch(
            updateLifestyle(
              getSearchUserProfileData?.payload?.message?.profile?.lifestyle
            )
          );
          dispatch(
            updateEducation(
              getSearchUserProfileData?.payload?.message?.profiledetails
                ?.education
            )
          );
          dispatch(
            updateRelationship(
              getSearchUserProfileData?.payload?.message?.profiledetails
                ?.relationship
            )
          );
          dispatch(
            updateSmoking(
              getSearchUserProfileData?.payload?.message?.profiledetails
                ?.smoking
            )
          );
          dispatch(
            updateDrinking(
              getSearchUserProfileData?.payload?.message?.profiledetails
                ?.drinking
            )
          );
          dispatch(
            updateHeight(
              getSearchUserProfileData?.payload?.message?.profiledetails?.height
            )
          );
          dispatch(
            updateBodyType(
              getSearchUserProfileData?.payload?.message?.profiledetails
                ?.bodytype
            )
          );
          dispatch(
            updateEthnicity(
              getSearchUserProfileData?.payload?.message?.profiledetails
                ?.ethnicity
            )
          );
          dispatch(
            updateEyeColor(
              getSearchUserProfileData?.payload?.message?.profiledetails
                ?.eyecolor
            )
          );
          dispatch(
            updateHairColor(
              getSearchUserProfileData?.payload?.message?.profiledetails
                ?.haircolor
            )
          );
          dispatch(
            updateChildren(
              getSearchUserProfileData?.payload?.message?.profiledetails
                ?.children
            )
          );
          dispatch(
            updateLastActive(
              getSearchUserProfileData?.payload?.message?.activity?.lastloggedin
            )
          );
          dispatch(
            updateMemberSince(
              getSearchUserProfileData?.payload?.message?.activity?.created_at
            )
          );
          dispatch(
            updatePrivatePhotoRequested(
              getSearchUserProfileData?.payload?.message?.alreadyrequested === 1
                ? true
                : false
            )
          );
          dispatch(
            updatePrivatePhotoApproval(
              getSearchUserProfileData?.payload?.message
                ?.privatephotopermission === 1
                ? true
                : false
            )
          );
          // dispatch(updateRequestedMyPrivatePhoto());
          // dispatch(updateApprovedMyPrivatePhoto());
          dispatch(
            updateIsOnline(getSearchUserProfileData?.payload?.message?.online)
          );
          dispatch(
            updateIsBlocked(
              getSearchUserProfileData?.payload?.message?.is_blocked
                ? true
                : false
            )
          );
          // dispatch(updateIsSilentInteraction());
          dispatch(
            updateIsFavorited(
              getSearchUserProfileData?.payload?.message?.profile
                ?.profilefavorited === 1
                ? true
                : false
            )
          );
          dispatch(
            updateIsCallEnabled(
              getSearchUserProfileData?.payload?.message?.private_calls_metadata
                ?.call_settings?.enable_calls &&
                getSearchUserProfileData?.payload?.message
                  ?.private_calls_metadata?.call_settings
                  ?.system_private_calls_enabled
            )
          );
          dispatch(
            updateIsSugarbaby(
              getSearchUserProfileData?.payload?.message?.role?.toUpperCase() ===
                userConst.userRole.sugarbaby ||
                getSearchUserProfileData?.payload?.message?.role?.toUpperCase() ===
                  userConst.userRole.maleSugarbaby
            )
          );
          dispatch(
            updateRole(getSearchUserProfileData?.payload?.message?.role)
          );
          break;
        case -1:
          onCloseDialog();
          break;
        default:
          break;
      }
    } else if (getSearchUserProfileError) {
    }
  }, [
    getSearchUserProfileFetching,
    getSearchUserProfileLoading,
    getSearchUserProfileSuccess,
    getSearchUserProfileError,
  ]);

  // Lifecycle | Check for update | searchParams |Testing
  // useEffect(() => {
  //   if (searchParams?.get("userProfileDialog") === "true") return;

  //   dispatch(updateUserProfileDialog(false));
  // }, [searchParams]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateUserProfileDialog(false));
  };

  // Utility Functions
  const calculateAge = (birthYear, birthMonth, birthDate) => {
    const birth = moment(
      `${birthYear}-${birthMonth}-${birthDate}`,
      "YYYY-MM-DD"
    );
    const now = moment();
    const age = now.diff(birth, "years");
    return age;
  };

  return (
    <Dialog
      className="custom-top-radius20-dialog custom-dialog-margin-top"
      fullScreen
      open={userProfileDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <ComponentNavbar userProfileType={profileConst.userProfileType.dialog} />

      {getSearchUserProfileFetching || getSearchUserProfileLoading ? (
        <Spinner />
      ) : getSearchUserProfileSuccess || !shouldCallApi ? (
        <div id="user-profile-dialog">
          <div
            className={`max-width-container ${
              isMobile ? "" : "profile-padding-container"
            }`}
          >
            {!isMobile && (
              <div className={`desktop-view profile-padding-container`}>
                <TopUserContainer
                  userProfileType={profileConst.userProfileType.dialog}
                />

                <BottomUserContainer
                  userProfileType={profileConst.userProfileType.dialog}
                />
              </div>
            )}

            {isMobile && (
              <div className="mobile-view">
                <PhotoInfo />

                <BasicInfo />

                <Divider />

                <TermsOfRelationship enableTitle={true} />

                <ProfileAchievements />

                <Divider />

                <AboutMeInfo />

                <LookingForInfo />

                <IndepthInfo />

                <ProfileActionButtons
                  userProfileType={profileConst.userProfileType.dialog}
                />
              </div>
            )}
          </div>
        </div>
      ) : null}
    </Dialog>
  );
};

export default UserProfileDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
