// General
import "./tor-filter.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetTorListQuery,
  useLazyGetProfileQuery,
} from "../../../../../services/data.service";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateTor } from "../../../../../redux/store/searchFilterStore";
import { updateTorList } from "../../../../../redux/store/searchStore";
import {
  updateTorFilterDialog,
  updateFullSugarExperienceDialog,
} from "../../../../../redux/store/dialogStore";
// Material UI
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../spinner/spinner";
import PremiumLock from "../../premium-lock/premium-lock";
import TorTag from "../../tor-tag/tor-tag";

const TorFilter = (props) => {
  const {
    type,
    filtered = false,
    enableTitle = true,
    enableCloseButton = false,
    maxTag = 5,
    enableSeeAllButton = true,
    enableTorDescription = false,
    enableDoneButton = false,
  } = props;

  // API variables
  const [
    getTorList,
    {
      data: getTorListData,
      error: getTorListErrorData,
      isFetching: getTorListFetching,
      isLoading: getTorListLoading,
      isSuccess: getTorListSuccess,
      isError: getTorListError,
    },
  ] = useLazyGetTorListQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const tor = useSelector((state) => state.searchFilter.tor);
  const torList = useSelector((state) => state.search.torList);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // General variables
  const [tagDetails, setTagDetails] = useState(
    t("profile.tor_tap_to_see_detail")
  );

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);

    if (torList?.length > 0) return;

    getTorList(null, true);
  }, []);

  // Lifecycle | Check for update | Tor List API Response
  useEffect(() => {
    if (getTorListFetching || getTorListLoading) {
    } else if (getTorListSuccess) {
      switch (getTorListData?.status) {
        case 0:
          let torList = [];

          for (let i = 0; i < getTorListData?.data?.results?.length; i++) {
            torList.push({
              id: getTorListData?.data?.results[i]?.id,
              locale: getTorListData?.data?.results[i]?.locale,
              description: getTorListData?.data?.results[i]?.description,
              blurred_out: getTorListData?.data?.results[i]?.blurred_out,
              selected: false,
              theme: getTorListData?.data?.results[i]?.theme,
            });
          }

          dispatch(updateTorList(torList));
          break;
        case 1:
          break;
        default:
          break;
      }
    } else if (getTorListError) {
    }
  }, [
    getTorListFetching,
    getTorListLoading,
    getTorListSuccess,
    getTorListError,
  ]);

  // Event Handlers | Button
  const onSeeAllTor = () => {
    dispatch(updateTorFilterDialog(true));
  };
  const onSelectTag = (tag) => {
    if (
      isDaddy &&
      getProfileData?.data?.membership_type === userConst.membershipType.free
    ) {
      dispatch(updateFullSugarExperienceDialog(true));
    } else {
      // Update tag details
      setTagDetails(tag?.description);

      // Check if the tag is already in the 'tor' list
      const isTagInTor = tor.includes(tag?.id);

      // Update 'tor' list based on conditions
      if (isTagInTor) {
        // Remove the tag from 'tor' list
        dispatch(updateTor(tor.filter((item) => item !== tag.id)));
      } else {
        // Add the tag to 'tor' list if its length is less than 5
        if (tor?.length < 5) {
          dispatch(updateTor([...tor, tag.id]));
        }
      }

      // Clone the 'torList' array to avoid direct mutations
      const newTorList = torList.map((d) => ({ ...d }));

      // Find the corresponding item in 'newTorList'
      const foundItem = newTorList.find((d) => d.id === tag.id);

      // Update the 'selected' property of the found item based on conditions
      if (foundItem) {
        foundItem.selected = tor?.length < 5 ? !foundItem.selected : false;
      }

      // Update 'torList' with the modified array
      dispatch(updateTorList(newTorList));
    }
  };
  const onCloseDialog = () => {
    dispatch(updateTorFilterDialog(false));
  };

  // Utility Functions
  const getFilteredTorList = () => {
    let filteredTorList = torList?.map((d) => ({ ...d }));

    return filteredTorList.sort((a, b) => {
      if (a.selected && !b.selected) return -1;
      if (!a.selected && b.selected) return 1;
      return 0;
    });
  };
  const shouldShowPremiumLock = () => {
    if (
      isDaddy &&
      getProfileData?.data?.membership_type === userConst.membershipType.free
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (getProfileFetching || getProfileLoading) {
    return <Spinner size={30} />;
  } else if (getProfileSuccess) {
    return (
      <div id="tor-filter-shared-component">
        <div className="dialog-header">
          <div className="subheader">
            {t("filter.terms_of_relationship")}
            <span className="new-label">{t("common.new")}</span>
          </div>

          {enableCloseButton && (
            <div className="close-button-container">
              <CloseIcon className="close-button" onClick={onCloseDialog} />
            </div>
          )}
        </div>

        <div className="filter-padding-container">
          <div className="select-tor-description">
            <Trans
              i18nKey={"search.select_terms"}
              values={{ maxTor: 5, selectedTor: tor?.length }}
              components={{
                span: (
                  <span
                    className={tor?.length >= 5 ? "max-tor-color" : ""}
                  ></span>
                ),
              }}
            />
          </div>

          <div className="tags-container">
            {/* Raw List from API */}
            {!filtered &&
              torList?.map((tag, index) => {
                if (index < maxTag) {
                  return (
                    <div
                      className="tag-container"
                      onClick={() => onSelectTag(tag)}
                      key={index}
                    >
                      <TorTag
                        torName={tag?.locale}
                        description={tag?.description}
                        blur={index !== 0 && shouldShowPremiumLock()}
                        match={tag?.selected ? true : false}
                        interactable={true}
                        readOnly={true}
                        showToolTip={false}
                        theme={tag?.theme}
                        key={index}
                      />
                      {index !== 0 && shouldShowPremiumLock() && (
                        <div className="premium-lock-container">
                          <PremiumLock />
                        </div>
                      )}
                    </div>
                  );
                }
              })}

            {/* Filtered List from Raw List */}
            {filtered &&
              getFilteredTorList()?.map((tag, index) => {
                if (index < maxTag) {
                  return (
                    <div
                      className="tag-container"
                      onClick={() => onSelectTag(tag)}
                      key={index}
                    >
                      <TorTag
                        torName={tag?.locale}
                        description={tag?.description}
                        blur={index !== 0 && shouldShowPremiumLock()}
                        match={tag?.selected ? true : false}
                        interactable={true}
                        readOnly={true}
                        showToolTip={false}
                        theme={tag?.theme}
                        key={index}
                      />
                      {index !== 0 && shouldShowPremiumLock() && (
                        <div className="premium-lock-container">
                          <PremiumLock />
                        </div>
                      )}
                    </div>
                  );
                }
              })}
          </div>

          {enableSeeAllButton && (
            <div className="see-all-terms-container">
              <div className="see-all-terms-label" onClick={onSeeAllTor}>
                {t("filter.see_all_terms")}
              </div>
              <ChevronRightIcon className="right-icon" />
            </div>
          )}

          {enableTorDescription && tagDetails && (
            <div className="tag-details">
              <Trans i18nKey={tagDetails} components={{ b: <b /> }} />
            </div>
          )}

          {false && enableDoneButton && (
            <div className="done-button" onClick={onCloseDialog}>
              {t("common.done")}
            </div>
          )}
        </div>
      </div>
    );
  } else if (getProfileError) {
  }
};

export default TorFilter;
