// General
import "./financial-information.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetAioQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Static Data
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLifestyle,
  updateNetworth,
  updateAnnualIncome,
} from "../../../../../redux/store/editProfileStore";
import { updateSuccessToast } from "../../../../../redux/store/toastStore";
// Material UI
import { Divider, MenuItem, FormControl, Select } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import ModerationStatus from "../../moderation-status/moderation-status";

const FinancialInformation = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // Redux variables
  const lifestyle = useSelector((state) => state.editProfile.lifestyle);
  const networth = useSelector((state) => state.editProfile.networth);
  const annualIncome = useSelector((state) => state.editProfile.annualIncome);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getAio(null, true);
  }, []);

  // Lifecycle | Check for update | Update User Profile API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      // Backend returning empty if success
      const toastObj = {
        message: t("signup.profile_updated"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));
    } else if (updateUserProfileError) {
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    if (!isFinancialInformationValid()) return;

    let obj = {};

    if (isDaddy) {
      obj = {
        lifestyle_budget: lifestyle,
        net_worth: networth?.replace(/,/g, ""),
        annual_income: annualIncome?.replace(/,/g, ""),
      };
    } else {
      obj = {
        lifestyle_budget: lifestyle,
      };
    }

    updateUserProfile(obj);
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "lifestyle":
        dispatch(updateLifestyle(event.target.value));
        break;
      case "networth":
        dispatch(updateNetworth(event.target.value));
        break;
      case "annual-income":
        dispatch(updateAnnualIncome(event.target.value));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isFinancialInformationValid = () => {
    if (isDaddy) {
      if (
        lifestyle &&
        annualIncome &&
        annualIncome !== "" &&
        networth &&
        networth !== ""
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (lifestyle) {
        return true;
      } else {
        return false;
      }
    }
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-financial-information-subcomponent">
        <div className="financial-information-label-container">
          <div className="financial-information-label">
            {t("profile.financial_information")}
          </div>

          <div className="expand-icon-container">
            {getIcon("chevronRightIcon", "expand-icon")}
          </div>
        </div>

        <div className="form-field-padding-container">
          {/* Lifestyle */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${lifestyle ? "" : "incomplete-field"}`}>
                {t("profile.lifestyle")}
              </div>
            </div>

            <div className="form-container">
              <FormControl className="form-field" variant="standard" fullWidth>
                <Select
                  className="form-select"
                  displayEmpty
                  value={lifestyle || ""}
                  onChange={onInputChange("lifestyle")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add lifestyle
                        </div>
                      );
                    }

                    const selectedLifestyle =
                      getAioData?.payload?.dropdown?.lifestyle?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedLifestyle?.name || (
                        <div className="default-select-value">
                          + Add lifestyle
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add lifestyle
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.lifestyle?.map(
                    (lifestyle, index) => (
                      <MenuItem key={index} value={lifestyle?.value}>
                        {t(`${lifestyle?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Net Worth */}
          {isDaddy && (
            <div className="form-field-container">
              <div className="label-container">
                <div className={`label ${networth ? "" : "incomplete-field"}`}>
                  {t("profile.net_worth")}
                </div>
              </div>

              <div className="form-container">
                <FormControl
                  className="form-field"
                  variant="standard"
                  fullWidth
                >
                  <Select
                    className="form-select"
                    displayEmpty
                    value={networth || ""}
                    onChange={onInputChange("networth")}
                    disableUnderline={true}
                    renderValue={(selected) => {
                      if (!selected || selected === "") {
                        return (
                          <div className="default-select-value">
                            + Add net worth
                          </div>
                        );
                      }

                      const selectedNetworth =
                        getAioData?.payload?.dropdown?.networth?.find(
                          (item) => item.value === selected
                        );

                      return (
                        selectedNetworth?.name || (
                          <div className="default-select-value">
                            + Add net worth
                          </div>
                        )
                      );
                    }}
                  >
                    <MenuItem disabled value="">
                      + Add net worth
                    </MenuItem>
                    {getAioData?.payload?.dropdown?.networth?.map(
                      (networth, index) => (
                        <MenuItem key={index} value={networth?.value}>
                          {t(`${networth.name}`)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}

          {/* Annual Income */}
          {isDaddy && (
            <div className="form-field-container">
              <div className="label-container">
                <div
                  className={`label ${annualIncome ? "" : "incomplete-field"}`}
                >
                  {t("profile.annual_income")}
                </div>
              </div>

              <div className="form-container">
                <FormControl
                  className="form-field"
                  variant="standard"
                  fullWidth
                >
                  <Select
                    className="form-select"
                    displayEmpty
                    value={annualIncome || ""}
                    onChange={onInputChange("annual-income")}
                    disableUnderline={true}
                    renderValue={(selected) => {
                      if (!selected || selected === "") {
                        return (
                          <div className="default-select-value">
                            + Add annual income
                          </div>
                        );
                      }

                      const selectedAnnualIncome =
                        getAioData?.payload?.dropdown?.annual_income?.find(
                          (item) => item.value === selected
                        );

                      return (
                        selectedAnnualIncome?.name || (
                          <div className="default-select-value">
                            + Add annual income
                          </div>
                        )
                      );
                    }}
                  >
                    <MenuItem disabled value="">
                      + Add annual income
                    </MenuItem>
                    {getAioData?.payload?.dropdown?.annual_income?.map(
                      (income, index) => (
                        <MenuItem key={index} value={income?.value}>
                          {t(`${income?.name}`)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div id="desktop-edit-profile-financial-information-subcomponent">
        {/* In Review */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.inReview}
        />

        {/* Rejected */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.rejected}
        />

        <div className="financial-information-static-label-container">
          <div className="financial-information-label">
            {t("profile.financial_information")}
          </div>
          <Divider className="short-divider" />
        </div>

        <div className="form-field-padding-container">
          {/* Lifestyle */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${lifestyle ? "" : "incomplete-field"}`}>
                {t("profile.lifestyle")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={lifestyle || ""}
                  onChange={onInputChange("lifestyle")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add lifestyle
                        </div>
                      );
                    }

                    const selectedLifestyle =
                      getAioData?.payload?.dropdown?.lifestyle?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedLifestyle?.name || (
                        <div className="default-select-value">
                          + Add lifestyle
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add lifestyle
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.lifestyle?.map(
                    (lifestyle, index) => (
                      <MenuItem key={index} value={lifestyle?.value}>
                        {t(`${lifestyle?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Net Worth */}
          {isDaddy && (
            <div className="form-field-container">
              <div className="label-container">
                <div className={`label ${networth ? "" : "incomplete-field"}`}>
                  {t("profile.net_worth")}
                </div>
              </div>

              <div className="form-container">
                <FormControl
                  className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    className="form-select"
                    displayEmpty
                    value={networth || ""}
                    onChange={onInputChange("networth")}
                    disableUnderline={true}
                    renderValue={(selected) => {
                      if (!selected || selected === "") {
                        return (
                          <div className="default-select-value">
                            + Add net worth
                          </div>
                        );
                      }

                      const selectedNetworth =
                        getAioData?.payload?.dropdown?.networth?.find(
                          (item) => item.value === selected
                        );

                      return (
                        selectedNetworth?.name || (
                          <div className="default-select-value">
                            + Add net worth
                          </div>
                        )
                      );
                    }}
                  >
                    <MenuItem disabled value="">
                      + Add net worth
                    </MenuItem>
                    {getAioData?.payload?.dropdown?.networth?.map(
                      (networth, index) => (
                        <MenuItem key={index} value={networth?.value}>
                          {t(`${networth.name}`)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}

          {/* Annual Income */}
          {isDaddy && (
            <div className="form-field-container">
              <div className="label-container">
                <div
                  className={`label ${annualIncome ? "" : "incomplete-field"}`}
                >
                  {t("profile.annual_income")}
                </div>
              </div>

              <div className="form-container">
                <FormControl
                  className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    className="form-select"
                    displayEmpty
                    value={annualIncome || ""}
                    onChange={onInputChange("annual-income")}
                    disableUnderline={true}
                    renderValue={(selected) => {
                      if (!selected || selected === "") {
                        return (
                          <div className="default-select-value">
                            + Add annual income
                          </div>
                        );
                      }

                      const selectedAnnualIncome =
                        getAioData?.payload?.dropdown?.annual_income?.find(
                          (item) => item.value === selected
                        );

                      return (
                        selectedAnnualIncome?.name || (
                          <div className="default-select-value">
                            + Add annual income
                          </div>
                        )
                      );
                    }}
                  >
                    <MenuItem disabled value="">
                      + Add annual income
                    </MenuItem>
                    {getAioData?.payload?.dropdown?.annual_income?.map(
                      (income, index) => (
                        <MenuItem key={index} value={income?.value}>
                          {t(`${income?.name}`)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        </div>

        <div className="bottom-section-container">
          <div
            className={`save-button ${
              isFinancialInformationValid() ? "" : "disabled-button"
            }`}
            onClick={onSaveDetails}
          >
            {updateUserProfileLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.save")
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default FinancialInformation;
