// General
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";
import { updateUtmSource } from "../../../../redux/store/trackingStore";

const UtmMounted = () => {
  // Redux variables
  const dispatch = useDispatch();

  // Router variables
  let location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // Lifecycle | Mounted
  useEffect(() => {
    checkUtmFromUrl();
  }, []);

  const checkUtmFromUrl = () => {
    let utmSource = searchParams.get("utm_source");
    let utmMedium = searchParams.get("utm_medium");
    let utmCampaign = searchParams.get("utm_campaign");
    let utmTerm = searchParams.get("utm_term");
    let utmContent = searchParams.get("utm_content");

    let utmData = {
      rawSearchParam: isUtmValid(location.search),
      stringyfiedSearchParam: convertQueryStringToJson(searchParams.toString()),
      organizedUtmSource: {},
    };

    if (utmSource) {
      utmData.utm_source = utmSource;
      utmData.organizedUtmSource.utm_source = utmSource;
    }
    if (utmMedium) {
      utmData.utm_medium = utmMedium;
      utmData.organizedUtmSource.utm_medium = utmMedium;
    }
    if (utmCampaign) {
      utmData.utm_campaign = utmCampaign;
      utmData.organizedUtmSource.utm_campaign = utmCampaign;
    }
    if (utmTerm) {
      utmData.utm_term = utmTerm;
      utmData.organizedUtmSource.utm_term = utmTerm;
    }
    if (utmContent) {
      utmData.utm_content = utmContent;
      utmData.organizedUtmSource.utm_content = utmContent;
    }

    dispatch(updateUtmSource(utmData));
  };

  // Utility Functions
  const convertQueryStringToJson = (queryString) => {
    // Split the query string into an array of parameter-value pairs
    const queryParams = queryString.split("&");

    // Create an empty object to store the parameters and values
    const jsonResult = {};

    // Iterate through each parameter-value pair and add them to the object
    queryParams.forEach((param) => {
      const [key, value] = param.split("=");
      jsonResult[key] = value;
    });

    // Convert the object to a JSON string
    const jsonString = JSON.stringify(jsonResult);

    return jsonString;
  };
  const isUtmValid = (searchParam) => {
    if (
      searchParam?.includes("utm_source") ||
      searchParam?.includes("utm_medium") ||
      searchParam?.includes("utm_campaign") ||
      searchParam?.includes("utm_term") ||
      searchParam?.includes("utm_content")
    ) {
      return searchParam;
    } else {
      return "";
    }
  };

  return <div id="utm-mounted-shadow-component"></div>;
};

export default UtmMounted;
