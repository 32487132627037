// General
import "./basic-information.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
import { removeHTMLEscape } from "../../../../../services/regex.service";
// Static Data
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateUsername,
  updateBirthdate,
  updateTagline,
  updateUsernameNewContent,
} from "../../../../../redux/store/editProfileStore";
import {
  updateErrorToast,
  updateSuccessToast,
} from "../../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// react-date-picker
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
// Material UI
import { Divider, TextField } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import ModerationStatus from "../../moderation-status/moderation-status";

const BasicInformation = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const minDate = moment().subtract(userConst.userAgeRange.min, "years");
  const maxDate = moment().subtract(userConst.userAgeRange.max, "years");

  // Redux variables
  const email = useSelector((state) => state.editProfile.email);
  const username = useSelector((state) => state.editProfile.username);
  const age = useSelector((state) => state.editProfile.age);
  const birthdate = useSelector((state) => state.editProfile.birthdate);
  const usernameStatus = useSelector(
    (state) => state.editProfile.usernameStatus
  );
  const usernameNewContent = useSelector(
    (state) => state.editProfile.usernameNewContent
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Update User Profile API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      // Backend returning empty if success
      const toastObj = {
        message: t("signup.profile_updated"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));
    } else if (updateUserProfileError) {
      switch (updateUserProfileErrorData?.data?.status) {
        case -1:
          const errorToastObj = {
            message: updateUserProfileErrorData?.data?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToastObj));
          break;
        default:
          break;
      }
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    if (!isBasicInformationValid()) return;

    let obj = {
      birth_date: moment(birthdate).format("YYYY-MM-DD"),
    };

    // Username
    if (
      usernameStatus === userConst.profileGeneralApprovalStatus.inReview ||
      usernameStatus === userConst.profileGeneralApprovalStatus.rejected
    ) {
      if (
        getProfileData?.data?.profile_details_approval?.username
          ?.new_content !== usernameNewContent
      ) {
        obj.username = usernameNewContent;
      }
    } else {
      if (getProfileData?.data?.username !== username) {
        obj.username = username;
      }
    }
    updateUserProfile(obj);
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "username":
        if (
          usernameStatus === userConst.profileGeneralApprovalStatus.inReview ||
          usernameStatus === userConst.profileGeneralApprovalStatus.rejected
        ) {
          dispatch(updateUsernameNewContent(event.target.value));
        } else {
          if (removeHTMLEscape.test(event.target.value)) {
            // Remove HTML Escape (prevent XSS)
            dispatch(updateUsername(""));

            // Send to Sentry
            Sentry.captureMessage(
              "PWA-EditProfile-Page-Username-Input-HTML-Escape-Detected"
            );

            // Send to GTM
            TagManager.dataLayer({
              dataLayer: {
                event:
                  "PWA-EditProfile-Page-Username-Input-HTML-Escape-Detected",
              },
            });
          } else {
            dispatch(updateUsername(event.target.value));
          }
        }
        break;
      case "birthday":
        dispatch(updateBirthdate(moment(event).format("YYYY-MM-DD")));
        break;
      case "tagline":
        dispatch(updateTagline(event.target.value));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isBasicInformationValid = () => {
    if (
      ((usernameNewContent && usernameNewContent !== "") ||
        (username && username !== "")) &&
      birthdate &&
      birthdate !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const returnUsernameValue = () => {
    if (
      usernameStatus === userConst.profileGeneralApprovalStatus.inReview ||
      usernameStatus === userConst.profileGeneralApprovalStatus.rejected
    ) {
      return usernameNewContent;
    } else {
      return username;
    }
  };
  const getTranslatedStatus = (status) => {
    switch (status) {
      case userConst.profileGeneralApprovalStatus.inReview:
        return t("common.in_review");
      case userConst.profileGeneralApprovalStatus.rejected:
        return t("common.rejected");
      default:
        return "";
    }
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-basic-information-subcomponent">
        <div className="basic-information-label-container">
          <div className="basic-information-label">
            {t("profile.basic_information")}
          </div>

          <div className="expand-icon-container">
            {getIcon("chevronRightIcon", "expand-icon")}
          </div>
        </div>

        <div className="form-field-padding-container">
          {/* Email */}
          <div className="form-field-container">
            <div className="label-container">
              <div className="label">{t("settings.email")}</div>
            </div>

            <div className="form-container">
              <div className="email-label">{email || ""}</div>
            </div>
          </div>

          {/* Username */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${
                  !username && !usernameNewContent ? "incomplete-field" : ""
                }`}
              >
                {t("profile.username")}
              </div>
            </div>

            <div
              className={`form-container ${
                usernameStatus !==
                userConst.profileGeneralApprovalStatus.approved
                  ? "reduced-width"
                  : ""
              }`}
            >
              <TextField
                sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                className="form-field"
                value={returnUsernameValue() || ""}
                placeholder={"+ Add username"}
                onChange={onInputChange("username")}
                variant="standard"
                InputProps={{ disableUnderline: true }}
                inputProps={{
                  sx: {
                    "&::placeholder": {
                      opacity: "1",
                      font: "500 1em Lato",
                      color: "#CCCCCC",
                    },
                  },
                }}
              ></TextField>
            </div>

            {(getProfileData?.data?.username ||
              getProfileData?.data?.profile_details_approval?.username
                ?.new_content) &&
              usernameStatus &&
              usernameStatus !==
                userConst.profileGeneralApprovalStatus.approved && (
                <div className="approval-status-container">
                  {getTranslatedStatus(usernameStatus)}
                </div>
              )}
          </div>

          {/* Birthday */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${birthdate ? "" : "incomplete-field"}`}>
                {t("common.age")}
              </div>
            </div>

            <div className="form-container">
              {age &&
              age > userConst.userAgeRange.max &&
              age < userConst.userAgeRange.min ? (
                <div className="age-label">{age}</div>
              ) : (
                <div className="date-picker-container">
                  <DatePicker
                    onChange={onInputChange("birthday")}
                    value={birthdate || moment().toDate()}
                    minDate={minDate.toDate()}
                    maxDate={maxDate.toDate()}
                    clearIcon={null}
                    format={"dd/MM/yyyy"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div id="desktop-edit-profile-basic-information-subcomponent">
        {/* In Review */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.inReview}
        />

        {/* Rejected */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.rejected}
        />

        <div className="basic-information-static-label-container">
          <div className="basic-information-label">
            {t("profile.basic_information")}
          </div>
          <Divider className="short-divider" />
        </div>

        <div className="form-field-padding-container">
          {/* Email */}
          <div className="form-field-container">
            <div className="label-container">
              <div className="label">{t("settings.email")}</div>
            </div>

            <div className="form-container">
              <div className="email-label">{email || ""}</div>
            </div>
          </div>

          {/* Username */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${
                  !username && !usernameNewContent ? "incomplete-field" : ""
                }`}
              >
                {t("profile.username")}

                {(getProfileData?.data?.username ||
                  getProfileData?.data?.profile_details_approval?.username
                    ?.new_content) &&
                  usernameStatus &&
                  usernameStatus !==
                    userConst.profileGeneralApprovalStatus.approved && (
                    <div className="approval-status-container">
                      {getTranslatedStatus(usernameStatus)}
                    </div>
                  )}
              </div>
            </div>

            <div className="form-container">
              <TextField
                sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                value={returnUsernameValue() || ""}
                placeholder={"+ Add username"}
                onChange={onInputChange("username")}
                variant="outlined"
                inputProps={{
                  sx: {
                    "&::placeholder": {
                      opacity: "1",
                      font: "500 1em Lato",
                      color: "#CCCCCC",
                    },
                  },
                }}
              ></TextField>
            </div>
          </div>

          {/* Birthday */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${birthdate ? "" : "incomplete-field"}`}>
                {t("common.age")}
              </div>
            </div>

            <div className="form-container">
              {age &&
              age > userConst.userAgeRange.max &&
              age < userConst.userAgeRange.min ? (
                <div className="age-label">{age}</div>
              ) : (
                <div className="date-picker-container">
                  <DatePicker
                    onChange={onInputChange("birthday")}
                    value={birthdate || moment().toDate()}
                    minDate={minDate.toDate()}
                    maxDate={maxDate.toDate()}
                    clearIcon={null}
                    format={"dd/MM/yyyy"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bottom-section-container">
          <div
            className={`save-button ${
              isBasicInformationValid() ? "" : "disabled-button"
            }`}
            onClick={onSaveDetails}
          >
            {updateUserProfileLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.save")
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default BasicInformation;
