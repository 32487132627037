// General
import "./customer-support-manual-verification-info.scss";
// Static Data
import routeConst from "../../../const/routeConst";
import verificationConst from "../../../const/verificationConst";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";

const CustomerSupportManualVerificationInfo = (props) => {
  const { type } = props;

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onRedirectToSearch = () => {
    onNavigate(-1);
  };

  return (
    <div id="customer-support-manual-verification-info-page">
      <div className="max-width-container">
        <div className="padding-container">
          <div className="navbar-container">
            <div className="back-button" onClick={() => onNavigate(-1)}>
              {getIcon("signupBackIcon", "signup-back-icon")}
            </div>
          </div>

          <div className="top-container">
            <div className="customer-support-title">
              {t("verification.customer_support_manual_verification_title")}
            </div>

            <div className="customer-support-description">
              {t("verification.customer_support_manual_verification_desc")}
            </div>

            <div className="customer-support-points-container">
              <div className="bulb-icon-container">
                {getIcon("blueBulbIcon", "bulb-icon")}
              </div>

              <div className="customer-support-unordered-list-container">
                {type === verificationConst.manualVerificationType.mobile && (
                  <div className="unordered-list-points">
                    {t(
                      "verification.customer_support_manual_verification_point_1"
                    )}
                  </div>
                )}

                {type === verificationConst.manualVerificationType.facial && (
                  <div className="unordered-list-points">
                    {t(
                      "verification.customer_support_manual_verification_point_2"
                    )}
                  </div>
                )}

                {type === verificationConst.manualVerificationType.facial && (
                  <div className="unordered-list-points">
                    {t(
                      "verification.customer_support_manual_verification_point_3"
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="bottom-container">
            <div className="done-button" onClick={onRedirectToSearch}>
              {t("common.got_it")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSupportManualVerificationInfo;
