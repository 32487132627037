// General
import "./private-call-pre-join-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import {
  spiApi,
  useLazyGetProfileQuery,
  useLazyGetPrivateCallStandbyPreJoinQuery,
  useLazyGetPrivateCallStandbyJoinQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateBusyInPrivateDialog,
  updateCoinsPackageDialog,
  updatePreJoinDialog,
} from "../../../../redux/store/dialogStore";
import {
  // Main Page Functions
  resetVideoCallSearchLists,

  // General Functions
  updateIsPrivateStandbyMode,
  updateRequestId,
} from "../../../../redux/store/privateCallStore";
import { updateVideoCallPusherSubscribe } from "../../../../redux/store/pusherStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";
import Spinner from "../../elements/spinner/spinner";

const PrivateCallPreJoinDialog = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getPrivateCallStandbyPreJoin,
    {
      data: getPrivateCallStandbyPreJoinData,
      error: getPrivateCallStandbyPreJoinErrorData,
      isFetching: getPrivateCallStandbyPreJoinFetching,
      isLoading: getPrivateCallStandbyPreJoinLoading,
      isSuccess: getPrivateCallStandbyPreJoinSuccess,
      isError: getPrivateCallStandbyPreJoinError,
    },
  ] = useLazyGetPrivateCallStandbyPreJoinQuery();
  const [
    getPrivateCallStandbyJoin,
    {
      data: getPrivateCallStandbyJoinData,
      error: getPrivateCallStandbyJoinErrorData,
      isFetching: getPrivateCallStandbyJoinFetching,
      isLoading: getPrivateCallStandbyJoinLoading,
      isSuccess: getPrivateCallStandbyJoinSuccess,
      isError: getPrivateCallStandbyJoinError,
    },
  ] = useLazyGetPrivateCallStandbyJoinQuery();

  // Redux variables
  const preJoinDialog = useSelector((state) => state.dialog.preJoinDialog);
  const calleeId = useSelector((state) => state.privateCall.calleeId);
  const calleeProfilePhoto = useSelector(
    (state) => state.privateCall.calleeProfilePhoto
  );
  const calleeUsername = useSelector(
    (state) => state.privateCall.calleeUsername
  );
  const videoCallPrejoinPaymentPassthrough = useSelector(
    (state) => state.privateCall.videoCallPrejoinPaymentPassthrough
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 600px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!preJoinDialog || !calleeId) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D14",
      },
    });

    getProfile(null, true);

    const preJoinBody = {
      user_id: calleeId,
    };
    getPrivateCallStandbyPreJoin(preJoinBody);
  }, [preJoinDialog, calleeId]);

  // Lifecycle | Check for update | Private Call Standby Pre Join API Response
  useEffect(() => {
    if (
      getPrivateCallStandbyPreJoinFetching ||
      getPrivateCallStandbyPreJoinLoading
    ) {
    } else if (getPrivateCallStandbyPreJoinSuccess) {
      if (getPrivateCallStandbyPreJoinData?.status === 0) {
        if (
          getPrivateCallStandbyPreJoinData?.data?.standby?.status === "busy"
        ) {
          // Callee is busy in another private call
          dispatch(updateBusyInPrivateDialog(true));

          onCloseDialog();
        }
      }
    } else if (getPrivateCallStandbyPreJoinError) {
    }
  }, [
    getPrivateCallStandbyPreJoinFetching,
    getPrivateCallStandbyPreJoinLoading,
    getPrivateCallStandbyPreJoinSuccess,
    getPrivateCallStandbyPreJoinError,
  ]);

  // Lifecycle | Check for update | Private Call Standby Join API Response
  useEffect(() => {
    if (getPrivateCallStandbyJoinFetching || getPrivateCallStandbyJoinLoading) {
    } else if (getPrivateCallStandbyJoinSuccess) {
      switch (getPrivateCallStandbyJoinData?.status) {
        case 0:
          // Get Request ID
          dispatch(
            updateRequestId(
              getPrivateCallStandbyJoinData?.data?.call_request?.call_request_id
            )
          );

          // Subscribe to Video Call Pusher
          dispatch(updateVideoCallPusherSubscribe({}));

          // Set isPrivateStandbyMode to true to prevent conflict with another video call component
          dispatch(updateIsPrivateStandbyMode(true));

          onCloseDialog();

          onNavigate(routeConst.videoCall.room.path);
          break;
        case -1:
          // Someone else is already in the private call
          const toastObj = {
            message: getPrivateCallStandbyJoinData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));

          onCloseDialog();

          // Reset Video Call List
          dispatch(resetVideoCallSearchLists());

          // Refetch Video Call List
          dispatch({
            type: `${spiApi.reducerPath}/invalidateTags`,
            payload: ["VideoCallSearch"],
          });
          break;
        default:
          break;
      }
    } else if (getPrivateCallStandbyJoinError) {
    }
  }, [
    getPrivateCallStandbyJoinFetching,
    getPrivateCallStandbyJoinLoading,
    getPrivateCallStandbyJoinSuccess,
    getPrivateCallStandbyJoinError,
  ]);

  // Lifecycle | Check for update | videoCallPrejoinPaymentPassthrough
  useEffect(() => {
    if (!preJoinDialog) return;
    const preJoinBody = {
      user_id: calleeId,
    };
    getPrivateCallStandbyPreJoin(preJoinBody);
  }, [videoCallPrejoinPaymentPassthrough]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updatePreJoinDialog(false));
  };
  const onJoinPrivateNow = () => {
    if (getPrivateCallStandbyJoinFetching || getPrivateCallStandbyJoinLoading)
      return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D14-14.1-Button",
      },
    });

    getPrivateCallStandbyJoin({ user_id: calleeId });
  };
  const onTopUpNow = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D14-14.2-Button",
      },
    });

    dispatch(updateCoinsPackageDialog(true));
  };

  return (
    <Dialog
      className={`${!isMobile ? "custom-radius35-dialog" : ""}`}
      fullScreen={isMobile}
      open={preJoinDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      <div
        id="private-call-pre-join-dialog"
        className={`${!isMobile ? "max-width-container" : ""}`}
      >
        <div className="close-button-container" onClick={onCloseDialog}>
          <CloseIcon className="close-button" />
        </div>

        <div className="padding-container">
          {getPrivateCallStandbyPreJoinData?.data?.remaining_call_time >=
            getPrivateCallStandbyPreJoinData?.data
              ?.minimum_call_seconds_required && (
            <div className="pre-join-layout-container">
              <div className="participants-container">
                <div className="callee-container">
                  <CustomAvatar
                    className="callee-avatar"
                    src={calleeProfilePhoto}
                    profileBorder={null}
                  />
                </div>

                <div className="caller-container">
                  <CustomAvatar
                    className="caller-avatar"
                    src={getProfileData?.data?.profile_photo?.original_photo}
                    profileBorder={null}
                  />
                </div>
              </div>

              <div className="header">
                {t("1on1.joining_call_title", { user: calleeUsername })}
              </div>

              <ul className="description-container">
                <li>
                  <Trans
                    i18nKey="1on1.joining_call_desc_1"
                    components={{ b: <b className="yellow" /> }}
                  />
                </li>

                <li>
                  <Trans
                    i18nKey="1on1.joining_call_desc_2"
                    components={{ b: <b className="yellow" /> }}
                  />
                </li>

                <li>
                  <Trans
                    i18nKey="1on1.joining_call_desc_3"
                    components={{ b: <b className="yellow" /> }}
                  />
                </li>
              </ul>

              <div className="cta-container">
                <div className="cta-subheader">
                  {t("1on1.joining_call_cta_title")}
                </div>

                <div className="cta-text">
                  {t("1on1.joining_call_cta_desc")}
                </div>
              </div>

              <div className="join-private-button" onClick={onJoinPrivateNow}>
                {getPrivateCallStandbyJoinFetching ? (
                  <Spinner size={20} isPadding={false} color="white-spinner" />
                ) : (
                  t("1on1.joining_call_cta_join_private_now")
                )}
              </div>

              <div className="top-up-button" onClick={onTopUpNow}>
                {t("1on1.joining_call_cta_top_up_now")}
              </div>
            </div>
          )}

          {getPrivateCallStandbyPreJoinData?.data?.remaining_call_time <
            getPrivateCallStandbyPreJoinData?.data
              ?.minimum_call_seconds_required && (
            <div className="low-balance-layout-container">
              <div className="coin-icon-container">
                {getIcon("sbCoinIcon", "coin-icon")}
              </div>

              <div className="header">
                <Trans
                  i18nKey="1on1.joining_call_short_coin"
                  components={{ br: <br /> }}
                  values={{
                    n:
                      (getPrivateCallStandbyPreJoinData?.data
                        ?.minimum_call_seconds_required /
                        60) *
                        getPrivateCallStandbyPreJoinData?.data?.call_rates
                          ?.coins -
                      getPrivateCallStandbyPreJoinData?.data?.wallets?.coins,
                  }}
                />
              </div>

              <div className="description">
                <Trans
                  i18nKey="1on1.joining_call_no_coin"
                  components={{ br: <br /> }}
                />
              </div>

              <div className="top-up-button" onClick={onTopUpNow}>
                {t("1on1.joining_call_cta_top_up_to_proceed")}
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default PrivateCallPreJoinDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
