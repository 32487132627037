// General
import "./education-filter.scss";
import { useEffect } from "react";
// Services
import { useLazyGetAioQuery } from "../../../../../services/data.service";
// Static Data
import searchConst from "../../../../../const/searchConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEducationHighSchool,
  updateEducationSomeCollege,
  updateEducationAssociateDegree,
  updateEducationBachelorsDegree,
  updateEducationGraduateDegree,
  updateEducationPostGraduateDegree,
} from "../../../../../redux/store/searchFilterStore";
import { updateEducationFilterDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";

const EducationFilter = (props) => {
  const { type, enableTitle = true } = props;

  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();

  // Redux variables
  const education = useSelector((state) => state.searchFilter.education);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getAio(null, true);
  }, []);

  // Event Handlers | MUI Checkbox
  const onCheckboxChange = (state) => (event) => {
    switch (state) {
      case "high-school":
        dispatch(updateEducationHighSchool(event.target.checked));
        break;
      case "some-college":
        dispatch(updateEducationSomeCollege(event.target.checked));
        break;
      case "associates-degree":
        dispatch(updateEducationAssociateDegree(event.target.checked));
        break;
      case "bachelors-degree":
        dispatch(updateEducationBachelorsDegree(event.target.checked));
        break;
      case "graduate-degree":
        dispatch(updateEducationGraduateDegree(event.target.checked));
        break;
      case "phd-or-post-doctoral":
        dispatch(updateEducationPostGraduateDegree(event.target.checked));
        break;
      default:
        break;
    }
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateEducationFilterDialog(false));
  };

  // Utility Functions
  const onGetState = (state) => {
    for (let i = 0; i < getAioData?.payload?.dropdown?.education?.length; i++) {
      if (state === getAioData?.payload?.dropdown?.education[i].value) {
        return education[state];
      }
    }
  };

  if (getAioFetching || getAioLoading) {
  } else if (getAioSuccess) {
    return (
      <div id="education-filter-shared-component">
        {enableTitle && (
          <div className="dialog-header">
            <div className="title">{t("filter.education")}</div>

            <div className="close-button-container">
              <CloseIcon className="close-button" onClick={onCloseDialog} />
            </div>
          </div>
        )}

        <div className="padding-container">
          <div
            className={`checkbox-container ${
              type === searchConst.searchFilterType.full ? "flexwrap" : ""
            }`}
          >
            {getAioData?.payload?.dropdown?.education?.map(
              (education, index) => (
                <FormGroup className="checkbox" key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={`custom-checkbox ${
                          type === searchConst.searchFilterType.full
                            ? "custom-checkbox-no-padding"
                            : ""
                        }`}
                        size={
                          type === searchConst.searchFilterType.full
                            ? "small"
                            : "medium"
                        }
                        checked={onGetState(education?.value)}
                        onChange={onCheckboxChange(education?.value)}
                      />
                    }
                    label={
                      <span
                        className={
                          type === searchConst.searchFilterType.full
                            ? "checkbox-font"
                            : ""
                        }
                      >
                        {education?.name}
                      </span>
                    }
                  />
                </FormGroup>
              )
            )}
          </div>
        </div>
      </div>
    );
  } else if (getAioError) {
  }
};

export default EducationFilter;
