// General
import "./action-buttons.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetUserAioQuery,
  useLazyGetPrivateCallSettingsQuery,
  useUpdatePrivateCallSettingsMutation,
  useLazyGetPrivateCallRequestRefreshQuery,
} from "../../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Charged Message Functions
  updateIsChargedMessage,
  updateChargedMessageCoinsReceived,
  updateChargedMessageDiamondsReceived,

  // Utility Functions
  updateReinitializeCameraStreamPassthrough,
  updateSwapVideoViewPassthrough,
  updateToggleCameraPassthrough,
  updateToggleMicrophonePassthrough,
} from "../../../../../../redux/store/privateCallStore";
import {
  updateKickUserDialog,
  updateEndCallDialog,
  updateChargedMessagePopupDialog,
  updateCameraMicrophoneSettingsDialog,
} from "../../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Menu, MenuItem, ListItemIcon } from "@mui/material";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import MicOffRoundedIcon from "@mui/icons-material/MicOffRounded";
import MicRoundedIcon from "@mui/icons-material/MicRounded";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../elements/spinner/spinner";

const ActionButtons = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getPrivateCallSettings,
    {
      data: getPrivateCallSettingsData,
      error: getPrivateCallSettingsErrorData,
      isFetching: getPrivateCallSettingsFetching,
      isLoading: getPrivateCallSettingsLoading,
      isSuccess: getPrivateCallSettingsSuccess,
      isError: getPrivateCallSettingsError,
    },
  ] = useLazyGetPrivateCallSettingsQuery();
  const [
    updatePrivateCallSettings,
    {
      data: updatePrivateCallSettingsData,
      error: updatePrivateCallSettingsErrorData,
      isLoading: updatePrivateCallSettingsLoading,
      isSuccess: updatePrivateCallSettingsSuccess,
      isError: updatePrivateCallSettingsError,
    },
  ] = useUpdatePrivateCallSettingsMutation();
  const [
    getPrivateCallRequestRefresh,
    {
      data: getPrivateCallRequestRefreshData,
      error: getPrivateCallRequestRefreshErrorData,
      isFetching: getPrivateCallRequestRefreshFetching,
      isLoading: getPrivateCallRequestRefreshLoading,
      isSuccess: getPrivateCallRequestRefreshSuccess,
      isError: getPrivateCallRequestRefreshError,
    },
  ] = useLazyGetPrivateCallRequestRefreshQuery();

  // General variables
  const [isRestartCameraButtonDisable, setIsRestartCameraButtonDisable] =
    useState(false);
  const refreshIsMounted = useRef(false);

  // Redux variables
  const callStatus = useSelector((state) => state.privateCall.callStatus);
  const requestId = useSelector((state) => state.privateCall.requestId);
  const isChargedMessage = useSelector(
    (state) => state.privateCall.isChargedMessage
  );
  const chargedMessageRefreshPassthrough = useSelector(
    (state) => state.privateCall.chargedMessageRefreshPassthrough
  );
  const swapVideoViewPassthrough = useSelector(
    (state) => state.privateCall.swapVideoViewPassthrough
  );
  const toggleCameraPassthrough = useSelector(
    (state) => state.privateCall.toggleCameraPassthrough
  );
  const isLocalCameraEnabled = useSelector(
    (state) => state.privateCall.isLocalCameraEnabled
  );
  const isLocalMicrophoneEnabled = useSelector(
    (state) => state.privateCall.isLocalMicrophoneEnabled
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // MUI variables
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    getUserAio();
  }, []);

  // Lifecycle | Check for update | User AIO API Response
  useEffect(() => {
    if (getUserAioFetching || getUserAioLoading) {
    } else if (getUserAioSuccess) {
      if (getUserAioData?.status === 1) {
        if (getUserAioData?.data?.private_calls?.charging_chat_messages) {
          if (isDaddy) {
            getPrivateCallRequestRefresh(`?call_request_id=${requestId}`);
          } else {
            getPrivateCallSettings();
          }
        }
      }
    } else if (getUserAioError) {
    }
  }, [
    getUserAioFetching,
    getUserAioLoading,
    getUserAioSuccess,
    getUserAioError,
  ]);

  // Lifecycle | Check for update | Private Call Settings API Response
  useEffect(() => {
    if (getPrivateCallSettingsFetching || getPrivateCallSettingsLoading) {
    } else if (getPrivateCallSettingsSuccess) {
      if (getPrivateCallSettingsData?.status === 0) {
        if (!isDaddy) {
          dispatch(
            updateIsChargedMessage(
              getPrivateCallSettingsData?.data?.call_settings
                ?.charging_chat_messages
            )
          );

          dispatch(
            updateChargedMessageCoinsReceived(
              getPrivateCallSettingsData?.data?.call_settings
                ?.charging_chat_messages_rate?.coins
            )
          );

          dispatch(
            updateChargedMessageDiamondsReceived(
              getPrivateCallSettingsData?.data?.call_settings
                ?.charging_chat_messages_rate?.diamonds
            )
          );

          dispatch(updateChargedMessagePopupDialog(true));
        }
      }
    } else if (getPrivateCallSettingsError) {
    }
  }, [
    getPrivateCallSettingsFetching,
    getPrivateCallSettingsLoading,
    getPrivateCallSettingsSuccess,
    getPrivateCallSettingsError,
  ]);

  // Lifecycle | Check for update | Private Call Request Refresh API Response
  useEffect(() => {
    if (
      getPrivateCallRequestRefreshFetching ||
      getPrivateCallRequestRefreshLoading
    ) {
    } else if (getPrivateCallRequestRefreshSuccess) {
      if (getPrivateCallRequestRefreshData?.status === 0) {
        let tempChargedMessage =
          getPrivateCallRequestRefreshData?.data?.call_settings[0]?.user_id ===
          getProfileData?.data?.id
            ? getPrivateCallRequestRefreshData?.data?.call_settings[1]
                ?.call_settings?.charging_chat_messages
            : getPrivateCallRequestRefreshData?.data?.call_settings[0]
                ?.call_settings?.charging_chat_messages;

        let tempChargedMessageCoinsReceived =
          getPrivateCallRequestRefreshData?.data?.call_settings[0]?.user_id ===
          getProfileData?.data?.id
            ? getPrivateCallRequestRefreshData?.data?.call_settings[1]
                ?.call_settings?.charging_chat_messages_rate?.coins
            : getPrivateCallRequestRefreshData?.data?.call_settings[0]
                ?.call_settings?.charging_chat_messages_rate?.coins;

        let tempChargedMessageDiamondsReceived =
          getPrivateCallRequestRefreshData?.data?.call_settings[0]?.user_id ===
          getProfileData?.data?.id
            ? getPrivateCallRequestRefreshData?.data?.call_settings[1]
                ?.call_settings?.charging_chat_messages_rate?.diamonds
            : getPrivateCallRequestRefreshData?.data?.call_settings[0]
                ?.call_settings?.charging_coin_messages_rate?.diamonds;

        dispatch(updateIsChargedMessage(tempChargedMessage));
        dispatch(
          updateChargedMessageCoinsReceived(tempChargedMessageCoinsReceived)
        );
        dispatch(
          updateChargedMessageDiamondsReceived(
            tempChargedMessageDiamondsReceived
          )
        );

        if (tempChargedMessage) {
          dispatch(updateChargedMessagePopupDialog(true));
        }
      }
    } else if (getPrivateCallRequestRefreshError) {
    }
  }, [
    getPrivateCallRequestRefreshFetching,
    getPrivateCallRequestRefreshLoading,
    getPrivateCallRequestRefreshSuccess,
    getPrivateCallRequestRefreshError,
  ]);

  // Lifecycle | Check for update | chargedMessagePassthorough
  useEffect(() => {
    if (refreshIsMounted.current) {
      if (!chargedMessageRefreshPassthrough) return;

      if (isDaddy) {
        getPrivateCallRequestRefresh(`?call_request_id=${requestId}`);
      }
    } else {
      refreshIsMounted.current = true;
    }
  }, [chargedMessageRefreshPassthrough]);

  // Event Handlers | Button
  const onToggleChargedMessage = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-49-49.1-Button",
      },
    });

    const obj = {
      enable_calls:
        getPrivateCallSettingsData?.data?.call_settings?.enable_calls,
      accept_calls_from:
        getPrivateCallSettingsData?.data?.call_settings?.accept_calls_from,
      available_hours_always:
        getPrivateCallSettingsData?.data?.call_settings?.available_hours_always,
      available_hours_from:
        getPrivateCallSettingsData?.data?.call_settings?.available_hours_from,
      available_hours_to:
        getPrivateCallSettingsData?.data?.call_settings?.available_hours_to,
      available_days:
        getPrivateCallSettingsData?.data?.call_settings?.available_days,
      charging_chat_messages: !isChargedMessage,
    };
    updatePrivateCallSettings(obj);
  };
  const onReinitializeCameraStream = () => {
    if (isRestartCameraButtonDisable) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-46-46.9-Button",
      },
    });

    setIsRestartCameraButtonDisable(true);

    setTimeout(() => {
      setIsRestartCameraButtonDisable(false);
    }, 6000);
    dispatch(updateReinitializeCameraStreamPassthrough({}));
  };
  const onKickOtherUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-49-49.2-Button",
      },
    });

    dispatch(updateKickUserDialog(true));
  };
  const onEndCallDialog = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-46-46.10-Button",
      },
    });

    dispatch(updateEndCallDialog(true));
  };
  const onOpenMoreOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Event Handlers | MUI Menu
  const onCloseMoreOptions = (state) => {
    switch (state) {
      case "reset-camera":
        onReinitializeCameraStream();
        break;
      case "kick-user":
        onKickOtherUser();
        break;
      case "camera-settings":
        dispatch(updateCameraMicrophoneSettingsDialog(true));
        break;
      case "swap-view":
        dispatch(
          updateSwapVideoViewPassthrough(
            swapVideoViewPassthrough === "full" ? "mini" : "full"
          )
        );
        break;
      case "stop-camera":
        dispatch(updateToggleCameraPassthrough({}));
        break;
      case "stop-microphone":
        dispatch(updateToggleMicrophonePassthrough({}));
        break;
      default:
        break;
    }

    setAnchorEl(null);
  };

  return (
    <div id="topbar-action-buttons-subcomponent">
      {/* Charged Message */}
      {!isDaddy &&
        getUserAioData?.data?.private_calls?.charging_chat_messages && (
          <div
            className="charged-message-button-container"
            onClick={onToggleChargedMessage}
          >
            {getPrivateCallSettingsFetching ||
            getPrivateCallSettingsLoading ||
            updatePrivateCallSettingsLoading ? (
              <Spinner size={20} isPadding={false} color="white-spinner" />
            ) : isChargedMessage ? (
              getIcon("chargedMessageEnableIcon", "charged-message-button")
            ) : (
              getIcon("chargedMessageDisableIcon", "charged-message-button")
            )}
          </div>
        )}

      {/* End Call */}
      <div className="end-call-button-container" onClick={onEndCallDialog}>
        {getIcon("endCallIcon", "end-call-button")}
      </div>

      {/* More Options */}
      <div className="more-button-container" onClick={onOpenMoreOptions}>
        {getIcon("videoCallMoreIcon", "more-icon")}
      </div>

      {/* More Settings Menu */}
      <Menu
        className="video-call-menu custom-dark-transparent-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseMoreOptions}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* Reset Camera */}
        <MenuItem
          className="video-call-action-menu-item"
          onClick={() => onCloseMoreOptions("reset-camera")}
        >
          <ListItemIcon
            className={`reset-camera-microphone-button-container ${
              isRestartCameraButtonDisable ? "disabled-reset-button" : ""
            }`}
          >
            {getIcon("resetPermissionIcon", "reset-camera-microphone-button")}
          </ListItemIcon>
          <div className="video-call-menu-text">{t("1on1.reset_camera")}</div>
        </MenuItem>

        {/* Kick User | Sugarbaby Only */}
        {!isDaddy && callStatus === "JOIN" && (
          <MenuItem
            className="video-call-action-menu-item"
            onClick={() => onCloseMoreOptions("kick-user")}
          >
            <ListItemIcon
              className={`reset-camera-microphone-button-container ${
                isRestartCameraButtonDisable ? "disabled-reset-button" : ""
              }`}
            >
              {getIcon("standbyKickIcon", "kick-caller-button")}
            </ListItemIcon>
            <div className="video-call-menu-text">{t("1on1.kick_user")}</div>
          </MenuItem>
        )}

        {/* Camera & Microphone Settings */}
        <MenuItem
          className="video-call-action-menu-item"
          onClick={() => onCloseMoreOptions("camera-settings")}
        >
          <ListItemIcon className="camera-settings-button-container">
            <FlipCameraIosIcon className="camera-settings-icon" />
          </ListItemIcon>
          <div className="video-call-menu-text">{t("1on1.switch_camera")}</div>
        </MenuItem>

        {/* Switch View */}
        <MenuItem
          className="video-call-action-menu-item"
          onClick={() => onCloseMoreOptions("swap-view")}
        >
          <ListItemIcon className="camera-settings-button-container">
            <BrandingWatermarkIcon className="camera-settings-icon" />
          </ListItemIcon>
          <div className="video-call-menu-text">{t("1on1.switch_view")}</div>
        </MenuItem>

        {/* Stop Camera */}
        <MenuItem
          className="video-call-action-menu-item"
          onClick={() => onCloseMoreOptions("stop-camera")}
        >
          <ListItemIcon className="camera-settings-button-container">
            {isLocalCameraEnabled ? (
              <VideocamOffIcon className="camera-settings-icon" />
            ) : (
              <VideocamIcon className="camera-settings-icon" />
            )}
          </ListItemIcon>
          <div className="video-call-menu-text">
            {isLocalCameraEnabled
              ? t("1on1.stop_camera")
              : t("1on1.start_camera")}
          </div>
        </MenuItem>

        {/* Mute Microphone */}
        <MenuItem
          className="video-call-action-menu-item"
          onClick={() => onCloseMoreOptions("stop-microphone")}
        >
          <ListItemIcon className="camera-settings-button-container">
            {isLocalMicrophoneEnabled ? (
              <MicOffRoundedIcon className="camera-settings-icon" />
            ) : (
              <MicRoundedIcon className="camera-settings-icon" />
            )}
          </ListItemIcon>
          <div className="video-call-menu-text">
            {isLocalMicrophoneEnabled
              ? t("1on1.mute_microphone")
              : t("1on1.unmute_microphone")}
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};
export default ActionButtons;
