// General
import "./video-call-tab.scss";
import { useState, useEffect, useRef } from "react";
// Services
import { useLazyGetVideoCallSearchQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
import userCardConst from "../../../../const/userCardConst";
import searchConst from "../../../../const/searchConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Main Page Functions
  updateVideoCallSearchLists,
  updateVideoCallSearchPagination,
  resetVideoCallSearchLists,
} from "../../../../redux/store/privateCallStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import useGetUserProfileStatus from "../../../utility/custom-hooks/useGetUserProfileStatus-hook";
// Components
import Banner from "../../../shared/elements/banner/banner";
import UserCard from "../../../shared/elements/user-card/user-card";
import EmptyList from "./empty-list/empty-list";
import Spinner from "../../../shared/elements/spinner/spinner";
import ResultEndCard from "../../../shared/elements/result-end-card/result-end-card";

const VideoCallTab = () => {
  // API variables
  const [
    getVideoCallSearch,
    {
      data: getVideoCallSearchData,
      error: getVideoCallSearchErrorData,
      isFetching: getVideoCallSearchFetching,
      isLoading: getVideoCallSearchLoading,
      isSuccess: getVideoCallSearchSuccess,
      isError: getVideoCallSearchError,
    },
  ] = useLazyGetVideoCallSearchQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [pagination, setPagination] = useState(1);
  const scrollContainerRef = useRef(null);
  const isMounted = useRef(false);

  // Render variables
  let videoCallListView = <div></div>;

  // Redux variables
  const videoCallSearchLists = useSelector(
    (state) => state.privateCall.videoCallSearchLists
  );
  const videoCallSearchPagination = useSelector(
    (state) => state.privateCall.videoCallSearchPagination
  );
  const videoCallScrollToTopPassthrough = useSelector(
    (state) => state.privateCall.videoCallScrollToTopPassthrough
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 425px)");
  const isDesktop = useMediaQuery("(min-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  let videoCallBody = {
    list_type: "calls",
    pagination_size: 30,
  };

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getProfileStatus = useGetUserProfileStatus();

  // Lifecycle | Mounted
  useEffect(() => {
    if (videoCallSearchLists?.length <= 0) {
      getVideoCallSearch(videoCallBody, true);
    }
  }, []);

  // Lifecycle | Check for update | Video Call Search API Response
  useEffect(() => {
    if (getVideoCallSearchFetching || getVideoCallSearchLoading) {
    } else if (getVideoCallSearchSuccess) {
      if (getVideoCallSearchData?.status === 0) {
        setFirstLoad(false);

        dispatch(
          updateVideoCallSearchPagination(
            getVideoCallSearchData?.data?.pagination?.next_cursor
          )
        );

        if (getVideoCallSearchData?.data?.results?.length > 0) {
          // Use to block infiniteScrollFunc from running into infinite loop
          setIsApiLoading(false);

          // Page number to send to API
          setPagination((prev) => prev + 1);

          dispatch(
            updateVideoCallSearchLists(getVideoCallSearchData?.data?.results)
          );
        }
      }
    } else if (getVideoCallSearchError) {
      if (getVideoCallSearchErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getVideoCallSearchFetching,
    getVideoCallSearchLoading,
    getVideoCallSearchSuccess,
    getVideoCallSearchError,
  ]);

  // Lifecycle | Check for update | videoCallScrollToTopPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (videoCallScrollToTopPassthrough) {
        scrollContainerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      isMounted.current = true;
    }
  }, [videoCallScrollToTopPassthrough]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (!isApiLoading && pagination > 1 && videoCallSearchPagination) {
      setIsApiLoading(true);

      setTimeout(() => {
        getVideoCallSearch(videoCallBody, true);
      }, 1000);
    }
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetVideoCallSearchLists());
    dispatch(updateVideoCallSearchPagination(null));
    setFirstLoad(true);
    setPagination(0);

    return new Promise((resolve) => {
      resolve(getVideoCallSearch(videoCallBody));
    });
  };

  // Render Functions | Video Call Search Data
  if (videoCallSearchLists?.length > 0) {
    videoCallListView = (
      <div
        className={`infinite-scroller-container ${
          !isMobile || videoCallSearchLists?.length >= 10 ? "multi-column" : ""
        }`}
      >
        {videoCallSearchLists?.map((user, index) => (
          <UserCard
            // User View
            id={user?.id}
            idInt={user?.id_int}
            username={user?.username}
            age={user?.appearance?.age}
            location={
              user?.location?.display_address ||
              user?.location?.city ||
              user?.location?.state
            }
            membershipType={user?.membership_type}
            verifiedProfile={user?.is_verified_profile}
            verifiedCaller={user?.verified_caller}
            levellingBadge={user?.levelling_profile?.badge}
            livestreamingAchievementBadge={
              user?.live_streaming_achievements?.chat_badges_set
            }
            profilePhoto={user?.profile_photo?.original_photo}
            profileDecorations={user?.profile_decorations}
            publicPhotos={user?.public_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
              };
            })}
            privatePhotos={user?.private_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
                thumbnailData: photo?.thumbnail_data,
              };
            })}
            torTags={user?.tor_tags}
            profileAchievementBadge={
              user?.live_streaming_achievements?.profile_badges
            }
            aboutMe={user?.aboutme}
            lookingFor={user?.lookingfor}
            tagline={user?.user?.tagline}
            annualIncome={user?.user?.occupation?.annual_income}
            networth={user?.user?.occupation?.net_worth}
            occupation={user?.occupation?.occupation}
            lifestyle={user?.occupation?.lifestyle_budget}
            education={user?.occupation?.education}
            relationship={user?.relationship?.relationship_status}
            smoking={user?.habit?.smoking}
            drinking={user?.habit?.drinking}
            height={user?.appearance?.height}
            bodyType={user?.appearance?.body_type}
            ethnicity={user?.appearance?.ethnicity}
            eyeColor={user?.appearance?.eye_color}
            hairColor={user?.appearance?.hair_color}
            children={user?.relationship?.children}
            // User Utility
            profileStatus={getProfileStatus(user)}
            privatePhotoRequested={user?.interaction?.private_photo_requested}
            privatePhotoApproval={user?.interaction?.private_photo_approval}
            requestedMyPrivatePhoto={
              user?.interaction?.requested_my_private_photo
            }
            approvedMyPrivatePhoto={
              user?.interaction?.approved_my_private_photo
            }
            isOnline={user?.user?.activity?.is_online}
            isBlocked={user?.interaction?.is_blocked}
            isSilentInteraction={user?.interaction?.should_silent_interactions}
            isFavorited={user?.interaction?.is_favorited}
            isCallEnabled={
              user?.private_call_settings?.enable_calls &&
              user?.private_call_settings?.system_private_calls_enabled
            }
            isSugarbaby={
              user?.role === userConst.userRole.sugarbaby ||
              user?.role === userConst.userRole.maleSugarbaby
            }
            role={user?.role}
            // Livestream
            channelId={user?.live_stream?.channel_id}
            livestreamerId={user?.id}
            followerCount={null}
            viewerCount={user?.live_stream?.viewer_count}
            diamondCount={null}
            isFollowing={null}
            // Card Utility
            cardStatus={userCardConst.cardStatus.videoCall}
            key={index}
          />
        ))}
      </div>
    );
  } else if (
    firstLoad &&
    (getVideoCallSearchFetching || getVideoCallSearchLoading)
  ) {
    videoCallListView = <Spinner />;
  } else {
    videoCallListView = <EmptyList />;
  }

  return (
    <div id="video-call-video-call-tab-subcomponent" ref={scrollContainerRef}>
      <PullToRefresh
        className="pull-to-refresh-container"
        onRefresh={pullToRefreshFunc}
        refreshingContent={t("common.release")}
        pullingContent={t("common.pull_to_refresh")}
        isPullable={!getVideoCallSearchFetching && !getVideoCallSearchLoading}
      >
        <div className="padding-container">
          {!isDesktop && <Banner type={"video-call"} view={"carousel"} />}

          <div className="video-call-list-container">
            <InfiniteScroll
              pageStart={0}
              loadMore={infiniteScrollFunc}
              hasMore={videoCallSearchPagination ? true : false}
              loader={<Spinner key={0} />}
              useWindow={false}
              getScrollParent={() =>
                document.getElementById(
                  "video-call-video-call-tab-subcomponent"
                )
              }
              threshold={10}
            >
              {videoCallListView}

              {videoCallSearchLists?.length > 0 &&
                !videoCallSearchPagination && (
                  <ResultEndCard
                    type={
                      isDaddy
                        ? searchConst.endCardType.generic
                        : searchConst.endCardType.videoCallSb
                    }
                  />
                )}
            </InfiniteScroll>
          </div>
        </div>
      </PullToRefresh>
    </div>
  );
};

export default VideoCallTab;
