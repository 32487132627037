// General
import "./video-call.scss";
import { useEffect } from "react";
// Services
import { sessionService } from "../../../services/session.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVideoCallTabValue } from "../../../redux/store/privateCallStore";
import { updateOnboardingVideoCallDialog } from "../../../redux/store/dialogStore";
// React Swipeable Views
import SwipeableViews from "react-swipeable-views/lib/SwipeableViews";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Tabs, Tab, Box, useTheme, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Banner from "../../shared/elements/banner/banner";
import VideoCallTab from "./video-call-tab/video-call-tab";
import VideoCallRecentTab from "./video-call-recent-tab/video-call-recent-tab";

const VideoCall = () => {
  // MUI variables
  const theme = useTheme();

  // Redux variables
  const videoCallTabValue = useSelector(
    (state) => state.privateCall.videoCallTabValue
  );
  const dispatch = useDispatch();

  // MUI variables
  const isDesktop = useMediaQuery("(min-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VideoCall-Page",
      },
    });

    // Check if "has-shown-video-call-dialog" in local storage is true or false
    if (!sessionService.getHasShownVideoCallDialog()) {
      dispatch(updateOnboardingVideoCallDialog(true));
    }
  }, []);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-VideoCall-Page-TabChangeSwipe-Button-${
          newValue === 0 ? "PrivateRoom" : "Recent"
        }`,
      },
    });

    dispatch(updateVideoCallTabValue(newValue));
  };
  const onTabChangeBySwipe = (index) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-VideoCall-Page-TabChangeSwipe-Button-${
          index === 0 ? "PrivateRoom" : "Recent"
        }`,
      },
    });

    dispatch(updateVideoCallTabValue(index));
  };

  return (
    <div id="video-call-page">
      {isDesktop && (
        <div className="desktop-banner-container">
          <Banner type={"video-call"} view={"carousel"} />
        </div>
      )}

      <div className="max-width-container">
        <div className="tab-container">
          <Tabs
            className="custom-tabs"
            value={videoCallTabValue}
            onChange={onTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
            variant={isDesktop ? "standard" : "fullWidth"}
          >
            <Tab label={t("1on1.private_room")} {...a11yProps(0)} />
            <Tab label={t("inbox.recent")} {...a11yProps(1)} />
          </Tabs>

          <SwipeableViews
            className="swipeable-container"
            containerStyle={{
              transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
            }}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={videoCallTabValue}
            onChangeIndex={onTabChangeBySwipe}
            disabled={true}
          >
            <TabPanel
              className="tab-panel"
              value={videoCallTabValue}
              index={0}
              dir={theme.direction}
            >
              <VideoCallTab />
            </TabPanel>

            <TabPanel
              className="tab-panel"
              value={videoCallTabValue}
              index={1}
              dir={theme.direction}
            >
              <VideoCallRecentTab />
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </div>
  );
};

export default VideoCall;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
          {/* <Typography component={"div"}>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
