// General
import "./buy-coin-dialog.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAddPaymentMethodDialog,
  updateBuyCoinsDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useSecurionPaymentFlow from "../../../utility/custom-hooks/useSecurionPaymentFlow-hook";
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const BuyCoinDialog = () => {
  // Redux variables
  const buyCoinsDialog = useSelector((state) => state.dialog.buyCoinsDialog);
  const selectedCoinPackage = useSelector(
    (state) => state.products.selectedCoinPackage
  );
  const cardDetail = useSelector((state) => state.payment.cardDetail);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const securionPaymentFlow = useSecurionPaymentFlow();
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!buyCoinsDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-BuyCoin-Dialog",
      },
    });
  }, [buyCoinsDialog]);

  // Event Handlers | Button
  const onPayNow = () => {
    securionPaymentFlow();
  };
  const onUseDifferentCard = () => {
    // onCloseDialog();

    // Route based
    // onNavigate(routeConst.payment.add.path);

    // Dialog based
    dispatch(updateAddPaymentMethodDialog(true));
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateBuyCoinsDialog(false));
  };

  return (
    <Dialog
      className="custom-radius10-dialog"
      open={buyCoinsDialog}
      onClose={onCloseDialog}
    >
      <div id="buy-coin-dialog">
        <div className="dialog-header">
          <div className="close-button-container" onClick={onCloseDialog}>
            {getIcon("closeIcon", "close-button")}
          </div>
        </div>

        <div className="padding-container">
          <div className="sb-coin-icon-container">
            {getIcon("sbCoinTickIcon", "sb-coin-icon")}
          </div>

          <div className="subheader">
            {t("purchase.pay_amount", {
              amount: `${selectedCoinPackage?.display_currency || "-"} ${
                (selectedCoinPackage?.discounted_amount ??
                  selectedCoinPackage?.amount) ||
                "-"
              }`,
              coins: selectedCoinPackage?.quantity?.toLocaleString() || 0,
            })}

            <span className="sb-coin-icon-span-container">
              {getIcon("sbCoinIcon", "sb-coin-icon")}
            </span>
          </div>

          <div className="text">
            {t("purchase.pay_desc_with_last4digit", {
              last4digit: cardDetail[0]?.last4 || "XXXX",
              amount: `${selectedCoinPackage?.display_currency || "-"} ${
                (selectedCoinPackage?.discounted_amount ??
                  selectedCoinPackage?.amount) ||
                "-"
              }`,
            })}
          </div>

          <div className="buttons-container">
            <div className="pay-button" onClick={onPayNow}>
              {t("purchase.pay_now")}
            </div>

            <div className="different-card-button" onClick={onUseDifferentCard}>
              {t("purchase.select_new_payment_option")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default BuyCoinDialog;
