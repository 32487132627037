// General
import "./app-settings.scss";
// Redux
import { useDispatch } from "react-redux";
import { updateConfirmAppResetDialog } from "../../../../redux/store/dialogStore";
// Material UI
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// i18next
import { useTranslation } from "react-i18next";
// Service Worker
import * as serviceWorkerRegistration from "../../../../serviceWorkerRegistration";
// Custom Hooks
import useLogout from "../../../utility/custom-hooks/useLogout-hook";

const AppSettings = () => {
  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onLogout = useLogout();

  // Event Handlers | Button
  const onConfirmAppReset = () => {
    dispatch(updateConfirmAppResetDialog(true));
  };
  const onClearLocalCache = () => {
    onLogout();
  };

  return (
    <div id="settings-app-settings-subcomponent">
      <div className="padding-container">
        <div className="account-label">{t("settings.app")}</div>

        <div className="buttons-list-container">
          <div className="button-container" onClick={onConfirmAppReset}>
            <div className="button-label">Restart SW</div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>

          <div className="button-container" onClick={onClearLocalCache}>
            <div className="button-label">Clear Local Cache</div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppSettings;
