// General
import "./one-on-one-video-call-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateStreamerCenterLearnMoreDialog } from "../../../../redux/store/dialogStore";
// React Swipeable Views
import SwipeableViews from "react-swipeable-views/lib/SwipeableViews";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Tabs, Tab, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import Spinner from "../../elements/spinner/spinner";

const OneOnOneVideoCall = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const streamerCenterLearnMoreDialog = useSelector(
    (state) => state.dialog.streamerCenterLearnMoreDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const theme = useTheme();
  const [value, setValue] = useState(0);

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!streamerCenterLearnMoreDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D11",
      },
    });

    getUserAio(null, true);
  }, [streamerCenterLearnMoreDialog]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updateStreamerCenterLearnMoreDialog(false));
  };
  const onRedirect = (image) => {
    switch (image?.cta) {
      case "private_call_search":
        onCloseDialog();
        onNavigate(routeConst.live.path);
        break;
      default:
        window.open(image?.url);
        break;
    }
  };

  return (
    <Dialog
      fullScreen
      open={streamerCenterLearnMoreDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      {getUserAioFetching ? (
        <Spinner />
      ) : (
        <div id="one-on-one-video-call-dialog">
          <div className="top-bar-container">
            <div className="close-icon-container" onClick={onCloseDialog}>
              <ChevronLeftIcon className="close-icon" />
            </div>
          </div>

          <div className="dialog-body-container">
            <div className="first-section-container">
              {getUserAioData?.data?.private_calls?.landing_pages?.private_call_landing?.map(
                (image, index) => (
                  <img
                    className="first-section-image"
                    src={image?.graphic_url}
                    onClick={() => onRedirect(image)}
                    key={index}
                  />
                )
              )}
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default OneOnOneVideoCall;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
