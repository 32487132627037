// General
import "./top-bar.scss";
// Redux
import { useDispatch } from "react-redux";
import {
  resetSearchFilterStore,
  updateIsFiltered,
} from "../../../../../redux/store/searchFilterStore";
import {
  resetSearchBodyFilter,
  resetTorListSelected,
} from "../../../../../redux/store/searchStore";
import { updateSearchFilterDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// i18next
import { useTranslation } from "react-i18next";

const TopBar = (props) => {
  const { applyFilterCallback } = props;

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onClose = () => {
    dispatch(updateSearchFilterDialog(false));
  };
  const onResetFilter = () => {
    dispatch(resetSearchBodyFilter());
    dispatch(resetSearchFilterStore());
    dispatch(resetTorListSelected());
    dispatch(updateIsFiltered());

    dispatch(updateSearchFilterDialog(false));
  };

  return (
    <div id="search-filter-top-bar-subcomponent">
      <div className="padding-container">
        <div className="left-container">
          <div className="back-icon-container" onClick={onClose}>
            <KeyboardArrowDownIcon />
          </div>
        </div>

        <div className="middle-container">
          <div className="middle-label">{t("filter.filters")}</div>
        </div>

        <div className="right-container" onClick={onResetFilter}>
          <div className="reset-button">{t("filter.reset")}</div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
