// General
import "./livestreaming-tab.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
// Static Data
import leaderboardConst from "../../../../const/leaderboardConst";
import routeConst from "../../../../const/routeConst";
// React Swipeable Views
import SwipeableViews from "react-swipeable-views/lib/SwipeableViews";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLivestreamingTab } from "../../../../redux/store/leaderboardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Tabs, Tab, Box, useTheme } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";
import EventsTab from "./events-tab/events-tab";
import DailyTab from "../../../shared/leaderboard/daily-tab/daily-tab";
import WeeklyTab from "../../../shared/leaderboard/weekly-tab/weekly-tab";
import MonthlyTab from "../../../shared/leaderboard/monthly-tab/monthly-tab";

const LivestreamingTab = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const livestreamingTab = useSelector(
    (state) => state.leaderboard.livestreamingTab
  );
  const dispatch = useDispatch();

  // MUI variables
  const theme = useTheme();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-19-19.1",
      },
    });

    getUserAio(null, true);
  }, []);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, index) => {
    dispatch(updateLivestreamingTab(index));
  };
  const onTabChangeBySwipe = (index) => {
    dispatch(updateLivestreamingTab(index));
  };

  // Render Functions | API Response
  if (getUserAioFetching || getUserAioLoading) {
    return <Spinner />;
  } else if (getUserAioSuccess) {
    if (getUserAioData?.status === 1) {
      return (
        <div id="leaderboard-livestreaming-tab-subcomponent">
          <div className="tab-container">
            <Tabs
              className="custom-tabs"
              value={livestreamingTab}
              onChange={onTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
              centered
            >
              {getUserAioData?.data?.leaderboard_config?.map((tab, index) => (
                <Tab label={t(tab?.name)} {...a11yProps(index)} key={index} />
              ))}
            </Tabs>

            <SwipeableViews
              className="swipeable-container"
              containerStyle={{
                transition:
                  "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
              }}
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={livestreamingTab}
              onChangeIndex={onTabChangeBySwipe}
            >
              {getUserAioData?.data?.leaderboard_config?.map((tab, index) => (
                <TabPanel
                  className="tab-panel"
                  value={livestreamingTab}
                  index={index}
                  dir={theme.direction}
                  key={index}
                >
                  {!tab?.board_period_type ? (
                    <EventsTab />
                  ) : tab?.board_period_type ===
                    leaderboardConst.boardPeriodType.daily ? (
                    <DailyTab state={leaderboardConst.type.livestreaming} />
                  ) : tab?.board_period_type ===
                    leaderboardConst.boardPeriodType.weekly ? (
                    <WeeklyTab state={leaderboardConst.type.livestreaming} />
                  ) : tab?.board_period_type ===
                    leaderboardConst.boardPeriodType.monthly ? (
                    <MonthlyTab state={leaderboardConst.type.livestreaming} />
                  ) : null}
                </TabPanel>
              ))}
            </SwipeableViews>
          </div>
        </div>
      );
    }
  } else if (getUserAioError) {
    if (getUserAioErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default LivestreamingTab;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
