// General
import "./description.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
import { removeHTMLEscape } from "../../../../../services/regex.service";
// Static Data
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAboutMe,
  updateAboutMeNewContent,
  updateLookingFor,
  updateLookingForNewContent,
} from "../../../../../redux/store/editProfileStore";
import { updateSuccessToast } from "../../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { Divider, TextField } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import ModerationStatus from "../../moderation-status/moderation-status";

const Description = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [aboutMeErrorMessage, setAboutMeErrorMessage] = useState("");
  const [aboutMeFocus, setAboutMeFocus] = useState(false);
  const [aboutMeValid, setAboutMeValid] = useState(false);
  const [aboutMeError, setAboutMeError] = useState(false);
  const [lookingForErrorMessage, setLookingForErrorMessage] = useState("");
  const [lookingForFocus, setLookingForFocus] = useState(false);
  const [lookingForValid, setLookingForValid] = useState(false);
  const [lookingForError, setLookingForError] = useState(false);

  // Redux variables
  const aboutMe = useSelector((state) => state.editProfile.aboutMe);
  const lookingFor = useSelector((state) => state.editProfile.lookingFor);
  const aboutMeNewContent = useSelector(
    (state) => state.editProfile.aboutMeNewContent
  );
  const aboutMeStatus = useSelector((state) => state.editProfile.aboutMeStatus);
  const lookingForNewContent = useSelector(
    (state) => state.editProfile.lookingForNewContent
  );
  const lookingForStatus = useSelector(
    (state) => state.editProfile.lookingForStatus
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Update User Profile API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      // Backend returning empty if success
      const toastObj = {
        message: t("signup.profile_updated"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));
    } else if (updateUserProfileError) {
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    if (!isDescriptionValid()) return;

    let obj = {};

    // About Me
    if (
      aboutMeStatus === userConst.profileGeneralApprovalStatus.inReview ||
      aboutMeStatus === userConst.profileGeneralApprovalStatus.rejected
    ) {
      if (
        getProfileData?.data?.profile_details_approval?.aboutme?.new_content !==
        aboutMeNewContent
      ) {
        obj.aboutme = aboutMeNewContent;
      }
    } else {
      if (getProfileData?.data?.aboutme !== aboutMe) {
        obj.aboutme = aboutMe;
      }
    }

    // Looking For
    if (
      lookingForStatus === userConst.profileGeneralApprovalStatus.inReview ||
      lookingForStatus === userConst.profileGeneralApprovalStatus.rejected
    ) {
      if (
        getProfileData?.data?.profile_details_approval?.lookingfor
          ?.new_content !== lookingForNewContent
      ) {
        obj.lookingfordetails = lookingForNewContent;
      }
    } else {
      if (getProfileData?.data?.lookingfor !== lookingFor) {
        obj.lookingfordetails = lookingFor;
      }
    }

    updateUserProfile(obj);
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "about-me":
        if (
          aboutMeStatus === userConst.profileGeneralApprovalStatus.inReview ||
          aboutMeStatus === userConst.profileGeneralApprovalStatus.rejected
        ) {
          dispatch(updateAboutMeNewContent(event.target.value));
        } else {
          dispatch(updateAboutMe(event.target.value));
        }
        break;
      case "looking-for":
        if (
          lookingForStatus ===
            userConst.profileGeneralApprovalStatus.inReview ||
          lookingForStatus === userConst.profileGeneralApprovalStatus.rejected
        ) {
          dispatch(updateLookingForNewContent(event.target.value));
        } else {
          dispatch(updateLookingFor(event.target.value));
        }
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const aboutMeValidation = () => {
    let valid = true;
    let error = false;

    if (
      aboutMeStatus === userConst.profileGeneralApprovalStatus.inReview ||
      aboutMeStatus === userConst.profileGeneralApprovalStatus.rejected
    ) {
      if (aboutMeNewContent === "") {
        setAboutMeErrorMessage(t("signup.about_me_is_required"));
        valid = false;
        error = true;
      } else if (
        // Update TW to 10 when backend is ready
        i18n.language.toLowerCase().includes("zh-tw") &&
        aboutMeNewContent?.length < 25
      ) {
        setAboutMeErrorMessage(
          t("signup.about_me_must_be_at_least_n_characters", {
            n: 25,
          })
        );
        valid = false;
        error = true;
      } else if (
        i18n.language.toLowerCase().includes("zh-tw") === false &&
        aboutMeNewContent?.length < 25
      ) {
        setAboutMeErrorMessage(
          t("signup.about_me_must_be_at_least_n_characters", {
            n: 25,
          })
        );
        valid = false;
        error = true;
      } else if (aboutMeNewContent?.length > 250) {
        setAboutMeErrorMessage(
          t("signup.about_me_must_be_less_than_n_characters", {
            n: 250,
          })
        );
        valid = false;
        error = true;
      } else if (removeHTMLEscape.test(aboutMeNewContent)) {
        valid = false;
        error = true;
        dispatch(updateAboutMeNewContent(""));

        // Send to Sentry
        Sentry.captureMessage("PWA-25-AboutMe-Input-HTML-Escape-Detected");

        // Send to GTM
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-25-AboutMe-Input-HTML-Escape-Detected",
          },
        });
      } else {
        setAboutMeErrorMessage("");
        valid = true;
        error = false;
      }
    } else {
      if (aboutMe === "") {
        setAboutMeErrorMessage(t("signup.about_me_is_required"));
        valid = false;
        error = true;
      } else if (
        // Update TW to 10 when backend is ready
        i18n.language.toLowerCase().includes("zh-tw") &&
        aboutMe?.length < 25
      ) {
        setAboutMeErrorMessage(
          t("signup.about_me_must_be_at_least_n_characters", {
            n: 25,
          })
        );
        valid = false;
        error = true;
      } else if (
        i18n.language.toLowerCase().includes("zh-tw") === false &&
        aboutMe?.length < 25
      ) {
        setAboutMeErrorMessage(
          t("signup.about_me_must_be_at_least_n_characters", {
            n: 25,
          })
        );
        valid = false;
        error = true;
      } else if (aboutMe?.length > 250) {
        setAboutMeErrorMessage(
          t("signup.about_me_must_be_less_than_n_characters", {
            n: 250,
          })
        );
        valid = false;
        error = true;
      } else if (removeHTMLEscape.test(aboutMe)) {
        valid = false;
        error = true;
        dispatch(updateAboutMe(""));

        // Send to Sentry
        Sentry.captureMessage("PWA-25-AboutMe-Input-HTML-Escape-Detected");

        // Send to GTM
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-25-AboutMe-Input-HTML-Escape-Detected",
          },
        });
      } else {
        setAboutMeErrorMessage("");
        valid = true;
        error = false;
      }
    }

    setAboutMeValid(valid);
    setAboutMeError(error);
  };
  const lookingForValidation = () => {
    let valid = true;
    let error = false;

    if (
      lookingForStatus === userConst.profileGeneralApprovalStatus.inReview ||
      lookingForStatus === userConst.profileGeneralApprovalStatus.rejected
    ) {
      if (lookingForNewContent === "") {
        setLookingForErrorMessage(t("signup.looking_for_is_required"));
        valid = false;
        error = true;
      } else if (
        // Update to 10 when backend is ready
        i18n.language.toLowerCase().includes("zh-tw") &&
        lookingForNewContent?.length < 25
      ) {
        setLookingForErrorMessage(
          t("signup.looking_for_must_be_at_least_n_characters", { n: 25 })
        );
        valid = false;
        error = true;
      } else if (
        i18n.language.toLowerCase().includes("zh-tw") === false &&
        lookingForNewContent?.length < 25
      ) {
        setLookingForErrorMessage(
          t("signup.looking_for_must_be_at_least_n_characters", { n: 25 })
        );
        valid = false;
        error = true;
      } else if (lookingForNewContent?.length > 250) {
        setLookingForErrorMessage(
          t("signup.looking_for_must_be_less_than_n_characters", { n: 250 })
        );
        valid = false;
        error = true;
      } else if (removeHTMLEscape.test(lookingForNewContent)) {
        valid = false;
        error = true;
        dispatch(updateLookingForNewContent(""));

        // Send to Sentry
        Sentry.captureMessage("PWA-25-LookingFor-Input-HTML-Escape-Detected");

        // Send to GTM
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-25-LookingFor-Input-HTML-Escape-Detected",
          },
        });
      } else {
        setLookingForErrorMessage("");
        valid = true;
        error = false;
      }
    } else {
      if (lookingFor === "") {
        setLookingForErrorMessage(t("signup.looking_for_is_required"));
        valid = false;
        error = true;
      } else if (
        // Update to 10 when backend is ready
        i18n.language.toLowerCase().includes("zh-tw") &&
        lookingFor?.length < 25
      ) {
        setLookingForErrorMessage(
          t("signup.looking_for_must_be_at_least_n_characters", { n: 25 })
        );
        valid = false;
        error = true;
      } else if (
        i18n.language.toLowerCase().includes("zh-tw") === false &&
        lookingFor?.length < 25
      ) {
        setLookingForErrorMessage(
          t("signup.looking_for_must_be_at_least_n_characters", { n: 25 })
        );
        valid = false;
        error = true;
      } else if (lookingFor?.length > 250) {
        setLookingForErrorMessage(
          t("signup.looking_for_must_be_less_than_n_characters", { n: 250 })
        );
        valid = false;
        error = true;
      } else if (removeHTMLEscape.test(lookingFor)) {
        valid = false;
        error = true;
        dispatch(updateLookingFor(""));

        // Send to Sentry
        Sentry.captureMessage("PWA-25-LookingFor-Input-HTML-Escape-Detected");

        // Send to GTM
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-25-LookingFor-Input-HTML-Escape-Detected",
          },
        });
      } else {
        setLookingForErrorMessage("");
        valid = true;
        error = false;
      }
    }

    setLookingForValid(valid);
    setLookingForError(error);
  };
  const isDescriptionValid = () => {
    const aboutMeValid =
      aboutMeStatus === userConst.profileGeneralApprovalStatus.inReview ||
      aboutMeStatus === userConst.profileGeneralApprovalStatus.rejected
        ? aboutMeNewContent?.length >= 25 && aboutMeNewContent?.length <= 250
        : aboutMe?.length >= 25 && aboutMe?.length <= 250;
    const lookingForValid =
      lookingForStatus === userConst.profileGeneralApprovalStatus.inReview ||
      lookingForStatus === userConst.profileGeneralApprovalStatus.rejected
        ? lookingForNewContent?.length >= 25 &&
          lookingForNewContent?.length <= 250
        : lookingFor?.length >= 25 && lookingFor?.length <= 250;

    if (aboutMeValid && lookingForValid) {
      return true;
    } else {
      return false;
    }
  };
  const returnAboutMeValue = () => {
    if (
      aboutMeStatus === userConst.profileGeneralApprovalStatus.inReview ||
      aboutMeStatus === userConst.profileGeneralApprovalStatus.rejected
    ) {
      return aboutMeNewContent;
    } else {
      return aboutMe;
    }
  };
  const returnLookingForValue = () => {
    if (
      lookingForStatus === userConst.profileGeneralApprovalStatus.inReview ||
      lookingForStatus === userConst.profileGeneralApprovalStatus.rejected
    ) {
      return lookingForNewContent;
    } else {
      return lookingFor;
    }
  };
  const getTranslatedStatus = (status) => {
    switch (status) {
      case userConst.profileGeneralApprovalStatus.inReview:
        return t("common.in_review");
      case userConst.profileGeneralApprovalStatus.rejected:
        return t("common.rejected");
      default:
        return "";
    }
  };

  // Check for about me and looking for validation
  useEffect(() => {
    aboutMeValidation();
    lookingForValidation();
  }, [
    aboutMe,
    aboutMeFocus,
    aboutMeFocus,
    aboutMeErrorMessage,
    lookingFor,
    lookingForFocus,
    lookingForErrorMessage,
  ]);

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-description-subcomponent">
        <div className="description-label-container">
          <div className="description-label">{t("profile.description")}</div>

          <div className="expand-icon-container">
            {getIcon("chevronRightIcon", "expand-icon")}
          </div>
        </div>

        {/* About Me */}
        <div className="form-field-container">
          <div className="label-container">
            <div className={`label`}>{t("profile.about_me")}</div>

            {(getProfileData?.data?.aboutme ||
              getProfileData?.data?.profile_details_approval?.aboutme
                ?.new_content) &&
              aboutMeStatus &&
              aboutMeStatus !==
                userConst.profileGeneralApprovalStatus.approved && (
                <div className="approval-status-container">
                  {getTranslatedStatus(aboutMeStatus)}
                </div>
              )}
          </div>

          <div className="form-container">
            <TextField
              className={"form-field"}
              placeholder={t("profile.about_me")}
              value={returnAboutMeValue() || ""}
              error={aboutMeError}
              onChange={onInputChange("about-me")}
              multiline
              rows={5}
              helperText={aboutMeErrorMessage}
              variant="standard"
              InputProps={{ disableUnderline: true }}
            ></TextField>

            <div className="character-limit-label">
              {aboutMeStatus ===
                userConst.profileGeneralApprovalStatus.inReview ||
              aboutMeStatus === userConst.profileGeneralApprovalStatus.rejected
                ? aboutMeNewContent?.length
                : aboutMe?.length}
              /250
            </div>
          </div>
        </div>

        {/* Looking For */}
        <div className="form-field-container">
          <div className="label-container">
            <div className={`label`}>{t("profile.looking_for")}</div>

            {(getProfileData?.data?.lookingfor ||
              getProfileData?.data?.profile_details_approval?.lookingfor
                ?.new_content) &&
              lookingForStatus &&
              lookingForStatus !==
                userConst.profileGeneralApprovalStatus.approved && (
                <div className="approval-status-container">
                  {getTranslatedStatus(lookingForStatus)}
                </div>
              )}
          </div>

          <div className="form-container">
            <TextField
              className={"form-field"}
              placeholder={t("profile.looking_for")}
              value={returnLookingForValue() || ""}
              error={lookingForError}
              onChange={onInputChange("looking-for")}
              multiline
              rows={5}
              helperText={lookingForErrorMessage}
              variant="standard"
              InputProps={{ disableUnderline: true }}
            ></TextField>

            <div className="character-limit-label">
              {lookingForStatus ===
                userConst.profileGeneralApprovalStatus.inReview ||
              lookingForStatus ===
                userConst.profileGeneralApprovalStatus.rejected
                ? lookingForNewContent?.length
                : lookingFor?.length}
              /250
            </div>
          </div>
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div id="desktop-edit-profile-description-subcomponent">
        {/* In Review */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.inReview}
        />

        {/* Rejected */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.rejected}
        />

        <div className="description-static-label-container">
          <div className="description-label">{t("profile.description")}</div>
          <Divider className="short-divider" />
        </div>

        <div className="form-field-padding-container">
          {/* About Me */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label`}>{t("profile.about_me")}</div>

              {(getProfileData?.data?.aboutme ||
                getProfileData?.data?.profile_details_approval?.aboutme
                  ?.new_content) &&
                aboutMeStatus &&
                aboutMeStatus !==
                  userConst.profileGeneralApprovalStatus.approved && (
                  <div className="approval-status-container">
                    {getTranslatedStatus(aboutMeStatus)}
                  </div>
                )}
            </div>

            <div className="form-container">
              <TextField
                className={"form-field"}
                placeholder={t("profile.about_me")}
                value={returnAboutMeValue() || ""}
                error={aboutMeError}
                onChange={onInputChange("about-me")}
                multiline
                rows={5}
                helperText={aboutMeErrorMessage}
                variant="standard"
                InputProps={{ disableUnderline: true }}
              ></TextField>

              <div className="character-limit-label">
                {aboutMeStatus ===
                  userConst.profileGeneralApprovalStatus.inReview ||
                aboutMeStatus ===
                  userConst.profileGeneralApprovalStatus.rejected
                  ? aboutMeNewContent?.length
                  : aboutMe?.length}
                /250
              </div>
            </div>
          </div>

          {/* Looking For */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label`}>{t("profile.looking_for")}</div>

              {(getProfileData?.data?.lookingfor ||
                getProfileData?.data?.profile_details_approval?.lookingfor
                  ?.new_content) &&
                lookingForStatus &&
                lookingForStatus !==
                  userConst.profileGeneralApprovalStatus.approved && (
                  <div className="approval-status-container">
                    {getTranslatedStatus(lookingForStatus)}
                  </div>
                )}
            </div>

            <div className="form-container">
              <TextField
                className={"form-field"}
                placeholder={t("profile.looking_for")}
                value={returnLookingForValue() || ""}
                error={lookingForError}
                onChange={onInputChange("looking-for")}
                multiline
                rows={5}
                helperText={lookingForErrorMessage}
                variant="standard"
                InputProps={{ disableUnderline: true }}
              ></TextField>

              <div className="character-limit-label">
                {lookingForStatus ===
                  userConst.profileGeneralApprovalStatus.inReview ||
                lookingForStatus ===
                  userConst.profileGeneralApprovalStatus.rejected
                  ? lookingForNewContent?.length
                  : lookingFor?.length}
                /250
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-section-container">
          <div
            className={`save-button ${
              isDescriptionValid() ? "" : "disabled-button"
            }`}
            onClick={onSaveDetails}
          >
            {updateUserProfileLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.save")
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default Description;
