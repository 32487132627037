// General
import "./game-list-tab.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCasinoPeriodTab } from "../../../../../redux/store/leaderboardStore";
// React Swipeable Views
import SwipeableViews from "react-swipeable-views/lib/SwipeableViews";
// Material UI
import { Tabs, Tab, Box, useTheme } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Components
import DailyTab from "../../../../shared/leaderboard/daily-tab/daily-tab";
import WeeklyTab from "../../../../shared/leaderboard/weekly-tab/weekly-tab";
import MonthlyTab from "../../../../shared/leaderboard/monthly-tab/monthly-tab";

const GameListTab = (props) => {
  const { gameId } = props;

  // Redux variables
  const casinoPeriodTab = useSelector(
    (state) => state.leaderboard.casinoPeriodTab
  );
  const dispatch = useDispatch();

  // MUI variables
  const theme = useTheme();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {}, []);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, index) => {
    dispatch(updateCasinoPeriodTab(index));
  };
  const onTabChangeBySwipe = (index) => {
    dispatch(updateCasinoPeriodTab(index));
  };

  return (
    <div id="leaderboard-one-on-one-tab-subcomponent">
      <div className="tab-container">
        <Tabs
          className="custom-tabs"
          value={casinoPeriodTab}
          onChange={onTabChange}
          TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
          centered
        >
          <Tab label={t("leaderboard.tabs.daily")} {...a11yProps(0)} />
          <Tab label={t("leaderboard.tabs.weekly")} {...a11yProps(1)} />
          <Tab label={t("leaderboard.tabs.monthly")} {...a11yProps(2)} />
        </Tabs>

        <SwipeableViews
          className="swipeable-container"
          containerStyle={{
            transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
          }}
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={casinoPeriodTab}
          onChangeIndex={onTabChangeBySwipe}
        >
          <TabPanel
            className="tab-panel"
            value={casinoPeriodTab}
            index={0}
            dir={theme.direction}
          >
            <DailyTab state={"casino"} gameId={gameId} />
          </TabPanel>

          <TabPanel
            className="tab-panel"
            value={casinoPeriodTab}
            index={1}
            dir={theme.direction}
          >
            <WeeklyTab state={"casino"} gameId={gameId} />
          </TabPanel>

          <TabPanel
            className="tab-panel"
            value={casinoPeriodTab}
            index={2}
            dir={theme.direction}
          >
            <MonthlyTab state={"casino"} gameId={gameId} />
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
};

export default GameListTab;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
