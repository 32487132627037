// General
import "./edit-profile.scss";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Components
import BasicInformation from "./basic-information/basic-information";
import TermsOfRelationship from "./terms-of-relationship/terms-of-relationship";
import FinancialInformation from "./financial-information/financial-information";
import Location from "./location/location";
import Appearance from "./apperance/appearance";
import PersonalInformation from "./personal-information/personal-information";
import Description from "./description/description";

const EditProfile = () => {
  return (
    <div id="profile-edit-edit-profile-subcomponent">
      <BasicInformation mode={utilityConst.editProfileMode.mobile} />

      <TermsOfRelationship mode={utilityConst.editProfileMode.mobile} />

      <FinancialInformation mode={utilityConst.editProfileMode.mobile} />

      <Location mode={utilityConst.editProfileMode.mobile} />

      <Appearance mode={utilityConst.editProfileMode.mobile} />

      <PersonalInformation mode={utilityConst.editProfileMode.mobile} />

      <Description mode={utilityConst.editProfileMode.mobile} />
    </div>
  );
};

export default EditProfile;
