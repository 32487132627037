// General
import "./terms-of-relationship.scss";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";
// Components
import TorTag from "../../tor-tag/tor-tag";

const TermsOfRelationship = (props) => {
  const { enableTitle = true, isShared = false, sharedTorTags } = props;

  // Redux variables
  const torTags = useSelector((state) => state.userCard.torTags);
  const selectedTorAfterApply = useSelector(
    (state) => state.search.selectedTorAfterApply
  );

  // i18next variables
  const { t } = useTranslation();

  // Utility Functions
  const returnMatchTor = (userTor) => {
    if (userTor?.matched) {
      return true;
    } else if (selectedTorAfterApply.includes(userTor?.id)) {
      return true;
    } else {
      return false;
    }
  };

  if (isShared) {
    return (
      <div id="profile-view-terms-of-relationship-subcomponent">
        <div className="padding-container">
          {enableTitle && (
            <div className="tor-label">
              {t("profile.terms_of_relationship")}
            </div>
          )}

          <div className="tor-list-container">
            {sharedTorTags?.map((tor, index) => (
              <TorTag
                torName={tor?.locale}
                description={tor?.description}
                blur={tor?.blurred_out}
                match={tor?.matched}
                theme={tor?.theme}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    if (torTags) {
      return (
        <div id="profile-view-terms-of-relationship-subcomponent">
          <div className="padding-container">
            {enableTitle && (
              <div className="tor-label">
                {t("profile.terms_of_relationship")}
              </div>
            )}

            <div className="tor-list-container">
              {torTags?.map((tor, index) => (
                <TorTag
                  torName={tor?.locale}
                  description={tor?.description}
                  blur={tor?.blurred_out}
                  match={returnMatchTor(tor)}
                  theme={tor?.theme}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
      );
    }
  }
};

export default TermsOfRelationship;
