// General
import { useEffect } from "react";
// Service
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
import { sessionService } from "../../../../services/session.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateNotificationPermissionPromptDialog } from "../../../../redux/store/dialogStore";
import { updateSuccessToast } from "../../../../redux/store/toastStore";
// react-device-detect
import { isIOS } from "react-device-detect";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Firebase
import { getToken, isSupported } from "firebase/messaging";
import { messaging } from "../../../../firebase";
// i18next
import { useTranslation } from "react-i18next";

const NotificationPermissionMounted = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    // iOS is very strict about notifications API, it needs to be standalone mode and user need to interact with a UI
    // Notification API is supported in iOS version 16.4 and above
    if (!isLoggedIn) return;

    if (sessionService.getNotificationToken()) {
      // Use token and send to server
      getUserAio(
        `?firebase_token=${sessionService.getNotificationToken()}`,
        false
      );

      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-NotificationPermission-Mounted-TokenExist",
        },
      });
    } else if (window.Notification && isSupported()) {
      if (isIOS) {
        if (window.matchMedia("(display-mode: standalone)").matches) {
          if (Notification?.permission === "default") {
            dispatch(updateNotificationPermissionPromptDialog(true));
          } else if (Notification?.permission === "granted") {
            const successToastObj = {
              message: t("push_notification.notification_permission_granted"),
              autoClose: 5000,
            };
            dispatch(updateSuccessToast(successToastObj));

            getToken(messaging, {
              vapidKey:
                "BIBMuzxAc9Qtu3vTJIVc44tJuWMlO9vcQ2ZMNZEw48B5UomdpE3egYzaJCta5YC5pTFaw5qPDYgXyAaOnwyezxY",
            })
              .then((currentToken) => {
                if (currentToken) {
                  getUserAio(`?firebase_token=${currentToken}`, false);
                  sessionService.setNotificationToken(currentToken);

                  // Send to Sentry
                  Sentry.captureMessage(
                    `PWA-NotificationPermission-Mounted-GetToken-Success`
                  );

                  // Send to GTM
                  TagManager.dataLayer({
                    dataLayer: {
                      event:
                        "PWA-NotificationPermission-Mounted-GetToken-Success",
                    },
                  });
                }
              })
              .catch((err) => {
                console.log(
                  "An error occurred when requesting to receive the token.",
                  err
                );

                const error =
                  "AbortError: Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker";

                // Send to Sentry
                Sentry.captureMessage(
                  `PWA-NotificationPermission-Mounted-GetToken-Error-${err?.toString()}`
                );

                // Send to GTM
                TagManager.dataLayer({
                  dataLayer: {
                    event: "PWA-NotificationPermission-Mounted-GetToken-Error",
                  },
                });

                if (err.toString() === error) {
                  onGetToken();
                }
              });
          }
        }
      } else {
        if (Notification?.permission === "default") {
          dispatch(updateNotificationPermissionPromptDialog(true));
        } else if (Notification?.permission === "granted") {
          const successToastObj = {
            message: t("push_notification.notification_permission_granted"),
            autoClose: 5000,
          };
          dispatch(updateSuccessToast(successToastObj));

          getToken(messaging, {
            vapidKey:
              "BIBMuzxAc9Qtu3vTJIVc44tJuWMlO9vcQ2ZMNZEw48B5UomdpE3egYzaJCta5YC5pTFaw5qPDYgXyAaOnwyezxY",
          })
            .then((currentToken) => {
              if (currentToken) {
                getUserAio(`?firebase_token=${currentToken}`, false);
                sessionService.setNotificationToken(currentToken);

                // Send to Sentry
                Sentry.captureMessage(
                  `PWA-NotificationPermission-Mounted-GetToken-Success`
                );

                // Send to GTM
                TagManager.dataLayer({
                  dataLayer: {
                    event:
                      "PWA-NotificationPermission-Mounted-GetToken-Success",
                  },
                });
              } else {
                // Failed to generate the app registration token

                // Send to Sentry
                Sentry.captureMessage(
                  `PWA-NotificationPermissionPrompt-Dialog-GetToken-NoToken`
                );

                // Send to GTM
                TagManager.dataLayer({
                  dataLayer: {
                    event:
                      "PWA-NotificationPermissionPrompt-Dialog-GetToken-NoToken",
                  },
                });
              }
            })
            .catch((err) => {
              console.log(
                "An error occurred when requesting to receive the token.",
                err
              );

              const error =
                "AbortError: Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker";

              // Send to Sentry
              Sentry.captureMessage(
                `PWA-NotificationPermission-Mounted-GetToken-Error-${err?.toString()}`
              );

              // Send to GTM
              TagManager.dataLayer({
                dataLayer: {
                  event: "PWA-NotificationPermission-Mounted-GetToken-Error",
                },
              });

              if (err.toString() === error) {
                onGetToken();
              }
            });
        }
      }

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-NotificationPermission-Mounted-NoToken",
        },
      });
    }
  }, [isLoggedIn]);

  // Utility Functions
  const onGetToken = () => {
    getToken(messaging, {
      vapidKey:
        "BOlkSkrOScyL4MIOOp5fxsgtOsIN-ZmIqNF0DxuqVlzH4Vf4j4l7MpX_W86AglPimyVBsQiRwBxWKZqEPP7hfLk",
    })
      .then((currentToken) => {
        if (currentToken) {
          sessionService.setNotificationToken(currentToken);

          // Send to Sentry
          Sentry.captureMessage(
            `PWA-NotificationPermission-Mounted-GetToken-Retry-Success`
          );

          // Send to GTM
          TagManager.dataLayer({
            dataLayer: {
              event:
                "PWA-NotificationPermission-Mounted-GetToken-Retry-Success",
            },
          });
        } else {
          // Failed to generate the app registration token

          // Send to Sentry
          Sentry.captureMessage(
            `PWA-NotificationPermissionPrompt-Dialog-GetToken-Retry-NoToken`
          );

          // Send to GTM
          TagManager.dataLayer({
            dataLayer: {
              event:
                "PWA-NotificationPermissionPrompt-Dialog-GetToken-Retry-NoToken",
            },
          });
        }
      })
      .catch((err) => {
        console.log(
          "An error occurred when requesting to receive the token.",
          err
        );

        // Send to Sentry
        Sentry.captureMessage(
          `PWA-NotificationPermission-Mounted-GetToken-Retry-NoToken`
        );

        // Send to GTM
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-NotificationPermission-Mounted-GetToken-Retry-NoToken",
          },
        });
      });
  };

  return <div id="notification-permission-mounted-shadow-component"></div>;
};

export default NotificationPermissionMounted;
