// General
import "./join.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetAioQuery,
  useUpdateProfileMutation,
} from "../../services/data.service";
// Static Data
import routeConst from "../../const/routeConst";
// Enums
import user from "../../enums/user";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateGender, updateLookingFor } from "../../redux/store/signupStore";
import { updateWhatsARoleDialog } from "../../redux/store/dialogStore";
import { updateErrorToast } from "../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Button } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../utility/manager/icon-manager/icon-manager";

const Join = () => {
  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateProfileMutation();

  // Redux variables
  const email = useSelector((state) => state.signup.email);
  const gender = useSelector((state) => state.signup.gender);
  const lookingFor = useSelector((state) => state.signup.lookingFor);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-6",
      },
    });

    getAio();
  }, []);

  // Lifecycle | Check for update | Update Profile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      switch (updateProfileData?.status) {
        case 0:
        case 1:
          onNavigate(routeConst.infoSignup.path);
          break;
        default:
          const toastObj = {
            message: t("common.something_went_wrong"),
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));
          break;
      }
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Event Handlers | Button
  const onNextPage = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Join-Page-To-Account-Signup-Page",
      },
    });

    onNavigate(routeConst.accountSignup.path);
  };
  const onOpenRoleInfoDialog = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-6-6.1",
      },
    });

    dispatch(updateWhatsARoleDialog(true));
  };

  // Event Handlers | MUI Select
  const onGenderSelect = (event) => {
    dispatch(updateGender(event));

    if (event === user.gender.MAN) {
      dispatch(updateLookingFor(user.lookingFor.SUGARBABY));
    } else if (event === user.gender.WOMAN) {
      dispatch(updateLookingFor(user.lookingFor.SUGARDADDY));
    }
  };
  const onLookingForSelect = (event) => {
    dispatch(updateLookingFor(event));
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (gender !== "" && lookingFor !== "") {
      valid = true;
    }

    return valid;
  };
  const getRoles = (gender, lookingFor) => {
    if (gender === "man") {
      if (lookingFor === "sugardaddy") {
        return "sugarbaby";
      } else if (lookingFor === "sugarbaby") {
        return "sugardaddy";
      }
    } else if (gender === "woman") {
      if (lookingFor === "sugardaddy") {
        return "sugarbaby";
      } else if (lookingFor === "sugarbaby") {
        return "sugardaddy";
      }
    }
  };

  return (
    <div id="join-page" className="join-container">
      <div className="max-width-container">
        <div className="navbar-container">
          <div
            className="back-button"
            onClick={() => onNavigate(routeConst.login.path)}
          >
            {getIcon("signupBackIcon", "signup-back-icon")}
          </div>
        </div>

        <div className="top-container">
          <div className="join-title">
            <Trans i18nKey={"signup.join_title"} />
          </div>

          <div className="i-am-container">
            <div className="header">{t("signup.choose_your_role")}</div>
            <div className="selection-container">
              <div
                className={`body-label ${gender === "man" ? "selected" : ""}`}
                onClick={() => onGenderSelect(user.gender.MAN)}
              >
                {t("signup.opt_man")}
              </div>
              <div
                className={`body-label ${gender === "woman" ? "selected" : ""}`}
                onClick={() => onGenderSelect(user.gender.WOMAN)}
              >
                {t("signup.opt_woman")}
              </div>
            </div>
          </div>

          {false && gender !== "" && (
            <div className="looking-for-container">
              <div className="looking-for-label-container">
                <div className="header">{t("signup.im_looking_for")}</div>
                <div className="role-info" onClick={onOpenRoleInfoDialog}>
                  {getIcon("helpIcon", "help-icon")}
                </div>
              </div>

              {gender === "man" && (
                <div className="selection-container">
                  <div
                    className={`body-label ${
                      lookingFor === "sugarbaby" ? "selected" : ""
                    }`}
                    onClick={() =>
                      onLookingForSelect(user.lookingFor.SUGARBABY)
                    }
                  >
                    {t("signup.opt_sugar_baby")}
                  </div>
                  <div
                    className={`body-label ${
                      lookingFor === "sugardaddy" ? "selected" : ""
                    }`}
                    onClick={() =>
                      onLookingForSelect(user.lookingFor.SUGARMOMMY)
                    }
                  >
                    {t("signup.opt_sugar_mommy")}
                  </div>
                </div>
              )}
              {gender === "woman" && (
                <div className="selection-container">
                  <div
                    className={`body-label ${
                      lookingFor === "sugardaddy" ? "selected" : ""
                    }`}
                    onClick={() =>
                      onLookingForSelect(user.lookingFor.SUGARDADDY)
                    }
                  >
                    {t("signup.opt_sugar_daddy")}
                  </div>
                  <div
                    className={`body-label ${
                      lookingFor === "sugarbaby" ? "selected" : ""
                    }`}
                    onClick={() =>
                      onLookingForSelect(user.lookingFor.MALE_SUGARBABY)
                    }
                  >
                    {t("signup.opt_male_sugar_baby")}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="bottom-container">
          <Button
            className={`continue-button ${
              isFormValid() ? "" : "disabled-button"
            }`}
            variant="text"
            onClick={onNextPage}
            disabled={!isFormValid()}
          >
            {t("common.continue")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Join;
