// General
import "./sugarbook-verified-status.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useInitiateManualVerificationMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import verificationConst from "../../../../const/verificationConst";
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useDispatch } from "react-redux";
import { updateSugarbookVerifiedSelfieImage } from "../../../../redux/store/verificationStore";
import { updateInfoToast } from "../../../../redux/store/toastStore";
// lottie-react
import Lottie from "lottie-react";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../shared/elements/custom-avatar/custom-avatar";
import Spinner from "../../../shared/elements/spinner/spinner";

const SugarbookVerifiedStatus = (props) => {
  const { status } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    initiateManualVerification,
    {
      data: initiateManualVerificationData,
      error: initiateManualVerificationErrorData,
      isLoading: initiateManualVerificationLoading,
      isSuccess: initiateManualVerificationSuccess,
      isError: initiateManualVerificationError,
    },
  ] = useInitiateManualVerificationMutation();

  // General variables
  const [isAnimationDone, setIsAnimationDone] = useState(false);

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setTimeout(() => {
      setIsAnimationDone(true);
    }, 2500);

    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Initiate Manual Verification API Response
  useEffect(() => {
    if (initiateManualVerificationLoading) {
    } else if (initiateManualVerificationSuccess) {
      switch (initiateManualVerificationData?.status) {
        case 1:
          onNavigate(routeConst.verify.customerSupportInfo.facial.path);
          break;
        default:
          const infoToastObj = {
            message: initiateManualVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateInfoToast(infoToastObj));
          break;
      }
    } else if (initiateManualVerificationError) {
    }
  }, [
    initiateManualVerificationLoading,
    initiateManualVerificationSuccess,
    initiateManualVerificationError,
  ]);

  // Event Handlers | Button
  const onRedirectToSearch = () => {
    dispatch(updateSugarbookVerifiedSelfieImage(null));
    onNavigate(routeConst.search.path);
  };
  const onTryAgain = () => {
    dispatch(updateSugarbookVerifiedSelfieImage(null));
    onNavigate(routeConst.verify.sugarbookVerified.path);
  };
  const onContactCustomerSupport = () => {
    const obj = {
      type: verificationConst.manualVerificationType.facial,
      remarks:
        "Existing Face found in our database, please contact our customer support to do manual verification if you think this is a mistake",
    };
    initiateManualVerification(obj);
  };
  const onSkipVerification = () => {
    onNavigate(routeConst.search.path);
  };

  if (status === utilityConst.sugarbookVerifiedStatus.approved) {
    return (
      <div id="sugarbook-verified-status-approved">
        <div className="max-width-container">
          <div className="padding-container">
            <div className="top-container">
              <div className="verified-approved-image-container">
                <img
                  className="verified-approved-image"
                  src={getAsset(
                    "sugarbookVerifiedApprovedBackground",
                    "verified-approved-background"
                  )}
                />

                <div className="user-details-container">
                  <CustomAvatar
                    className="own-user-profile-photo"
                    src={getProfileData?.data?.profile_photo?.original_photo}
                    role={getProfileData?.data?.role}
                  />
                  <div className="username">
                    {getProfileData?.data?.username || t("common.new_user")}
                  </div>
                </div>
              </div>

              <div className="verified-approved-label">
                <Trans
                  i18nKey={"verification.great_news_your_verified"}
                  components={{ br: <br /> }}
                />
                <span className="verified-profile-badge-container">
                  {getIcon("verifiedProfileBadge", "verified-profile-badge")}
                </span>
              </div>

              <div className="verified-approved-description">
                {t("verification.sugarbook_verified_success_desc")}
              </div>

              <div className="verified-approved-points-container">
                <ul className="verified-approved-unordered-list-container">
                  <li>
                    <Trans
                      i18nKey={
                        "verification.sugarbook_verified_success_point_1"
                      }
                      components={{ b: <b /> }}
                    />
                  </li>
                  <li>
                    <Trans
                      i18nKey={
                        "verification.sugarbook_verified_success_point_2"
                      }
                      components={{ b: <b /> }}
                    />
                  </li>
                  <li>
                    <Trans
                      i18nKey={
                        "verification.sugarbook_verified_success_point_3"
                      }
                      components={{ b: <b /> }}
                    />
                  </li>
                </ul>
              </div>
            </div>

            <div className="bottom-container">
              <div className="button-container">
                <div className="done-button" onClick={onRedirectToSearch}>
                  {t("common.done")}
                </div>
              </div>
            </div>
          </div>

          {!isAnimationDone && (
            <div className="lottie-overlay-container">
              <Lottie
                className="general-success-lottie"
                animationData={getAsset("generalSuccessLottie")}
                autoPlay={true}
                loop={false}
              />

              <div className="verification-status-label">
                {t("verification.verification_successful")}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else if (status === utilityConst.sugarbookVerifiedStatus.rejected) {
    return (
      <div id="sugarbook-verified-status-rejected">
        <div className="max-width-container">
          <div className="padding-container">
            <div className="top-container">
              <div className="verified-rejected-image-container">
                <img
                  className="verified-rejected-image apply-opacity"
                  src={getAsset(
                    "sugarbookVerifiedRejectedBackground",
                    "verified-rejected-background"
                  )}
                />

                <div className="user-details-container apply-opacity">
                  <CustomAvatar
                    className="own-user-profile-photo"
                    src={getProfileData?.data?.profile_photo?.original_photo}
                    role={getProfileData?.data?.role}
                  />
                  <div className="username">
                    {getProfileData?.data?.username || t("common.new_user")}
                  </div>
                </div>

                <div className="caution-icon-container">
                  {getIcon("redCautionIcon", "caution-icon")}
                </div>
              </div>

              <div className="verified-rejected-label">
                {t("verification.sugarbook_verified_rejected_title")}
              </div>

              <div className="verified-rejected-description">
                {t("verification.sugarbook_verified_rejected_desc")}
              </div>

              <div className="verified-rejected-points-container">
                <div className="caution-icon-container">
                  {getIcon("redCautionIcon", "caution-icon")}
                </div>

                <ul className="verified-rejected-unordered-list-container">
                  <li>
                    {t("verification.sugarbook_verified_rejected_point_1")}
                  </li>
                  <li>
                    {t("verification.sugarbook_verified_rejected_point_2")}
                  </li>
                  <li>
                    {t("verification.sugarbook_verified_rejected_point_3")}
                  </li>
                  {/* <li>
                  Existed Face found in our database, please contact our
                  customer support to do manual verification if you think this
                  is a mistake
                </li> */}
                </ul>
              </div>

              <div className="verified-rejected-description">
                {t("verification.sugarbook_verified_rejected_retry_desc")}
              </div>
            </div>

            <div className="bottom-container">
              <div className="button-container">
                <div className="done-button" onClick={onTryAgain}>
                  {t("verification.try_again")}
                </div>

                <div className="skip-button" onClick={onSkipVerification}>
                  {t("signup.skip_for_now")}
                </div>
              </div>
            </div>
          </div>

          {!isAnimationDone && (
            <div className="lottie-overlay-container">
              <Lottie
                className="general-error-lottie"
                animationData={getAsset("generalErrorLottie")}
                autoPlay={true}
                loop={false}
              />

              <div className="verification-status-label">
                {t("verification.verification_rejected")}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else if (status === utilityConst.sugarbookVerifiedStatus.duplicate) {
    return (
      <div id="sugarbook-verified-status-rejected">
        <div className="max-width-container">
          <div className="padding-container">
            <div className="top-container">
              <div className="verified-rejected-image-container">
                <img
                  className="verified-rejected-image apply-opacity"
                  src={getAsset(
                    "sugarbookVerifiedRejectedBackground",
                    "verified-rejected-background"
                  )}
                />

                <div className="user-details-container apply-opacity">
                  <CustomAvatar
                    className="own-user-profile-photo"
                    src={getProfileData?.data?.profile_photo?.original_photo}
                    role={getProfileData?.data?.role}
                  />
                  <div className="username">
                    {getProfileData?.data?.username || t("common.new_user")}
                  </div>
                </div>

                <div className="caution-icon-container">
                  {getIcon("redCautionIcon", "caution-icon")}
                </div>
              </div>

              <div className="verified-rejected-label">
                {t("verification.sugarbook_verified_duplicate_title")}
              </div>

              <div className="verified-rejected-description">
                {t("verification.sugarbook_verified_duplicate_desc")}
              </div>

              <div className="verified-rejected-points-container">
                <div className="caution-icon-container">
                  {getIcon("redCautionIcon", "caution-icon")}
                </div>

                <ul className="verified-rejected-unordered-list-container">
                  <li>
                    {t("verification.sugarbook_verified_duplicate_point_1")}
                  </li>
                </ul>
              </div>

              <div className="verified-rejected-description">
                <i>
                  {t(
                    "verification.sugarbook_verified_duplicate_contact_customer_support"
                  )}
                </i>
              </div>
            </div>

            <div className="bottom-container">
              <div className="button-container">
                <div className="done-button" onClick={onContactCustomerSupport}>
                  {initiateManualVerificationLoading ? (
                    <Spinner
                      size={22}
                      isPading={false}
                      color={"white-spinner"}
                    />
                  ) : (
                    t("verification.contact_us")
                  )}
                </div>

                <div className="skip-button" onClick={onSkipVerification}>
                  {t("signup.skip_for_now")}
                </div>
              </div>
            </div>
          </div>

          {!isAnimationDone && (
            <div className="lottie-overlay-container">
              <Lottie
                className="general-error-lottie"
                animationData={getAsset("generalErrorLottie")}
                autoPlay={true}
                loop={false}
              />

              <div className="verification-status-label">
                {t("verification.verification_rejected")}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default SugarbookVerifiedStatus;
