// General
import "./account-signup.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useAuthSignupMutation,
  useInitiateEmailVerificationMutation,
} from "../../../services/data.service";
import { sessionService } from "../../../services/session.service";
import { emailRegex, removeHTMLEscape } from "../../../services/regex.service";
// Static Data
import routeConst from "../../../const/routeConst";
import userConst from "../../../const/userConst";
import utilityConst from "../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEmail,
  updatePassword,
  updateIsPremium,
  updateSignupDate,
  updateUserId,
  updateGoogleRecaptchaToken,
} from "../../../redux/store/signupStore";
import {
  // Email Verification Functions
  setEmailVerificationTimer,
  updateEmailVerificationDisplay,
  updateEmailVerificationRequired,

  // Mobile Verification Functions
  updateMobileVerificationDisplay,
  updateMobileVerificationRequired,
} from "../../../redux/store/verificationStore";
import {
  updateApiToken,
  updateSpiToken,
} from "../../../redux/store/publicStore";
import { updateErrorToast } from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";
// Material UI
import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const AccountSignup = () => {
  // API variables
  const [
    authSignup,
    {
      data: authSignupData,
      error: authSignupErrorData,
      isLoading: authSignupLoading,
      isSuccess: authSignupSuccess,
      isError: authSignupError,
    },
  ] = useAuthSignupMutation();
  const [
    initiateEmailVerification,
    {
      data: initiateEmailVerificationData,
      error: initiateEmailVerificationErrorData,
      isLoading: initiateEmailVerificationLoading,
      isSuccess: initiateEmailVerificationSuccess,
      isError: initiateEmailVerificationError,
    },
  ] = useInitiateEmailVerificationMutation();

  // General variables
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [googleRecaptchaLoading, setGoogleRecaptchaLoading] = useState(false);
  const recaptchaRef = useRef();

  // Redux variables
  const country = useSelector((state) => state.signup.country);
  const phone = useSelector((state) => state.signup.phone);
  const email = useSelector((state) => state.signup.email);
  const password = useSelector((state) => state.signup.password);
  const gender = useSelector((state) => state.signup.gender);
  const lookingFor = useSelector((state) => state.signup.lookingFor);
  const wantToBe = useSelector((state) => state.signup.wantToBe);
  const role = useSelector((state) => state.signup.role);
  const facebookAuthToken = useSelector(
    (state) => state.signup.facebookAuthToken
  );
  const isFbSignup = useSelector((state) => state.signup.isFbSignup);
  const hasEmail = useSelector((state) => state.signup.hasEmail);
  const googleRecaptchaToken = useSelector(
    (state) => state.signup.googleRecaptchaToken
  );
  const utmSource = useSelector((state) => state.tracking.utmSource);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Check for update | Auth Signup API Response
  useEffect(() => {
    if (authSignupLoading) {
    } else if (authSignupSuccess) {
      switch (authSignupData?.status) {
        case 1:
          dispatch(updateApiToken(authSignupData?.data?.legacy_token)); // Remove this after all API using SPI
          dispatch(updateSpiToken(authSignupData?.data?.token)); // Use this after move all API to SPI
          sessionService.setEmail(email);

          // GTM Use
          dispatch(
            updateIsPremium(
              authSignupData?.data?.user?.membership_type ===
                userConst.membershipType.premium ||
                authSignupData?.data?.user?.membership_type ===
                  userConst.membershipType.freePremium ||
                authSignupData?.data?.user?.membership_type ===
                  userConst.membershipType.diamond
            )
          );
          dispatch(
            updateSignupDate(moment().utc().format("YYYY-MM-DD HH:mm:ss"))
          );
          dispatch(updateUserId(authSignupData?.data?.user?.id_int));

          // Insider
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-RegistrationCompleted",
              email,
              role,
              is_premium:
                authSignupData?.data?.user?.membership_type ===
                  userConst.membershipType.premium ||
                authSignupData?.data?.user?.membership_type ===
                  userConst.membershipType.freePremium ||
                authSignupData?.data?.user?.membership_type ===
                  userConst.membershipType.diamond,
              signup_date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
              user_id: authSignupData?.data?.user?.id_int,
            },
          });

          // Meta Pixel
          submitMeta();

          // Reddit Pixel
          submitRedditPixel();

          // Google Ads
          submitGoogleAds();

          // Save the state of email and mobile verification required
          // Used for checking if email and mobile verification is skippable
          dispatch(
            updateEmailVerificationDisplay(
              authSignupData?.data?.user?.verification?.email
                ?.verification_display
            )
          );
          dispatch(
            updateEmailVerificationRequired(
              authSignupData?.data?.user?.verification?.email
                ?.verification_required
            )
          );
          dispatch(
            updateMobileVerificationDisplay(
              authSignupData?.data?.user?.verification?.mobile
                ?.verification_display
            )
          );
          dispatch(
            updateMobileVerificationRequired(
              authSignupData?.data?.user?.verification?.mobile
                ?.verification_required
            )
          );

          if (
            !authSignupData?.data?.user?.verifications?.email?.verified &&
            authSignupData?.data?.user?.verifications?.email
              ?.verification_display
          ) {
            // Email Verification
            initiateEmailVerification();
          } else if (
            !authSignupData?.data?.user?.verifications?.mobile?.verified &&
            authSignupData?.data?.user?.verifications?.mobile
              ?.verification_display
          ) {
            // Phone Verification
            onNavigate(routeConst.phoneAuthentication.path);
          } else {
            // No Verifications, to Info Signup
            onNavigate(routeConst.infoSignup.path);
          }
          break;
        case -1:
          const errorToast = {
            message: authSignupData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          break;
      }
    } else if (authSignupError) {
      switch (authSignupErrorData?.data?.status) {
        case -1:
          const errorToast = {
            message: authSignupErrorData?.data?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          const warningToast = {
            message: authSignupErrorData?.data?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(warningToast));
          break;
      }
    }
  }, [authSignupLoading, authSignupSuccess, authSignupError]);

  // Lifecycle | Check for update | Initiate Email Verification API Response
  useEffect(() => {
    if (initiateEmailVerificationLoading) {
    } else if (initiateEmailVerificationSuccess) {
      switch (initiateEmailVerificationData?.status) {
        case 1:
          const createdAt =
            initiateEmailVerificationData?.data?.email_verification?.created_at;
          const expiredAt =
            initiateEmailVerificationData?.data?.email_verification?.expired_at;
          const diffInSeconds = moment(expiredAt).diff(createdAt, "seconds");

          dispatch(setEmailVerificationTimer(60));
          onNavigate(routeConst.verify.emailVerification.path);
          break;
        case -1:
          // Need to wait 3 minutes
          const errorToast = {
            message: initiateEmailVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          // Not sure
          break;
      }
    } else if (initiateEmailVerificationError) {
    }
  }, [
    initiateEmailVerificationLoading,
    initiateEmailVerificationSuccess,
    initiateEmailVerificationError,
  ]);

  // Event Handlers | Button
  const onSignup = async () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-18-18.1-18.1.1-Button",
      },
    });

    setGoogleRecaptchaLoading(true);

    // Google Recaptcha Token
    await recaptchaRef.current
      .executeAsync()
      .then((token) => {
        setGoogleRecaptchaLoading(false);

        dispatch(updateGoogleRecaptchaToken(token));

        let obj = {
          email,
          password,
          gender,
          wanttobe: wantToBe,
          gre_token: token,
          utm_data: utmSource?.organizedUtmSource || [],
        };

        if (isFbSignup) {
          obj.auth_method = utilityConst.authMethod.facebook;
          obj.auth_token = facebookAuthToken;
        } else {
          obj.auth_method = utilityConst.authMethod.emailPassword;
        }
        authSignup(obj);
      })
      .catch((error) => {
        console.log("error");
        setGoogleRecaptchaLoading(false);
      });
  };

  // Event Handlers | Button
  const onNavigateToTerms = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-18-18.1-18.1.2-Button",
      },
    });

    onNavigate(routeConst.terms.path);
  };
  const onNavigateToPrivacyPolicy = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-18-18.1-18.1.3-Button",
      },
    });

    onNavigate(routeConst.privacy.path);
  };
  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Event Handlers | MUI Text Fields
  const onEmailChange = (event) => {
    dispatch(updateEmail(event.target.value.trim().toLowerCase()));
  };
  const onEmailFocus = (event) => {
    setEmailFocus(true);
  };
  const onPasswordChange = (event) => {
    dispatch(updatePassword(event.target.value.trim()));
  };
  const onPasswordFocus = (event) => {
    setPasswordFocus(true);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (
      emailValid &&
      passwordValid &&
      !googleRecaptchaLoading &&
      !authSignupLoading &&
      !initiateEmailVerificationLoading
    ) {
      valid = true;
    }

    return valid;
  };
  const emailValidation = () => {
    let valid = true;
    let error = false;

    const regex = emailRegex;

    if (email === "") {
      setEmailErrorMessage(t("login.email_address_is_required"));
      valid = false;
      error = true;
    } else if (!regex.test(email)) {
      setEmailErrorMessage(t("login.email_address_is_not_valid"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(email)) {
      dispatch(updateEmail(""));
      valid = false;
      error = true;

      // Send to Sentry
      Sentry.captureMessage("PWA-18-18.2-Email-Input-HTML-Escape-Detected");

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-18-18.2-Email-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setEmailErrorMessage("");
      valid = true;
      error = false;
    }

    setEmailValid(valid);
    setEmailError(error);
  };
  const passwordValidation = () => {
    let valid = true;
    let error = false;

    if (password === "") {
      setPasswordErrorMessage(t("login.password_is_required"));
      valid = false;
      error = true;
    } else if (password.length < 6) {
      setPasswordErrorMessage(
        t("login.password_must_be_n_characters", { n: 6 })
      );
      valid = false;
      error = true;
    } else {
      setPasswordErrorMessage("");
      valid = true;
      error = false;
    }

    setPasswordValid(valid);
    setPasswordError(error);
  };
  const submitMeta = () => {
    if (window.fbq) {
      window.fbq("track", "CompleteRegistration", {
        email: email,
      });
    }
  };
  const submitRedditPixel = () => {
    // Inactive
    // if (window.rdt) {
    //   window.rdt("track", "SignUp");
    // }
  };
  const submitGoogleAds = () => {
    // Inactive
    // if (window.gtag) {
    //   window.gtag("event", "conversion", {
    //     send_to: "AW-16577831170/bXynCLDDm7MZEILC9uA9",
    //   });
    // }
  };

  // Check for email and password validation
  useEffect(() => {
    emailValidation();
    passwordValidation();
  }, [
    email,
    password,
    emailFocus,
    passwordFocus,
    emailErrorMessage,
    passwordErrorMessage,
  ]);

  return (
    <Box
      id="account-signup-page"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <div className="navbar-container">
          <div
            className="back-button"
            onClick={() => onNavigate(routeConst.join.path)}
          >
            {getIcon("signupBackIcon", "signup-back-icon")}
          </div>
        </div>

        <div className="top-container">
          <div className="create-account-label">
            {t("signup.create_new_account")}
          </div>

          <div className="email-form-field-container">
            <TextField
              className="email-form-field custom-text-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-background-color"
              required
              value={email}
              error={emailError}
              disabled={hasEmail}
              onChange={onEmailChange}
              placeholder={t("signup.email_address")}
              helperText={emailErrorMessage}
              variant="outlined"
              onFocus={onEmailFocus}
            />
          </div>

          <div className="password-form-field-container">
            <TextField
              className="password-form-field custom-text-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-background-color"
              required
              value={password}
              error={passwordError}
              onChange={onPasswordChange}
              type={showPassword ? "text" : "password"}
              placeholder={t("signup.password")}
              helperText={passwordErrorMessage}
              variant="outlined"
              onFocus={onPasswordFocus}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onShowPassword}>
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="terms-and-condition">
            {t("signup.signup_agree")}{" "}
            <span className="terms" onClick={onNavigateToTerms}>
              {t("signup.signup_terms")}
            </span>{" "}
            &{" "}
            <span
              className="privacy-policy"
              onClick={onNavigateToPrivacyPolicy}
            >
              {t("signup.signup_policy")}
            </span>
            . {t("signup.signup_desc")}
          </div>
        </div>

        <div className="bottom-container">
          <div
            className={`signup-button ${
              isFormValid() ? "" : "disabled-button"
            }`}
            onClick={onSignup}
          >
            {authSignupLoading || initiateEmailVerificationLoading ? (
              <Spinner size={22} isPadding={false} color={"white-spinner"} />
            ) : (
              t("signup.send_code_to_email")
            )}
          </div>
        </div>
      </div>

      <div className="recaptcha-container">
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env["REACT_APP_RECAPTCHA_V3_SITE_KEY"]}
          theme="light"
        />
      </div>
    </Box>
  );
};

export default AccountSignup;
