// General
import "./update-app.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../services/data.service";
// Static Data
import utilityConst from "../../../const/utilityConst";
// Environment
import environment from "../../../environment/environment";
// Redux
import { useSelector } from "react-redux";
// Material UI
import RefreshIcon from "@mui/icons-material/Refresh";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const UpdateApp = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const releaseChannel = useSelector((state) => state.app.releaseChannel);

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getUserAio(null, true);
  }, [isLoggedIn]);

  // Event Handlers | Button
  const onRefreshApp = () => {
    // TODO: Unregister service worker
    window.location.reload(true);
  };

  if (
    releaseChannel === utilityConst.releaseChannel.nightly &&
    getUserAioSuccess
  ) {
    if (getUserAioData?.data?.pwa?.version !== environment.appVersion) {
      return (
        <div id="update-app-component">
          <div className="padding-container">
            <div className="refresh-logo-container">
              <div className="refresh-outer-circle">
                <RefreshIcon className="refresh-logo" />
              </div>
            </div>

            <div className="refresh-description-container">
              <div className="update-available-label">Update available</div>
              <div className="click-to-update-container" onClick={onRefreshApp}>
                <div className="click-to-update-label">
                  Click to update Sugarbook
                </div>
                <div className="next-icon-container">
                  <ChevronRightIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
};

export default UpdateApp;
