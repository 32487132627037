// General
import "./404.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../const/routeConst";
// Sentry
import * as Sentry from "@sentry/react";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../utility/custom-hooks/useCustomNavigate-hook";

const Error404Page = () => {
  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    Sentry.captureMessage("404-Page");

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-13",
      },
    });
  }, []);

  // Event Handlers | Button
  const onGoHome = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-13-To-7-Button",
      },
    });

    onNavigate(routeConst.search.path);
  };

  return (
    <div id="error-404-page">
      <div className="error-label">404</div>
      <div className="error-description">
        {t("error.your_not_suppose_to_be_here")}
      </div>
      <div className="go-home-button" onClick={onGoHome}>
        {t("common.back")}
      </div>
    </div>
  );
};

export default Error404Page;
