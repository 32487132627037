// General
// Components
import OpenPackageAfterAddCard from "../../helper/open-package-after-add-card/open-package-after-add-card";

const HelperMounted = () => {
  return (
    <div id="helper-mounted-shadow-component">
      <OpenPackageAfterAddCard />
    </div>
  );
};

export default HelperMounted;
