// General
import "./personal-information.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetAioQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
import { removeHTMLEscape } from "../../../../../services/regex.service";
// Static Data
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateOccupation,
  updateEducation,
  updateRelationshipStatus,
  updateChildren,
  updateSmoking,
  updateDrinking,
} from "../../../../../redux/store/editProfileStore";
import { updateSuccessToast } from "../../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import {
  Divider,
  TextField,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import ModerationStatus from "../../moderation-status/moderation-status";

const PersonalInformation = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  // API variables
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // Redux variables
  const occupation = useSelector((state) => state.editProfile.occupation);
  const education = useSelector((state) => state.editProfile.education);
  const relationshipStatus = useSelector(
    (state) => state.editProfile.relationshipStatus
  );
  const children = useSelector((state) => state.editProfile.children);
  const smoking = useSelector((state) => state.editProfile.smoking);
  const drinking = useSelector((state) => state.editProfile.drinking);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getAio(null, true);
  }, []);

  // Lifecycle | Check for update | Update User Profile API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      // Backend returning empty if success
      const toastObj = {
        message: t("signup.profile_updated"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));
    } else if (updateUserProfileError) {
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    if (!isPersonalInformationValid()) return;

    const obj = {
      occupation,
      education,
      relationship: relationshipStatus,
      children,
      smoking,
      drinking,
    };
    updateUserProfile(obj);
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "occupation":
        if (removeHTMLEscape.test(event.target.value)) {
          // Remove HTML Escape (prevent XSS)
          dispatch(updateOccupation(""));

          // Send to Sentry
          Sentry.captureMessage(
            "PWA-25-Occupation-Input-HTML-Escape-Detected"
          );

          // Send to GTM
          TagManager.dataLayer({
            dataLayer: {
              event:
                "PWA-25-Occupation-Input-HTML-Escape-Detected",
            },
          });
        } else {
          dispatch(updateOccupation(event.target.value));
        }
        break;
      case "education":
        dispatch(updateEducation(event.target.value));
        break;
      case "relationship":
        dispatch(updateRelationshipStatus(event.target.value));
        break;
      case "children":
        dispatch(updateChildren(event.target.value));
        break;
      case "smoking":
        dispatch(updateSmoking(event.target.value));
        break;
      case "drinking":
        dispatch(updateDrinking(event.target.value));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isPersonalInformationValid = () => {
    if (
      occupation &&
      education &&
      relationshipStatus &&
      children &&
      smoking &&
      drinking
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-personal-information-subcomponent">
        <div className="personal-information-label-container">
          <div className="personal-information-label">
            {t("profile.personal_information")}
          </div>

          <div className="expand-icon-container">
            {getIcon("chevronRightIcon", "expand-icon")}
          </div>
        </div>

        <div className="form-field-padding-container">
          {/* Occupation */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${occupation ? "" : "incomplete-field"}`}>
                {t("profile.occupation")}
              </div>
            </div>

            <div className="input-container">
              <TextField
                sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                value={occupation || ""}
                placeholder={"+ Add occupation"}
                onChange={onInputChange("occupation")}
                variant="standard"
                InputProps={{ disableUnderline: true }}
                inputProps={{
                  sx: {
                    "&::placeholder": {
                      opacity: "1",
                      font: "500 1em Lato",
                      color: "#CCCCCC",
                    },
                  },
                }}
              ></TextField>
            </div>
          </div>

          {/* Education */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${education ? "" : "incomplete-field"}`}>
                {t("profile.education")}
              </div>
            </div>

            <div className="form-container">
              <FormControl className="form-field" variant="standard" fullWidth>
                <Select
                  className="form-select"
                  displayEmpty
                  value={education || ""}
                  onChange={onInputChange("education")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add education
                        </div>
                      );
                    }

                    const selectedEducation =
                      getAioData?.payload?.dropdown?.education?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedEducation?.name || (
                        <div className="default-select-value">
                          + Add education
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add education
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.education?.map(
                    (education, index) => (
                      <MenuItem key={index} value={education?.value}>
                        {t(`${education?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Relationship */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${
                  relationshipStatus ? "" : "incomplete-field"
                }`}
              >
                {t("profile.relationship")}
              </div>
            </div>

            <div className="form-container">
              <FormControl className="form-field" variant="standard" fullWidth>
                <Select
                  className="form-select"
                  displayEmpty
                  value={relationshipStatus || ""}
                  onChange={onInputChange("relationship")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add relationship
                        </div>
                      );
                    }

                    const selectedRelationship =
                      getAioData?.payload?.dropdown?.relationship?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedRelationship?.name || (
                        <div className="default-select-value">
                          + Add relationship
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add relationship
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.relationship?.map(
                    (relationship, index) => (
                      <MenuItem key={index} value={relationship?.value}>
                        {t(`${relationship?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Children */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${children ? "" : "incomplete-field"}`}>
                {t("profile.children")}
              </div>
            </div>

            <div className="form-container">
              <FormControl className="form-field" variant="standard" fullWidth>
                <Select
                  className="form-select"
                  displayEmpty
                  value={children || ""}
                  onChange={onInputChange("children")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add children
                        </div>
                      );
                    }

                    const selectedChildren =
                      getAioData?.payload?.dropdown?.children?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedChildren?.name || (
                        <div className="default-select-value">
                          + Add children
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add children
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.children?.map(
                    (children, index) => (
                      <MenuItem key={index} value={children?.value}>
                        {t(`${children?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Smoking */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${smoking ? "" : "incomplete-field"}`}>
                {t("profile.smoking")}
              </div>
            </div>

            <div className="form-container">
              <FormControl className="form-field" variant="standard" fullWidth>
                <Select
                  className="form-select"
                  displayEmpty
                  value={smoking || ""}
                  onChange={onInputChange("smoking")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add smoking
                        </div>
                      );
                    }

                    const selectedSmoking =
                      getAioData?.payload?.dropdown?.smoking?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedSmoking?.name || (
                        <div className="default-select-value">
                          + Add smoking
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add smoking
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.smoking?.map(
                    (smoking, index) => (
                      <MenuItem key={index} value={smoking?.value}>
                        {t(`${smoking?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Drinking */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${drinking ? "" : "incomplete-field"}`}>
                {t("profile.drinking")}
              </div>
            </div>

            <div className="form-container">
              <FormControl className="form-field" variant="standard" fullWidth>
                <Select
                  className="form-select"
                  displayEmpty
                  value={drinking || ""}
                  onChange={onInputChange("drinking")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add drinking
                        </div>
                      );
                    }

                    const selectedDrinking =
                      getAioData?.payload?.dropdown?.drinking?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedDrinking?.name || (
                        <div className="default-select-value">
                          + Add drinking
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add drinking
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.drinking?.map(
                    (drinking, index) => (
                      <MenuItem key={index} value={drinking?.value}>
                        {t(`${drinking?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div id="desktop-edit-profile-personal-information-subcomponent">
        {/* In Review */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.inReview}
        />

        {/* Rejected */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.rejected}
        />

        <div className="personal-information-static-label-container">
          <div className="personal-information-label">
            {t("profile.personal_information")}
          </div>
          <Divider className="short-divider" />
        </div>

        <div className="form-field-padding-container">
          {/* Occupation */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${occupation ? "" : "incomplete-field"}`}>
                {t("profile.occupation")}
              </div>
            </div>

            <div className="input-container">
              <TextField
                sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                value={occupation || ""}
                placeholder={"+ Add occupation"}
                onChange={onInputChange("occupation")}
                variant="outlined"
                InputProps={{ disableUnderline: true }}
                inputProps={{
                  sx: {
                    "&::placeholder": {
                      opacity: "1",
                      font: "500 1em Lato",
                      color: "#CCCCCC",
                    },
                  },
                }}
              ></TextField>
            </div>
          </div>

          {/* Education */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${education ? "" : "incomplete-field"}`}>
                {t("profile.education")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={education || ""}
                  onChange={onInputChange("education")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add education
                        </div>
                      );
                    }

                    const selectedEducation =
                      getAioData?.payload?.dropdown?.education?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedEducation?.name || (
                        <div className="default-select-value">
                          + Add education
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add education
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.education?.map(
                    (education, index) => (
                      <MenuItem key={index} value={education?.value}>
                        {t(`${education?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Relationship */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${
                  relationshipStatus ? "" : "incomplete-field"
                }`}
              >
                {t("profile.relationship")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={relationshipStatus || ""}
                  onChange={onInputChange("relationship")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add relationship
                        </div>
                      );
                    }

                    const selectedRelationship =
                      getAioData?.payload?.dropdown?.relationship?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedRelationship?.name || (
                        <div className="default-select-value">
                          + Add relationship
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add relationship
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.relationship?.map(
                    (relationship, index) => (
                      <MenuItem key={index} value={relationship?.value}>
                        {t(`${relationship?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Children */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${children ? "" : "incomplete-field"}`}>
                {t("profile.children")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={children || ""}
                  onChange={onInputChange("children")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add children
                        </div>
                      );
                    }

                    const selectedChildren =
                      getAioData?.payload?.dropdown?.children?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedChildren?.name || (
                        <div className="default-select-value">
                          + Add children
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add children
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.children?.map(
                    (children, index) => (
                      <MenuItem key={index} value={children?.value}>
                        {t(`${children?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Smoking */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${smoking ? "" : "incomplete-field"}`}>
                {t("profile.smoking")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={smoking || ""}
                  onChange={onInputChange("smoking")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add smoking
                        </div>
                      );
                    }

                    const selectedSmoking =
                      getAioData?.payload?.dropdown?.smoking?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedSmoking?.name || (
                        <div className="default-select-value">
                          + Add smoking
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add smoking
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.smoking?.map(
                    (smoking, index) => (
                      <MenuItem key={index} value={smoking?.value}>
                        {t(`${smoking?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Drinking */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${drinking ? "" : "incomplete-field"}`}>
                {t("profile.drinking")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={drinking || ""}
                  onChange={onInputChange("drinking")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add drinking
                        </div>
                      );
                    }

                    const selectedDrinking =
                      getAioData?.payload?.dropdown?.drinking?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedDrinking?.name || (
                        <div className="default-select-value">
                          + Add drinking
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add drinking
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.drinking?.map(
                    (drinking, index) => (
                      <MenuItem key={index} value={drinking?.value}>
                        {t(`${drinking?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="bottom-section-container">
          <div
            className={`save-button ${
              isPersonalInformationValid() ? "" : "disabled-button"
            }`}
            onClick={onSaveDetails}
          >
            {updateUserProfileLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.save")
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default PersonalInformation;
