// General
import "./smoking-filter.scss";
import { useEffect } from "react";
// Services
import { useLazyGetAioQuery } from "../../../../../services/data.service";
// Static Data
import searchConst from "../../../../../const/searchConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateSmokingNonSmoker,
  updateSmokingLightSmoker,
  updateSmokingHeavySmoker,
} from "../../../../../redux/store/searchFilterStore";
import { updateSmokingFilterDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";

const SmokingFilter = (props) => {
  const { type, enableTitle = true } = props;

  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();

  // Redux variables
  const smoking = useSelector((state) => state.searchFilter.smoking);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getAio(null, true);
  }, []);

  // Event Handlers | MUI Checkbox
  const onCheckboxChange = (state) => (event) => {
    switch (state) {
      case "non-smoker":
        dispatch(updateSmokingNonSmoker(event.target.checked));
        break;
      case "light-smoker":
        dispatch(updateSmokingLightSmoker(event.target.checked));
        break;
      case "heavy-smoker":
        dispatch(updateSmokingHeavySmoker(event.target.checked));
        break;
      default:
        break;
    }
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateSmokingFilterDialog(false));
  };

  // Utility Functions
  const onGetState = (state) => {
    for (let i = 0; i < getAioData?.payload?.dropdown?.smoking?.length; i++) {
      if (state === getAioData?.payload?.dropdown?.smoking[i].value) {
        return smoking[state];
      }
    }
  };

  if (getAioFetching || getAioLoading) {
  } else if (getAioSuccess) {
    return (
      <div id="smoking-filter-shared-component">
        {enableTitle && (
          <div className="dialog-header">
            <div className="title">{t("filter.smoking")}</div>

            <div className="close-button-container">
              <CloseIcon className="close-button" onClick={onCloseDialog} />
            </div>
          </div>
        )}

        <div className="padding-container">
          <div
            className={`checkbox-container ${
              type === searchConst.searchFilterType.full ? "flexwrap" : ""
            }`}
          >
            {getAioData?.payload?.dropdown?.smoking?.map((smoking, index) => (
              <FormGroup className="checkbox" key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={`custom-checkbox ${
                        type === searchConst.searchFilterType.full
                          ? "custom-checkbox-no-padding"
                          : ""
                      }`}
                      size={
                        type === searchConst.searchFilterType.full
                          ? "small"
                          : "medium"
                      }
                      checked={onGetState(smoking?.value)}
                      onChange={onCheckboxChange(smoking?.value)}
                    />
                  }
                  label={
                    <span
                      className={
                        type === searchConst.searchFilterType.full
                          ? "checkbox-font"
                          : ""
                      }
                    >
                      {smoking?.name}
                    </span>
                  }
                />
              </FormGroup>
            ))}
          </div>
        </div>
      </div>
    );
  } else if (getAioError) {
  }
};

export default SmokingFilter;
