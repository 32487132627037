// General
import "./sugarbook-notice.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../services/data.service";
// Material UI
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const SugarbookNotice = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // General variables
  const [isNoticeClose, setIsNoticeClose] = useState(false);

  // Lifecycle | Mounted
  useEffect(() => {
    getUserAio(null, true);
  }, []);

  // Event Handlers | Button
  const onCloseNotice = () => {
    setIsNoticeClose(true);
  };

  if (
    !isNoticeClose &&
    getUserAioSuccess &&
    getUserAioData?.data?.notices?.platform_wide?.length > 0
  )
    return (
      <div id="sugarbook-notice-component">
        <div className="padding-container">
          <div
            className="notice-container"
            dangerouslySetInnerHTML={{
              __html: getUserAioData?.data?.notices?.platform_wide[0]?.content,
            }}
          ></div>

          <div className="close-button-container">
            <CloseRoundedIcon
              className="close-button"
              onClick={onCloseNotice}
            />
          </div>
        </div>
      </div>
    );
};

export default SugarbookNotice;
