// Services
import { sessionService } from "../../../services/session.service";
import {
  authApi,
  comApi,
  spiApi,
  paymentApi,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateGlobalPusherDestroy,
  updateMessagingPusherDestroy,
  updateLiveViewPusherDestroy,
  updateVideoCallPusherDestroy,
} from "../../../redux/store/pusherStore";
import { resetAppStore } from "../../../redux/store/appStore";
import { resetBannerStore } from "../../../redux/store/bannerStore";
import { resetDeactivateStore } from "../../../redux/store/deactivateStore";
import { resetDebugStore } from "../../../redux/store/debugStore";
import { resetDialogStore } from "../../../redux/store/dialogStore";
import { resetEditProfileStore } from "../../../redux/store/editProfileStore";
import { resetGameStore } from "../../../redux/store/gameStore";
import { resetInboxStore } from "../../../redux/store/inboxStore";
import { resetInterestStore } from "../../../redux/store/interestStore";
import { resetLeaderboardStore } from "../../../redux/store/leaderboardStore";
import { resetLevellingStore } from "../../../redux/store/levellingStore";
import { resetLivestreamingStore } from "../../../redux/store/livestreamingStore";
import { resetSurveyStore } from "../../../redux/store/surveyStore";
import { resetToastStore } from "../../../redux/store/toastStore";
import { resetNavbarStore } from "../../../redux/store/navbarStore";
import { resetNavigationStore } from "../../../redux/store/navigationStore";
import { resetNotificationStore } from "../../../redux/store/notificationStore";
import { resetPaymentStore } from "../../../redux/store/paymentStore";
import {
  resetPrivateCallStore,
  clearStandbyTimerInterval,
  clearStandbyKeepAliveInterval,
  clearRoomKeepAliveInterval,
  clearCallDurationRemainingInterval,
  clearPrivateCallCallingInterval,
  clearPrivateCallWaitingRoomInterval,
} from "../../../redux/store/privateCallStore";
import { resetProductsStore } from "../../../redux/store/productsStore";
import { resetProfileStore } from "../../../redux/store/profileStore";
import {
  resetPublicStore,
  clearDailyCheckInInterval,
} from "../../../redux/store/publicStore";
import { resetPusherStore } from "../../../redux/store/pusherStore";
import { resetSearchFilterStore } from "../../../redux/store/searchFilterStore";
import { resetSearchStore } from "../../../redux/store/searchStore";
import { resetSigninStore } from "../../../redux/store/signinStore";
import { resetSignupStore } from "../../../redux/store/signupStore";
import { resetUpgradeStore } from "../../../redux/store/upgradeStore";
import { resetUserCardStore } from "../../../redux/store/userCardStore";
import { resetUserStore } from "../../../redux/store/userStore";
import { resetVerificationStore } from "../../../redux/store/verificationStore";
// Custom Hooks
import useCustomNavigate from "./useCustomNavigate-hook";

const useLogout = () => {
  // Redux variables
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Main Function
  const onLogout = () => {
    onClearState();
  };

  const onClearState = () => {
    // Unsubscribe Pusher
    dispatch(updateGlobalPusherDestroy({}));
    dispatch(updateMessagingPusherDestroy({}));
    dispatch(updateLiveViewPusherDestroy({}));
    dispatch(updateVideoCallPusherDestroy({}));

    // Clear Intervals
    dispatch(clearDailyCheckInInterval());
    dispatch(clearStandbyTimerInterval());
    dispatch(clearStandbyKeepAliveInterval());
    dispatch(clearRoomKeepAliveInterval());
    dispatch(clearCallDurationRemainingInterval());
    dispatch(clearPrivateCallCallingInterval());
    dispatch(clearPrivateCallWaitingRoomInterval());

    // Clear session & local storage
    sessionService.deleteAllSessionData();

    // Clear RTK Query Cache
    dispatch(authApi.util.resetApiState());
    dispatch(comApi.util.resetApiState());
    dispatch(spiApi.util.resetApiState());
    dispatch(paymentApi.util.resetApiState());

    // Reset redux store to initial state
    setTimeout(() => {
      dispatch(resetAppStore());
      dispatch(resetBannerStore());
      dispatch(resetDeactivateStore());
      dispatch(resetDebugStore());
      dispatch(resetDialogStore());
      dispatch(resetEditProfileStore());
      dispatch(resetGameStore());
      dispatch(resetInboxStore());
      dispatch(resetInterestStore());
      dispatch(resetLeaderboardStore());
      dispatch(resetLevellingStore());
      dispatch(resetLivestreamingStore());
      dispatch(resetSurveyStore());
      dispatch(resetToastStore());
      dispatch(resetNavbarStore());
      dispatch(resetNavigationStore());
      dispatch(resetNotificationStore());
      dispatch(resetPaymentStore());
      dispatch(resetPrivateCallStore());
      dispatch(resetProductsStore());
      dispatch(resetProfileStore());
      dispatch(resetPublicStore());
      dispatch(resetPusherStore());
      dispatch(resetSearchFilterStore());
      dispatch(resetSearchStore());
      dispatch(resetSigninStore());
      dispatch(resetSignupStore());
      dispatch(resetUpgradeStore());
      dispatch(resetUserCardStore());
      dispatch(resetUserStore());
      dispatch(resetVerificationStore());

      onNavigate(routeConst.landing.path);
    }, 2000);
  };

  return onLogout;
};

export default useLogout;
