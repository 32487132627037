// General
import "./photo-permissions-list.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  usePhotoPermissionApproveMutation,
  usePhotoPermissionRevokeMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Redux
import { useDispatch } from "react-redux";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updateProfileDecorations,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../redux/store/userCardStore";
import { updateLocalPhotoPermissionList } from "../../../../redux/store/interestStore";
import { updateVerifiedCallerDescriptionDialog } from "../../../../redux/store/dialogStore";
import { updateSuccessToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import StarIcon from "@mui/icons-material/Star";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../shared/elements/custom-avatar/custom-avatar";
import Spinner from "../../../shared/elements/spinner/spinner";
import VerifiedProfileBadge from "../../../shared/elements/verified-profile-badge/verified-profile-badge";

const PhotoPermissionsList = (props) => {
  const {
    id,
    idInt,
    username,
    age,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    livestreamingAchievementBadge,
    profilePhoto,
    profileDecorations,
    publicPhotos,
    privatePhotos,
    torTags,
    profileAchievementBadge,
    aboutMe,
    lookingFor,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    isFollowing,
    // User Utility
    shouldCallApi,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,
  } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    photoPermissionApprove,
    {
      data: photoPermissionApproveData,
      error: photoPermissionApproveErrorData,
      isLoading: photoPermissionApproveLoading,
      isSuccess: photoPermissionApproveSuccess,
      isError: photoPermissionApproveError,
    },
  ] = usePhotoPermissionApproveMutation();
  const [
    photoPermissionRevoke,
    {
      data: photoPermissionRevokeData,
      error: photoPermissionRevokeErrorData,
      isLoading: photoPermissionRevokeLoading,
      isSuccess: photoPermissionRevokeSuccess,
      isError: photoPermissionRevokeError,
    },
  ] = usePhotoPermissionRevokeMutation();

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Photo Permission Approve API Response
  useEffect(() => {
    if (photoPermissionApproveLoading) {
    } else if (photoPermissionApproveSuccess) {
      switch (photoPermissionApproveData?.status) {
        case 0:
        case 1:
          const obj = {
            idInt,
            approvedMyPrivatePhoto: !approvedMyPrivatePhoto,
          };
          dispatch(updateLocalPhotoPermissionList(obj));

          const toastObj = {
            message: t("profile.photo_private_granted"),
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(toastObj));
          break;
        default:
          break;
      }
    } else if (photoPermissionApproveError) {
    }
  }, [
    photoPermissionApproveLoading,
    photoPermissionApproveSuccess,
    photoPermissionApproveError,
  ]);

  // Lifecycle | Check for update | Photo Permission Revoke API Response
  useEffect(() => {
    if (photoPermissionRevokeLoading) {
    } else if (photoPermissionRevokeSuccess) {
      switch (photoPermissionRevokeData?.status) {
        case 0:
        case 1:
          const obj = {
            idInt,
            approvedMyPrivatePhoto: !approvedMyPrivatePhoto,
          };
          dispatch(updateLocalPhotoPermissionList(obj));

          const toastObj = {
            message: t("profile.photo_private_revoked"),
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(toastObj));
          break;
        default:
          break;
      }
    } else if (photoPermissionRevokeError) {
    }
  }, [
    photoPermissionRevokeLoading,
    photoPermissionRevokeSuccess,
    photoPermissionRevokeError,
  ]);

  // Event Handlers | Button
  const onViewProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-S3-3.1-Button",
      },
    });

    // User View
    dispatch(updateId(id));
    dispatch(updateIdInt(idInt));
    dispatch(updateUsername(username));
    dispatch(updateAge(age));
    dispatch(updateLocation(location));
    dispatch(updateMembershipType(membershipType));
    dispatch(updateVerifiedProfile(verifiedProfile));
    dispatch(updateVerifiedCaller(verifiedCaller));
    dispatch(updateLevellingBadge(levellingBadge));
    dispatch(
      updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
    );
    dispatch(updateProfilePhoto(profilePhoto));
    dispatch(updateProfileDecorations(profileDecorations));
    dispatch(updatePublicPhotos(publicPhotos));
    dispatch(updatePrivatePhotos(privatePhotos));
    dispatch(updateTorTags(torTags));
    dispatch(updateProfileAchievementBadge(profileAchievementBadge));
    dispatch(updateAboutMe(aboutMe));
    dispatch(updateLookingFor(lookingFor));
    dispatch(updateOccupation(occupation));
    dispatch(updateLifestyle(lifestyle));
    dispatch(updateEducation(education));
    dispatch(updateRelationship(relationship));
    dispatch(updateSmoking(smoking));
    dispatch(updateDrinking(drinking));
    dispatch(updateHeight(height));
    dispatch(updateBodyType(bodyType));
    dispatch(updateEthnicity(ethnicity));
    dispatch(updateEyeColor(eyeColor));
    dispatch(updateHairColor(hairColor));
    dispatch(updateChildren(children));
    // User Utility
    dispatch(updateShouldCallApi(shouldCallApi));
    dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
    dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
    dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
    dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
    dispatch(updateIsBlocked(isBlocked ? true : false));
    dispatch(updateIsSilentInteraction(isSilentInteraction));
    dispatch(updateIsFavorited(isFavorited));
    dispatch(updateIsCallEnabled(isCallEnabled));
    dispatch(updateIsSugarbaby(isSugarbaby));
    dispatch(updateRole(role));

    onNavigate(`${routeConst.profile.view.clearPath}${id}`);
  };
  const onApprovePhotoRequest = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-S3-3.2-Button",
      },
    });

    const obj = {
      user_id: id,
    };
    photoPermissionApprove(obj);
  };
  const onRevokePhotoRequest = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-S3-3.3-Button",
      },
    });

    const obj = {
      user_id: id,
    };
    photoPermissionRevoke(obj);
  };
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };

  // Render Functions
  const renderRequestButton = () => {
    if (getProfileData?.data?.id !== id) {
      if (approvedMyPrivatePhoto) {
        return (
          <div className="revoke-button" onClick={onRevokePhotoRequest}>
            {photoPermissionRevokeLoading ? (
              <Spinner isPadding={false} size={20} color={"white-spinner"} />
            ) : (
              t("common.revoke")
            )}
          </div>
        );
      } else {
        return (
          <div className="approve-button" onClick={onApprovePhotoRequest}>
            {photoPermissionApproveLoading ? (
              <Spinner isPadding={false} size={20} color={"white-spinner"} />
            ) : (
              t("common.approve")
            )}
          </div>
        );
      }
    }
  };

  return (
    <div id="photo-permissions-photo-permissions-list-subcomponent">
      <CustomAvatar
        className="profile-photo"
        src={profilePhoto}
        role={role}
        profileBorder={profileDecorations?.profile_border_url}
        onClick={onViewProfile}
      />

      <div className="user-description-container">
        {livestreamingAchievementBadge?.length > 0 && (
          <div className="livestreaming-achievement-badge-container">
            {livestreamingAchievementBadge?.map((badge, index) => (
              <img
                className="livestreaming-achievement-badge"
                src={badge?.url}
                alt={badge?.type}
                key={index}
              />
            ))}
          </div>
        )}

        <div className="username">{username}</div>

        <div className="misc-badge-container">
          {/* Verified Profile */}
          <div className="verified-profile-badge-container">
            <VerifiedProfileBadge
              verifiedProfile={verifiedProfile}
              className={"verified-profile-badge"}
            />
          </div>

          {/* Membership Type */}
          {membershipType !== userConst.membershipType.free && (
            <StarIcon className="star-icon" />
          )}

          {/* Verified Caller Badge */}
          {verifiedCaller && (
            <div
              className="verified-caller-badge-container"
              onClick={onOpenVerifiedCallerDialog}
            >
              {getIcon("verifiedCallerBadge", "verified-caller-badge")}
            </div>
          )}
        </div>

        {false && levellingBadge && (
          <div className="levelling-badge-container">
            <img className="levelling-badge" src={levellingBadge} />
          </div>
        )}
      </div>

      <span className="spacer"></span>

      {renderRequestButton()}
    </div>
  );
};

export default PhotoPermissionsList;
