// General
import "./photos.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetProfilePwaQuery,
  useUploadUserPhotoMutation,
} from "../../../../services/data.service";
// Static Data
import userConst from "../../../../const/userConst";
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateProfilePhoto as updateEditProfilePhoto,
  updatePublicPhotos as updateEditPublicPhotos,
  updatePrivatePhotos as updateEditPrivatePhotos,
} from "../../../../redux/store/editProfileStore";
import { updateProfilePreviewPhotoInfo } from "../../../../redux/store/profileStore";
import {
  updateGeneralLoadingDialog,
  updatePreviewPhotoDialog,
} from "../../../../redux/store/dialogStore";
import { updateWarningToast } from "../../../../redux/store/toastStore";
// browser-image-compression
import imageCompression from "browser-image-compression";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const Photos = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    uploadUserPhoto,
    {
      data: uploadUserPhotoData,
      error: uploadUserPhotoErrorData,
      isLoading: uploadUserPhotoLoading,
      isSuccess: uploadUserPhotoSuccess,
      isError: uploadUserPhotoError,
    },
  ] = useUploadUserPhotoMutation();

  // General variables
  const [imageData, setImageData] = useState(null);
  const [imageState, setImageState] = useState(null);

  // Redux variables
  const profilePhoto = useSelector((state) => state.editProfile.profilePhoto);
  const publicPhotos = useSelector((state) => state.editProfile.publicPhotos);
  const privatePhotos = useSelector((state) => state.editProfile.privatePhotos);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile("photo", false);
  }, []);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (getProfileData?.status === 0) {
        dispatch(
          updateEditProfilePhoto(
            getProfileData?.data?.profile_photo?.photo_status === "APPROVED"
              ? getProfileData?.data?.profile_photo?.original_photo
              : null
          )
        );
        dispatch(updateEditPublicPhotos(getProfileData?.data?.public_photos));
        dispatch(updateEditPrivatePhotos(getProfileData?.data?.private_photos));
      }
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Upload User Photo API Response
  useEffect(() => {
    if (uploadUserPhotoLoading) {
      dispatch(updateGeneralLoadingDialog(true));
    } else if (uploadUserPhotoSuccess) {
      dispatch(updateGeneralLoadingDialog(false));

      setImageData(null);
      setImageState(null);

      getProfile("photo", false);
    } else if (uploadUserPhotoError) {
      switch (uploadUserPhotoErrorData?.data?.status) {
        case -1:
          dispatch(updateGeneralLoadingDialog(false));

          const warningToastObj = {
            message: uploadUserPhotoErrorData?.data?.message || "",
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));
          break;
        default:
          break;
      }
    }
  }, [uploadUserPhotoLoading, uploadUserPhotoSuccess, uploadUserPhotoError]);

  // Lifecycle | Check for update | imageData & orientationData
  useEffect(() => {
    if (!imageData || !imageState) return;

    const obj = {
      photos: [
        {
          is_private: imageState === "private" ? true : false,
          base64: imageData.image,
        },
      ],
    };

    uploadUserPhoto(obj);
  }, [imageData, imageState]);

  // Event Handlers | Button
  const onFilePicker = (state) => async (event) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-25-25.4-Button",
      },
    });

    setImageState(state);

    const file = event.target.files[0];

    const options = {
      maxSizeMB: 1,
    };

    if (file !== undefined) {
      try {
        const compressedFile = await imageCompression(file, options);
        const imageData = await readFile(compressedFile);
        setImageData((prevState) => ({
          ...prevState,
          image: imageData,
          file: file,
        }));
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onPreviewPhoto = (photo) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-25-25.5-Button",
      },
    });

    const obj = {
      id: photo?.id,
      photoUrl: photo?.original_photo,
      isPrivate: photo?.is_private,
      isProfilePhoto: profilePhoto?.id === photo?.id,
      isApproved:
        photo?.photo_status === userConst.photoApprovalStatus.approved,
    };
    dispatch(updateProfilePreviewPhotoInfo(obj));
    dispatch(updatePreviewPhotoDialog(true));
  };

  // Utility Functions
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-profile-edit-photos-subcomponent">
        <div className="padding-container">
          <div className="public-photos-container">
            <div className="public-photos-label">
              {t("profile.public_photos")}
            </div>

            <Divider />

            {publicPhotos?.length > 0 ? (
              <div className="photos-container">
                {publicPhotos?.map((photo, index) => (
                  <div
                    className="photo-container"
                    onClick={() => onPreviewPhoto(photo)}
                    key={index}
                  >
                    <img className="public-photo" src={photo?.original_photo} />

                    {photo?.photo_status ===
                      userConst.photoApprovalStatus.inReview && (
                      <div className="in-review-overlay">
                        {t("profile.in_review")}
                      </div>
                    )}

                    {photo?.photo_status ===
                      userConst.photoApprovalStatus.rejected && (
                      <div className="rejected-overlay">
                        <div className="rejected-label">
                          {t("profile.rejected")}
                        </div>
                      </div>
                    )}
                  </div>
                ))}

                {publicPhotos?.length < 6 && (
                  <Button
                    className="add-photo-container"
                    disableRipple
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={onFilePicker("public")}
                    />
                    {getIcon("addPhotoIcon", "add-photo")}
                  </Button>
                )}
              </div>
            ) : (
              <Button
                className="upload-photo-container"
                disableRipple
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={onFilePicker("public")}
                />
                <div className="upload-icon-container">
                  {getIcon("uploadIcon", "upload-icon")}
                </div>

                <div className="upload-photo-label">
                  {t("profile.upload_photo")}
                </div>
              </Button>
            )}
          </div>

          <div className="private-photos-container">
            <div className="private-photos-label">
              {t("profile.private_photos")}
            </div>

            <Divider />

            {privatePhotos?.length > 0 ? (
              <div className="photos-container">
                {privatePhotos?.map((photo, index) => (
                  <div
                    className="photo-container"
                    onClick={() => onPreviewPhoto(photo)}
                    key={index}
                  >
                    <img
                      className="private-photo"
                      src={photo?.original_photo}
                    />

                    {photo?.photo_status ===
                      userConst.photoApprovalStatus.inReview && (
                      <div className="in-review-overlay">
                        {t("profile.in_review")}
                      </div>
                    )}

                    {photo?.photo_status ===
                      userConst.photoApprovalStatus.rejected && (
                      <div className="rejected-overlay">
                        <div className="rejected-label">
                          {t("profile.rejected")}
                        </div>
                      </div>
                    )}
                  </div>
                ))}

                {privatePhotos?.length < 6 && (
                  <Button
                    className="add-photo-container"
                    disableRipple
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={onFilePicker("private")}
                    />
                    {getIcon("addPhotoIcon", "add-photo")}
                  </Button>
                )}
              </div>
            ) : (
              <Button
                className="upload-photo-container"
                disableRipple
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={onFilePicker("private")}
                />
                <div className="upload-icon-container">
                  {getIcon("uploadIcon", "upload-icon")}
                </div>

                <div className="upload-photo-label">
                  {t("profile.upload_photo")}
                </div>
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div id="desktop-profile-edit-photos-subcomponent">
        <div className="padding-container">
          <div className="public-photos-container">
            <div className="public-photos-top-container">
              <div className="public-photos-label-container">
                <div className="public-photos-label">
                  {t("profile.public_photos")}
                </div>

                <div className="public-photos-amount">
                  ({publicPhotos?.length} {t("profile.edit_photos")})
                </div>
              </div>

              {publicPhotos?.length < 6 && (
                <Button
                  className="add-photos-button-container"
                  disableRipple
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={onFilePicker("public")}
                  />

                  <div className="add-photo-icon-container">
                    {getIcon("addRoundedIcon", "add-photo-icon")}
                  </div>
                  <div className="add-photo-label">
                    {t("profile.add_photos")}
                  </div>
                </Button>
              )}
            </div>

            <Divider className="short-divider" />

            {publicPhotos?.length > 0 ? (
              <div className="photos-container">
                {publicPhotos?.map((photo, index) => (
                  <div
                    className="photo-container"
                    onClick={() => onPreviewPhoto(photo)}
                    key={index}
                  >
                    <img className="public-photo" src={photo?.original_photo} />

                    {photo?.photo_status ===
                      userConst.photoApprovalStatus.inReview && (
                      <div className="in-review-overlay">
                        {t("profile.in_review")}
                      </div>
                    )}

                    {photo?.photo_status ===
                      userConst.photoApprovalStatus.rejected && (
                      <div className="rejected-overlay">
                        <div className="rejected-label">
                          {t("profile.rejected")}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </div>

          <div className="private-photos-container">
            <div className="private-photos-top-container">
              <div className="private-photos-label-container">
                <div className="private-photos-label">
                  {t("profile.private_photos")}
                </div>

                <div className="private-photos-amount">
                  ({privatePhotos?.length} {t("profile.edit_photos")})
                </div>
              </div>

              {privatePhotos?.length < 6 && (
                <Button
                  className="add-photos-button-container"
                  disableRipple
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={onFilePicker("private")}
                  />

                  <div className="add-photo-icon-container">
                    {getIcon("addRoundedIcon", "add-photo-icon")}
                  </div>
                  <div className="add-photo-label">
                    {t("profile.add_photos")}
                  </div>
                </Button>
              )}
            </div>

            <Divider className="short-divider" />

            {privatePhotos?.length > 0 ? (
              <div className="photos-container">
                {privatePhotos?.map((photo, index) => (
                  <div
                    className="photo-container"
                    onClick={() => onPreviewPhoto(photo)}
                    key={index}
                  >
                    <img
                      className="private-photo"
                      src={photo?.original_photo}
                    />

                    {photo?.photo_status ===
                      userConst.photoApprovalStatus.inReview && (
                      <div className="in-review-overlay">
                        {t("profile.in_review")}
                      </div>
                    )}

                    {photo?.photo_status ===
                      userConst.photoApprovalStatus.rejected && (
                      <div className="rejected-overlay">
                        <div className="rejected-label">
                          {t("profile.rejected")}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
};

export default Photos;
