// General
import "./preview-photo-dialog.scss";
import { useEffect } from "react";
// Services
import { useUpdateUserPhotoMutation } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateProfilePreviewPhotoInfo } from "../../../../redux/store/profileStore";
import {
  updateProfilePhoto as updateEditProfilePhoto,
  updatePublicPhotos as updateEditPublicPhotos,
  updatePrivatePhotos as updateEditPrivatePhotos,
} from "../../../../redux/store/editProfileStore";
import { updatePreviewPhotoDialog } from "../../../../redux/store/dialogStore";
import { updateSuccessToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, useMediaQuery } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../elements/spinner/spinner";

const PreviewPhotoDialog = () => {
  // API variables
  const [
    updateUserPhoto,
    {
      data: updateUserPhotoData,
      error: updateUserPhotoErrorData,
      isLoading: updateUserPhotoLoading,
      isSuccess: updateUserPhotoSuccess,
      isError: updateUserPhotoError,
    },
  ] = useUpdateUserPhotoMutation();

  // Redux variables
  const previewPhotoDialog = useSelector(
    (state) => state.dialog.previewPhotoDialog
  );
  const profilePreviewPhotoInfo = useSelector(
    (state) => state.profile.profilePreviewPhotoInfo
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!previewPhotoDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PreviewPhoto-Dialog",
      },
    });
  }, [previewPhotoDialog]);

  // Lifecycle | Check for update | Update User Photo API Response
  useEffect(() => {
    if (updateUserPhotoLoading) {
    } else if (updateUserPhotoSuccess) {
      switch (updateUserPhotoData?.status) {
        case 1:
          const toastObj = {
            message: t("signup.profile_updated"),
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(toastObj));

          dispatch(
            updateEditProfilePhoto(updateUserPhotoData?.data?.profile_photo)
          );
          dispatch(
            updateEditPublicPhotos(updateUserPhotoData?.data?.public_photos)
          );
          dispatch(
            updateEditPrivatePhotos(updateUserPhotoData?.data?.private_photos)
          );

          onCloseDialog();
        default:
          break;
      }
    } else if (updateUserPhotoError) {
    }
  }, [updateUserPhotoLoading, updateUserPhotoSuccess, updateUserPhotoError]);

  // Event Handlers | Button
  const onDeletePhoto = () => {
    const obj = {
      photo_id: profilePreviewPhotoInfo?.id,
      delete: true,
    };
    updateUserPhoto(obj);
  };
  const onSetProfilePhoto = () => {
    const obj = {
      photo_id: profilePreviewPhotoInfo?.id,
      set_profile_photo: true,
    };
    updateUserPhoto(obj);
  };
  const onPreviousPhoto = () => {
    if (
      !profilePreviewPhotoInfo ||
      profilePreviewPhotoInfo.index === undefined ||
      profilePreviewPhotoInfo?.index - 1 < 0
    )
      return;

    const obj = {
      id: profilePreviewPhotoInfo?.id,
      photoUrl:
        profilePreviewPhotoInfo?.allPhotos[profilePreviewPhotoInfo?.index - 1]
          ?.photoUrl,
      allPhotos: profilePreviewPhotoInfo?.allPhotos,
      index: profilePreviewPhotoInfo?.index - 1,
    };
    dispatch(updateProfilePreviewPhotoInfo(obj));
  };
  const onNextPhoto = () => {
    if (
      !profilePreviewPhotoInfo ||
      !profilePreviewPhotoInfo.allPhotos ||
      profilePreviewPhotoInfo?.index + 1 >=
        profilePreviewPhotoInfo?.allPhotos?.length
    )
      return;

    const obj = {
      id: profilePreviewPhotoInfo?.id,
      photoUrl:
        profilePreviewPhotoInfo?.allPhotos[profilePreviewPhotoInfo?.index + 1]
          ?.photoUrl,
      allPhotos: profilePreviewPhotoInfo?.allPhotos,
      index: profilePreviewPhotoInfo?.index + 1,
    };
    dispatch(updateProfilePreviewPhotoInfo(obj));
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updatePreviewPhotoDialog(false));
  };

  return (
    <Dialog
      className="custom-fullscreen-transparent-dialog custom-dialog-height-inherit"
      fullScreen={isMobile ? true : false}
      open={previewPhotoDialog}
      onClose={onCloseDialog}
    >
      <div id="preview-photo-dialog">
        {profilePreviewPhotoInfo?.id ? (
          <div className="dialog-header">
            <div className="close-button-container" onClick={onCloseDialog}>
              <CloseIcon className="close-button" />
            </div>

            {updateUserPhotoLoading ? (
              <Spinner size={24} isPadding={false} color={"white-spinner"} />
            ) : (
              <div className="delete-button-container" onClick={onDeletePhoto}>
                <DeleteIcon className="delete-button" />
              </div>
            )}
          </div>
        ) : (
          <div className="dialog-header"></div>
        )}

        <div className="profile-photo-container">
          <div className="previous-button-container" onClick={onPreviousPhoto}>
            <ArrowBackIosIcon
              className={`previous-button ${
                profilePreviewPhotoInfo?.index !== undefined &&
                profilePreviewPhotoInfo?.index - 1 >= 0
                  ? "default-button"
                  : "transparent-button"
              }`}
            />
          </div>

          <img
            className={`profile-photo ${
              isMobile ? "dynamic-width" : "fixed-width"
            }`}
            src={profilePreviewPhotoInfo?.photoUrl}
            alt="profile-photo"
          />

          <div className="forward-button-container" onClick={onNextPhoto}>
            <ArrowForwardIosIcon
              className={`next-button ${
                profilePreviewPhotoInfo?.index !== undefined &&
                profilePreviewPhotoInfo?.index + 1 <
                  profilePreviewPhotoInfo?.allPhotos?.length
                  ? "default-button"
                  : "transparent-button"
              }`}
            />
          </div>
        </div>

        {profilePreviewPhotoInfo?.isProfilePhoto === false &&
          profilePreviewPhotoInfo?.isPrivate === false &&
          profilePreviewPhotoInfo?.isApproved && (
            <div className="set-profile-photo-container">
              <div
                className="set-profile-photo-button"
                onClick={onSetProfilePhoto}
              >
                {updateUserPhotoLoading ? (
                  <Spinner
                    size={20}
                    isPadding={false}
                    color={"white-spinner"}
                  />
                ) : (
                  t("profile.photo_set_profile_photo")
                )}
              </div>
            </div>
          )}
      </div>
    </Dialog>
  );
};

export default PreviewPhotoDialog;
