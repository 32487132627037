// General
import "./login.scss";
import { useState, useEffect, useRef } from "react";
// Environment
import environment from "../../environment/environment";
// Services
import {
  useLazyGetAioQuery,
  useLazyGetUserAioQuery,
  useAuthSigninMutation,
} from "../../services/data.service";
import { sessionService } from "../../services/session.service";
import { emailRegex, removeHTMLEscape } from "../../services/regex.service";
// Static Data
import routeConst from "../../const/routeConst";
import utilityConst from "../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEmail,
  updateCountry,
  updatePhone,
  updatePassword,
} from "../../redux/store/signinStore";
import {
  updateEmail as updateSignupEmail,
  updateGoogleRecaptchaToken,
} from "../../redux/store/signupStore";
import {
  updateIsLoggedIn,
  updateApiToken,
  updateSpiToken,
} from "../../redux/store/publicStore";
import {
  updateWarningToast,
  updateErrorToast,
} from "../../redux/store/toastStore";
import { updateEmail as updateUserEmail } from "../../redux/store/userStore";
import { updateLoginPageTroubleshootDialog } from "../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// React Swipeable Views
import SwipeableViews from "react-swipeable-views/lib/SwipeableViews";
// react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";
// Material UI
import {
  Box,
  Avatar,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  Button,
  InputAdornment,
  IconButton,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../utility/manager/icon-manager/icon-manager";
// Components
import FacebookLoginButton from "./facebook-login-button/facebook-login-button";
import Spinner from "../shared/elements/spinner/spinner";

const Login = () => {
  // API variables
  const [
    authSignin,
    {
      data: authSigninData,
      error: authSigninErrorData,
      isLoading: authSigninLoading,
      isSuccess: authSigninSuccess,
      isError: authSigninError,
    },
  ] = useAuthSigninMutation();
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // General variables
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailFocus, setEmailFocus] = useState(false); // Not using
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [phoneFocus, setPhoneFocus] = useState(false); // Not using
  const [phoneValid, setPhoneValid] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false); // Not using
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [countryErrorMessage, setCountryErrorMessage] = useState("");
  const [countryFocus, setCountryFocus] = useState(false);
  const [countryValid, setCountryValid] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [loginTab, setLoginTab] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const recaptchaRef = useRef();

  // Redux variables
  const email = useSelector((state) => state.signin.email);
  const country = useSelector((state) => state.signin.country);
  const phone = useSelector((state) => state.signin.phone);
  const password = useSelector((state) => state.signin.password);
  const rememberMe = useSelector((state) => state.signin.rememberMe);
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const dispatch = useDispatch();

  // MUI variables
  const theme = useTheme();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getAio();
  }, []);

  // Lifecycle | Check for update | Auth Signin API Response
  useEffect(() => {
    if (authSigninLoading) {
    } else if (authSigninSuccess) {
      switch (authSigninData?.status) {
        case 0:
          dispatch(updateApiToken(authSigninData?.data?.legacy_token)); // Remove this after all API using SPI
          dispatch(updateSpiToken(authSigninData?.data?.token)); // Use this after move all API to SPI
          sessionService.setEmail(email);
          dispatch(updateIsLoggedIn(true));

          getUserAio();

          // GTM (Not Tested)
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-Login-Page-LoginSuccess",
              // value: {
              //   userId: signinData?.data?.user?.id,
              //   role: getRole(
              //     signinData?.data?.user?.wanttobe,
              //     signinData?.data?.user?.interest
              //   ),
              // },
            },
          });
          break;
        case -1:
          const toastObj = {
            message: authSigninData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(toastObj));
        default:
          break;
      }
    } else if (authSigninError) {
      const toastObj = {
        message: authSigninErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));
    }
  }, [authSigninLoading, authSigninSuccess, authSigninError]);

  // Lifecycle | Check for update | AIO API Response
  useEffect(() => {
    if (getAioFetching || getAioLoading) {
    } else if (getAioSuccess) {
      dispatch(updateCountry(getAioData?.payload?.default_country_data));
    } else if (getAioError) {
    }
  }, [getAioFetching, getAioLoading, getAioSuccess, getAioError]);

  // Lifecycle | Check for update | getUserAio API Response
  useEffect(() => {
    if (getUserAioFetching || getUserAioLoading) {
    } else if (getUserAioSuccess) {
      if (getUserAioData?.status === 1) {
        switch (getUserAioData?.data?.pwa?.default_view) {
          case "live":
            onNavigate(routeConst.live.path);
          case "home":
          case "search":
          default:
            onNavigate(routeConst.search.path);
            break;
        }
      }
    } else if (getUserAioError) {
    }
  }, [
    getUserAioFetching,
    getUserAioLoading,
    getUserAioSuccess,
    getUserAioError,
  ]);

  // Event Handlers | Button
  const onSignin = async () => {
    if (!isFormValid()) return;

    // Google Recaptcha Token
    const token = await recaptchaRef.current.executeAsync();
    dispatch(updateGoogleRecaptchaToken(token));

    const obj = {
      email,
      password,
      auth_method: utilityConst.authMethod.emailPassword,
      gre_token: token,
    };
    authSignin(obj);
  };
  const onSignup = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-1-1.5-Button",
      },
    });

    onNavigate(routeConst.join.path);
  };
  const onForgotPassword = () => {
    onNavigate(routeConst.forgotPassword.path);
  };
  const onEnterPress = (event) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      if (isFormValid()) {
        onSignin();
      }
    }
  };
  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onOpenTroubleshootDialog = () => {
    dispatch(updateLoginPageTroubleshootDialog(true));
  };

  // Event Handlers | MUI Text Field
  const onEmailChange = (event) => {
    dispatch(updateEmail(event.target.value?.trim()?.toLowerCase()));
    dispatch(updateUserEmail(event.target.value));
  };
  const onEmailFocus = (event) => {
    setEmailFocus(true);
  };
  const onPhoneChange = (event) => {
    if (isNaN(event.target.value)) return;

    dispatch(updatePhone(event.target.value?.trim()?.toLowerCase()));
  };
  const onPhoneFocus = (event) => {
    setPhoneFocus(true);
  };
  const onPasswordChange = (event) => {
    dispatch(updatePassword(event.target.value));
  };
  const onPasswordFocus = (event) => {
    setPasswordFocus(true);
  };
  const onCountryChange = (event) => {
    dispatch(updateCountry(event.target.value));
  };

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    setLoginTab(newValue);
  };
  const onTabChangeBySwipe = (index) => {
    setLoginTab(index);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if ((emailValid || phoneValid) && passwordValid) {
      valid = true;
    }

    return valid;
  };
  const emailValidation = () => {
    let valid = true;
    let error = false;

    const regex = emailRegex;

    if (email === "") {
      setEmailErrorMessage(t("login.email_address_is_required"));
      valid = false;
      error = true;
    } else if (!regex.test(email)) {
      setEmailErrorMessage(t("login.email_address_is_not_valid"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(email)) {
      valid = false;
      error = true;
      dispatch(updateEmail(""));

      // Send to Sentry
      Sentry.captureMessage("PWA-1-Email-Input-HTML-Escape-Detected");

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-1-Email-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setEmailErrorMessage("");
      valid = true;
      error = false;
    }

    setEmailValid(valid);
    setEmailError(error);
  };
  const phoneValidation = () => {
    let valid = true;
    let error = false;

    if (phone === "") {
      setPhoneErrorMessage(t("login.phone_number_is_required"));
      valid = false;
      error = true;
    } else {
      setPhoneErrorMessage("");
      valid = true;
      error = false;
    }

    setPhoneValid(valid);
    setPhoneError(error);
  };
  const passwordValidation = () => {
    let valid = true;
    let error = false;

    if (password === "") {
      setPasswordErrorMessage(t("login.password_is_required"));
      valid = false;
      error = true;
    } else if (password.length < 6) {
      setPasswordErrorMessage(
        t("login.password_must_be_n_characters", { n: 6 })
      );
      valid = false;
      error = true;
    } else {
      setPasswordErrorMessage("");
      valid = true;
      error = false;
    }

    setPasswordValid(valid);
    setPasswordError(error);
  };
  const getRole = (wantToBe, lookingFor) => {
    let role = "";

    if (wantToBe === "sugardaddy") {
      if (lookingFor === "men") {
        role = "sbm";
      } else if (lookingFor === "women") {
        role = "sd";
      }
    } else if (wantToBe === "sugarbaby") {
      if (lookingFor === "men") {
        role = "sb";
      } else if (lookingFor === "women") {
        role = "sbm";
      }
    }

    return role;
  };

  // Check for email and password validation
  useEffect(() => {
    if (loginTab === 0) {
      emailValidation();
    } else if (loginTab === 1) {
      phoneValidation();
    }

    passwordValidation();
  }, [
    email,
    phone,
    password,
    emailFocus,
    phoneFocus,
    passwordFocus,
    emailErrorMessage,
    phoneErrorMessage,
    passwordErrorMessage,
  ]);

  return (
    <Box
      id="login-page"
      className="login-container"
      // component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <div
          className="sugarbook-logo-container"
          onClick={onOpenTroubleshootDialog}
        >
          {getIcon("sugarbookLogoWithTagline", "sugarbook-logo")}
        </div>

        {getAioData?.payload?.phone_auth?.enabled ? (
          <div className="tab-container">
            <Tabs
              className="custom-tabs login-tabs"
              value={loginTab}
              onChange={onTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
              variant={"fullWidth"}
            >
              <Tab label={t("settings.email")} {...a11yProps(0)} />
              <Tab label={t("login.mobile_number")} {...a11yProps(1)} />
            </Tabs>

            <SwipeableViews
              className="livestream-swipeable-container"
              containerStyle={{
                transition:
                  "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
              }}
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={loginTab}
              onChangeIndex={onTabChangeBySwipe}
            >
              <div className="email-container" index={0} dir={theme.direction}>
                <div className="email-form-field-container">
                  <TextField
                    className="email-form-field custom-text-field"
                    required
                    type="email"
                    value={email}
                    error={emailError}
                    onChange={onEmailChange}
                    label={t("login.email_address")}
                    placeholder={t("login.email_address")}
                    helperText={emailErrorMessage}
                    variant="standard"
                    onFocus={onEmailFocus}
                    onKeyDown={onEnterPress}
                  />
                </div>
              </div>

              <div className="phone-container" index={1} dir={theme.direction}>
                <div className="country-form-field-container">
                  <TextField
                    sx={{ "& .MuiSelect-select": { marginTop: "16px" } }}
                    className="country-form-field custom-text-field"
                    select
                    value={country}
                    onChange={onCountryChange}
                    variant="standard"
                    SelectProps={{
                      renderValue: (value) => value?.phone_country_code,
                    }}
                  >
                    {getAioData?.payload?.country_list_with_iso_code?.map(
                      (country, index) => (
                        <MenuItem key={index} value={country}>
                          <div className="select-output">
                            <div className="flag-icon-container">
                              <Avatar
                                className="flag-icon"
                                src={country?.country_flag}
                                alt={country?.country_name}
                              />
                            </div>
                            <div className="country-name">
                              {country?.country_name}
                            </div>
                            <div className="country-name">
                              {country?.phone_country_code}
                            </div>
                          </div>
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </div>

                <div className="phone-form-field-container">
                  <TextField
                    className="phone-form-field custom-text-field"
                    required
                    value={phone}
                    error={phoneError}
                    onChange={onPhoneChange}
                    label={t("login.mobile_number")}
                    placeholder={t("login.mobile_number")}
                    helperText={phoneErrorMessage}
                    variant="standard"
                    onFocus={onPhoneFocus}
                    onKeyDown={onEnterPress}
                  />
                </div>
              </div>
            </SwipeableViews>
          </div>
        ) : (
          <div className="email-container" index={0} dir={theme.direction}>
            <div className="email-form-field-container">
              <TextField
                className="email-form-field custom-text-field"
                required
                value={email}
                error={emailError}
                onChange={onEmailChange}
                label={t("login.email_address")}
                placeholder={t("login.email_address")}
                helperText={emailErrorMessage}
                variant="standard"
                onFocus={onEmailFocus}
                onKeyDown={onEnterPress}
              />
            </div>
          </div>
        )}

        <div className="password-form-field-container">
          <TextField
            className="password-form-field custom-text-field"
            sx={{
              "& .MuiInput-underline:after": { borderBottomColor: "#710D0D" },
              "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
            }}
            required
            value={password}
            error={passwordError}
            onChange={onPasswordChange}
            label={t("login.password")}
            type={showPassword ? "text" : "password"}
            placeholder={t("login.password")}
            helperText={passwordErrorMessage}
            variant="standard"
            onFocus={onPasswordFocus}
            onKeyDown={onEnterPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onShowPassword}>
                    {showPassword ? (
                      <VisibilityIcon className="show-password" />
                    ) : (
                      <VisibilityOffIcon className="hide-password" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="forgot-password-button-container">
          <div className="forgot-password-button" onClick={onForgotPassword}>
            {t("login.forgot_password")}
          </div>
        </div>

        <div className="recaptcha-container">
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env["REACT_APP_RECAPTCHA_V3_SITE_KEY"]}
            theme="light"
          />
        </div>

        <div className="button-container">
          <Button
            className={`login-button ${isFormValid() ? "" : "disabled-button"}`}
            variant="text"
            onClick={onSignin}
            disabled={!isFormValid() || authSigninLoading || getUserAioFetching}
          >
            {authSigninLoading || getUserAioFetching ? (
              <Spinner size={24} isPadding={false} color={"white-spinner"} />
            ) : (
              t("login.login")
            )}
          </Button>

          <FacebookLoginButton />

          <div className="join-button-container">
            <div className="join-button" onClick={onSignup}>
              {t("login.join_free_today")}
            </div>
          </div>

          <div className="create-account-label" onClick={onSignup}>
            {t("login.do_not_have_an_account")}
          </div>
        </div>
      </div>

      <div className="version-container">
        <div className="version">
          {t("settings.pwa_version")} {environment.appVersion}
        </div>
      </div>
    </Box>
  );
};

export default Login;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
          {/* <Typography component={"div"}>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
