// General
import "./tip-menu-tab.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetPrivateCallTipItemsMenuQuery,
  usePostPrivateCallSendTipMutation,
  usePostPrivateCallRequestTipMutation,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateProductPurchaseDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider, TextField } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const TipMenuTab = (props) => {
  const { state } = props;

  // API variables
  const [
    getPrivateCallTipItemsMenu,
    {
      data: privateCallTipItemsMenuData,
      error: privateCallTipItemsMenuErrorData,
      isFetching: privateCallTipItemsMenuFetching,
      isLoading: privateCallTipItemsMenuLoading,
      isSuccess: privateCallTipItemsMenuSuccess,
      isError: privateCallTipItemsMenuError,
    },
  ] = useLazyGetPrivateCallTipItemsMenuQuery();
  const [
    sendTip,
    {
      data: sendTipData,
      error: sendTipErrorData,
      isLoading: sendTipLoading,
      isSuccess: sendTipSuccess,
      isError: sendTipError,
    },
  ] = usePostPrivateCallSendTipMutation();
  const [
    requestTip,
    {
      data: requestTipData,
      error: requestTipErrorData,
      isLoading: requestTipLoading,
      isSuccess: requestTipSuccess,
      isError: requestTipError,
    },
  ] = usePostPrivateCallRequestTipMutation();

  // General variables
  const [actionInputValue, setActionInputValue] = useState("");
  const [coinsInputValue, setCoinsInputValue] = useState("");

  // Render variables
  let actionItemsView;

  // Redux variables
  const requestId = useSelector((state) => state.privateCall.requestId);
  const calleeId = useSelector((state) => state.privateCall.calleeId);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getPrivateCallTipItemsMenu(`?user_id=${calleeId}`);
  }, []);

  // Lifecycle | Check for update | sendTip API Response
  useEffect(() => {
    if (sendTipLoading) {
    } else if (sendTipSuccess) {
      if (sendTipData?.status === 0) {
        dispatch(updateProductPurchaseDialog(false));
      }
    } else if (sendTipError) {
    }
  }, [sendTipLoading, sendTipSuccess, sendTipError]);

  // Lifecycle | Check for update | requestTip API Response
  useEffect(() => {
    if (requestTipLoading) {
    } else if (requestTipSuccess) {
      if (requestTipData?.status === 0) {
        dispatch(updateProductPurchaseDialog(false));
        setActionInputValue("");
        setCoinsInputValue("");
      }
    } else if (requestTipError) {
    }
  }, [requestTipLoading, requestTipSuccess, requestTipError]);

  // Event Handlers | Button
  const onSendTip = (itemId) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D19-19.1-Button",
      },
    });

    const obj = {
      recipient_user_id: calleeId,
      related_id: requestId,
      related_module: "private_calls",
      tipping_item_id: itemId,
    };

    sendTip(obj);
  };
  const onRequestCustomTip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D19-19.2-Button",
      },
    });

    const obj = {
      description: actionInputValue,
      related_id: requestId,
      related_module: "private_calls",
      value: coinsInputValue,
    };

    requestTip(obj);
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (type) => (event) => {
    if (type === "action") {
      setActionInputValue(event.target.value);
    } else if (type === "coins") {
      setCoinsInputValue(event.target.value);
    }
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (
      actionInputValue !== "" &&
      coinsInputValue !== "" &&
      coinsInputValue >= 10
    ) {
      valid = true;
    }

    return valid;
  };

  // Render Functions | API Response
  if (privateCallTipItemsMenuFetching || privateCallTipItemsMenuLoading) {
  } else if (privateCallTipItemsMenuSuccess) {
    if (privateCallTipItemsMenuData?.status === 0) {
      actionItemsView = privateCallTipItemsMenuData?.data?.results?.map(
        (tip, index) => {
          if (tip?.description && tip?.value && tip?.item_id) {
            return (
              <div className="action-container" key={index}>
                <div className="action-description">{tip?.description}</div>

                <div className="action-value">
                  <div className="coin-icon-container">
                    {getIcon("sbCoinIcon", "coin-icon")}
                  </div>
                  {tip?.value.toLocaleString()}
                </div>

                <div
                  className="send-action-button"
                  onClick={() => onSendTip(tip.item_id)}
                >
                  {t("common.send")}
                </div>
              </div>
            );
          }
        }
      );
    }
  } else if (privateCallTipItemsMenuError) {
    if (privateCallTipItemsMenuErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }

  return (
    <div id="product-purchase-dialog-tip-menu-tab-subcomponent">
      <div className="padding-container">
        <div className="tip-header">{t("1on1.tip_task")}</div>

        <div className="subheader">{t("1on1.action")}</div>
        {actionItemsView}
        <Divider />

        <div className="request-header">{t("1on1.request_an_action")}</div>

        <div className="request-an-action-container">
          <div className="request-label-container">
            <div className="action-request-label">{t("1on1.action")}</div>
            <div className="coins-request-label">
              <div className="coin-icon-container">
                {getIcon("sbCoinIcon", "coin-icon")}
              </div>
              {t("1on1.coins_value")}
            </div>
            <div className="send-request-label"></div>
          </div>

          <div className="request-item-container">
            <div className="action-form-field">
              <TextField
                className="form-field"
                value={actionInputValue}
                placeholder={t("1on1.action_example")}
                onChange={onInputChange("action")}
                variant="standard"
                InputProps={{ disableUnderline: true }}
              />
            </div>

            <div className="coins-value-form-field">
              <TextField
                className="form-field"
                type="number"
                value={coinsInputValue}
                placeholder="e.g.1000"
                onChange={onInputChange("coins")}
                variant="standard"
                InputProps={{ disableUnderline: true }}
              />
            </div>

            <div
              className={`send-custom-action-button ${
                isFormValid() ? "" : "disabled-button"
              }`}
              onClick={onRequestCustomTip}
            >
              {t("common.send")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TipMenuTab;
