// General
import "./indepth-info.scss";
import { useEffect } from "react";
// Services
import { useLazyGetAioQuery } from "../../../../../services/data.service";
import { dashToUnderscore } from "../../../../../services/regex.service";
// Static Data
import userConst from "../../../../../const/userConst";
import profileConst from "../../../../../const/profileConst";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";

const IndepthInfo = (props) => {
  const { userProfileType = profileConst.userProfileType.dialog } = props;

  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();

  // Constant variables
  const userInfo = userConst.indeptInfo;

  // Redux variables
  const annualIncome = useSelector((state) => state.userCard.annualIncome);
  const networth = useSelector((state) => state.userCard.networth);
  const occupation = useSelector((state) => state.userCard.occupation);
  const lifestyle = useSelector((state) => state.userCard.lifestyle);
  const education = useSelector((state) => state.userCard.education);
  const relationship = useSelector((state) => state.userCard.relationship);
  const smoking = useSelector((state) => state.userCard.smoking);
  const drinking = useSelector((state) => state.userCard.drinking);
  const height = useSelector((state) => state.userCard.height);
  const bodyType = useSelector((state) => state.userCard.bodyType);
  const ethnicity = useSelector((state) => state.userCard.ethnicity);
  const eyeColor = useSelector((state) => state.userCard.eyeColor);
  const hairColor = useSelector((state) => state.userCard.hairColor);
  const children = useSelector((state) => state.userCard.children);
  const isSugarbaby = useSelector((state) => state.userCard.isSugarbaby);

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  const regex = dashToUnderscore;

  // Lifecycle | Mounted
  useEffect(() => {
    getAio(null, true);
  }, []);

  // Utility Functions
  const convertToMultiLanguage = (output, index) => {
    let result = "-";

    if (output && output !== "-") {
      // Convert to lower case except height
      let outputLowerCase;
      if (index !== 8 && index !== 13) {
        outputLowerCase = output.toLowerCase();
      }

      switch (index) {
        case 0: // annualIncome
          if (output === "0" || output === 0 || !existInApi(output, index)) {
            result = "-";
          } else {
            result = t(
              `filter.opt_income.${outputLowerCase
                ?.replace(regex, "_")
                ?.replace(/,/g, "")}`
            );
          }
          break;
        case 1: // networth
          if (output === "0" || output === 0 || !existInApi(output, index)) {
            result = "-";
          } else {
            result = t(
              `filter.opt_net_worth.${outputLowerCase
                ?.replace(regex, "_")
                ?.replace(/,/g, "")}`
            );
          }
          break;
        case 2: // occupation
          result = output;
          break;
        case 3: // lifestyle
          if (existInApi(output, index)) {
            result = t(
              `filter.opt_lifestyle.${outputLowerCase.replace(regex, "_")}`
            );
          } else {
            result = "-";
          }

          break;
        case 4: // education
          if (existInApi(output, index)) {
            result = t(
              `filter.opt_education.${outputLowerCase.replace(regex, "_")}`
            );
          } else {
            result = "-";
          }
          break;
        case 5: // relationship
          if (existInApi(output, index)) {
            result = t(
              `filter.opt_relationship.${outputLowerCase.replace(regex, "_")}`
            );
          } else {
            result = "-";
          }
          break;
        case 6: // smoking
          if (existInApi(output, index)) {
            result = t(
              `filter.opt_smoking.${outputLowerCase.replace(regex, "_")}`
            );
          } else {
            result = "-";
          }
          break;
        case 7: // drinking
          if (existInApi(output, index)) {
            result = t(
              `filter.opt_drinking.${outputLowerCase.replace(regex, "_")}`
            );
          } else {
            result = "-";
          }
          break;
        case 8: // height
          result = `${output} cm`;
          break;
        case 9: // bodyType
          if (existInApi(output, index)) {
            result = t(
              `filter.opt_body_type.${outputLowerCase.replace(regex, "_")}`
            );
          } else {
            result = "-";
          }
          break;
        case 10: // ethnicity
          if (existInApi(output, index)) {
            result = t(
              `filter.opt_ethnicity.${outputLowerCase.replace(regex, "_")}`
            );
          } else {
            result = "-";
          }
          break;
        case 11: // eyeColor
          if (existInApi(output, index)) {
            result = t(
              `filter.opt_color.${outputLowerCase.replace(regex, "_")}`
            );
          } else {
            result = "-";
          }
          break;
        case 12: // hairColor
          if (existInApi(output, index)) {
            result = t(
              `filter.opt_color.${outputLowerCase.replace(regex, "_")}`
            );
          } else {
            result = "-";
          }
          break;
        case 13: // children
          if (existInApi(output, index)) {
            result = output;
          } else {
            result = "-";
          }
          break;
        default:
          break;
      }
    }

    return result;
  };
  const existInApi = (value, index) => {
    let exist = false;

    switch (index) {
      case 0:
        exist = getAioData?.payload?.dropdown?.annual_income?.some(
          (item) =>
            item.value === value ||
            item.value === parseFloat(value).toLocaleString()
        );
        break;
      case 1:
        exist = getAioData?.payload?.dropdown?.networth?.some(
          (item) =>
            item.value === value ||
            item.value === parseFloat(value).toLocaleString()
        );
        break;
      case 2:
        exist = getAioData?.payload?.dropdown?.occupation?.some(
          (item) => item.value === value
        );
        break;
      case 3:
        exist = getAioData?.payload?.dropdown?.lifestyle?.some(
          (item) => item.value === value
        );
        break;
      case 4:
        exist = getAioData?.payload?.dropdown?.education?.some(
          (item) => item.value === value
        );
        break;
      case 5:
        exist = getAioData?.payload?.dropdown?.relationship?.some(
          (item) => item.value === value
        );
        break;
      case 6:
        exist = getAioData?.payload?.dropdown?.smoking?.some(
          (item) => item.value === value
        );
        break;
      case 7:
        exist = getAioData?.payload?.dropdown?.drinking?.some(
          (item) => item.value === value
        );
        break;
      case 8:
        exist = getAioData?.payload?.dropdown?.height?.some(
          (item) => item.value === value
        );
        break;
      case 9:
        exist = getAioData?.payload?.dropdown?.body_type?.some(
          (item) => item.value === value
        );
        break;
      case 10:
        exist = getAioData?.payload?.dropdown?.ethnicity?.some(
          (item) => item.value === value
        );
        break;
      case 11:
        exist = getAioData?.payload?.dropdown?.eye_color?.some(
          (item) => item.value === value
        );
        break;
      case 12:
        exist = getAioData?.payload?.dropdown?.hair_color?.some(
          (item) => item.value === value
        );
        break;
      case 13:
        exist = getAioData?.payload?.dropdown?.children?.some(
          (item) => item.value === value
        );
        break;
      default:
        break;
    }

    return exist;
  };

  // Sorting static data with props
  userInfo.forEach((user) => {
    switch (user.value) {
      case "annual-income":
        user.output = annualIncome;
        break;
      case "networth":
        user.output = networth;
        break;
      case "occupation":
        user.output = occupation;
        break;
      case "lifestyle":
        user.output = lifestyle;
        break;
      case "education":
        user.output = education;
        break;
      case "relationship":
        user.output = relationship;
        break;
      case "smoking":
        user.output = smoking;
        break;
      case "drinking":
        user.output = drinking;
        break;
      case "height":
        user.output = height;
        break;
      case "body-type":
        user.output = bodyType;
        break;
      case "ethnicity":
        user.output = ethnicity;
        break;
      case "eye-color":
        user.output = eyeColor;
        break;
      case "hair-color":
        user.output = hairColor;
        break;
      case "children":
        user.output = children;
        break;
      default:
        break;
    }
  });

  return (
    <div
      id="profile-view-indepth-info-subcomponent"
      className={
        userProfileType === profileConst.userProfileType.page
          ? "apply-fix-width"
          : ""
      }
    >
      <div
        className={
          userProfileType === profileConst.userProfileType.dialog
            ? "padding-container"
            : ""
        }
      >
        {userProfileType === profileConst.userProfileType.dialog && (
          <div className="detail-information-container">
            {userInfo?.map((item, index) => {
              if (isSugarbaby) {
                if (
                  item?.value === "annual-income" ||
                  item?.value === "networth"
                ) {
                  return null;
                } else {
                  return (
                    <div className="information-container" key={index}>
                      <div className="category-label">{t(item?.jsonTitle)}</div>
                      <div className="data-label">
                        {convertToMultiLanguage(item?.output, index)}
                      </div>
                    </div>
                  );
                }
              } else {
                return (
                  <div className="information-container" key={index}>
                    <div className="category-label">{t(item?.jsonTitle)}</div>
                    <div className="data-label">
                      {convertToMultiLanguage(item?.output, index)}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}

        {userProfileType === profileConst.userProfileType.page && (
          <div className="page-detail-information-container">
            {userInfo?.map((item, index) => {
              if (isSugarbaby) {
                if (
                  item?.value === "annual-income" ||
                  item?.value === "networth"
                ) {
                  return null;
                } else {
                  return (
                    <div className="indepth-item-container" key={index}>
                      <div className="indepth-label">{t(item?.jsonTitle)}</div>
                      <div className="indepth-data">
                        {convertToMultiLanguage(item?.output, index)}
                      </div>
                    </div>
                  );
                }
              } else {
                return (
                  <div className="indepth-item-container" key={index}>
                    <div className="indepth-label">{t(item?.jsonTitle)}</div>
                    <div className="indepth-data">
                      {convertToMultiLanguage(item?.output, index)}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default IndepthInfo;
