// General
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfilePwaQuery,
  useLazyGetProfileQuery,
  useLazyGetUserAioQuery,
  useLazyGetPrivateCallFreeMinutesQuery,
  useLazyGetAioQuery,
  useLazyGetLevellingTasksQuery,
  useLazyGetLivestreamingContestListQuery,
  useLazyGetPrivateCallContestListQuery,
  useInitiateEmailVerificationMutation,
  useLazyGetNotificationsCountQuery,
} from "../../../../services/data.service";
import { sessionService } from "../../../../services/session.service";
import { API_CONFIG_HEADERS } from "../../../../const/apiConst";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Timer Functions
  setDailyCheckInTimer,
  updateDailyCheckInTimer,
  resetDailyCheckInTimer,

  // Interval Functions
  updateDailyCheckInInterval,
  clearDailyCheckInInterval,
  updateVpnBlockInfo,
} from "../../../../redux/store/publicStore";
import {
  updateIsDaddy,
  updateIsMommy,
  updateIsSugarbaby,
  updateIsSugarbabyMale,
  updateMembershipType,

  // Utility Functions
  updateIsMissingDetails,
} from "../../../../redux/store/userStore";
import {
  updateLivestreamingContestId,
  updateLivestreamingContestPkId,
  updatePrivateCallContestId,
} from "../../../../redux/store/leaderboardStore";
import { updateOwnPrivateCallEnable } from "../../../../redux/store/inboxStore";
import {
  // Email Verification Functions
  setEmailVerificationTimer,
  updateEmailVerificationDisplay,
  updateEmailVerificationRequired,

  // Mobile Verification Functions
  updateMobileVerificationDisplay,
  updateMobileVerificationRequired,
} from "../../../../redux/store/verificationStore";
import {
  updateNotificationCount,
  updateNotificationCountLoaded,
  updateShowNotificationBadge,
} from "../../../../redux/store/notificationStore";
import { updateGlobalPusherSubscribe } from "../../../../redux/store/pusherStore";
import {
  updateDailyCheckInDialog,
  updateVpnBlockDialog,
} from "../../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// Sentry
import * as Sentry from "@sentry/react";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../custom-hooks/useCustomNavigate-hook";

const ApiMounted = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getProfilePwa,
    {
      data: getProfilePwaData,
      error: getProfilePwaErrorData,
      isFetching: getProfilePwaFetching,
      isLoading: getProfilePwaLoading,
      isSuccess: getProfilePwaSuccess,
      isError: getProfilePwaError,
    },
  ] = useLazyGetProfilePwaQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getPrivateCallFreeMinutes,
    {
      data: getPrivateCallFreeMinutesData,
      error: getPrivateCallFreeMinutesErrorData,
      isFetching: getPrivateCallFreeMinutesFetching,
      isLoading: getPrivateCallFreeMinutesLoading,
      isSuccess: getPrivateCallFreeMinutesSuccess,
      isError: getPrivateCallFreeMinutesError,
    },
  ] = useLazyGetPrivateCallFreeMinutesQuery();
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    getLevellingTasks,
    {
      data: getLevellingTasksData,
      error: getLevellingTasksErrorData,
      isFetching: getLevellingTasksFetching,
      isLoading: getLevellingTasksLoading,
      isSuccess: getLevellingTasksSuccess,
      isError: getLevellingTasksError,
    },
  ] = useLazyGetLevellingTasksQuery();
  const [
    getLivestreamingContestList,
    {
      data: getLivestreamingContestListData,
      error: getLivestreamingContestListErrorData,
      isFetching: getLivestreamingContestListFetching,
      isLoading: getLivestreamingContestListLoading,
      isSuccess: getLivestreamingContestListSuccess,
      isError: getLivestreamingContestListError,
    },
  ] = useLazyGetLivestreamingContestListQuery();
  const [
    getPrivateCallContestList,
    {
      data: getPrivateCallContestListData,
      error: getPrivateCallContestListErrorData,
      isFetching: getPrivateCallContestListFetching,
      isLoading: getPrivateCallContestListLoading,
      isSuccess: getPrivateCallContestListSuccess,
      isError: getPrivateCallContestListError,
    },
  ] = useLazyGetPrivateCallContestListQuery();
  const [
    initiateEmailVerification,
    {
      data: initiateEmailVerificationData,
      error: initiateEmailVerificationErrorData,
      isLoading: initiateEmailVerificationLoading,
      isSuccess: initiateEmailVerificationSuccess,
      isError: initiateEmailVerificationError,
    },
  ] = useInitiateEmailVerificationMutation();
  const [
    getNotificationsCount,
    {
      data: getNotificationsCountData,
      error: getNotificationsCountErrorData,
      isFetching: getNotificationsCountFetching,
      isLoading: getNotificationsCountLoading,
      isSuccess: getNotificationsCountSuccess,
      isError: getNotificationsCountError,
    },
  ] = useLazyGetNotificationsCountQuery();

  // General variables
  const [hasLoaded, setHasLoaded] = useState(false);

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dailyCheckInInterval = useSelector(
    (state) => state.public.dailyCheckInInterval
  );
  const dailyCheckInTimer = useSelector(
    (state) => state.public.dailyCheckInTimer
  );
  const dispatch = useDispatch();

  // i18next variables
  const { i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    // Add sb-development header for development environment
    if (process.env["REACT_APP_ENVIRONMENT"] === "development") {
      API_CONFIG_HEADERS.COM_HEADERS["sb-development"] = 1;
      API_CONFIG_HEADERS.COM_HEADERS_FORM_DATA["sb-development"] = 1;
    }

    if (window.matchMedia("(display-mode: standalone)").matches) {
      API_CONFIG_HEADERS.SB_API_HEADERS["display-mode"] = "standalone";
      API_CONFIG_HEADERS.COM_HEADERS["display-mode"] = "standalone";
      API_CONFIG_HEADERS.SPI_HEADERS["display-mode"] = "standalone";
      API_CONFIG_HEADERS.SB_API_HEADERS_FORM_DATA["display-mode"] =
        "standalone";
      API_CONFIG_HEADERS.COM_HEADERS_FORM_DATA["display-mode"] = "standalone";
    }

    getAio();

    document.addEventListener("visibilitychange", generalVisibilityChange);
  }, []);

  // Lifecycle | Check for update | isLoggedIn
  useEffect(() => {
    if (isLoggedIn) {
      getProfilePwa();
      getProfile();
      getUserAio();
      getPrivateCallFreeMinutes(); // Not used
      // getLevellingTasks(); // Moved to User AIO
      getLivestreamingContestList();
      getPrivateCallContestList();
      getNotificationsCount();

      document.addEventListener("visibilitychange", loggedInVisibilityChange);
    } else {
      setHasLoaded(false);
      document.removeEventListener(
        "visibilitychange",
        loggedInVisibilityChange
      );
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener(
        "visibilitychange",
        loggedInVisibilityChange
      );
    };
  }, [isLoggedIn]);

  // Lifecycle | Check for update | AIO API Response
  useEffect(() => {
    if (getAioFetching || getAioLoading) {
    } else if (getAioSuccess) {
      if (getAioData?.status === 0) {
        // Close VPN Block Dialog
        dispatch(updateVpnBlockDialog(false));
      } else if (
        getAioData?.success === "false" &&
        getAioData?.code === "403"
      ) {
        // Open VPN Block Dialog
        dispatch(updateVpnBlockInfo(getAioData?.message?.pwa));
        dispatch(updateVpnBlockDialog(true));
      }
    } else if (getAioError) {
      if (getAioErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [getAioFetching, getAioLoading, getAioSuccess, getAioError]);

  // Lifecycle | Check for update | Profile PWA API Response
  useEffect(() => {
    if (getProfilePwaFetching || getProfilePwaLoading) {
    } else if (getProfilePwaSuccess) {
      if (getProfilePwaData?.status === 0) {
        dispatch(
          updateMembershipType(
            getProfilePwaData?.payload?.message?.profile?.membershiptype
          )
        );
      }
    } else if (getProfilePwaError) {
    }
  }, [
    getProfilePwaFetching,
    getProfilePwaLoading,
    getProfilePwaSuccess,
    getProfilePwaError,
  ]);

  // Lifecycle | Check for update | Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (getProfileData?.status === 0) {
        dispatch(updateGlobalPusherSubscribe({}));

        dispatch(
          updateIsDaddy(
            getProfileData?.data?.role === userConst.userRole.sugardaddy ||
              getProfileData?.data?.role === userConst.userRole.sugarmommy
          )
        ); // TODO: Changing to individual role instead of merged sugardaddy and sugarmommy
        dispatch(
          updateIsMommy(
            getProfileData?.data?.role === userConst.userRole.sugarmommy
          )
        );
        dispatch(
          updateIsSugarbaby(
            getProfileData?.data?.role === userConst.userRole.sugarbaby
          )
        );
        dispatch(
          updateIsSugarbabyMale(
            getProfileData?.data?.role === userConst.userRole.maleSugarbaby
          )
        );
        // dispatch(updateCalleeId(getProfileData?.data?.id)); // TBA

        // Sentry
        Sentry.setUser({ id: getProfileData?.data?.id_int });

        // Initialize Insider
        const userData = {
          email: getProfileData?.data?.email,
          username: getProfileData?.data?.username,
          role: "",
          is_premium: "",
          signup_date: "",
        };
        window.dataLayer.push(userData);

        if (!hasLoaded) {
          // Set hasLoaded to only run this function once
          setHasLoaded(true);

          // Save the state of email and mobile verification required
          // Used for checking if email and mobile verification is skippable
          dispatch(
            updateEmailVerificationDisplay(
              getProfileData?.data?.user?.verification?.email
                ?.verification_display
            )
          );
          dispatch(
            updateEmailVerificationRequired(
              getProfileData?.data?.user?.verification?.email
                ?.verification_required
            )
          );
          dispatch(
            updateMobileVerificationDisplay(
              getProfileData?.data?.user?.verification?.mobile
                ?.verification_display
            )
          );
          dispatch(
            updateMobileVerificationRequired(
              getProfileData?.data?.user?.verification?.mobile
                ?.verification_required
            )
          );
          dispatch(
            updateIsMissingDetails(
              !getProfileData?.data?.username &&
                !getProfileData?.data?.profile_details_approval?.username
                  ?.new_content
            )
          );

          if (
            !getProfileData?.data?.verifications?.email?.verified &&
            getProfileData?.data?.verifications?.email?.verification_display
          ) {
            initiateEmailVerification();
          } else if (
            !getProfileData?.data?.verifications?.mobile?.verified &&
            getProfileData?.data?.verifications?.mobile?.verification_display
          ) {
            onNavigate(routeConst.phoneAuthentication.altPath);
          } else if (
            !getProfileData?.data?.verifications?.facial?.verified &&
            getProfileData?.data?.verifications?.facial?.verification_required
          ) {
            onNavigate(routeConst.verify.sugarbookVerified.path);
          } else if (
            !getProfileData?.data?.username &&
            !getProfileData?.data?.profile_details_approval?.username
              ?.new_content
          ) {
            onNavigate(routeConst.missingDetails.path);
          }
        }
      }
    } else if (getProfileError) {
      if (getProfileErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | User AIO API Response
  useEffect(() => {
    if (getUserAioFetching || getUserAioLoading) {
    } else if (getUserAioSuccess) {
      if (getUserAioData?.status === 1) {
        if (getUserAioData?.data?.modules?.levelling_enabled) {
          // REMEMBER: Remove after TW is available for premium
          if (!i18n.language.toLowerCase().includes("zh-tw")) {
            getLevellingTasks();
          }
        }

        dispatch(
          updateOwnPrivateCallEnable(
            getUserAioData?.data?.private_call_settings
              ?.system_private_calls_enabled &&
              getUserAioData?.data?.private_call_settings?.enable_calls
          )
        );
      } else {
      }
    } else if (getUserAioError) {
      if (getUserAioErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getUserAioFetching,
    getUserAioLoading,
    getUserAioSuccess,
    getUserAioError,
  ]);

  // Lifecycle | Check for update | Private Call Free Minutes API Response
  useEffect(() => {
    if (getPrivateCallFreeMinutesFetching || getPrivateCallFreeMinutesLoading) {
    } else if (getPrivateCallFreeMinutesSuccess) {
    } else if (getPrivateCallFreeMinutesError) {
      if (getPrivateCallFreeMinutesErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallFreeMinutesFetching,
    getPrivateCallFreeMinutesLoading,
    getPrivateCallFreeMinutesSuccess,
    getPrivateCallFreeMinutesError,
  ]);

  // Lifecycle | Check for update | Levelling Tasks API Response
  useEffect(() => {
    if (getLevellingTasksFetching || getLevellingTasksLoading) {
    } else if (getLevellingTasksSuccess) {
      if (getLevellingTasksData?.status === 0) {
        const levellingTasks = getLevellingTasksData?.data?.tasks;

        if (levellingTasks) {
          const dailyCheckInTask = levellingTasks.find(
            (task) => task.task_id === 3
          );

          if (dailyCheckInTask) {
            // Set Daily Check In Timer
            const endDateSeconds = dailyCheckInTask.date_end_epoch;
            const currentTime = moment().unix();
            dispatch(setDailyCheckInTimer(endDateSeconds - currentTime));

            if (!dailyCheckInInterval) {
              dispatch(
                updateDailyCheckInInterval(
                  setInterval(() => {
                    dispatch(updateDailyCheckInTimer());
                  }, 1000)
                )
              );
            }

            // Check if "daily-check-in" in local storage is true or false
            if (
              i18n.language.toLowerCase().includes("zh-tw") === false &&
              !sessionService.getDailyCheckIn() &&
              !dailyCheckInTask.claimed
            ) {
              dispatch(updateDailyCheckInDialog(true));
              sessionService.setDailyCheckIn(true);
            }
          }
        }
      }
    } else if (getLevellingTasksError) {
      if (getLevellingTasksErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLevellingTasksFetching,
    getLevellingTasksLoading,
    getLevellingTasksSuccess,
    getLevellingTasksError,
  ]);

  // Lifecycle | Check for update | Livestreaming Contest List API Response
  useEffect(() => {
    if (
      getLivestreamingContestListFetching ||
      getLivestreamingContestListLoading
    ) {
    } else if (getLivestreamingContestListSuccess) {
      if (getLivestreamingContestListData?.status === 1) {
        for (
          let i = 0;
          i < getLivestreamingContestListData?.data?.results?.length;
          i++
        ) {
          if (
            getLivestreamingContestListData?.data?.results[i]?.contest_type ===
            "single"
          ) {
            dispatch(
              updateLivestreamingContestId(
                getLivestreamingContestListData?.data?.results[0]?.contest_id
              )
            );
          }

          if (
            getLivestreamingContestListData?.data?.results[i]?.contest_type ===
            "pk"
          ) {
            dispatch(
              updateLivestreamingContestPkId(
                getLivestreamingContestListData?.data?.results[i]?.contest_id
              )
            );
          }
        }
      }
    } else if (getLivestreamingContestListError) {
      if (getLivestreamingContestListErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingContestListFetching,
    getLivestreamingContestListLoading,
    getLivestreamingContestListSuccess,
    getLivestreamingContestListError,
  ]);

  // Lifecycle | Check for update | Private Call Contest List API Response
  useEffect(() => {
    if (getPrivateCallContestListFetching || getPrivateCallContestListLoading) {
    } else if (getPrivateCallContestListSuccess) {
      if (getPrivateCallContestListData?.status === 0) {
        if (getPrivateCallContestListData?.data?.results) {
          dispatch(
            updatePrivateCallContestId(
              getPrivateCallContestListData?.data?.results[0]?.contest_id
            )
          );
        }
      }
    } else if (getPrivateCallContestListError) {
      if (getPrivateCallContestListErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallContestListFetching,
    getPrivateCallContestListLoading,
    getPrivateCallContestListSuccess,
    getPrivateCallContestListError,
  ]);

  // Lifecycle | Check for update | Initiate Email Verification API Response
  useEffect(() => {
    if (initiateEmailVerificationLoading) {
    } else if (initiateEmailVerificationSuccess) {
      switch (initiateEmailVerificationData?.status) {
        case 1:
          const createdAt =
            initiateEmailVerificationData?.data?.email_verification?.created_at;
          const expiredAt =
            initiateEmailVerificationData?.data?.email_verification?.expired_at;
          const diffInSeconds = moment(expiredAt).diff(createdAt, "seconds");
          dispatch(setEmailVerificationTimer(60));
          onNavigate(routeConst.verify.emailVerification.altPath);
          break;
        case -1:
          // Need to wait 3 minutes
          const errorToast = {
            message: initiateEmailVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          onNavigate(routeConst.verify.emailVerification.altPath);
        default:
          // Not sure
          break;
      }
    } else if (initiateEmailVerificationError) {
      switch (initiateEmailVerificationErrorData?.data?.status) {
        case -1:
          // There is pending verification request, please request again awhile later
          const errorToast = {
            message: initiateEmailVerificationErrorData?.data?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          onNavigate(routeConst.verify.emailVerification.altPath);
          break;
        default:
          break;
      }
    }
  }, [
    initiateEmailVerificationLoading,
    initiateEmailVerificationSuccess,
    initiateEmailVerificationError,
  ]);

  // Lifecycle | Check for update | Notifications Count API Response
  useEffect(() => {
    if (getNotificationsCountFetching || getNotificationsCountLoading) {
    } else if (getNotificationsCountSuccess) {
      switch (getNotificationsCountData?.status) {
        case 0:
          dispatch(updateNotificationCountLoaded(true));
          dispatch(
            updateNotificationCount(getNotificationsCountData?.data?.unseen)
          );

          setTimeout(() => {
            dispatch(updateShowNotificationBadge());
          }, 1000);
          break;
        default:
          break;
      }
    } else if (getNotificationsCountError) {
    }
  }, [
    getNotificationsCountFetching,
    getNotificationsCountLoading,
    getNotificationsCountSuccess,
    getNotificationsCountError,
  ]);

  // Lifecycle | Check for update | dailyCheckInTimer
  useEffect(() => {
    if (!isLoggedIn || !dailyCheckInTimer) return;

    // After reaching endDate, will clean up Daily Check In variables & recall Levelling Task API
    if (dailyCheckInTimer <= 0) {
      dispatch(clearDailyCheckInInterval());
      dispatch(resetDailyCheckInTimer());
      sessionService.deleteDailyCheckIn(false);
      getLevellingTasks();
    }
  }, [isLoggedIn, dailyCheckInTimer]);

  // Utility Functions
  const generalVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      // When user goes out and in the page, AIO API will be called again to detect VPN
      getAio(null, false);
    }
  };
  const loggedInVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      getUserAio("?mounted=true", false);
      getProfile(null, false);
    }
  };

  return <div id="app-mounted-shadow-component"></div>;
};

export default ApiMounted;
