// General
import "./app-routing.scss";
import { Outlet, useLocation } from "react-router-dom";
// Static Data
import routeConst from "../../const/routeConst";
import utilityConst from "../../const/utilityConst";
// Redux
import { useSelector } from "react-redux";
// Material UI
import { useMediaQuery } from "@mui/material";

const AppRouting = () => {
  // Redux variables
  const sugarbookTheme = useSelector((state) => state.app.sugarbookTheme);

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Router variables
  const location = useLocation();

  // Utility Functions
  const returnBackground = () => {
    if (isMobile) {
      switch (sugarbookTheme) {
        case utilityConst.sugarbookTheme.christmas:
          if (
            location.pathname === routeConst.landing.path ||
            location.pathname.includes(routeConst.login.path)
          ) {
            return "mobile-christmas-background";
          } else if (
            location.pathname.includes(routeConst.accountSignup.path) ||
            location.pathname.includes(routeConst.phoneAuthentication.path) ||
            location.pathname.includes(
              routeConst.phoneAuthentication.altPath
            ) ||
            location.pathname.includes(routeConst.infoSignup.path) ||
            location.pathname.includes(routeConst.missingDetails.path) ||
            location.pathname.includes(routeConst.torSignup.path) ||
            location.pathname.includes(routeConst.descriptionSignup.path) ||
            location.pathname.includes(routeConst.lifestyleSignup.path) ||
            location.pathname.includes(routeConst.detailSignup.path) ||
            location.pathname.includes(routeConst.live.path) ||
            location.pathname.includes(routeConst.profile.overview.path) ||
            location.pathname.includes(
              routeConst.verify.sugarbookVerified.path
            ) ||
            location.pathname.includes(
              routeConst.verify.sugarbookVerified.status.path
            )
          ) {
            return "mobile-white-background-color";
          }

          return "mobile-default-background-color";
        case utilityConst.sugarbookTheme.valentine:
          if (
            location.pathname === routeConst.landing.path ||
            location.pathname.includes(routeConst.login.path)
          ) {
            return "mobile-default-background-color";
          } else if (
            location.pathname.includes(routeConst.accountSignup.path) ||
            location.pathname.includes(routeConst.phoneAuthentication.path) ||
            location.pathname.includes(
              routeConst.phoneAuthentication.altPath
            ) ||
            location.pathname.includes(routeConst.infoSignup.path) ||
            location.pathname.includes(routeConst.missingDetails.path) ||
            location.pathname.includes(routeConst.torSignup.path) ||
            location.pathname.includes(routeConst.descriptionSignup.path) ||
            location.pathname.includes(routeConst.lifestyleSignup.path) ||
            location.pathname.includes(routeConst.detailSignup.path) ||
            location.pathname.includes(routeConst.live.path) ||
            location.pathname.includes(routeConst.profile.overview.path) ||
            location.pathname.includes(
              routeConst.verify.sugarbookVerified.path
            ) ||
            location.pathname.includes(
              routeConst.verify.sugarbookVerified.status.path
            )
          ) {
            return "mobile-white-background-color";
          }

          return "mobile-default-background-color";
        default:
          return "mobile-white-background-color";
      }
    } else {
      if (
        location.pathname.includes(routeConst.accountSignup.path) ||
        location.pathname.includes(routeConst.verify.emailVerification.path) ||
        location.pathname.includes(
          routeConst.verify.emailVerification.altPath
        ) ||
        location.pathname.includes(routeConst.phoneAuthentication.path) ||
        location.pathname.includes(routeConst.phoneAuthentication.altPath) ||
        location.pathname.includes(routeConst.verify.mobileVerification.path) ||
        location.pathname.includes(
          routeConst.verify.mobileVerification.altPath
        ) ||
        location.pathname.includes(routeConst.infoSignup.path) ||
        location.pathname.includes(routeConst.torSignup.path) ||
        location.pathname.includes(routeConst.descriptionSignup.path) ||
        location.pathname.includes(routeConst.detailSignup.path) ||
        location.pathname.includes(routeConst.lifestyleSignup.path)
      ) {
        return "";
      }

      switch (sugarbookTheme) {
        case utilityConst.sugarbookTheme.christmas:
          return "christmas-background";
        case utilityConst.sugarbookTheme.anniversary:
          return "anniversary-background";
        case utilityConst.sugarbookTheme.valentine:
          return "valentine-background";
        case utilityConst.sugarbookTheme.raya:
          return "raya-background";
        default:
          return "desktop-background-color";
      }
    }
  };

  return (
    <div id="body-container" className={returnBackground()}>
      <Outlet />
    </div>
  );
};

export default AppRouting;
