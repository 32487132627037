// General
import "./inbox.scss";
import { useState, useEffect, useRef } from "react";
// Static Data
import routeConst from "../../../const/routeConst";
import utilityConst from "../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateConversationSelectPassthrough } from "../../../redux/store/inboxStore";
// React Swipeable Views
import SwipeableViews from "react-swipeable-views/lib/SwipeableViews";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Tabs, Tab, Box, useTheme, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../utility/manager/asset-manager/asset-manager";
// Components
import Banner from "../../shared/elements/banner/banner";
import MessagesTab from "./messages-tab/messages-tab";
import UnreadTab from "./unread-tab/unread-tab";
import ChatConversation from "../chat-conversation/chat-conversation";

const Inbox = () => {
  // General variables
  const isMounted = useRef(false);

  // Redux variables
  const conversationSelectPassthrough = useSelector(
    (state) => state.inbox.conversationSelectPassthrough
  );
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const dispatch = useDispatch();

  // MUI variables
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery("(max-width: 850px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();

  // Lifecycle | Mounted & Unmounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-8",
      },
    });

    return () => {
      // Reset conversationSelectPassthrough
      dispatch(updateConversationSelectPassthrough({}));
    };
  }, []);

  // Lifecycle | Check for update | conversationSelectPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (!conversationSelectPassthrough) return;
    } else {
      isMounted.current = true;
    }
  }, [conversationSelectPassthrough]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const onTabChangeBySwipe = (index) => {
    setValue(index);
  };

  return (
    <div id="inbox-page" className="inbox-page-container">
      {releaseChannel !== utilityConst.releaseChannel.stable && (
        <div className="desktop-banner-container">
          <Banner type={"inbox"} view={"carousel"} />
        </div>
      )}

      <div className="max-width-container">
        <div className="tab-container">
          <Tabs
            className="custom-tabs"
            value={value}
            onChange={onTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
            variant="fullWidth"
          >
            <Tab label={t("inbox.messages")} {...a11yProps(0)} />
            <Tab label={t("inbox.unread")} {...a11yProps(1)} />
          </Tabs>

          <div className="mobile-banner-container">
            <Banner type={"inbox"} view={"carousel"} />
          </div>

          <SwipeableViews
            className="swipeable-container"
            containerStyle={{
              transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
            }}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={onTabChangeBySwipe}
          >
            <TabPanel
              className="tab-panel"
              value={value}
              index={0}
              dir={theme.direction}
            >
              <MessagesTab />
            </TabPanel>

            <TabPanel
              className="tab-panel"
              value={value}
              index={1}
              dir={theme.direction}
            >
              <UnreadTab />
            </TabPanel>
          </SwipeableViews>
        </div>

        {!isMobile && (
          <div className="conversation-container">
            {conversationSelectPassthrough?.id ? (
              <ChatConversation />
            ) : (
              <img
                className="background"
                src={getAsset("messagingStartBackground")}
                alt="no-conversation"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Inbox;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
          {/* <Typography component={"div"}>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
