// General
import "./sugarbook-verified-camera-dialog.scss";
import { useState, useEffect, useRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateSugarbookVerifiedSelfieImage,
  updateSugarbookVerifiedSelfieInterval,
  setSugarbookVerifiedSelfieTimer,
  updateSugarbookVerifiedSelfieTimer,
  clearSugarbookVerifiedSelfieInterval,
} from "../../../../redux/store/verificationStore";
import { updateSugarbookVerifiedCameraDialog } from "../../../../redux/store/dialogStore";
import { updateWarningToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
// i18next
import { useTranslation } from "react-i18next";

const SugarbookVerifiedCameraDialog = () => {
  // General variables
  const [imageData, setImageData] = useState(null);
  let verifiedVideo = useRef(null);
  let mediaStream = useRef(null);
  const [countdownStart, setCountdownStart] = useState(false);

  // Redux variables
  const sugarbookVerifiedCameraDialog = useSelector(
    (state) => state.dialog.sugarbookVerifiedCameraDialog
  );
  const sugarbookVerifiedSelfieInterval = useSelector(
    (state) => state.verification.sugarbookVerifiedSelfieInterval
  );
  const sugarbookVerifiedSelfieTimer = useSelector(
    (state) => state.verification.sugarbookVerifiedSelfieTimer
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 500px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (sugarbookVerifiedCameraDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-D37-37.1",
        },
      });

      onGetCameraAccess();
    } else {
      cleanUpCamera();
    }
  }, [sugarbookVerifiedCameraDialog]);

  // Lifecycle | Check for update | sugarbookVerifiedSelfieTimer
  useEffect(() => {
    if (sugarbookVerifiedSelfieTimer <= 0) {
      onSaveImageToCanvas();
      setCountdownStart(false);
    }
  }, [sugarbookVerifiedSelfieTimer]);

  // Event Handlers | Button
  const onTakePhoto = () => {
    handleCapture();
  };
  const onRetakePhoto = () => {
    setImageData(null);
    dispatch(updateSugarbookVerifiedSelfieImage(null));
    dispatch(clearSugarbookVerifiedSelfieInterval());
    dispatch(setSugarbookVerifiedSelfieTimer(3));
    // onGetCameraAccess();
  };

  // Event Handlers | Button
  const handleCapture = async () => {
    dispatch(
      updateSugarbookVerifiedSelfieInterval(
        setInterval(() => {
          dispatch(updateSugarbookVerifiedSelfieTimer());
        }, 1000)
      )
    );
    setCountdownStart(true);
  };
  const onFlipImage = () => {
    flipBase64Image(imageData).then((response) => {
      setImageData(response);

      dispatch(updateSugarbookVerifiedSelfieImage(response));
    });
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (
      reason === "backdropClick" ||
      reason === "clickaway" ||
      reason === "escapeKeyDown"
    )
      return;

    dispatch(updateSugarbookVerifiedCameraDialog(false));
  };

  // Utility Functions
  const onSaveImageToCanvas = () => {
    try {
      const canvas = document.createElement("canvas");
      canvas.width = verifiedVideo.current.videoWidth;
      canvas.height = verifiedVideo.current.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(
        verifiedVideo.current,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const capturedImageData = canvas.toDataURL("image/png");
      setImageData(capturedImageData);

      dispatch(updateSugarbookVerifiedSelfieImage(capturedImageData));
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };
  const flipBase64Image = (base64Image) => {
    return new Promise((resolve, reject) => {
      // Create an image
      let img = new Image();
      img.onload = function () {
        // Create a canvas and get its context
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        // Set canvas dimensions to image dimensions
        canvas.width = img.width;
        canvas.height = img.height;

        // Flip the context horizontally
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);

        // Draw the image onto the context
        ctx.drawImage(img, 0, 0, img.width, img.height);

        // Get the base64 representation of the flipped image
        let flippedBase64Image = canvas.toDataURL();

        // Resolve the promise with the flipped image
        resolve(flippedBase64Image);
      };
      img.onerror = function (error) {
        // Reject the promise with the error
        reject(error);
      };
      // Start loading the image
      img.src = base64Image;
    });
  };

  // Camera & Microphone Functions | Native JS Method
  const onGetCameraAccess = () => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "user" } })
        .then((stream) => {
          if (verifiedVideo.current) {
            verifiedVideo.current.srcObject = stream;
            mediaStream.current = stream;

            var playPromise = verifiedVideo.current.play();

            if (playPromise !== undefined) {
              playPromise
                .then((_) => {
                  // Automatic playback started!
                  // Show playing UI.
                })
                .catch((error) => {
                  // Auto-play was prevented
                  // Show paused UI.
                });
            }
          }
        })
        .catch((err) => {
          const toastObj = {
            message: err?.toString(),
            autoClose: 3000,
          };
          dispatch(updateWarningToast(toastObj));
        });
    }
  };
  const cleanUpCamera = () => {
    if (mediaStream.current && mediaStream.current.getTracks) {
      mediaStream.current.getTracks().forEach((track) => track.stop());
      mediaStream.current = null;
    }
  };

  return (
    <Dialog
      className={
        isMobile ? "" : "custom-radius35-dialog custom-dialog-transparent"
      }
      fullScreen={isMobile ? true : false}
      fullWidth={isMobile ? false : true}
      maxWidth="xs"
      open={sugarbookVerifiedCameraDialog}
      onClose={onCloseDialog}
    >
      <div id="sugarbook-verified-camera-dialog">
        <div className="dialog-header">
          <div className="flip-image-button-container">
            {imageData && (
              <FlipCameraAndroidIcon
                className="flip-image-button"
                onClick={onFlipImage}
              />
            )}
          </div>

          <div className="close-button-container">
            {!imageData && (
              <CloseIcon className="close-button" onClick={onCloseDialog} />
            )}
          </div>
        </div>

        <div className="padding-container">
          <div className="aspect-ratio-container">
            <video
              id="sugarbook-verified-video"
              ref={verifiedVideo}
              playsInline
            ></video>

            {imageData && (
              <img className="captured-image" src={imageData} alt="Captured" />
            )}

            {sugarbookVerifiedSelfieTimer > 0 && countdownStart && (
              <div className="countdown-overlay">
                {sugarbookVerifiedSelfieTimer}
              </div>
            )}
          </div>

          <div className="button-container">
            {!imageData && !sugarbookVerifiedSelfieInterval && (
              <div className="outer-circle" onClick={onTakePhoto}>
                <div className="inner-circle"></div>
              </div>
            )}

            {imageData && (
              <div className="use-image-container">
                <div className="use-photo-label" onClick={onCloseDialog}>
                  Use Photo
                </div>
                <div className="retake-label" onClick={onRetakePhoto}>
                  Retake
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SugarbookVerifiedCameraDialog;
