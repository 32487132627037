// General
import "./user-profile-tab.scss";
import { useState } from "react";
// react-gtm-module
import TagManager from "react-gtm-module";
// React Swipeable Views
import SwipeableViews from "react-swipeable-views/lib/SwipeableViews";
// Material UI
import { Tabs, Tab, Box, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import LockIcon from "@mui/icons-material/Lock";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import PhotosTab from "./photos-tab/photos-tab";
import AboutMeTab from "./about-me-tab/about-me-tab";
import AchievementTab from "./achievement-tab/achievement-tab";
import RequestPrivatePhotoDialog from "../../../../shared/dialog-content/request-private-photo-dialog/request-private-photo-dialog";

const UserProfileTab = (props) => {
  const { userProfileType } = props;

  // MUI variables
  const theme = useTheme();
  const [value, setValue] = useState(0);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-30-30.1-Button-${
          newValue === 0
            ? "PhotosTab"
            : newValue === 1
            ? "AboutTab"
            : newValue === 2
            ? "AchievementTab"
            : "ReviewsTab"
        }`,
      },
    });

    setValue(newValue);
  };
  const onTabChangeBySwipe = (index) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-30-30.2-Button-${
          index === 0
            ? "PhotosTab"
            : index === 1
            ? "AboutTab"
            : index === 2
            ? "AchievementTab"
            : "ReviewsTab"
        }`,
      },
    });

    setValue(index);
  };

  return (
    <div id="bottom-user-container-user-profile-tab-subcomponent">
      <Tabs
        className="custom-tabs"
        value={value}
        onChange={onTabChange}
        TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
        variant="fullWidth"
      >
        <Tab
          className="user-profile-tab-label"
          icon={getIcon("profilePhotoIcon", "tab-photo-icon")}
          iconPosition="start"
          label={t("profile.edit_photos")}
          {...a11yProps(0)}
        />
        <Tab
          className="user-profile-tab-label"
          icon={getIcon("profileAboutMeIcon", "tab-about-me-icon")}
          iconPosition="start"
          label={t("settings.about")}
          {...a11yProps(1)}
        />
        <Tab
          className="user-profile-tab-label"
          icon={getIcon("profileAchievementIcon", "tab-achievement-icon")}
          iconPosition="start"
          label={t("profile.achievements")}
          {...a11yProps(2)}
        />
        <Tab
          className="user-profile-tab-label"
          icon={<LockIcon />}
          iconPosition="start"
          label={t("profile.review")}
          disabled
          {...a11yProps(3)}
        />
      </Tabs>

      <SwipeableViews
        className="swipeable-container"
        containerStyle={{
          transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
        }}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={onTabChangeBySwipe}
      >
        <TabPanel
          className="tab-panel"
          value={value}
          index={0}
          dir={theme.direction}
        >
          <PhotosTab userProfileType={userProfileType} />
        </TabPanel>

        <TabPanel
          className="tab-panel"
          value={value}
          index={1}
          dir={theme.direction}
        >
          <AboutMeTab />
        </TabPanel>

        <TabPanel
          className="tab-panel"
          value={value}
          index={2}
          dir={theme.direction}
        >
          <AchievementTab />
        </TabPanel>

        <TabPanel
          className="tab-panel"
          value={value}
          index={3}
          dir={theme.direction}
        >
          <div>Reviews</div>
        </TabPanel>
      </SwipeableViews>

      <RequestPrivatePhotoDialog />
    </div>
  );
};

export default UserProfileTab;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
          {/* <Typography component={"div"}>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
