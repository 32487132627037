// General
import "./livestream-memory-leak-alert.scss";
// Redux
import { useSelector } from "react-redux";
// Material UI
import RefreshIcon from "@mui/icons-material/Refresh";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const LivestreamMemoryLeakAlert = () => {
  // Redux variables
  const isTcPlayerMemoryLeak = useSelector(
    (state) => state.livestreaming.isTcPlayerMemoryLeak
  );

  // Event Handlers | Button
  const onRefreshApp = () => {
    window.location.reload(true);
  };

  if (isTcPlayerMemoryLeak) {
    return (
      <div id="livestream-memory-leak-alert-component">
        <div className="padding-container">
          <div className="refresh-logo-container">
            <div className="refresh-outer-circle">
              <RefreshIcon className="refresh-logo" />
            </div>
          </div>

          <div className="refresh-description-container">
            <div className="update-available-label">Something went wrong</div>
            <div className="click-to-update-container" onClick={onRefreshApp}>
              <div className="click-to-update-label">
                Click to refresh Sugarbook
              </div>
              <div className="next-icon-container">
                <ChevronRightIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default LivestreamMemoryLeakAlert;
