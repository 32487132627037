// General
import "./video-call-tutorial.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../services/data.service";
// Static Data
import tutorialConst from "../../../const/tutorialConst";
// Redux
import { useDispatch } from "react-redux";
import { updateVideoCallTutorialDialog } from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// i18next
import { useTranslation } from "react-i18next";
// Components
import VideoCallTutorialsDialog from "../../shared/dialog-content/video-call-tutorials-dialog/video-call-tutorials-dialog";
import Spinner from "../../shared/elements/spinner/spinner";

const VideoCallTutorial = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // General variables
  const [tutorialList, setTutorialList] = useState([]);
  const [type, setType] = useState("");
  const [pages, setPages] = useState(0);

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-52",
      },
    });

    getUserAio(null, true);
  }, []);

  // Lifecycle | Check for update | User AIO API Response
  useEffect(() => {
    if (getUserAioFetching || getUserAioLoading) {
    } else if (getUserAioSuccess) {
      const filteredArray = tutorialConst.videoCall.filter((obj) => {
        if (getUserAioData?.data?.modules?.live_streaming_enabled) {
          return true;
        } else {
          return obj.value !== "settings";
        }
      });

      setTutorialList(filteredArray);
    } else if (getUserAioError) {
    }
  }, [
    getUserAioFetching,
    getUserAioLoading,
    getUserAioSuccess,
    getUserAioError,
  ]);

  // Event Handlers | MUI Dialog
  const onOpenDialog = (value, page) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-52-52.1-Button",
      },
    });

    setType(value);
    setPages(page);

    dispatch(updateVideoCallTutorialDialog(true));
  };

  if (getUserAioFetching || getUserAioLoading) {
    return <Spinner />;
  } else if (getUserAioSuccess) {
    return (
      <div id="video-call-tutorial-page">
        <div className="max-width-container">
          <div className="padding-container">
            <div className="video-call-label">{t("1on1.video_call")}</div>

            <div className="buttons-list-container">
              {tutorialList.map((item, index) => (
                <div
                  className="button-container"
                  onClick={() => onOpenDialog(item.value, item.pages)}
                  key={index}
                >
                  <div className="button-label">{t(item.title)}</div>
                  <div className="suffix">
                    <NavigateNextIcon />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <VideoCallTutorialsDialog type={type} pages={pages} />
      </div>
    );
  } else if (getUserAioError) {
  }
};

export default VideoCallTutorial;
