// General
import "./edit.scss";
import { useEffect, useRef } from "react";
// Services
import {
  spiApi,
  useLazyGetProfileQuery,
  useUpdateEditUserProfileMutation,
  useUpdateUserLocationMutation,
} from "../../../services/data.service";
// Static Data
import userConst from "../../../const/userConst";
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateIsSilentInteraction,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateTorTags,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updateRole,
} from "../../../redux/store/userCardStore";
import { updateEditProfileTab } from "../../../redux/store/userStore";
import {
  updateEmail,
  updateProfilePhoto as updateEditProfilePhoto,
  updatePublicPhotos as updateEditPublicPhotos,
  updatePrivatePhotos as updateEditPrivatePhotos,
  updateUsername as updateEditUsername,
  updateAge as updateEditAge,
  updateBirthdate as updateEditBirthdate,
  updateAboutMe as updateEditAboutMe,
  updateLookingFor as updateEditLookingFor,
  updateTagline as updateEditTagline,
  updateAnnualIncome as updateEditAnnualIncome,
  updateNetworth as updateEditNetworth,
  updateOccupation as updateEditOccupation,
  updateLifestyle as updateEditLifestyle,
  updateEducation as updateEditEducation,
  updateRelationshipStatus as updateEditRelationshipStatus,
  updateSmoking as updateEditSmoking,
  updateDrinking as updateEditDrinking,
  updateHeight as updateEditHeight,
  updateBodyType as updateEditBodyType,
  updateEthnicity as updateEditEthnicity,
  updateRace as updateEditRace,
  updateEyeColor as updateEditEyeColor,
  updateHairColor as updateEditHairColor,
  updateChildren as updateEditChildren,

  // Status Functions
  updateUsernameStatus,
  updateUsernameNewContent,
  updateAboutMeStatus,
  updateAboutMeNewContent,
  updateLookingForStatus,
  updateLookingForNewContent,

  // Location Functions
  updateLocation as updateEditLocation,
} from "../../../redux/store/editProfileStore";
import {
  resetDatingSearchLists,
  resetFilteredLists,
  resetRecentlyActiveLists,
  resetNearbyLists,
  resetNewestLists,
  resetCollegeLists,
  resetPremiumLists,

  // Pagination
  updateFilteredPagination,
  updateRecentlyActivePagination,
  updateNearbyPagination,
  updateNewestPagination,
  updateCollegePagination,
  updatePremiumPagination,
} from "../../../redux/store/searchStore";
import {
  updateUserProfileDialog,
  updateGeneralLoadingDialog,
} from "../../../redux/store/dialogStore";
import {
  updateSuccessToast,
  updateErrorToast,
} from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// React Swipeable Views
import SwipeableViews from "react-swipeable-views/lib/SwipeableViews";
// Material UI
import {
  Tabs,
  Tab,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import UtilityDesktopNavbar from "../../shared/elements/utility-desktop-navbar/utility-desktop-navbar";
import CustomAvatar from "../../shared/elements/custom-avatar/custom-avatar";
import EditProfile from "./edit-profile/edit-profile";
import Photos from "./photos/photos";
import Spinner from "../../shared/elements/spinner/spinner";
import DesktopEditProfile from "./desktop-edit-profile/desktop-edit-profile";
import ModerationStatus from "./moderation-status/moderation-status";
import VerifiedProfileBadge from "../../shared/elements/verified-profile-badge/verified-profile-badge";
import ProfileRejectedInfoDialog from "../../shared/dialog-content/profile-rejected-info-dialog/profile-rejected-info-dialog";

const ProfileEdit = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getProfileReference,
    {
      data: getProfileReferenceData,
      error: getProfileReferenceErrorData,
      isFetching: getProfileReferenceFetching,
      isLoading: getProfileReferenceLoading,
      isSuccess: getProfileReferenceSuccess,
      isError: getProfileReferenceError,
    },
  ] = useLazyGetProfileQuery();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateEditUserProfileMutation();
  const [
    updateUserLocation,
    {
      data: updateUserLocationData,
      error: updateUserLocationErrorData,
      isLoading: updateUserLocationLoading,
      isSuccess: updateUserLocationSuccess,
      isError: updateUserLocationError,
    },
  ] = useUpdateUserLocationMutation();

  // General variables
  const isMounted = useRef(false);

  // Redux variables
  const profilePhoto = useSelector((state) => state.editProfile.profilePhoto);
  const username = useSelector((state) => state.editProfile.username);
  const usernameNewContent = useSelector(
    (state) => state.editProfile.usernameNewContent
  );
  const usernameStatus = useSelector(
    (state) => state.editProfile.usernameStatus
  );
  const age = useSelector((state) => state.editProfile.age);
  const birthdate = useSelector((state) => state.editProfile.birthdate);
  const location = useSelector((state) => state.editProfile.location);
  const aboutMe = useSelector((state) => state.editProfile.aboutMe);
  const aboutMeNewContent = useSelector(
    (state) => state.editProfile.aboutMeNewContent
  );
  const aboutMeStatus = useSelector((state) => state.editProfile.aboutMeStatus);
  const lookingFor = useSelector((state) => state.editProfile.lookingFor);
  const lookingForNewContent = useSelector(
    (state) => state.editProfile.lookingForNewContent
  );
  const lookingForStatus = useSelector(
    (state) => state.editProfile.lookingForStatus
  );
  const tagline = useSelector((state) => state.editProfile.tagline);
  const annualIncome = useSelector((state) => state.editProfile.annualIncome);
  const networth = useSelector((state) => state.editProfile.networth);
  const occupation = useSelector((state) => state.editProfile.occupation);
  const lifestyle = useSelector((state) => state.editProfile.lifestyle);
  const education = useSelector((state) => state.editProfile.education);
  const relationshipStatus = useSelector(
    (state) => state.editProfile.relationshipStatus
  );
  const smoking = useSelector((state) => state.editProfile.smoking);
  const drinking = useSelector((state) => state.editProfile.drinking);
  const height = useSelector((state) => state.editProfile.height);
  const bodyType = useSelector((state) => state.editProfile.bodyType);
  const ethnicity = useSelector((state) => state.editProfile.ethnicity);
  const race = useSelector((state) => state.editProfile.race);
  const eyeColor = useSelector((state) => state.editProfile.eyeColor);
  const hairColor = useSelector((state) => state.editProfile.hairColor);
  const children = useSelector((state) => state.editProfile.children);
  const latitude = useSelector((state) => state.user.latitude);
  const longitude = useSelector((state) => state.user.longitude);
  const editLocation = useSelector((state) => state.editProfile.location);
  const editProfilePassthrough = useSelector(
    (state) => state.user.editProfilePassthrough
  );
  const editProfileTab = useSelector((state) => state.user.editProfileTab);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // MUI variables
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-25-Page",
      },
    });

    getProfile(null, true);
    getProfileReference("editProfile", false);
  }, []);

  // Lifecycle | Check for update | Get Profile Reference API Response
  useEffect(() => {
    if (getProfileReferenceFetching || getProfileReferenceLoading) {
    } else if (getProfileReferenceSuccess) {
      if (getProfileReferenceData?.status === 0) {
        dispatch(updateEmail(getProfileData?.data?.email));
        dispatch(
          updateEditProfilePhoto(
            getProfileReferenceData?.data?.profile_photo?.photo_status ===
              "APPROVED"
              ? getProfileReferenceData?.data?.profile_photo?.original_photo
              : null
          )
        );
        dispatch(
          updateEditPublicPhotos(getProfileReferenceData?.data?.public_photos)
        );
        dispatch(
          updateEditPrivatePhotos(getProfileReferenceData?.data?.private_photos)
        );
        dispatch(updateEditUsername(getProfileReferenceData?.data?.username));
        dispatch(updateEditAge(getProfileReferenceData?.data?.appearance?.age));
        dispatch(
          updateEditBirthdate(
            getProfileReferenceData?.data?.appearance?.birth_date
          )
        );
        dispatch(updateEditAboutMe(getProfileReferenceData?.data?.aboutme));
        dispatch(
          updateEditLookingFor(getProfileReferenceData?.data?.lookingfor)
        );
        dispatch(updateEditTagline(getProfileReferenceData?.data?.tagline));
        dispatch(
          updateEditAnnualIncome(
            addCommasToNumberString(
              getProfileReferenceData?.data?.occupation?.annual_income
            )
          )
        );
        dispatch(
          updateEditNetworth(
            addCommasToNumberString(
              getProfileReferenceData?.data?.occupation?.net_worth
            )
          )
        );
        dispatch(
          updateEditOccupation(
            getProfileReferenceData?.data?.occupation?.occupation
          )
        );
        dispatch(
          updateEditLifestyle(
            getProfileReferenceData?.data?.occupation?.lifestyle_budget
          )
        );
        dispatch(
          updateEditEducation(
            getProfileReferenceData?.data?.occupation?.education
          )
        );
        dispatch(
          updateEditRelationshipStatus(
            getProfileReferenceData?.data?.relationship?.relationship_status
          )
        );
        dispatch(
          updateEditSmoking(getProfileReferenceData?.data?.habit?.smoking)
        );
        dispatch(
          updateEditDrinking(getProfileReferenceData?.data?.habit?.drinking)
        );
        dispatch(
          updateEditHeight(
            getProfileReferenceData?.data?.appearance?.height?.toString()
          )
        );
        dispatch(
          updateEditBodyType(
            getProfileReferenceData?.data?.appearance?.body_type
          )
        );
        dispatch(
          updateEditEthnicity(
            getProfileReferenceData?.data?.appearance?.ethnicity
          )
        );
        dispatch(
          updateEditRace(getProfileReferenceData?.data?.appearance?.race)
        );
        dispatch(
          updateEditEyeColor(
            getProfileReferenceData?.data?.appearance?.eye_color
          )
        );
        dispatch(
          updateEditHairColor(
            getProfileReferenceData?.data?.appearance?.hair_color
          )
        );
        dispatch(
          updateEditChildren(
            getProfileReferenceData?.data?.relationship?.children
          )
        );

        // Status Functions
        dispatch(
          updateUsernameStatus(
            getApprovalStatus(
              getProfileReferenceData?.data?.profile_details_approval?.username
                ?.approval,
              getProfileReferenceData?.data?.profile_details_approval?.username
                ?.new_content,
              getProfileReferenceData?.data?.profile_details_approval?.username
                ?.new_content_rejected,
              username
            )
          )
        );
        dispatch(
          updateUsernameNewContent(
            getProfileReferenceData?.data?.profile_details_approval?.username
              ?.new_content
          )
        );
        dispatch(
          updateAboutMeStatus(
            getApprovalStatus(
              getProfileReferenceData?.data?.profile_details_approval?.aboutme
                ?.approval,
              getProfileReferenceData?.data?.profile_details_approval?.aboutme
                ?.new_content,
              getProfileReferenceData?.data?.profile_details_approval?.aboutme
                ?.new_content_rejected,
              aboutMe
            )
          )
        );
        dispatch(
          updateAboutMeNewContent(
            getProfileReferenceData?.data?.profile_details_approval?.aboutme
              ?.new_content
          )
        );
        dispatch(
          updateLookingForStatus(
            getApprovalStatus(
              getProfileReferenceData?.data?.profile_details_approval
                ?.lookingfor?.approval,
              getProfileReferenceData?.data?.profile_details_approval
                ?.lookingfor?.new_content,
              getProfileReferenceData?.data?.profile_details_approval
                ?.lookingfor?.new_content_rejected,
              lookingFor
            )
          )
        );
        dispatch(
          updateLookingForNewContent(
            getProfileReferenceData?.data?.profile_details_approval?.lookingfor
              ?.new_content
          )
        );

        const locationObj = {
          lat: getProfileReferenceData?.data?.location?.latitude,
          lng: getProfileReferenceData?.data?.location?.longitude,
          location_data: {
            city: getProfileReferenceData?.data?.location?.city,
            state: getProfileReferenceData?.data?.location?.state,
            formatted_address:
              getProfileReferenceData?.data?.location?.full_address || "",
            country: getProfileReferenceData?.data?.location?.country,
          },
        };

        dispatch(updateEditLocation(locationObj));
      }
    } else if (getProfileReferenceError) {
    }
  }, [
    getProfileReferenceFetching,
    getProfileReferenceLoading,
    getProfileReferenceSuccess,
    getProfileReferenceError,
  ]);

  // Lifecycle | Check for update | Update User Profile API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
      dispatch(updateGeneralLoadingDialog(true));
    } else if (updateUserProfileSuccess) {
      dispatch(updateGeneralLoadingDialog(false));

      // Backend returning empty if success
      const toastObj = {
        message: t("signup.profile_updated"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));

      // Reset Search Page
      dispatch(resetDatingSearchLists());
      dispatch(resetFilteredLists());
      dispatch(resetRecentlyActiveLists());
      dispatch(resetNearbyLists());
      dispatch(resetNewestLists());
      dispatch(resetCollegeLists());
      dispatch(resetPremiumLists());
      dispatch(updateFilteredPagination(null));
      dispatch(updateRecentlyActivePagination(null));
      dispatch(updateNearbyPagination(null));
      dispatch(updateNewestPagination(null));
      dispatch(updateCollegePagination(null));
      dispatch(updatePremiumPagination(null));

      // Recall GET User Profile API
      dispatch({
        type: `${spiApi.reducerPath}/invalidateTags`,
        payload: ["OwnProfile"],
      });

      onNavigate(routeConst.profile.overview.path);
    } else if (updateUserProfileError) {
      dispatch(updateGeneralLoadingDialog(false));

      const errorToastObj = {
        message: updateUserProfileErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(errorToastObj));
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Lifecycle | Check for update | editProfilePassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (isDetailValid()) {
        let profileObj = {
          birth_date: moment(birthdate).format("YYYY-MM-DD"),
          // tagline,
          annual_income: annualIncome?.replace(/,/g, ""),
          net_worth: networth?.replace(/,/g, ""),
          occupation,
          lifestyle_budget: lifestyle,
          education,
          relationship: relationshipStatus,
          smoking,
          drinking,
          height,
          body_type: bodyType,
          ethnicity,
          eye_color: eyeColor,
          hair_color: hairColor,
          children,
        };

        if (race) {
          profileObj.race = race;
        }

        // Username
        if (
          usernameStatus === userConst.profileGeneralApprovalStatus.inReview ||
          usernameStatus === userConst.profileGeneralApprovalStatus.rejected
        ) {
          if (
            getProfileData?.data?.profile_details_approval?.username
              ?.new_content !== usernameNewContent
          ) {
            profileObj.username = usernameNewContent;
          }
        } else {
          if (getProfileData?.data?.username !== username) {
            profileObj.username = username;
          }
        }

        // About Me
        if (
          aboutMeStatus === userConst.profileGeneralApprovalStatus.inReview ||
          aboutMeStatus === userConst.profileGeneralApprovalStatus.rejected
        ) {
          if (
            getProfileData?.data?.profile_details_approval?.aboutme
              ?.new_content !== aboutMeNewContent
          ) {
            profileObj.aboutme = aboutMeNewContent;
          }
        } else {
          if (getProfileData?.data?.aboutme !== aboutMe) {
            profileObj.aboutme = aboutMe;
          }
        }

        // Looking For
        if (
          lookingForStatus ===
            userConst.profileGeneralApprovalStatus.inReview ||
          lookingForStatus === userConst.profileGeneralApprovalStatus.rejected
        ) {
          if (
            getProfileData?.data?.profile_details_approval?.lookingfor
              ?.new_content !== lookingForNewContent
          ) {
            profileObj.lookingfordetails = lookingForNewContent;
          }
        } else {
          if (getProfileData?.data?.lookingfor !== lookingFor) {
            profileObj.lookingfordetails = lookingFor;
          }
        }

        // Update User Profile API
        updateUserProfile(profileObj);

        // Update Location API
        updateUserLocation(location);
      }
    } else {
      isMounted.current = true;
    }
  }, [editProfilePassthrough]);

  // Event Handlers | Button
  const onViewOwnProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-25-25.1-Button",
      },
    });

    // User View
    dispatch(updateId(getProfileData?.data?.id));
    dispatch(updateIdInt(getProfileData?.data?.id_int));
    dispatch(updateUsername(usernameNewContent || username));
    dispatch(updateAge(age));
    dispatch(updateLocation(getProfileData?.data?.location?.city));
    dispatch(updateMembershipType(getProfileData?.data?.membership_type));
    dispatch(updateVerifiedProfile(getProfileData?.data?.is_verified_profile));
    dispatch(updateVerifiedCaller(getProfileData?.data?.verified_caller));
    dispatch(
      updateLevellingBadge(getProfileData?.data?.levelling_profile?.badge)
    );
    dispatch(
      updateLivestreamingAchievementBadge(
        getProfileData?.data?.live_streaming_achievements?.chat_badges_set
      )
    );
    dispatch(
      updateProfilePhoto(
        getProfileData?.data?.profile_photo?.photo_status === "APPROVED"
          ? getProfileData?.data?.profile_photo?.original_photo
          : null
      )
    );
    dispatch(
      updatePublicPhotos(
        getProfileData?.data?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            status: photo?.photo_status,
          };
        })
      )
    );
    dispatch(
      updatePrivatePhotos(
        getProfileData?.data?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            status: photo?.photo_status,
          };
        })
      )
    );
    dispatch(updateIsSilentInteraction(true));
    dispatch(updatePrivatePhotoRequested(true));
    dispatch(updatePrivatePhotoApproval(true));
    dispatch(updateTorTags(getProfileData?.data?.tor_tags));
    dispatch(updateAboutMe(aboutMeNewContent || aboutMe));
    dispatch(updateLookingFor(lookingForNewContent || lookingFor));
    dispatch(updateOccupation(occupation));
    dispatch(updateLifestyle(lifestyle));
    dispatch(updateEducation(education));
    dispatch(updateRelationship(relationshipStatus));
    dispatch(updateSmoking(smoking));
    dispatch(updateDrinking(drinking));
    dispatch(updateHeight(height));
    dispatch(updateBodyType(bodyType));
    dispatch(updateEthnicity(ethnicity));
    dispatch(updateEyeColor(eyeColor));
    dispatch(updateHairColor(hairColor));
    dispatch(updateChildren(children));

    // User Utility
    dispatch(updateRole(getProfileData?.data?.role));

    dispatch(updateUserProfileDialog(true));
  };

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-25-25.2-Button-${
          newValue === 0 ? "EditProfileTab" : "PhotosTab"
        }`,
      },
    });

    dispatch(updateEditProfileTab(newValue));
  };
  const onTabChangeBySwipe = (index) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-25-25.3-Button-${
          index === 0 ? "EditProfileTab" : "PhotosTab"
        }`,
      },
    });

    dispatch(updateEditProfileTab(index));
  };

  // Utility Functions
  const isDetailValid = () => {
    // Comment out latitude & longiture, because API does not return these value
    const aboutMeValid =
      aboutMeStatus === userConst.profileGeneralApprovalStatus.inReview ||
      aboutMeStatus === userConst.profileGeneralApprovalStatus.rejected
        ? aboutMeNewContent?.length >= 25 && aboutMeNewContent?.length <= 500
        : aboutMe?.length >= 25 && aboutMe?.length <= 500;
    const lookingForValid =
      lookingForStatus === userConst.profileGeneralApprovalStatus.inReview ||
      lookingForStatus === userConst.profileGeneralApprovalStatus.rejected
        ? lookingForNewContent?.length >= 25 &&
          lookingForNewContent?.length <= 500
        : lookingFor?.length >= 25 && lookingFor?.length <= 500;

    const isRequiredFieldsValid =
      (username || usernameNewContent) &&
      birthdate &&
      birthdate !== "" &&
      lifestyle &&
      height &&
      bodyType &&
      ethnicity &&
      eyeColor &&
      hairColor &&
      occupation &&
      education &&
      relationshipStatus &&
      children &&
      smoking &&
      drinking &&
      aboutMeValid &&
      lookingForValid;

    if (!isRequiredFieldsValid) {
      const toastObj = {
        message: t("profile.fill_in_all_fields"),
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));
      return false;
    }

    // Removed due to clashing with different country
    // if (
    //   !i18n.language.toLowerCase().includes("zh-tw") &&
    //   ethnicity === "asian" &&
    //   !race
    // ) {
    //   const toastObj = {
    //     message: t("profile.fill_in_all_fields"),
    //     autoClose: 3000,
    //   };
    //   dispatch(updateErrorToast(toastObj));
    //   return false;
    // }

    if (
      isDaddy &&
      (!annualIncome || annualIncome === "" || !networth || networth === "")
    ) {
      const toastObj = {
        message: t("profile.fill_in_all_fields"),
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));
      return false;
    }

    return true;
  };
  const addCommasToNumberString = (numberString) => {
    return numberString?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const getApprovalStatus = (approval, newContent, newContentRejected) => {
    if (approval === true && newContent === null) {
      return userConst.profileGeneralApprovalStatus.approved;
    } else if (
      approval === false &&
      newContent !== null &&
      newContentRejected === true
    ) {
      return userConst.profileGeneralApprovalStatus.rejected;
    } else {
      return userConst.profileGeneralApprovalStatus.inReview;
    }
  };

  if (isMobile) {
    return (
      <div id="profile-edit-page">
        <div className="max-width-container">
          <UtilityDesktopNavbar
            leftButton={"back"}
            rightButton={"save-edit-profile"}
          />

          {/* In Review */}
          <ModerationStatus
            status={userConst.profileGeneralApprovalStatus.inReview}
          />

          {/* Rejected */}
          <ModerationStatus
            status={userConst.profileGeneralApprovalStatus.rejected}
          />

          <div className="top-container">
            <CustomAvatar
              className="profile-photo"
              src={profilePhoto}
              role={getProfileData?.data?.role}
            />

            <div className="username-container">
              <div className="name">{username}</div>
              {(getProfileData?.data?.membership_type ===
                userConst.membershipType.premium ||
                getProfileData?.data?.membership_type ===
                  userConst.membershipType.freePremium) &&
                getIcon("premiumMiniBadge", "premium-mini-badge")}

              <VerifiedProfileBadge
                verifiedProfile={getProfileData?.data?.is_verified_profile}
                className={"verified-profile-badge"}
              />
            </div>

            <div className="preview-button" onClick={onViewOwnProfile}>
              {t("profile.preview")}
            </div>
          </div>

          <div className="tab-container">
            <Tabs
              className="custom-tabs add-underline"
              value={editProfileTab}
              onChange={onTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
              variant="fullWidth"
            >
              <Tab
                className="tab-upper-case"
                label={t("profile.edit_profile")}
                {...a11yProps(0)}
              />
              <Tab
                className="tab-upper-case"
                label={t("profile.edit_photos")}
                {...a11yProps(1)}
              />
            </Tabs>

            <SwipeableViews
              className="swipeable-container"
              containerStyle={{
                transition:
                  "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
              }}
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={editProfileTab}
              onChangeIndex={onTabChangeBySwipe}
            >
              <TabPanel
                className="tab-panel"
                value={editProfileTab}
                index={0}
                dir={theme.direction}
              >
                <EditProfile />
              </TabPanel>

              <TabPanel
                className="tab-panel"
                value={editProfileTab}
                index={1}
                dir={theme.direction}
                style={{ overflow: "hidden" }}
              >
                <Photos />
              </TabPanel>
            </SwipeableViews>
          </div>
        </div>

        <ProfileRejectedInfoDialog />
      </div>
    );
  } else {
    return <DesktopEditProfile />;
  }
};

export default ProfileEdit;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
          {/* <Typography component={"div"}>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
