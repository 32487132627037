// General
import "./streamer-stats.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetStreamerStatsMonthlyQuery } from "../../../../../../services/data.service";
// Material UI
import { Select, MenuItem, Divider } from "@mui/material";
// Moment
import moment from "moment";
// Components
import Spinner from "../../../../../shared/elements/spinner/spinner";

const StreamerStats = () => {
  // API variables
  const [
    getStreamerStatsMonthly,
    {
      data: streamerStatsMonthlyData,
      error: streamerStatsMonthlyErrorData,
      isFetching: streamerStatsMonthlyFetching,
      isLoading: streamerStatsMonthlyLoading,
      isSuccess: streamerStatsMonthlySuccess,
      isError: streamerStatsMonthlyError,
    },
  ] = useLazyGetStreamerStatsMonthlyQuery();

  // General variables
  const [month, setMonth] = useState(
    JSON.stringify({
      month: moment().format("M"),
      year: moment().format("YYYY"),
    })
  );
  const [monthList, setMonthList] = useState([]);

  // Lifecycle | Mounted
  useEffect(() => {
    const obj = {
      month: parseInt(moment().format("M")),
      year: parseInt(moment().format("YYYY")),
    };
    getStreamerStatsMonthly(obj, false);
    setMonthList(getMonths());
  }, []);

  // Event Handlers | MUI Select
  const onMonthChange = (event) => {
    const value = JSON.parse(event.target.value);
    setMonth(
      JSON.stringify({
        month: value.month,
        year: value.year,
      })
    );

    const obj = {
      month: parseInt(value.month),
      year: parseInt(value.year),
    };
    getStreamerStatsMonthly(obj, false);
  };

  // Utility Functions
  const getMonths = () => {
    const months = [];
    for (let i = 0; i < 4; i++) {
      months.push({
        value: {
          month: moment().subtract(i, "months").format("M"),
          year: moment().subtract(i, "months").format("YYYY"),
        },
        display: moment().subtract(i, "months").format("MMMM YYYY"),
      });
    }
    return months;
  };
  const getLivestreamingHours = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (hours <= 0) {
      return `${minutes} Mins`;
    } else {
      return `${hours} Hours ${minutes} Mins`;
    }
  };

  return (
    <div id="more-info-tab-streamer-stats-subcomponent">
      <div className="padding-container">
        <div className="livestreaming-data-header">
          <div className="livestreaming-data-label">Livestreaming Data</div>

          <div className="livestreaming-data-description">
            Your livestreaming monthly summary
          </div>
        </div>

        <div className="livestreaming-data-container">
          <div className="livestreaming-month-container">
            <div className="livestreaming-month-label">Month :</div>
            <div className="livestreaming-month-value">
              <Select
                className="livestreaming-month-value-select-form"
                value={month}
                onChange={onMonthChange}
                variant="standard"
                disableUnderline={true}
              >
                {monthList?.map((month, index) => {
                  return (
                    <MenuItem value={JSON.stringify(month?.value)} key={index}>
                      {month?.display}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>

          <Divider />

          <div className="livestreaming-total-hours-container">
            <div className="livestreaming-total-hours-label">Total Hours :</div>
            <div className="livestreaming-total-hours-value">
              {streamerStatsMonthlyFetching ? (
                <Spinner size={20} isPadding={false} />
              ) : (
                getLivestreamingHours(streamerStatsMonthlyData?.data?.seconds)
              )}
            </div>
          </div>

          <Divider />

          <div className="livestreaming-total-days-container">
            <div className="livestreaming-total-days-label">Total Days :</div>
            <div className="livestreaming-total-days-value">
              {streamerStatsMonthlyFetching ? (
                <Spinner size={20} isPadding={false} />
              ) : (
                streamerStatsMonthlyData?.data?.days
              )}
            </div>
          </div>

          <Divider />

          <div className="livestreaming-total-diamonds-container">
            <div className="livestreaming-total-diamonds-label">
              Total Diamonds :
            </div>
            <div className="livestreaming-total-diamonds-value">
              {streamerStatsMonthlyFetching ? (
                <Spinner size={20} isPadding={false} />
              ) : (
                streamerStatsMonthlyData?.data?.diamonds?.toLocaleString()
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamerStats;
