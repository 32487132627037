// General
import "./about-me-info.scss";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";

const AboutMeInfo = () => {
  // Redux variables
  const aboutMe = useSelector((state) => state.userCard.aboutMe);

  // i18next variables
  const { t } = useTranslation();

  if (aboutMe) {
    return (
      <div id="profile-view-about-me-info-subcomponent">
        <div className="padding-container">
          <div className="label">{t("profile.about_me")}</div>

          <div className="text">{aboutMe || "-"}</div>
        </div>
      </div>
    );
  }
};

export default AboutMeInfo;
