// General
import "./request-private-photo-dialog.scss";
import { useEffect } from "react";
// Services
import { usePhotoPermissionRequestMutation } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePrivatePhotoRequested } from "../../../../redux/store/userCardStore";
import { updateSuccessToast } from "../../../../redux/store/toastStore";
import { updateRequestPrivatePhotoDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../elements/spinner/spinner";

const RequestPrivatePhotoDialog = () => {
  // API variables
  const [
    photoPermissionRequest,
    {
      data: photoPermissionRequestData,
      error: photoPermissionRequestErrorData,
      isLoading: photoPermissionRequestLoading,
      isSuccess: photoPermissionRequestSuccess,
      isError: photoPermissionRequestError,
    },
  ] = usePhotoPermissionRequestMutation();

  // Redux variables
  const requestPrivatePhotoDialog = useSelector(
    (state) => state.dialog.requestPrivatePhotoDialog
  );
  const id = useSelector((state) => state.userCard.id);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!requestPrivatePhotoDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-RequestPrivatePhoto-Dialog",
      },
    });
  }, [requestPrivatePhotoDialog]);

  // Lifecycle | Check for update | Photo Permission Request API Response
  useEffect(() => {
    if (photoPermissionRequestLoading) {
    } else if (photoPermissionRequestSuccess) {
      switch (photoPermissionRequestData?.status) {
        case 1:
          const toastObj = {
            message: photoPermissionRequestData?.message,
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(toastObj));

          // Update Local State
          dispatch(updatePrivatePhotoRequested(true));

          onCloseDialog();
          break;
        default:
          break;
      }
    } else if (photoPermissionRequestError) {
    }
  }, [
    photoPermissionRequestLoading,
    photoPermissionRequestSuccess,
    photoPermissionRequestError,
  ]);

  // Event Handlers | Button
  const onRequestPrivatePhoto = () => {
    const obj = {
      user_id: id,
    };
    photoPermissionRequest(obj);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateRequestPrivatePhotoDialog(false));
  };

  return (
    <Dialog
      className="custom-radius25-dialog"
      open={requestPrivatePhotoDialog}
      onClose={onCloseDialog}
    >
      <div id="request-private-photo-dialog">
        <div className="padding-container">
          <div className="title">
            {t("profile.request_private_photos_to_view_title")}
          </div>

          <div className="description">
            {t("profile.request_private_photos_to_view_desc")}
          </div>

          <div className="button-container">
            <div className="request-button" onClick={onRequestPrivatePhoto}>
              {photoPermissionRequestLoading ? (
                <Spinner size={19} isPadding={false} color="white-spinner" />
              ) : (
                "Request Now"
              )}
            </div>

            <div className="cancel-button" onClick={onCloseDialog}>
              {t("common.cancel")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default RequestPrivatePhotoDialog;
