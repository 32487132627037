// General
import "./appearance.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetAioQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Static Data
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateRace,
  updateEyeColor,
  updateHairColor,
} from "../../../../../redux/store/editProfileStore";
import { updateSuccessToast } from "../../../../../redux/store/toastStore";
// Material UI
import { Divider, MenuItem, FormControl, Select } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import ModerationStatus from "../../moderation-status/moderation-status";

const Appearance = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // Redux variables
  const height = useSelector((state) => state.editProfile.height);
  const bodyType = useSelector((state) => state.editProfile.bodyType);
  const ethnicity = useSelector((state) => state.editProfile.ethnicity);
  const race = useSelector((state) => state.editProfile.race);
  const eyeColor = useSelector((state) => state.editProfile.eyeColor);
  const hairColor = useSelector((state) => state.editProfile.hairColor);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getAio(null, true);
  }, []);

  // Lifecycle | Check for update | Update User Profile API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      // Backend returning empty if success
      const toastObj = {
        message: t("signup.profile_updated"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));
    } else if (updateUserProfileError) {
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    if (!isAppearanceValid()) return;

    const obj = {
      height,
      body_type: bodyType,
      ethnicity,
      eye_color: eyeColor,
      hair_color: hairColor,
    };
    updateUserProfile(obj);
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "height":
        dispatch(updateHeight(event.target.value));
        break;
      case "body-type":
        dispatch(updateBodyType(event.target.value));
        break;
      case "ethnicity":
        dispatch(updateEthnicity(event.target.value));
        break;
      case "race":
        dispatch(updateRace(event.target.value));
        break;
      case "eye-color":
        dispatch(updateEyeColor(event.target.value));
        break;
      case "hair-color":
        dispatch(updateHairColor(event.target.value));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isAppearanceValid = () => {
    if (height && bodyType && ethnicity && eyeColor && hairColor) {
      // Removed due to clashing with different country
      // if (ethnicity === "asian" && !race) {
      //   return false;
      // }
      return true;
    } else {
      return false;
    }
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-appearance-subcomponent">
        <div className="appearance-label-container">
          <div className="appearance-label">{t("profile.appearance")}</div>

          <div className="expand-icon-container">
            {getIcon("chevronRightIcon", "expand-icon")}
          </div>
        </div>

        <div className="form-field-padding-container">
          {/* Height */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${height ? "" : "incomplete-field"}`}>
                {t("profile.height")}
              </div>
            </div>

            <div className="form-container">
              <FormControl className="form-field" variant="standard" fullWidth>
                <Select
                  className="form-select"
                  displayEmpty
                  value={height || ""}
                  onChange={onInputChange("height")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">+ Add height</div>
                      );
                    }

                    const selectedHeight =
                      getAioData?.payload?.dropdown?.height?.find(
                        (item) => item.name === selected?.toString()
                      );

                    return (
                      selectedHeight?.name || (
                        <div className="default-select-value">+ Add height</div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add height
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.height?.map(
                    (height, index) => (
                      <MenuItem key={index} value={height?.value}>
                        {t(`${height?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Body Type */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${bodyType ? "" : "incomplete-field"}`}>
                {t("profile.body_type")}
              </div>
            </div>

            <div className="form-container">
              <FormControl className="form-field" variant="standard" fullWidth>
                <Select
                  className="form-select"
                  displayEmpty
                  value={bodyType || ""}
                  onChange={onInputChange("body-type")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add body type
                        </div>
                      );
                    }

                    const selectedBodyType =
                      getAioData?.payload?.dropdown?.body_type?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedBodyType?.name || (
                        <div className="default-select-value">
                          + Add body type
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add body type
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.body_type?.map(
                    (bodyType, index) => (
                      <MenuItem key={index} value={bodyType?.value}>
                        {t(`${bodyType?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Ethnicity */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${ethnicity ? "" : "incomplete-field"}`}>
                {t("profile.ethnicity")}
              </div>
            </div>

            <div className="form-container">
              <FormControl className="form-field" variant="standard" fullWidth>
                <Select
                  className="form-select"
                  displayEmpty
                  value={ethnicity || ""}
                  onChange={onInputChange("ethnicity")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add ethnicity
                        </div>
                      );
                    }

                    const selectedEthnicity =
                      getAioData?.payload?.dropdown?.ethnicity?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedEthnicity?.name || (
                        <div className="default-select-value">
                          + Add ethnicity
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add ethnicity
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.ethnicity?.map(
                    (ethnicity, index) => (
                      <MenuItem key={index} value={ethnicity?.value}>
                        {t(`${ethnicity?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Race */}
          {ethnicity === "asian" &&
            getAioData?.payload?.dropdown?.race[0]?.child_value?.length > 0 && (
              <div className="form-field-container">
                <div className="label-container">
                  <div className={`label ${race ? "" : "incomplete-field"}`}>
                    {t("profile.race")}
                  </div>
                </div>

                <div className="form-container">
                  <FormControl
                    className="form-field"
                    variant="standard"
                    fullWidth
                  >
                    <Select
                      className="form-select"
                      displayEmpty
                      value={race || ""}
                      onChange={onInputChange("race")}
                      disableUnderline={true}
                      renderValue={(selected) => {
                        if (!selected || selected === "") {
                          return (
                            <div className="default-select-value">
                              + Add race
                            </div>
                          );
                        }

                        const selectedRace =
                          getAioData?.payload?.dropdown?.race[0]?.child_value?.find(
                            (item) => item.value === selected
                          );

                        return (
                          selectedRace?.name || (
                            <div className="default-select-value">
                              + Add race
                            </div>
                          )
                        );
                      }}
                    >
                      <MenuItem disabled value="">
                        + Add race
                      </MenuItem>
                      {getAioData?.payload?.dropdown?.race[0]?.child_value?.map(
                        (race, index) => (
                          <MenuItem key={index} value={race?.value}>
                            {t(`${race?.name}`)}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}

          {/* Eye Color */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${eyeColor ? "" : "incomplete-field"}`}>
                {t("profile.eye_color")}
              </div>
            </div>

            <div className="form-container">
              <FormControl className="form-field" variant="standard" fullWidth>
                <Select
                  className="form-select"
                  displayEmpty
                  value={eyeColor || ""}
                  onChange={onInputChange("eye-color")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add eye color
                        </div>
                      );
                    }

                    const selectedEyeColor =
                      getAioData?.payload?.dropdown?.eye_color?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedEyeColor?.name || (
                        <div className="default-select-value">
                          + Add eye color
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add eye color
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.eye_color?.map(
                    (eyeColor, index) => (
                      <MenuItem key={index} value={eyeColor?.value}>
                        {t(`${eyeColor?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Hair Color */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${hairColor ? "" : "incomplete-field"}`}>
                {t("profile.hair_color")}
              </div>
            </div>

            <div className="form-container">
              <FormControl className="form-field" variant="standard" fullWidth>
                <Select
                  className="form-select"
                  displayEmpty
                  value={hairColor || ""}
                  onChange={onInputChange("hair-color")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add hair color
                        </div>
                      );
                    }

                    const selectedHairColor =
                      getAioData?.payload?.dropdown?.hair_color?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedHairColor?.name || (
                        <div className="default-select-value">
                          + Add hair color
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    <em>+ Add hair color</em>
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.hair_color?.map(
                    (hairColor, index) => (
                      <MenuItem key={index} value={hairColor?.value}>
                        {t(`${hairColor?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div id="desktop-edit-profile-appearance-subcomponent">
        {/* In Review */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.inReview}
        />

        {/* Rejected */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.rejected}
        />

        <div className="appearance-static-label-container">
          <div className="appearance-label">{t("profile.appearance")}</div>
          <Divider className="short-divider" />
        </div>

        <div className="form-field-padding-container">
          {/* Height */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${height ? "" : "incomplete-field"}`}>
                {t("profile.height")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={height || ""}
                  onChange={onInputChange("height")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">+ Add height</div>
                      );
                    }

                    const selectedHeight =
                      getAioData?.payload?.dropdown?.height?.find(
                        (item) => item.name === selected?.toString()
                      );

                    return (
                      selectedHeight?.name || (
                        <div className="default-select-value">+ Add height</div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add height
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.height?.map(
                    (height, index) => (
                      <MenuItem key={index} value={height?.value}>
                        {t(`${height?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Body Type */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${bodyType ? "" : "incomplete-field"}`}>
                {t("profile.body_type")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={bodyType || ""}
                  onChange={onInputChange("body-type")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add body type
                        </div>
                      );
                    }

                    const selectedBodyType =
                      getAioData?.payload?.dropdown?.body_type?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedBodyType?.name || (
                        <div className="default-select-value">
                          + Add body type
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add body type
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.body_type?.map(
                    (bodyType, index) => (
                      <MenuItem key={index} value={bodyType?.value}>
                        {t(`${bodyType?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Ethnicity */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${ethnicity ? "" : "incomplete-field"}`}>
                {t("profile.ethnicity")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={ethnicity || ""}
                  onChange={onInputChange("ethnicity")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add ethnicity
                        </div>
                      );
                    }

                    const selectedEthnicity =
                      getAioData?.payload?.dropdown?.ethnicity?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedEthnicity?.name || (
                        <div className="default-select-value">
                          + Add ethnicity
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add ethnicity
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.ethnicity?.map(
                    (ethnicity, index) => (
                      <MenuItem key={index} value={ethnicity?.value}>
                        {t(`${ethnicity?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Race */}
          {ethnicity === "asian" &&
            getAioData?.payload?.dropdown?.race[0]?.child_value?.length > 0 && (
              <div className="form-field-container">
                <div className="label-container">
                  <div className={`label ${race ? "" : "incomplete-field"}`}>
                    {t("profile.race")}
                  </div>
                </div>

                <div className="form-container">
                  <FormControl
                    className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                    variant="outlined"
                    fullWidth
                  >
                    <Select
                      className="form-select"
                      displayEmpty
                      value={race || ""}
                      onChange={onInputChange("race")}
                      disableUnderline={true}
                      renderValue={(selected) => {
                        if (!selected || selected === "") {
                          return (
                            <div className="default-select-value">
                              + Add race
                            </div>
                          );
                        }

                        const selectedRace =
                          getAioData?.payload?.dropdown?.race[0]?.child_value?.find(
                            (item) => item.value === selected
                          );

                        return (
                          selectedRace?.name || (
                            <div className="default-select-value">
                              + Add race
                            </div>
                          )
                        );
                      }}
                    >
                      <MenuItem disabled value="">
                        + Add race
                      </MenuItem>
                      {getAioData?.payload?.dropdown?.race[0]?.child_value?.map(
                        (race, index) => (
                          <MenuItem key={index} value={race?.value}>
                            {t(`${race?.name}`)}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}

          {/* Eye Color */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${eyeColor ? "" : "incomplete-field"}`}>
                {t("profile.eye_color")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={eyeColor || ""}
                  onChange={onInputChange("eye-color")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add eye color
                        </div>
                      );
                    }

                    const selectedEyeColor =
                      getAioData?.payload?.dropdown?.eye_color?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedEyeColor?.name || (
                        <div className="default-select-value">
                          + Add eye color
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + Add eye color
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.eye_color?.map(
                    (eyeColor, index) => (
                      <MenuItem key={index} value={eyeColor?.value}>
                        {t(`${eyeColor?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Hair Color */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${hairColor ? "" : "incomplete-field"}`}>
                {t("profile.hair_color")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={hairColor || ""}
                  onChange={onInputChange("hair-color")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + Add hair color
                        </div>
                      );
                    }

                    const selectedHairColor =
                      getAioData?.payload?.dropdown?.hair_color?.find(
                        (item) => item.value === selected
                      );

                    return (
                      selectedHairColor?.name || (
                        <div className="default-select-value">
                          + Add hair color
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    <em>+ Add hair color</em>
                  </MenuItem>
                  {getAioData?.payload?.dropdown?.hair_color?.map(
                    (hairColor, index) => (
                      <MenuItem key={index} value={hairColor?.value}>
                        {t(`${hairColor?.name}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="bottom-section-container">
          <div
            className={`save-button ${
              isAppearanceValid() ? "" : "disabled-button"
            }`}
            onClick={onSaveDetails}
          >
            {updateUserProfileLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.save")
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default Appearance;
