// General
import "./notification-list.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../services/data.service";
// Static Data
import userConst from "../../../const/userConst";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Components
import ViewedYourProfileElement from "../viewed-your-profile-element/viewed-your-profile-element";
import FavoritedYouElement from "../favorited-you-element/favorited-you-element";
import PrivatePhotoRequestElement from "../private-photo-request-element/private-photo-request-element";
import PrivatePhotoApproveElement from "../private-photo-approve-element/private-photo-approve-element";
import TextMessageElement from "../text-message-element/text-message-element";
import LiveElement from "../live-element/live-element";

const NotificationList = (props) => {
  const { title, notificationList } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const isDaddy = useSelector((state) => state.user.isDaddy);

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Utility Functions
  const onFormattedDate = (date) => {
    const currentDate = moment();
    const notificationDate = moment(date);

    const diffInMinutes = currentDate.diff(notificationDate, "minutes");
    const diffInHours = currentDate.diff(notificationDate, "hours");
    const diffInDays = currentDate.diff(notificationDate, "days");
    const diffInWeeks = currentDate.diff(notificationDate, "weeks");
    const diffInMonths = currentDate.diff(notificationDate, "months");

    if (diffInHours === 0) {
      return `${t("notification.n_minute", { n: diffInMinutes })}`;
    } else if (diffInHours <= 24) {
      return `${t("notification.n_h", { n: diffInHours })}`;
    } else if (diffInDays === 0) {
      return `${t("notification.n_d", { n: diffInDays + 1 })}`;
    } else if (diffInDays <= 6) {
      return `${t("notification.n_d", { n: diffInDays })}`;
    } else if (diffInWeeks <= 3) {
      return `${t("notification.n_w", { n: diffInWeeks })}`;
    } else if (diffInWeeks === 4) {
      return `${t("notification.n_m", { n: 1 })}`;
    } else {
      return `${t("notification.n_m", { n: diffInMonths })}`;
    }
  };

  return (
    <div id="notification-notification-list-subcomponent">
      <div className="notification-date-title">{title}</div>

      {notificationList?.map((notification, index) => {
        switch (notification?.type) {
          case "view_profile":
            return (
              <ViewedYourProfileElement
                key={index}
                notificationId={notification?.notification_id}
                id={notification?.user?.id}
                idInt={notification?.user?.id_int}
                profilePhoto={notification?.user?.profile_photo?.original_photo}
                profileDecorations={notification?.user?.profile_decorations}
                publicPhotos={notification?.user?.public_photos?.map(
                  (photo) => {
                    return {
                      photoUrl: photo?.original_photo,
                    };
                  }
                )}
                privatePhotos={notification?.user?.private_photos?.map(
                  (photo) => {
                    return {
                      photoUrl: photo?.original_photo,
                      thumbnailData: photo?.thumbnail_data,
                    };
                  }
                )}
                username={notification?.user?.username}
                isVerifiedProfile={notification?.user?.is_verified_profile}
                membershipType={notification?.user?.membership_type}
                isLock={
                  isDaddy &&
                  getProfileData?.data?.membership_type ===
                    userConst.membershipType.free
                }
                timestamp={onFormattedDate(notification?.timestamp)}
                isSeen={notification?.is_seen}
              />
            );
          case "favorite_user":
            return (
              <FavoritedYouElement
                key={index}
                notificationId={notification?.notification_id}
                id={notification?.user?.id}
                idInt={notification?.user?.id_int}
                profilePhoto={notification?.user?.profile_photo?.original_photo}
                profileDecorations={notification?.user?.profile_decorations}
                publicPhotos={notification?.user?.public_photos?.map(
                  (photo) => {
                    return {
                      photoUrl: photo?.original_photo,
                    };
                  }
                )}
                privatePhotos={notification?.user?.private_photos?.map(
                  (photo) => {
                    return {
                      photoUrl: photo?.original_photo,
                      thumbnailData: photo?.thumbnail_data,
                    };
                  }
                )}
                username={notification?.user?.username}
                isVerifiedProfile={notification?.user?.is_verified_profile}
                membershipType={notification?.user?.membership_type}
                isLock={
                  isDaddy &&
                  getProfileData?.data?.membership_type ===
                    userConst.membershipType.free
                }
                timestamp={onFormattedDate(notification?.timestamp)}
                isSeen={notification?.is_seen}
              />
            );
          case "private_photo_request":
            return (
              <PrivatePhotoRequestElement
                key={index}
                notificationId={notification?.notification_id}
                id={notification?.user?.id}
                idInt={notification?.user?.id_int}
                profilePhoto={notification?.user?.profile_photo?.original_photo}
                profileDecorations={notification?.user?.profile_decorations}
                username={notification?.user?.username}
                isVerifiedProfile={notification?.user?.is_verified_profile}
                membershipType={notification?.user?.membership_type}
                isLock={
                  false &&
                  isDaddy &&
                  getProfileData?.data?.membership_type ===
                    userConst.membershipType.free
                }
                timestamp={onFormattedDate(notification?.timestamp)}
                isSeen={notification?.is_seen}
              />
            );
          case "private_photo_approve":
            return (
              <PrivatePhotoApproveElement
                key={index}
                notificationId={notification?.notification_id}
                id={notification?.user?.id}
                idInt={notification?.user?.id_int}
                profilePhoto={notification?.user?.profile_photo?.original_photo}
                profileDecorations={notification?.user?.profile_decorations}
                publicPhotos={notification?.user?.public_photos?.map(
                  (photo) => {
                    return {
                      photoUrl: photo?.original_photo,
                    };
                  }
                )}
                privatePhotos={notification?.user?.private_photos?.map(
                  (photo) => {
                    return {
                      photoUrl: photo?.original_photo,
                      thumbnailData: photo?.thumbnail_data,
                    };
                  }
                )}
                username={notification?.user?.username}
                isVerifiedProfile={notification?.user?.is_verified_profile}
                membershipType={notification?.user?.membership_type}
                isLock={
                  isDaddy &&
                  getProfileData?.data?.membership_type ===
                    userConst.membershipType.free
                }
                timestamp={onFormattedDate(notification?.timestamp)}
                isSeen={notification?.is_seen}
              />
            );
          case "text_message":
            return (
              <TextMessageElement
                key={index}
                notificationId={notification?.notification_id}
                id={notification?.user?.id}
                idInt={notification?.user?.id_int}
                conversationId={notification?.conversation_id}
                profilePhoto={notification?.user?.profile_photo?.original_photo}
                profileDecorations={notification?.user?.profile_decorations}
                username={notification?.user?.username}
                isVerifiedProfile={notification?.user?.is_verified_profile}
                membershipType={notification?.user?.membership_type}
                isLock={
                  false &&
                  isDaddy &&
                  getProfileData?.data?.membership_type ===
                    userConst.membershipType.free
                }
                timestamp={onFormattedDate(notification?.timestamp)}
                isSeen={notification?.is_seen}
              />
            );
          case "live":
            return (
              <LiveElement
                key={index}
                notificationId={notification?.notification_id}
                id={notification?.user?.id}
                idInt={notification?.user?.id_int}
                channelId={notification?.channel_id}
                channelStatus={notification?.channel_status}
                profilePhoto={notification?.user?.profile_photo?.original_photo}
                profileDecorations={notification?.user?.profile_decorations}
                publicPhotos={notification?.user?.public_photos?.map(
                  (photo) => {
                    return {
                      photoUrl: photo?.original_photo,
                    };
                  }
                )}
                privatePhotos={notification?.user?.private_photos?.map(
                  (photo) => {
                    return {
                      photoUrl: photo?.original_photo,
                      thumbnailData: photo?.thumbnail_data,
                    };
                  }
                )}
                username={notification?.user?.username}
                isVerifiedProfile={notification?.user?.is_verified_profile}
                membershipType={notification?.user?.membership_type}
                isLock={
                  false &&
                  isDaddy &&
                  getProfileData?.data?.membership_type ===
                    userConst.membershipType.free
                }
                timestamp={onFormattedDate(notification?.timestamp)}
                isSeen={notification?.is_seen}
              />
            );
          default:
            break;
        }
      })}
    </div>
  );
};

export default NotificationList;
