// General
import "./looking-for-info.scss";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";

const LookingForInfo = () => {
  // Redux variables
  const lookingFor = useSelector((state) => state.userCard.lookingFor);

  // i18next variables
  const { t } = useTranslation();

  if (lookingFor) {
    return (
      <div id="profile-view-looking-for-info-subcomponent">
        <div className="padding-container">
          <div className="label">{t("profile.looking_for")}</div>

          <div className="text">{lookingFor || "-"}</div>
        </div>
      </div>
    );
  }
};

export default LookingForInfo;
