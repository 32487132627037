// General
import "./notification-button.scss";
import { useEffect, useState } from "react";
// Static Data
import routeConst from "../../../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateInterestTab } from "../../../../../../redux/store/interestStore";
// Material UI
import { Menu, Badge } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../../utility/manager/icon-manager/icon-manager";
// Components
import Notification from "../../../../../notification/notification";

const NotificationButton = () => {
  // General variables
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Redux variables
  const showNotificationBadge = useSelector(
    (state) => state.notification.showNotificationBadge
  );
  const closeNotificationTabPassthrough = useSelector(
    (state) => state.notification.closeNotificationTabPassthrough
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Check for update | closeNotificationTabPassthrough
  useEffect(() => {
    if (!closeNotificationTabPassthrough) return;

    onCloseNotificationTab();
  }, [closeNotificationTabPassthrough]);

  // Event Handlers | Button
  const onOpenNotificationTab = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onCloseNotificationTab = () => {
    setAnchorEl(null);
  };
  const onRedirectToNotificationsTab = () => {
    onCloseNotificationTab();
    dispatch(updateInterestTab(0));
    onNavigate(routeConst.interest.path);
  };

  return (
    <div>
      <div className="notification-container" onClick={onOpenNotificationTab}>
        <Badge
          className="custom-badge notification-badge"
          badgeContent={showNotificationBadge === true ? 1 : 0}
          variant="dot"
        >
          {getIcon("notificationIcon", "notification-icon")}
        </Badge>
        <div className="notification-label">
          {t("interest.tabs.notifications")}
        </div>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseNotificationTab}
      >
        <div className="notification-max-width-container">
          <div className="notification-header-container">
            <div className="notifications-label">
              {t("interest.tabs.notifications")}
            </div>

            <div
              className="see-all-button"
              onClick={onRedirectToNotificationsTab}
            >
              {t("common.see_all")}
            </div>
          </div>
          <Notification />
        </div>
      </Menu>
    </div>
  );
};

export default NotificationButton;
