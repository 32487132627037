// General
import "./following-tab.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetFollowingListQuery,
} from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateFollowingList,
  resetFollowingList,
} from "../../../../../redux/store/userStore";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import EmptyList from "../empty-list/empty-list";
import FollowerUserList from "../../../../shared/profile/follower-user-list/follower-user-list";

const FollowingTab = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getFollowingList,
    {
      data: getFollowingListData,
      error: getFollowingListErrorData,
      isFetching: getFollowingListFetching,
      isLoading: getFollowingListLoading,
      isSuccess: getFollowingListSuccess,
      isError: getFollowingListError,
    },
  ] = useLazyGetFollowingListQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);

  // Render variables
  let followingListView = <div></div>;

  // Redux variables
  const followingList = useSelector((state) => state.user.followingList);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    dispatch(resetFollowingList());
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      getFollowingList(`?user_id=${getProfileData?.data?.id}`, true);
    } else if (getProfileError) {
      if (getProfileErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Get Following List API Response
  useEffect(() => {
    if (getFollowingListFetching || getFollowingListLoading) {
    } else if (getFollowingListSuccess) {
      if (getFollowingListData?.status === 1) {
        setFirstLoad(false);

        if (getFollowingListData?.data?.users?.length > 0) {
          dispatch(updateFollowingList(getFollowingListData?.data?.users));
        }
      }
    } else if (getFollowingListError) {
      if (getFollowingListErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getFollowingListFetching,
    getFollowingListLoading,
    getFollowingListSuccess,
    getFollowingListError,
  ]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (getFollowingListData?.data?.pagination?.next_cursor) {
      setTimeout(() => {
        getFollowingList(
          `?user_id=${getProfileData?.data?.id}&pagination_token=${getFollowingListData?.data?.pagination?.next_cursor}`
        );
      }, 1000);
    }
  };

  // Render Functions | Following List Data
  if (followingList?.length > 0) {
    followingListView = (
      <div className="infinite-scroller-container">
        {followingList?.map((following, index) => (
          <FollowerUserList
            // User View
            id={following?.id}
            idInt={following?.id_int}
            username={following?.username}
            age={following?.appearance?.age}
            location={
              following?.location?.display_address ||
              following?.location?.city ||
              following?.location?.state
            }
            membershipType={following?.membership_type}
            verifiedProfile={following?.is_verified_profile}
            verifiedCaller={following?.verified_caller}
            levellingBadge={following?.levelling_profile?.badge}
            livestreamingAchievementBadge={
              following?.live_streaming_achievements?.chat_badges_set
            }
            profilePhoto={following?.profile_photo?.original_photo}
            publicPhotos={following?.public_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
              };
            })}
            privatePhotos={following?.private_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
                thumbnailData: photo?.thumbnail_data,
              };
            })}
            torTags={following?.tor_tags}
            profileAchievementBadge={
              following?.live_streaming_achievements?.profile_badges
            }
            aboutMe={following?.aboutme}
            lookingFor={following?.lookingfor}
            tagline={following?.tagline}
            occupation={following?.occupation?.occupation}
            lifestyle={following?.occupation?.lifestyle_budget}
            education={following?.occupation?.education}
            relationship={following?.relationship?.relationship_status}
            smoking={following?.habit?.smoking}
            drinking={following?.habit?.drinking}
            height={following?.appearance?.height}
            bodyType={following?.appearance?.body_type}
            ethnicity={following?.appearance?.ethnicity}
            eyeColor={following?.appearance?.eye_color}
            hairColor={following?.appearance?.hair_color}
            children={following?.relationship?.children}
            // User Utility
            shouldCallApi={false}
            privatePhotoAbleToRequest={
              following?.interaction?.private_photo_requested === false
                ? true
                : false
            }
            privatePhotoRequested={
              following?.interaction?.private_photo_requested
            }
            privatePhotoApproval={
              following?.interaction?.private_photo_approval
            }
            requestedMyPrivatePhoto={
              following?.interaction?.requested_my_private_photo
            }
            approvedMyPrivatePhoto={
              following?.interaction?.approved_my_private_photo
            }
            isBlocked={following?.interaction?.is_blocked}
            isSilentInteraction={
              following?.interaction?.should_silent_interactions
            }
            isFavorited={following?.interaction?.is_favorited}
            isCallEnabled={
              following?.private_call_settings?.enable_calls &&
              following?.private_call_settings?.system_private_calls_enabled
            }
            isSugarbaby={
              following?.role === userConst.userRole.sugarbaby ||
              following?.role === userConst.userRole.maleSugarbaby
            }
            role={following?.role}
            // Livestream
            channelId={following?.live_stream?.channel_id}
            livestreamerId={following?.id}
            followerCount={following?.live_streaming_meta?.follower_count}
            viewerCount={following?.live_stream?.viewer_count}
            diamondCount={
              following?.live_streaming_meta?.lifetime_diamonds_earned
            }
            isFollowing={following?.live_streaming_meta?.is_following}
            type={"following"}
            key={index}
          />
        ))}
      </div>
    );
  } else if (
    firstLoad &&
    (getFollowingListFetching || getFollowingListLoading)
  ) {
    followingListView = <Spinner />;
  } else {
    return <EmptyList />;
  }

  return (
    <div id="follows-following-tab-subcomponent">
      <div className="followers-list-container">
        <InfiniteScroll
          pageStart={0}
          loadMore={infiniteScrollFunc}
          hasMore={
            getFollowingListData?.data?.pagination?.next_cursor ? true : false
          }
          loader={<Spinner key={0} />}
          useWindow={false}
        >
          {followingListView}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default FollowingTab;
