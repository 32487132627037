// General
import "./messages-tab.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useLazyGetInboxSingleConversationQuery,
  useLazyGetProfileQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Main Page Functions
  updateChatLists,
  updateChatListsFromTop,
  updateChatPagination,
  resetChatLists,

  // Utility variables
  updateSelectedConversation,
  updateNewUserConversation,
  updateNewUserConversationPassthrough,
} from "../../../../redux/store/inboxStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Component
import MessagesList from "../../../shared/inbox/messages-list/messages-list";
import EmptyList from "../../../shared/inbox/empty-list/empty-list";
import Spinner from "../../../shared/elements/spinner/spinner";

const MessagesTab = () => {
  // API variables
  const [
    getInboxSingleConversation,
    {
      data: getInboxSingleConversationData,
      error: getInboxSingleConversationErrorData,
      isFetching: getInboxSingleConversationFetching,
      isLoading: getInboxSingleConversationLoading,
      isSuccess: getInboxSingleConversationSuccess,
      isError: getInboxSingleConversationError,
    },
  ] = useLazyGetInboxSingleConversationQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);
  const scrollContainerRef = useRef(null);
  const isMounted = useRef(false);

  // Render variables
  let messagesListView = <div></div>;

  // Redux variables
  const chatLists = useSelector((state) => state.inbox.chatLists);
  const chatPagination = useSelector((state) => state.inbox.chatPagination);
  const inboxScrollToTopPassthrough = useSelector(
    (state) => state.inbox.inboxScrollToTopPassthrough
  );
  const newUserConversation = useSelector(
    (state) => state.inbox.newUserConversation
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-8-Messages-Tab",
      },
    });

    getProfile(null, true);

    // Inbox is always refresh when mounted
    setFirstLoad(true);
    dispatch(resetChatLists());

    getInboxSingleConversation();
  }, []);

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      dispatch(updateNewUserConversation(null));
      dispatch(updateSelectedConversation(null));
    };
  }, []);

  // Lifecycle | Check for update | Inbox Single Conversation API Response
  useEffect(() => {
    if (
      getInboxSingleConversationFetching ||
      getInboxSingleConversationLoading
    ) {
    } else if (getInboxSingleConversationSuccess) {
      switch (getInboxSingleConversationData?.status) {
        case 0:
          setFirstLoad(false);

          dispatch(
            updateChatPagination(
              getInboxSingleConversationData?.data?.pagination?.next_cursor
            )
          );
          dispatch(
            updateChatLists(getInboxSingleConversationData?.data?.results)
          );

          // Used for desktop mode only, will check if user exist in API, if not, add it to the top of the array
          // firstLoad seems like its going to be false all the time, but, because of how React works, the first run will be true, due to React still updating
          if (firstLoad && newUserConversation) {
            const isNewConversation =
              !getInboxSingleConversationData?.data?.results?.some(
                (conversation) =>
                  conversation?.conversation_id ===
                  newUserConversation?.conversation_id
              );

            dispatch(updateNewUserConversationPassthrough());

            if (isNewConversation) {
              dispatch(updateChatListsFromTop([newUserConversation]));
            }
          }
          break;
        case -1:
          break;
        default:
          break;
      }
    } else if (getInboxSingleConversationError) {
      if (getInboxSingleConversationErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getInboxSingleConversationFetching,
    getInboxSingleConversationLoading,
    getInboxSingleConversationSuccess,
    getInboxSingleConversationError,
  ]);

  // Lifecycle | Check for update | inboxScrollToTopPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (inboxScrollToTopPassthrough) {
        scrollContainerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      isMounted.current = true;
    }
  }, [inboxScrollToTopPassthrough]);

  // Utility Functions
  const getCachedMessagesList = () => {
    // Display cache data method | Not using due to business decision
    // Place this function inside useEffect() | Mounted
    if (chatLists?.length <= 0) {
      getInboxSingleConversation(null, true);
    }
  };

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (!chatPagination) return;

    setTimeout(() => {
      getInboxSingleConversation(`?pagination_token=${chatPagination}`, true);
    }, 2000);
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetChatLists());
    dispatch(updateChatPagination(null));
    setFirstLoad(true);

    return new Promise((resolve) => {
      resolve(getInboxSingleConversation());
    });
  };

  // Render Functions | Chat List Data
  if (chatLists?.length > 0) {
    messagesListView = (
      <div className="infinite-scroller-container">
        {chatLists?.map((conversation, index) => (
          <MessagesList
            // User Data
            userId={conversation?.other_user?.id}
            userIdInt={conversation?.other_user?.id_int}
            username={conversation?.other_user?.username}
            age={conversation?.other_user?.appearance?.age}
            location={
              conversation?.other_user?.location?.display_address ||
              conversation?.other_user?.location?.city ||
              conversation?.other_user?.location?.state
            }
            membershipType={conversation?.other_user?.membership_type}
            verifiedProfile={conversation?.other_user?.is_verified_profile}
            verifiedCaller={conversation?.other_user?.verified_caller}
            levellingBadge={conversation?.other_user?.levelling_profile?.badge}
            profilePhoto={
              conversation?.other_user?.profile_photo?.original_photo
            }
            profileDecorations={conversation?.other_user?.profile_decorations}
            publicPhotos={conversation?.other_user?.public_photos?.map(
              (photo) => {
                return {
                  photoUrl: photo?.original_photo,
                };
              }
            )}
            privatePhotos={conversation?.other_user?.private_photos?.map(
              (photo) => {
                return {
                  photoUrl: photo?.original_photo,
                  thumbnailData: photo?.thumbnail_data,
                };
              }
            )}
            torTags={conversation?.other_user?.tor_tags}
            aboutMe={conversation?.other_user?.aboutme}
            lookingFor={conversation?.other_user?.lookingfor}
            occupation={conversation?.other_user?.occupation?.occupation}
            lifestyle={conversation?.other_user?.occupation?.lifestyle_budget}
            education={conversation?.other_user?.occupation?.education}
            relationship={
              conversation?.other_user?.relationship?.relationship_status
            }
            smoking={conversation?.other_user?.habit?.smoking}
            drinking={conversation?.other_user?.habit?.drinking}
            height={conversation?.other_user?.appearance?.height}
            bodyType={conversation?.other_user?.appearance?.body_type}
            ethnicity={conversation?.other_user?.appearance?.ethnicity}
            eyeColor={conversation?.other_user?.appearance?.eye_color}
            hairColor={conversation?.other_user?.appearance?.hair_color}
            children={conversation?.other_user?.relationship?.children}
            // User Utility
            shouldCallApi={true}
            privatePhotoAbleToRequest={
              conversation?.other_user?.interaction?.private_photo_requested ===
              false
                ? true
                : false
            }
            privatePhotoRequested={
              conversation?.other_user?.interaction?.private_photo_requested
            }
            privatePhotoApproval={
              conversation?.other_user?.interaction?.private_photo_approval
            }
            requestedMyPrivatePhoto={
              conversation?.other_user?.interaction?.requested_my_private_photo
            }
            approvedMyPrivatePhoto={
              conversation?.other_user?.interaction?.approved_my_private_photo
            }
            isOnline={conversation?.other_user?.activity?.is_online}
            isBlocked={conversation?.other_user?.interaction?.is_blocked}
            isSilentInteraction={
              conversation?.other_user?.interaction?.should_silent_interactions
            }
            isFavorited={conversation?.other_user?.interaction?.is_favorited}
            isCallEnabled={
              conversation?.other_user?.private_call_settings?.enable_calls &&
              conversation?.other_user?.private_call_settings
                ?.system_private_calls_enabled
            }
            isSugarbaby={
              conversation?.other_user?.role === userConst.userRole.sugarbaby ||
              conversation?.other_user?.role ===
                userConst.userRole.maleSugarbaby
            }
            role={conversation?.other_user?.role}
            // Conversation Data
            conversationId={conversation?.conversation_id}
            conversationIdInt={conversation?.conversation_id_int}
            livestreamingAchievementBadge={
              conversation?.other_user?.live_streaming_achievements
                ?.chat_badges_set
            }
            privateCallSettings={
              conversation?.other_user?.private_call_settings
            }
            lastMessageContent={conversation?.last_message?.message_content}
            lastMessageTimestamp={conversation?.last_message?.message_timestamp}
            lastMessageMine={conversation?.last_message?.message_mine}
            lastMessageSeen={conversation?.last_message?.message_seen}
            callStatus={conversation?.last_message?.call_request?.status}
            callDuration={
              conversation?.last_message?.call_request?.call_duration
            }
            ownMembershipType={getProfileData?.data?.membership_type}
            key={index}
          />
        ))}
      </div>
    );
  } else if (
    firstLoad &&
    (getInboxSingleConversationFetching || getInboxSingleConversationLoading)
  ) {
    messagesListView = <Spinner />;
  } else {
    messagesListView = <EmptyList />;
  }

  return (
    <div id="inbox-messages-tab-subcomponent">
      <PullToRefresh
        className="pull-to-refresh-container"
        onRefresh={pullToRefreshFunc}
        refreshingContent={t("common.release")}
        pullingContent={t("common.pull_to_refresh")}
        isPullable={
          !getInboxSingleConversationFetching &&
          !getInboxSingleConversationLoading
        }
      >
        <div className="inbox-messages-list-container" ref={scrollContainerRef}>
          <InfiniteScroll
            pageStart={0}
            loadMore={infiniteScrollFunc}
            hasMore={chatPagination ? true : false}
            loader={<Spinner key={0} />}
            useWindow={false}
          >
            {messagesListView}
          </InfiniteScroll>
        </div>
      </PullToRefresh>
    </div>
  );
};

export default MessagesTab;
