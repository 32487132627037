// General
import "./sugarbook-verified.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useInitiateFacialVerificationMutation,
  useVerifyFacialVerificationMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateSugarbookVerifiedSelfieImage } from "../../../../redux/store/verificationStore";
import { updateSugarbookVerifiedCameraDialog } from "../../../../redux/store/dialogStore";
import {
  updateInfoToast,
  updateErrorToast,
} from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const SugarbookVerified = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    initiateFacialVerification,
    {
      data: initiateFacialVerificationData,
      error: initiateFacialVerificationErrorData,
      isLoading: initiateFacialVerificationLoading,
      isSuccess: initiateFacialVerificationSuccess,
      isError: initiateFacialVerificationError,
    },
  ] = useInitiateFacialVerificationMutation();
  const [
    verifyFacialVerification,
    {
      data: verifyFacialVerificationData,
      error: verifyFacialVerificationErrorData,
      isLoading: verifyFacialVerificationLoading,
      isSuccess: verifyFacialVerificationSuccess,
      isError: verifyFacialVerificationError,
    },
  ] = useVerifyFacialVerificationMutation();

  // Redux variables
  const sugarbookVerifiedSelfieImage = useSelector(
    (state) => state.verification.sugarbookVerifiedSelfieImage
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    dispatch(updateSugarbookVerifiedSelfieImage(null));
    initiateFacialVerification();

    return () => {
      dispatch(updateSugarbookVerifiedSelfieImage(null));
    };
  }, []);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (getProfileData?.status === 0) {
        if (getProfileData?.data?.verifications?.facial?.verified) {
          const infoToastObj = {
            message: "You are already Verified!",
            autoClose: 3000,
          };
          dispatch(updateInfoToast(infoToastObj));

          onNavigate(-1);
        }
      }
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Initiate Facial Verification
  useEffect(() => {
    if (initiateFacialVerificationLoading) {
    } else if (initiateFacialVerificationSuccess) {
    } else if (initiateFacialVerificationError) {
    }
  }, [
    initiateFacialVerificationLoading,
    initiateFacialVerificationSuccess,
    initiateFacialVerificationError,
  ]);

  // Lifecycle | Check for update | Verify Facial Verification
  useEffect(() => {
    if (verifyFacialVerificationLoading) {
    } else if (verifyFacialVerificationSuccess) {
      let errorToast = {};

      switch (verifyFacialVerificationData?.status) {
        case 0:
        case 1:
          onNavigate(routeConst.verify.sugarbookVerified.status.approved.path);
          break;
        case -1:
          // Front Features Needed
          errorToast = {
            message: verifyFacialVerificationData?.message,
            autoClose: 10000,
          };
          // dispatch(updateErrorToast(errorToast));
          onNavigate(routeConst.verify.sugarbookVerified.status.rejected.path);
          break;
        case -2:
          // Mismatched Code
          errorToast = {
            message: verifyFacialVerificationData?.message,
            autoClose: 10000,
          };
          // dispatch(updateErrorToast(errorToast));
          onNavigate(routeConst.verify.sugarbookVerified.status.rejected.path);
          break;
        case -3:
          // Existing Face found in our database, please contact our customer support to do manual verification if you think this is a mistake
          errorToast = {
            message: verifyFacialVerificationData?.message,
            autoClose: 10000,
          };
          // dispatch(updateErrorToast(errorToast));
          onNavigate(
            routeConst.verify.sugarbookVerified.status.rejected.duplicate
          );
          break;
        default:
          const defaultToast = {
            message: verifyFacialVerificationData?.message,
            autoClose: 10000,
          };
          dispatch(updateErrorToast(defaultToast));
          break;
      }
    } else if (verifyFacialVerificationError) {
      switch (verifyFacialVerificationErrorData?.data?.status) {
        case -1:
          // Front Features Needed
          // Mismatched Codes
          // Existing Face found in our database, please contact our customer support to do manual verification if you think this is a mistake
          const errorToast = {
            message: verifyFacialVerificationErrorData?.data?.message,
            autoClose: 10000,
          };
          // dispatch(updateErrorToast(errorToast));
          break;
        default:
          const defaultToast = {
            message: verifyFacialVerificationErrorData?.data?.message,
            autoClose: 10000,
          };
          dispatch(updateErrorToast(defaultToast));
          break;
      }
      onNavigate(routeConst.verify.sugarbookVerified.status.rejected.path);
    }
  }, [
    verifyFacialVerificationLoading,
    verifyFacialVerificationSuccess,
    verifyFacialVerificationError,
  ]);

  // Event Handlers | Button
  const onOpenVerifiedCamera = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-61-61.1",
      },
    });

    dispatch(updateSugarbookVerifiedCameraDialog(true));
  };
  const onVerifyFacialVerification = () => {
    if (!sugarbookVerifiedSelfieImage) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-61-61.2",
      },
    });

    const obj = {
      image_base64: sugarbookVerifiedSelfieImage,
    };
    verifyFacialVerification(obj);
  };

  if (initiateFacialVerificationLoading) {
    return <Spinner />;
  } else if (initiateFacialVerificationSuccess) {
    return (
      <div id="sugarbook-verified-page">
        <div className="max-width-container">
          <div className="padding-container">
            <div className="navbar-container">
              {!getProfileData?.data?.verifications?.facial?.verified &&
                !getProfileData?.data?.verifications?.facial
                  ?.verification_required && (
                  <div
                    className="back-button"
                    onClick={() => onNavigate(routeConst.search.path)}
                  >
                    {getIcon("signupBackIcon", "signup-back-icon")}
                  </div>
                )}
            </div>

            <div className="sugarbook-verified-label-container">
              <div className="sugarbook-verified-label">
                {t("verification.sugarbook_verified")}
              </div>

              <div className="verified-profile-badge-container">
                {getIcon("verifiedProfileBadge", "verified-profile-badge")}
              </div>
            </div>

            <div className="sugarbook-verified-description">
              {t("verification.sugarbook_verified_desc")}
            </div>

            <div className="how-to-verify-container">
              <div className="how-to-verify-label">
                {t("verification.how_to_verify")}
              </div>

              <ol className="how-to-verify-ordered-list-container">
                <li>
                  <Trans
                    i18nKey={"verification.write_your_code"}
                    values={{
                      code: initiateFacialVerificationData?.data
                        ?.facial_verification?.code,
                    }}
                    components={{
                      span: <span className="verify-code" />,
                    }}
                  />
                </li>
                <li>{t("verification.take_a_selfie")}</li>
                <li>{t("verification.upload_it_as_shown")}</li>
              </ol>
            </div>

            <div className="selfie-container">
              <div className="sample-selfie-container">
                <div className="aspect-ratio-container">
                  <img
                    className="sample-selfie"
                    src={getAsset("sugarbookVerifiedBackground")}
                    alt="sample"
                  />

                  <div className="code-overlay">
                    {
                      initiateFacialVerificationData?.data?.facial_verification
                        ?.code
                    }
                  </div>
                </div>
              </div>

              <div className="own-selfie-container">
                <div className="aspect-ratio-container">
                  {sugarbookVerifiedSelfieImage ? (
                    <img
                      className="own-selfie"
                      src={sugarbookVerifiedSelfieImage}
                      alt="own-selfie"
                      onClick={onOpenVerifiedCamera}
                    />
                  ) : (
                    <div
                      className="upload-photo-container"
                      onClick={onOpenVerifiedCamera}
                    >
                      <div className="upload-photo-icon-container">
                        {getIcon("cameraBlueOutlineIcon", "upload-icon")}
                      </div>

                      <div className="upload-photo-label">
                        {t("verification.upload_photo")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="button-container">
              <div
                className={`next-button ${
                  sugarbookVerifiedSelfieImage ? "" : "disabled-button"
                }`}
                onClick={onVerifyFacialVerification}
              >
                {verifyFacialVerificationLoading ? (
                  <Spinner
                    size={21}
                    isPadding={false}
                    color={"white-spinner"}
                  />
                ) : (
                  t("common.next")
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (initiateFacialVerificationError) {
    return (
      <div id="sugarbook-verified-page">
        <div className="max-width-container">
          <div className="padding-container">
            <div className="navbar-container">
              <div
                className="back-button"
                onClick={() => onNavigate(routeConst.search.path)}
              >
                {getIcon("signupBackIcon", "signup-back-icon")}
              </div>
            </div>

            <div className="sugarbook-verified-label-container">
              <div className="sugarbook-verified-label">
                {t("verification.sugarbook_verified")}
              </div>

              <div className="verified-profile-badge-container">
                {getIcon("verifiedProfileBadge", "verified-profile-badge")}
              </div>
            </div>

            <div className="sugarbook-verified-description">
              {t("verification.sugarbook_verified_desc")}
            </div>

            <div className="how-to-verify-container">
              <div className="how-to-verify-label">
                {t("verification.how_to_verify")}
              </div>

              <ol className="how-to-verify-ordered-list-container">
                <li>
                  <Trans
                    i18nKey={"verification.write_your_code"}
                    values={{
                      code: initiateFacialVerificationData?.data
                        ?.facial_verification?.code,
                    }}
                    components={{
                      span: <span className="verify-code" />,
                    }}
                  />
                </li>
                <li>{t("verification.take_a_selfie")}</li>
                <li>{t("verification.upload_it_as_shown")}</li>
              </ol>
            </div>

            <div className="selfie-container">
              <div className="sample-selfie-container">
                <div className="aspect-ratio-container">
                  <img
                    className="sample-selfie"
                    src={getAsset("sugarbookVerifiedBackground")}
                    alt="sample"
                  />

                  <div className="code-overlay">
                    {
                      initiateFacialVerificationData?.data?.facial_verification
                        ?.code
                    }
                  </div>
                </div>
              </div>

              <div className="own-selfie-container">
                <div className="aspect-ratio-container">
                  {sugarbookVerifiedSelfieImage ? (
                    <img
                      className="own-selfie"
                      src={sugarbookVerifiedSelfieImage}
                      alt="own-selfie"
                      onClick={onOpenVerifiedCamera}
                    />
                  ) : (
                    <div
                      className="upload-photo-container"
                      onClick={onOpenVerifiedCamera}
                    >
                      <div className="upload-photo-icon-container">
                        {getIcon("cameraBlueOutlineIcon", "upload-icon")}
                      </div>

                      <div className="upload-photo-label">
                        {t("verification.upload_photo")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="button-container">
              <div
                className={`next-button ${
                  sugarbookVerifiedSelfieImage ? "" : "disabled-button"
                }`}
                onClick={onVerifyFacialVerification}
              >
                {verifyFacialVerificationLoading ? (
                  <Spinner
                    size={21}
                    isPadding={false}
                    color={"white-spinner"}
                  />
                ) : (
                  t("common.next")
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default SugarbookVerified;
