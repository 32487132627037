// General
import "./hair-color-filter.scss";
import { useEffect } from "react";
// Services
import { useLazyGetAioQuery } from "../../../../../services/data.service";
// Static Data
import searchConst from "../../../../../const/searchConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateHairColorAuburn,
  updateHairColorBlack,
  updateHairColorBlonde,
  updateHairColorLightBrown,
  updateHairColorDarkBrown,
  updateHairColorGrey,
  updateHairColorRed,
  updateHairColorWhite,
  updateHairColorOther,
} from "../../../../../redux/store/searchFilterStore";
import { updateHairColorFilterDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";

const HairColorFilter = (props) => {
  const { type, enableTitle = true } = props;

  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();

  // Redux variables
  const hairColor = useSelector((state) => state.searchFilter.hairColor);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getAio(null, true);
  }, []);

  // Event Handlers | MUI Checkbox
  const onCheckboxChange = (state) => (event) => {
    switch (state) {
      case "auburn":
        dispatch(updateHairColorAuburn(event.target.checked));
        break;
      case "black":
        dispatch(updateHairColorBlack(event.target.checked));
        break;
      case "blonde":
        dispatch(updateHairColorBlonde(event.target.checked));
        break;
      case "light-brown":
        dispatch(updateHairColorLightBrown(event.target.checked));
        break;
      case "dark-brown":
        dispatch(updateHairColorDarkBrown(event.target.checked));
        break;
      case "grey":
        dispatch(updateHairColorGrey(event.target.checked));
        break;
      case "red":
        dispatch(updateHairColorRed(event.target.checked));
        break;
      case "white":
        dispatch(updateHairColorWhite(event.target.checked));
        break;
      case "other":
        dispatch(updateHairColorOther(event.target.checked));
        break;
      default:
        break;
    }
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateHairColorFilterDialog(false));
  };

  // Utility Functions
  const onGetState = (state) => {
    for (
      let i = 0;
      i < getAioData?.payload?.dropdown?.hair_color?.length;
      i++
    ) {
      if (state === getAioData?.payload?.dropdown?.hair_color[i].value) {
        return hairColor[state];
      }
    }
  };

  if (getAioFetching || getAioLoading) {
  } else if (getAioSuccess) {
    return (
      <div id="hair-color-filter-shared-component">
        {enableTitle && (
          <div className="dialog-header">
            <div className="title">{t("filter.hair_color")}</div>

            <div className="close-button-container">
              <CloseIcon className="close-button" onClick={onCloseDialog} />
            </div>
          </div>
        )}

        <div className="padding-container">
          <div
            className={`checkbox-container ${
              type === searchConst.searchFilterType.full ? "flexwrap" : ""
            }`}
          >
            {getAioData?.payload?.dropdown?.hair_color?.map(
              (hairColor, index) => (
                <FormGroup className="checkbox" key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={`custom-checkbox ${
                          type === searchConst.searchFilterType.full
                            ? "custom-checkbox-no-padding"
                            : ""
                        }`}
                        size={
                          type === searchConst.searchFilterType.full
                            ? "small"
                            : "medium"
                        }
                        checked={onGetState(hairColor?.value)}
                        onChange={onCheckboxChange(hairColor?.value)}
                      />
                    }
                    label={
                      <span
                        className={
                          type === searchConst.searchFilterType.full
                            ? "checkbox-font"
                            : ""
                        }
                      >
                        {hairColor?.name}
                      </span>
                    }
                  />
                </FormGroup>
              )
            )}
          </div>
        </div>
      </div>
    );
  } else if (getAioError) {
  }
};

export default HairColorFilter;
