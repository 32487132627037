// General
import "./chat-input.scss";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetInboxSingleConversationQuery,
  usePostSingleConversationMutation,
} from "../../../../services/data.service";
import { removeHTMLEscape } from "../../../../services/regex.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateChatListsLastMessage,
  updateConversationObj,
} from "../../../../redux/store/inboxStore";
import { updateWarningToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { TextField, Button, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const ChatInput = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getInboxSingleConversation,
    {
      data: getInboxSingleConversationData,
      error: getInboxSingleConversationErrorData,
      isFetching: getInboxSingleConversationFetching,
      isLoading: getInboxSingleConversationLoading,
      isSuccess: getInboxSingleConversationSuccess,
      isError: getInboxSingleConversationError,
    },
  ] = useLazyGetInboxSingleConversationQuery();
  const [
    postSingleConversation,
    {
      data: postSingleConversationData,
      error: postSingleConversationErrorData,
      isLoading: postSingleConversationLoading,
      isSuccess: postSingleConversationSuccess,
      isError: postSingleConversationError,
    },
  ] = usePostSingleConversationMutation();

  // General variables
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  // Redux variables
  const conversationId = useSelector((state) => state.inbox.conversationId);
  const chatLists = useSelector((state) => state.inbox.chatLists);
  const unreadLists = useSelector((state) => state.inbox.unreadLists);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 850px)");

  // Params variables
  const { id } = useParams();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    getInboxSingleConversation(`/${id ?? conversationId}`);
  }, []);

  // Lifecycle | Check for update | Send Message API Response
  useEffect(() => {
    if (postSingleConversationLoading) {
    } else if (postSingleConversationSuccess) {
      switch (postSingleConversationData?.status) {
        case 0:
          const obj = {
            conversation_id: conversationId,
            conversation_id_int: "",
            data: {
              message: postSingleConversationData?.data,
            },
            type: "private-messages",
          };

          dispatch(updateConversationObj(obj));

          if (!isMobile) {
            // Prevent crashing for mobile users
            // Only desktop user run this function somewhere else
            let isChatListSanitized = true;
            let isUnreadListSanitized = true;

            for (let i = 0; i < chatLists?.length; i++) {
              if (!chatLists[i]?.conversation_id) {
                isChatListSanitized = false;
                break;
              }
            }

            for (let i = 0; i < unreadLists?.length; i++) {
              if (!unreadLists[i]?.conversation_id) {
                isUnreadListSanitized = false;
                break;
              }
            }

            if (isChatListSanitized && isUnreadListSanitized) {
              dispatch(updateChatListsLastMessage(obj));
            }
          }
          break;
        case -1:
          const toastObj = {
            message: postSingleConversationData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(toastObj));
          break;
        default:
          if (postSingleConversationData?.message) {
            const defaultToastObj = {
              message: postSingleConversationData?.message,
              autoClose: 3000,
            };
            dispatch(updateWarningToast(defaultToastObj));
          }
          break;
      }
    } else if (postSingleConversationError) {
      if (postSingleConversationErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    postSingleConversationLoading,
    postSingleConversationSuccess,
    postSingleConversationError,
  ]);

  // Event Handlers | Button
  const onSendMessage = (event) => {
    if (inputValue === "") return;

    // Prevent keyboard from closing on mobile
    inputRef.current.focus();

    const obj = {
      message_content: inputValue,
      message_type: "text",
    };
    postSingleConversation({ id: id ?? conversationId, body: obj });
    setInputValue("");
  };
  const onEnterPress = (event) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault(); // Reset multiline
      onSendMessage();
    }
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (event) => {
    if (removeHTMLEscape.test(event.target.value)) {
      // Remove HTML Escape (prevent XSS)
      setInputValue("");

      // Send to Sentry
      Sentry.captureMessage("PWA-8.1-Input-HTML-Escape-Detected");

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-8.1-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setInputValue(event.target.value);
    }
  };

  // Render Functions | API Response
  if (
    getProfileFetching ||
    getProfileLoading ||
    getInboxSingleConversationFetching ||
    getInboxSingleConversationLoading
  ) {
  } else if (getProfileSuccess && getInboxSingleConversationSuccess) {
    if (
      getProfileData?.status === 0 &&
      getInboxSingleConversationData?.status === 0
    ) {
      if (
        getProfileData?.data?.membership_type !==
          userConst.membershipType.free ||
        !getInboxSingleConversationData?.data?.conversation_is_blocked
      ) {
        return (
          <div
            id="chat-conversation-chat-input-subcomponent"
            className={!isMobile ? "rounded-corner" : ""}
          >
            <div className="input-container">
              <div className="form-container">
                <TextField
                  className="form-field"
                  inputRef={inputRef}
                  multiline
                  size="small"
                  value={inputValue}
                  placeholder={t("inbox.type_something")}
                  onChange={onInputChange}
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  onKeyDown={onEnterPress}
                />
              </div>
            </div>

            <div className="send-button-container">
              <Button
                className={`send-button ${!inputValue && "disabled-button"}`}
                variant="text"
                onClick={onSendMessage}
                disabled={!inputValue}
              >
                {t("common.send")}
              </Button>
            </div>
          </div>
        );
      }
    }
  } else if (getProfileError || getInboxSingleConversationError) {
    if (
      getProfileErrorData?.status === 401 ||
      getInboxSingleConversationErrorData?.status === 401
    ) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default ChatInput;
