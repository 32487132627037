// General
import "./about-me-tab.scss";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";

const AboutMeTab = () => {
  // Redux variables
  const aboutMe = useSelector((state) => state.userCard.aboutMe);
  const lookingFor = useSelector((state) => state.userCard.lookingFor);

  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="user-profile-tab-about-me-tab-subcomponent">
      <div className="about-me-container">
        <div className="about-me-label">{t("profile.about_me")}</div>

        <div className="about-me">{aboutMe || "-"}</div>
      </div>

      <div className="looking-for-container">
        <div className="looking-for-label">{t("profile.looking_for")}</div>

        <div className="looking-for">{lookingFor || "-"}</div>
      </div>
    </div>
  );
};

export default AboutMeTab;
