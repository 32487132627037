// General
import "./cny-tag.scss";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const CNYTag = (props) => {
  const { torName, blur, match } = props;

  // Custom Hooks Functions
  const getIcon = IconManager();

  return (
    <div
      id="tor-tag-cny-tag-subcomponent"
      className={`${blur ? "blur" : ""} ${
        match ? "cny-matched" : "cny-unmatched"
      }
      "cny-tor-theme"`}
    >
      <div className="left-tor-asset-container">
        {getIcon("torCnyIcon", "tor-asset")}
      </div>

      <div className="tor-name">{torName}</div>

      <div className="right-tor-asset-container">
        {getIcon("torCnyIcon", "tor-asset")}
      </div>
    </div>
  );
};

export default CNYTag;
