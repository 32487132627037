// General
import "./top-fans-tab.scss";
import { useState } from "react";
// React Swipeable Views
import SwipeableViews from "react-swipeable-views/lib/SwipeableViews";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Tabs, Tab, Box, useTheme } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Components
import DailyTab from "./daily-tab/daily-tab";
import WeeklyTab from "./weekly-tab/weekly-tab";
import MonthlyTab from "./monthly-tab/monthly-tab";
import OverallTab from "./overall-tab/overall-tab";

const TopFansTab = () => {
  // MUI variables
  const theme = useTheme();
  const [value, setValue] = useState(0);

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-D6-6.7-6.7.1-Button-${
          newValue === 0
            ? "DailyTab"
            : newValue === 1
            ? "WeeklyTab"
            : newValue === 2
            ? "MonthlyTab"
            : "OverallTab"
        }`,
      },
    });

    setValue(newValue);
  };
  const onTabChangeBySwipe = (index) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-D6-6.7-6.7.2-Button-${
          index === 0
            ? "DailyTab"
            : index === 1
            ? "WeeklyTab"
            : index === 2
            ? "MonthlyTab"
            : "OverallTab"
        }`,
      },
    });

    setValue(index);
  };

  return (
    <div id="viewer-info-top-fans-tab-subcomponent">
      <div className="tab-container">
        <Tabs
          className="custom-tabs"
          value={value}
          onChange={onTabChange}
          TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
          variant="fullWidth"
        >
          <Tab label={t("top-fan.tabs.daily")} {...a11yProps(0)} />
          <Tab label={t("top-fan.tabs.weekly")} {...a11yProps(1)} />
          <Tab label={t("top-fan.tabs.monthly")} {...a11yProps(2)} />
          <Tab label={t("top-fan.tabs.overall")} {...a11yProps(3)} />
        </Tabs>

        <SwipeableViews
          className="swipeable-container"
          containerStyle={{
            transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
          }}
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={onTabChangeBySwipe}
        >
          <TabPanel
            className="tab-panel"
            value={value}
            index={0}
            dir={theme.direction}
          >
            <DailyTab />
          </TabPanel>

          <TabPanel
            className="tab-panel"
            value={value}
            index={1}
            dir={theme.direction}
          >
            <WeeklyTab />
          </TabPanel>

          <TabPanel
            className="tab-panel"
            value={value}
            index={2}
            dir={theme.direction}
          >
            <MonthlyTab />
          </TabPanel>

          <TabPanel
            className="tab-panel"
            value={value}
            index={3}
            dir={theme.direction}
          >
            <OverallTab />
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
};

export default TopFansTab;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
