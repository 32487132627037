// General
import "./search-filter-dialog.scss";
import { useEffect, forwardRef } from "react";
import { useSearchParams } from "react-router-dom";
// Static Data
import searchConst from "../../../../const/searchConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateSearchFilterDialog,
  updateDialogSearchParam,
} from "../../../../redux/store/dialogStore";
import {
  composeSearchBody,
  updateSelectedTorAfterApply,
  resetSelectedTorAfterApply,
} from "../../../../redux/store/searchStore";
import { updateIsFiltered } from "../../../../redux/store/searchFilterStore";
// React Swipeable Views
import SwipeableViews from "react-swipeable-views/lib/SwipeableViews";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Divider, useTheme } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import TopBar from "./top-bar/top-bar";
import SearchInput from "./search-input/search-input";
import Location from "./location/location";
import TorFilter from "../../elements/filters/tor-filter/tor-filter";
import BasicFilters from "./basic-filters/basic-filters";
import AgeRange from "./age-range/age-range";
import HeightRange from "./height-range/height-range";
import AdvancedFilters from "./advanced-filters/advanced-filters";

const SearchFilterDialog = () => {
  // Redux variables
  const searchFilterDialog = useSelector(
    (state) => state.dialog.searchFilterDialog
  );
  const keyword = useSelector((state) => state.searchFilter.keyword);
  const location = useSelector((state) => state.searchFilter.location);
  const location_data = useSelector(
    (state) => state.searchFilter.location_data
  );
  const premiumOnly = useSelector((state) => state.searchFilter.premiumOnly);
  const photos = useSelector((state) => state.searchFilter.photos);
  const viewed = useSelector((state) => state.searchFilter.viewed);
  const unviewed = useSelector((state) => state.searchFilter.unviewed);
  const favorited = useSelector((state) => state.searchFilter.favorited);
  const favoritedMe = useSelector((state) => state.searchFilter.favoritedMe);
  const minAge = useSelector((state) => state.searchFilter.minAge);
  const maxAge = useSelector((state) => state.searchFilter.maxAge);
  const minHeight = useSelector((state) => state.searchFilter.minHeight);
  const maxHeight = useSelector((state) => state.searchFilter.maxHeight);
  const lifestyle = useSelector((state) => state.searchFilter.lifestyle);
  const bodyType = useSelector((state) => state.searchFilter.bodyType);
  const ethnicity = useSelector((state) => state.searchFilter.ethnicity);
  const eyeColor = useSelector((state) => state.searchFilter.eyeColor);
  const hairColor = useSelector((state) => state.searchFilter.hairColor);
  const smoking = useSelector((state) => state.searchFilter.smoking);
  const drinking = useSelector((state) => state.searchFilter.drinking);
  const relationshipStatus = useSelector(
    (state) => state.searchFilter.relationshipStatus
  );
  const education = useSelector((state) => state.searchFilter.education);
  const tor = useSelector((state) => state.searchFilter.tor);
  const dispatch = useDispatch();

  // Router variables
  const [searchParams, setSearchParams] = useSearchParams();

  // MUI variables
  const theme = useTheme();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (searchFilterDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-D21",
        },
      });

      dispatch(
        updateDialogSearchParam({
          key: "searchFilterDialog=true",
          value: true,
        })
      );
    } else {
      dispatch(
        updateDialogSearchParam({
          key: "searchFilterDialog=true",
          value: false,
        })
      );
    }
  }, [searchFilterDialog]);

  // Lifecycle | Check for update | searchParams
  useEffect(() => {
    if (searchParams?.get("searchFilterDialog") === "true") return;

    dispatch(updateSearchFilterDialog(false));
  }, [searchParams]);

  // Event Handlers | Button
  const onApplyFilter = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D21-21.1-Button",
      },
    });

    const obj = {
      keyword,
      location,
      location_data,
      premiumOnly,
      photos,
      viewed,
      unviewed,
      favorited,
      favoritedMe,
      minAge,
      maxAge,
      minHeight,
      maxHeight,
      lifestyle,
      bodyType,
      ethnicity,
      eyeColor,
      hairColor,
      smoking,
      drinking,
      relationshipStatus,
      education,
      tor,
    };

    dispatch(composeSearchBody(obj));
    dispatch(updateIsFiltered());
    dispatch(updateSelectedTorAfterApply(tor));
    dispatch(updateSearchFilterDialog(false));
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(resetSelectedTorAfterApply());
    dispatch(updateSearchFilterDialog(false));
  };

  // Event Handlers | MUI Tabs
  const onTabChange = (event, index) => {};
  const onTabChangeBySwipe = (index) => {};

  // Utility Functions
  const onApplyFilterCallback = () => {
    onApplyFilter();
  };

  return (
    <Dialog
      className="custom-top-radius20-dialog custom-dialog-margin-top"
      fullScreen
      open={searchFilterDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      {/* <SwipeableViews
        className="swipeable-container"
        containerStyle={{
          transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
        }}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={0}
        onChangeIndex={onTabChangeBySwipe}
      > */}
      <div id="search-filter-dialog" index={0} dir={theme.direction}>
        <TopBar applyFilterCallback={onApplyFilterCallback} />

        <div className="body-container">
          <SearchInput />

          <Divider />

          <Location />

          <Divider />

          <TorFilter filtered={true} />

          <Divider />

          <BasicFilters />

          <Divider />

          <AgeRange type={searchConst.searchFilterType.dialog} />

          <Divider />

          <HeightRange type={searchConst.searchFilterType.dialog} />

          <AdvancedFilters />

          <Divider />
        </div>

        <div className="apply-filter-button" onClick={onApplyFilter}>
          {t("filter.apply_filters")}
        </div>
      </div>
      {/* </SwipeableViews> */}
    </Dialog>
  );
};

export default SearchFilterDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
