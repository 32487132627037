// General
import "./initializing-stream-overlay.scss";
// Redux
import { useSelector } from "react-redux";

const InitializingStreamOverlay = () => {
  // Redux variables
  const showVideoCallStreamInitializingOverlay = useSelector(
    (state) => state.privateCall.showVideoCallStreamInitializingOverlay
  );

  if (showVideoCallStreamInitializingOverlay) {
    return (
      <div id="initializing-stream-overlay-subcomponent">
        <div className="subheader">Initializing Stream</div>
      </div>
    );
  }
};

export default InitializingStreamOverlay;
