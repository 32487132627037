// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  keyword: "",
  locationFormattedAddress: "",
  locationLatitude: null,
  locationLongitude: null,
  locationCity: null,
  locationState: null,
  locationCountry: null,
  locationCountryIso: null,
  location: "",
  location_data: "",
  premiumOnly: false,
  photos: false,
  viewed: false,
  unviewed: false,
  favorited: false,
  favoritedMe: false,
  minAge: 18,
  maxAge: 60,
  // minHeight: 130,
  // maxHeight: 220,
  lifestyle: {
    negotiable: false,
    minimal: false,
    practical: false,
    moderate: false,
    substantial: false,
    high: false,
  },
  bodyType: {
    slim: false,
    athletic: false,
    average: false,
    "a-few-extra-pounds": false,
    "full-or-overweight": false,
    other: false,
  },
  ethnicity: {
    asian: false,
    "black-or-african-descent": false,
    latin: false,
    "east-indian": false,
    "middle-eastern": false,
    mixed: false,
    "native-american": false,
    "pacific-islander": false,
    caucasian: false,
    other: false,
  },
  eyeColor: {
    black: false,
    blue: false,
    brown: false,
    grey: false,
    green: false,
    hazel: false,
    other: false,
  },
  hairColor: {
    auburn: false,
    black: false,
    blonde: false,
    "light-brown": false,
    "dark-brown": false,
    grey: false,
    red: false,
    white: false,
    other: false,
  },
  smoking: {
    "non-smoker": false,
    "light-smoker": false,
    "heavy-smoker": false,
  },
  drinking: {
    "non-drinker": false,
    "social-drinker": false,
    "heavy-drinker": false,
  },
  relationshipStatus: {
    single: false,
    divorced: false,
    separated: false,
    "married-but-looking": false,
    widowed: false,
  },
  education: {
    "high-school": false,
    "some-college": false,
    "associates-degree": false,
    "bachelors-degree": false,
    "graduate-degree": false,
    "phd-or-post-doctoral": false,
  },
  tor: [],

  // Utility variables
  lifestyleSelectedCount: 0,
  bodyTypeSelectedCount: 0,
  ethnicitySelectedCount: 0,
  eyeColorSelectedCount: 0,
  hairColorSelectedCount: 0,
  smokingSelectedCount: 0,
  drinkingSelectedCount: 0,
  relationshipStatusSelectedCount: 0,
  educationSelectedCount: 0,
  torSelectedCount: 0,

  isFiltered: false,
  selectedFilters: [],
  triggerSearchFilteredPassthrough: {},
};

export const searchFilterSlice = createSlice({
  name: "searchFilter",
  initialState,
  reducers: {
    // General Functions
    updateKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    updateLocationFormattedAddress: (state, action) => {
      state.locationFormattedAddress = action.payload;
    },
    updateLocationLatitude: (state, action) => {
      state.locationLatitude = action.payload;
    },
    updateLocationLongitude: (state, action) => {
      state.locationLongitude = action.payload;
    },
    updateLocationCity: (state, action) => {
      state.locationCity = action.payload;
    },
    updateLocationState: (state, action) => {
      state.locationState = action.payload;
    },
    updateLocationCountry: (state, action) => {
      state.locationCountry = action.payload;
    },
    updateLocationCountryIso: (state, action) => {
      state.locationCountryIso = action.payload;
    },
    updateLocation: (state, action) => {
      state.location = action.payload;
    },
    updateLocationData: (state, action) => {
      state.location_data = action.payload;
    },
    updatePremiumOnly: (state, action) => {
      state.premiumOnly = action.payload;
    },
    updatePhotos: (state, action) => {
      state.photos = action.payload;
    },
    updateViewed: (state, action) => {
      state.viewed = action.payload;
    },
    updateUnviewed: (state, action) => {
      state.unviewed = action.payload;
    },
    updateFavorited: (state, action) => {
      state.favorited = action.payload;
    },
    updateFavoritedMe: (state, action) => {
      state.favoritedMe = action.payload;
    },
    updateMinAge: (state, action) => {
      state.minAge = action.payload;
    },
    updateMaxAge: (state, action) => {
      state.maxAge = action.payload;
    },
    updateMinHeight: (state, action) => {
      state.minHeight = action.payload;
    },
    updateMaxHeight: (state, action) => {
      state.maxHeight = action.payload;
    },
    updateLifestyleNegotiable: (state, action) => {
      state.lifestyle.negotiable = action.payload;

      searchFilterSlice.caseReducers.updateLifestyleSelectedCount(state);
    },
    updateLifestyleMinimal: (state, action) => {
      state.lifestyle.minimal = action.payload;
      searchFilterSlice.caseReducers.updateLifestyleSelectedCount(state);
    },
    updateLifestylePractical: (state, action) => {
      state.lifestyle.practical = action.payload;
      searchFilterSlice.caseReducers.updateLifestyleSelectedCount(state);
    },
    updateLifestyleModerate: (state, action) => {
      state.lifestyle.moderate = action.payload;
      searchFilterSlice.caseReducers.updateLifestyleSelectedCount(state);
    },
    updateLifestyleSubstantial: (state, action) => {
      state.lifestyle.substantial = action.payload;
      searchFilterSlice.caseReducers.updateLifestyleSelectedCount(state);
    },
    updateLifestyleHigh: (state, action) => {
      state.lifestyle.high = action.payload;
      searchFilterSlice.caseReducers.updateLifestyleSelectedCount(state);
    },
    updateBodyTypeSlim: (state, action) => {
      state.bodyType.slim = action.payload;
      searchFilterSlice.caseReducers.updateBodyTypeSelectedCount(state);
    },
    updateBodyTypeAthletic: (state, action) => {
      state.bodyType.athletic = action.payload;
      searchFilterSlice.caseReducers.updateBodyTypeSelectedCount(state);
    },
    updateBodyTypeAverage: (state, action) => {
      state.bodyType.average = action.payload;
      searchFilterSlice.caseReducers.updateBodyTypeSelectedCount(state);
    },
    updateBodyTypeAFewExtraPounds: (state, action) => {
      state.bodyType["a-few-extra-pounds"] = action.payload;
      searchFilterSlice.caseReducers.updateBodyTypeSelectedCount(state);
    },
    updateBodyTypeOverweight: (state, action) => {
      state.bodyType["full-or-overweight"] = action.payload;
      searchFilterSlice.caseReducers.updateBodyTypeSelectedCount(state);
    },
    updateBodyTypeOther: (state, action) => {
      state.bodyType.other = action.payload;
      searchFilterSlice.caseReducers.updateBodyTypeSelectedCount(state);
    },
    updateEthnicityAsian: (state, action) => {
      state.ethnicity.asian = action.payload;
      searchFilterSlice.caseReducers.updateEthnicitySelectedCount(state);
    },
    updateEthnicityBlackAfrican: (state, action) => {
      state.ethnicity["black-or-african-descent"] = action.payload;
      searchFilterSlice.caseReducers.updateEthnicitySelectedCount(state);
    },
    updateEthnicityLatinHispanic: (state, action) => {
      state.ethnicity.latin = action.payload;
      searchFilterSlice.caseReducers.updateEthnicitySelectedCount(state);
    },
    updateEthnicityEastIndian: (state, action) => {
      state.ethnicity["east-indian"] = action.payload;
      searchFilterSlice.caseReducers.updateEthnicitySelectedCount(state);
    },
    updateEthnicityMiddleEastern: (state, action) => {
      state.ethnicity["middle-eastern"] = action.payload;
      searchFilterSlice.caseReducers.updateEthnicitySelectedCount(state);
    },
    updateEthnicityMixed: (state, action) => {
      state.ethnicity.mixed = action.payload;
      searchFilterSlice.caseReducers.updateEthnicitySelectedCount(state);
    },
    updateEthnicityNativeAmerican: (state, action) => {
      state.ethnicity["native-american"] = action.payload;
      searchFilterSlice.caseReducers.updateEthnicitySelectedCount(state);
    },
    updateEthnicityPacificIslander: (state, action) => {
      state.ethnicity["pacific-islander"] = action.payload;
      searchFilterSlice.caseReducers.updateEthnicitySelectedCount(state);
    },
    updateEthnicityWhiteCaucasian: (state, action) => {
      state.ethnicity.caucasian = action.payload;
      searchFilterSlice.caseReducers.updateEthnicitySelectedCount(state);
    },
    updateEthnicityOther: (state, action) => {
      state.ethnicity.other = action.payload;
      searchFilterSlice.caseReducers.updateEthnicitySelectedCount(state);
    },
    updateEyeColorBlack: (state, action) => {
      state.eyeColor.black = action.payload;
      searchFilterSlice.caseReducers.updateEyeColorSelectedCount(state);
    },
    updateEyeColorBlue: (state, action) => {
      state.eyeColor.blue = action.payload;
      searchFilterSlice.caseReducers.updateEyeColorSelectedCount(state);
    },
    updateEyeColorBrown: (state, action) => {
      state.eyeColor.brown = action.payload;
      searchFilterSlice.caseReducers.updateEyeColorSelectedCount(state);
    },
    updateEyeColorGrey: (state, action) => {
      state.eyeColor.grey = action.payload;
      searchFilterSlice.caseReducers.updateEyeColorSelectedCount(state);
    },
    updateEyeColorGreen: (state, action) => {
      state.eyeColor.green = action.payload;
      searchFilterSlice.caseReducers.updateEyeColorSelectedCount(state);
    },
    updateEyeColorHazel: (state, action) => {
      state.eyeColor.hazel = action.payload;
      searchFilterSlice.caseReducers.updateEyeColorSelectedCount(state);
    },
    updateEyeColorOther: (state, action) => {
      state.eyeColor.other = action.payload;
      searchFilterSlice.caseReducers.updateEyeColorSelectedCount(state);
    },
    updateHairColorAuburn: (state, action) => {
      state.hairColor.auburn = action.payload;
      searchFilterSlice.caseReducers.updateHairColorSelectedCount(state);
    },
    updateHairColorBlack: (state, action) => {
      state.hairColor.black = action.payload;
      searchFilterSlice.caseReducers.updateHairColorSelectedCount(state);
    },
    updateHairColorBlonde: (state, action) => {
      state.hairColor.blonde = action.payload;
      searchFilterSlice.caseReducers.updateHairColorSelectedCount(state);
    },
    updateHairColorLightBrown: (state, action) => {
      state.hairColor["light-brown"] = action.payload;
      searchFilterSlice.caseReducers.updateHairColorSelectedCount(state);
    },
    updateHairColorDarkBrown: (state, action) => {
      state.hairColor["dark-brown"] = action.payload;
      searchFilterSlice.caseReducers.updateHairColorSelectedCount(state);
    },
    updateHairColorGrey: (state, action) => {
      state.hairColor.grey = action.payload;
      searchFilterSlice.caseReducers.updateHairColorSelectedCount(state);
    },
    updateHairColorRed: (state, action) => {
      state.hairColor.red = action.payload;
      searchFilterSlice.caseReducers.updateHairColorSelectedCount(state);
    },
    updateHairColorWhite: (state, action) => {
      state.hairColor.white = action.payload;
      searchFilterSlice.caseReducers.updateHairColorSelectedCount(state);
    },
    updateHairColorOther: (state, action) => {
      state.hairColor.other = action.payload;
      searchFilterSlice.caseReducers.updateHairColorSelectedCount(state);
    },
    updateSmokingNonSmoker: (state, action) => {
      state.smoking["non-smoker"] = action.payload;
      searchFilterSlice.caseReducers.updateSmokingSelectedCount(state);
    },
    updateSmokingLightSmoker: (state, action) => {
      state.smoking["light-smoker"] = action.payload;
      searchFilterSlice.caseReducers.updateSmokingSelectedCount(state);
    },
    updateSmokingHeavySmoker: (state, action) => {
      state.smoking["heavy-smoker"] = action.payload;
      searchFilterSlice.caseReducers.updateSmokingSelectedCount(state);
    },
    updateDrinkingNonDrinker: (state, action) => {
      state.drinking["non-drinker"] = action.payload;
      searchFilterSlice.caseReducers.updateDrinkingSelectedCount(state);
    },
    updateDrinkingSocialDrinker: (state, action) => {
      state.drinking["social-drinker"] = action.payload;
      searchFilterSlice.caseReducers.updateDrinkingSelectedCount(state);
    },
    updateDrinkingHeavyDrinker: (state, action) => {
      state.drinking["heavy-drinker"] = action.payload;
      searchFilterSlice.caseReducers.updateDrinkingSelectedCount(state);
    },
    updateRelationshipStatusSingle: (state, action) => {
      state.relationshipStatus.single = action.payload;
      searchFilterSlice.caseReducers.updateRelationshipStatusSelectedCount(
        state
      );
    },
    updateRelationshipStatusDivorced: (state, action) => {
      state.relationshipStatus.divorced = action.payload;
      searchFilterSlice.caseReducers.updateRelationshipStatusSelectedCount(
        state
      );
    },
    updateRelationshipStatusSeparated: (state, action) => {
      state.relationshipStatus.separated = action.payload;
      searchFilterSlice.caseReducers.updateRelationshipStatusSelectedCount(
        state
      );
    },
    updateRelationshipMarriedButLooking: (state, action) => {
      state.relationshipStatus["married-but-looking"] = action.payload;
      searchFilterSlice.caseReducers.updateRelationshipStatusSelectedCount(
        state
      );
    },
    updateRelationshipStatusWidowed: (state, action) => {
      state.relationshipStatus.widowed = action.payload;
      searchFilterSlice.caseReducers.updateRelationshipStatusSelectedCount(
        state
      );
    },
    updateEducationHighSchool: (state, action) => {
      state.education["high-school"] = action.payload;
      searchFilterSlice.caseReducers.updateEducationSelectedCount(state);
    },
    updateEducationSomeCollege: (state, action) => {
      state.education["some-college"] = action.payload;
      searchFilterSlice.caseReducers.updateEducationSelectedCount(state);
    },
    updateEducationAssociateDegree: (state, action) => {
      state.education["associates-degree"] = action.payload;
      searchFilterSlice.caseReducers.updateEducationSelectedCount(state);
    },
    updateEducationBachelorsDegree: (state, action) => {
      state.education["bachelors-degree"] = action.payload;
      searchFilterSlice.caseReducers.updateEducationSelectedCount(state);
    },
    updateEducationGraduateDegree: (state, action) => {
      state.education["graduate-degree"] = action.payload;
      searchFilterSlice.caseReducers.updateEducationSelectedCount(state);
    },
    updateEducationPostGraduateDegree: (state, action) => {
      state.education["phd-or-post-doctoral"] = action.payload;
      searchFilterSlice.caseReducers.updateEducationSelectedCount(state);
    },
    updateTor: (state, action) => {
      state.tor = action.payload;
    },

    // Utility Functions
    resetSearchFilterStore: () => {
      return { ...initialState };
    },
    resetSearchFilter: (state) => {},
    updateLifestyleSelectedCount: (state) => {
      state.lifestyleSelectedCount = 0;

      if (state.lifestyle.negotiable) {
        state.lifestyleSelectedCount += 1;
      }
      if (state.lifestyle.minimal) {
        state.lifestyleSelectedCount += 1;
      }
      if (state.lifestyle.practical) {
        state.lifestyleSelectedCount += 1;
      }
      if (state.lifestyle.moderate) {
        state.lifestyleSelectedCount += 1;
      }
      if (state.lifestyle.substantial) {
        state.lifestyleSelectedCount += 1;
      }
      if (state.lifestyle.high) {
        state.lifestyleSelectedCount += 1;
      }
    },
    updateBodyTypeSelectedCount: (state) => {
      state.bodyTypeSelectedCount = 0;

      if (state.bodyType.slim) {
        state.bodyTypeSelectedCount += 1;
      }
      if (state.bodyType.athletic) {
        state.bodyTypeSelectedCount += 1;
      }
      if (state.bodyType.average) {
        state.bodyTypeSelectedCount += 1;
      }
      if (state.bodyType["a-few-extra-pounds"]) {
        state.bodyTypeSelectedCount += 1;
      }
      if (state.bodyType["full-or-overweight"]) {
        state.bodyTypeSelectedCount += 1;
      }
      if (state.bodyType.other) {
        state.bodyTypeSelectedCount += 1;
      }
    },
    updateEthnicitySelectedCount: (state) => {
      state.ethnicitySelectedCount = 0;

      if (state.ethnicity.asian) {
        state.ethnicitySelectedCount += 1;
      }
      if (state.ethnicity["black-or-african-descent"]) {
        state.ethnicitySelectedCount += 1;
      }
      if (state.ethnicity.latin) {
        state.ethnicitySelectedCount += 1;
      }
      if (state.ethnicity["east-indian"]) {
        state.ethnicitySelectedCount += 1;
      }
      if (state.ethnicity["middle-eastern"]) {
        state.ethnicitySelectedCount += 1;
      }
      if (state.ethnicity.mixed) {
        state.ethnicitySelectedCount += 1;
      }
      if (state.ethnicity["native-american"]) {
        state.ethnicitySelectedCount += 1;
      }
      if (state.ethnicity["pacific-islander"]) {
        state.ethnicitySelectedCount += 1;
      }
      if (state.ethnicity.caucasian) {
        state.ethnicitySelectedCount += 1;
      }
      if (state.ethnicity.other) {
        state.ethnicitySelectedCount += 1;
      }
    },
    updateEyeColorSelectedCount: (state) => {
      state.eyeColorSelectedCount = 0;

      if (state.eyeColor.black) {
        state.eyeColorSelectedCount += 1;
      }
      if (state.eyeColor.blue) {
        state.eyeColorSelectedCount += 1;
      }
      if (state.eyeColor.brown) {
        state.eyeColorSelectedCount += 1;
      }
      if (state.eyeColor.grey) {
        state.eyeColorSelectedCount += 1;
      }
      if (state.eyeColor.green) {
        state.eyeColorSelectedCount += 1;
      }
      if (state.eyeColor.hazel) {
        state.eyeColorSelectedCount += 1;
      }
      if (state.eyeColor.other) {
        state.eyeColorSelectedCount += 1;
      }
    },
    updateHairColorSelectedCount: (state) => {
      state.hairColorSelectedCount = 0;

      if (state.hairColor.auburn) {
        state.hairColorSelectedCount += 1;
      }
      if (state.hairColor.black) {
        state.hairColorSelectedCount += 1;
      }
      if (state.hairColor.blonde) {
        state.hairColorSelectedCount += 1;
      }
      if (state.hairColor["light-brown"]) {
        state.hairColorSelectedCount += 1;
      }
      if (state.hairColor["dark-brown"]) {
        state.hairColorSelectedCount += 1;
      }
      if (state.hairColor.grey) {
        state.hairColorSelectedCount += 1;
      }
      if (state.hairColor.red) {
        state.hairColorSelectedCount += 1;
      }
      if (state.hairColor.white) {
        state.hairColorSelectedCount += 1;
      }
      if (state.hairColor.other) {
        state.hairColorSelectedCount += 1;
      }
    },
    updateSmokingSelectedCount: (state) => {
      state.smokingSelectedCount = 0;

      if (state.smoking["non-smoker"]) {
        state.smokingSelectedCount += 1;
      }
      if (state.smoking["light-smoker"]) {
        state.smokingSelectedCount += 1;
      }
      if (state.smoking["heavy-smoker"]) {
        state.smokingSelectedCount += 1;
      }
    },
    updateDrinkingSelectedCount: (state) => {
      state.drinkingSelectedCount = 0;

      if (state.drinking["non-drinker"]) {
        state.drinkingSelectedCount += 1;
      }
      if (state.drinking["social-drinker"]) {
        state.drinkingSelectedCount += 1;
      }
      if (state.drinking["heavy-drinker"]) {
        state.drinkingSelectedCount += 1;
      }
    },
    updateRelationshipStatusSelectedCount: (state) => {
      state.relationshipStatusSelectedCount = 0;

      if (state.relationshipStatus.single) {
        state.relationshipStatusSelectedCount += 1;
      }
      if (state.relationshipStatus.divorced) {
        state.relationshipStatusSelectedCount += 1;
      }
      if (state.relationshipStatus.separated) {
        state.relationshipStatusSelectedCount += 1;
      }
      if (state.relationshipStatus["married-but-looking"]) {
        state.relationshipStatusSelectedCount += 1;
      }
      if (state.relationshipStatus.widowed) {
        state.relationshipStatusSelectedCount += 1;
      }
    },
    updateEducationSelectedCount: (state) => {
      state.educationSelectedCount = 0;

      if (state.education["high-school"]) {
        state.educationSelectedCount += 1;
      }
      if (state.education["some-college"]) {
        state.educationSelectedCount += 1;
      }
      if (state.education["associates-degree"]) {
        state.educationSelectedCount += 1;
      }
      if (state.education["bachelors-degree"]) {
        state.educationSelectedCount += 1;
      }
      if (state.education["graduate-degree"]) {
        state.educationSelectedCount += 1;
      }
      if (state.education["phd-or-post-doctoral"]) {
        state.educationSelectedCount += 1;
      }
    },
    updateTorSelectedCount: (state) => {
      state.torSelectedCount = 0;
    },
    updateIsFiltered: (state) => {
      if (
        (state.keyword !== undefined && state.keyword !== "") ||
        state.location ||
        state.location_data ||
        state.premiumOnly ||
        state.photos ||
        state.viewed ||
        state.unviewed ||
        state.favorited ||
        state.favoritedMe ||
        state.minAge !== 18 ||
        state.maxAge !== 60 ||
        // state.minHeight !== 130 ||
        // state.maxHeight !== 220 ||
        state.lifestyleSelectedCount > 0 ||
        state.bodyTypeSelectedCount > 0 ||
        state.ethnicitySelectedCount > 0 ||
        state.tor.length > 0 ||
        state.eyeColorSelectedCount > 0 ||
        state.hairColorSelectedCount > 0 ||
        state.smokingSelectedCount > 0 ||
        state.drinkingSelectedCount > 0 ||
        state.relationshipStatusSelectedCount > 0 ||
        state.educationSelectedCount > 0
      ) {
        state.isFiltered = true;

        state.triggerSearchFilteredPassthrough = {};
      } else {
        state.isFiltered = false;
      }
    },
    composeSelectedFilters: (state, action) => {
      let selectedFilters = [];

      if (action.payload && action.payload.length > 0) {
        let torListData = [...action.payload];
        let selectedTor = [];

        if (torListData.length > 0) {
          for (let i = 0; i < torListData.length; i++) {
            if (torListData[i].selected === true) {
              selectedTor.push(torListData[i].locale);
            }
          }

          selectedFilters = [...selectedTor];
        }
      }

      if (state.keyword) {
        selectedFilters.push(state.keyword);
      }

      if (state.locationFormattedAddress) {
        selectedFilters.push(state.locationFormattedAddress);
      }

      if (state.premiumOnly) {
        selectedFilters.push("filter.premium");
      }

      if (state.favorited) {
        selectedFilters.push("filter.favorited");
      }

      if (state.favoritedMe) {
        selectedFilters.push("filter.favorited_me");
      }

      if (state.minAge !== 18 || state.maxAge !== 60) {
        selectedFilters.push(`${state.minAge}-${state.maxAge}`);
      }

      if (state.lifestyle.negotiable) {
        selectedFilters.push("filter.opt_lifestyle.negotiable");
      }

      if (state.lifestyle.minimal) {
        selectedFilters.push("filter.opt_lifestyle.minimal");
      }

      if (state.lifestyle.practical) {
        selectedFilters.push("filter.opt_lifestyle.practical");
      }

      if (state.lifestyle.moderate) {
        selectedFilters.push("filter.opt_lifestyle.moderate");
      }

      if (state.lifestyle.substantial) {
        selectedFilters.push("filter.opt_lifestyle.substantial");
      }

      if (state.lifestyle.high) {
        selectedFilters.push("filter.opt_lifestyle.high");
      }

      if (state.bodyType.slim) {
        selectedFilters.push("filter.opt_body_type.slim");
      }

      if (state.bodyType.athletic) {
        selectedFilters.push("filter.opt_body_type.athletic");
      }

      if (state.bodyType.average) {
        selectedFilters.push("filter.opt_body_type.average");
      }

      if (state.bodyType.aFewExtraPounds) {
        selectedFilters.push("filter.opt_body_type.a_few_extra_pounds");
      }

      if (state.bodyType.overweight) {
        selectedFilters.push("filter.opt_body_type.full__overweight");
      }

      if (state.bodyType.other) {
        selectedFilters.push("filter.opt_body_type.other");
      }

      if (state.ethnicity.asian) {
        selectedFilters.push("filter.opt_ethnicity.asian");
      }

      if (state.ethnicity["black-or-african-descent"]) {
        selectedFilters.push("filter.opt_ethnicity.black__african");
      }

      if (state.ethnicity.latin) {
        selectedFilters.push("filter.opt_ethnicity.latin__hispanic");
      }

      if (state.ethnicity["east-indian"]) {
        selectedFilters.push("filter.opt_ethnicity.east_indian");
      }

      if (state.ethnicity["middle-eastern"]) {
        selectedFilters.push("filter.opt_ethnicity.middle_eastern");
      }

      if (state.ethnicity.mixed) {
        selectedFilters.push("filter.opt_ethnicity.mixed");
      }

      if (state.ethnicity["native-american"]) {
        selectedFilters.push("filter.opt_ethnicity.native_american");
      }

      if (state.ethnicity["pacific-islander"]) {
        selectedFilters.push("filter.opt_ethnicity.pacific_islander");
      }

      if (state.ethnicity.caucasian) {
        selectedFilters.push("filter.opt_ethnicity.white__caucasian");
      }

      if (state.ethnicity.other) {
        selectedFilters.push("filter.opt_ethnicity.other");
      }

      if (state.eyeColor.black) {
        selectedFilters.push("filter.opt_color.black");
      }

      if (state.eyeColor.blue) {
        selectedFilters.push("filter.opt_color.blue");
      }

      if (state.eyeColor.brown) {
        selectedFilters.push("filter.opt_color.brown");
      }

      if (state.eyeColor.grey) {
        selectedFilters.push("filter.opt_color.grey");
      }

      if (state.eyeColor.green) {
        selectedFilters.push("filter.opt_color.green");
      }

      if (state.eyeColor.hazel) {
        selectedFilters.push("filter.opt_color.hazel");
      }

      if (state.eyeColor.other) {
        selectedFilters.push("filter.opt_color.other");
      }

      if (state.hairColor.auburn) {
        selectedFilters.push("filter.opt_color.auburn");
      }

      if (state.hairColor.black) {
        selectedFilters.push("filter.opt_color.black");
      }

      if (state.hairColor.blonde) {
        selectedFilters.push("filter.opt_color.blonde");
      }

      if (state.hairColor["light-brown"]) {
        selectedFilters.push("filter.opt_color.light_brown");
      }

      if (state.hairColor["dark-brown"]) {
        selectedFilters.push("filter.opt_color.dark_brown");
      }

      if (state.hairColor.grey) {
        selectedFilters.push("filter.opt_color.grey");
      }

      if (state.hairColor.red) {
        selectedFilters.push("filter.opt_color.red");
      }

      if (state.hairColor.white) {
        selectedFilters.push("filter.opt_color.white");
      }

      if (state.hairColor.other) {
        selectedFilters.push("filter.opt_color.other");
      }

      if (state.smoking["non-smoker"]) {
        selectedFilters.push("filter.opt_smoking.non_smoker");
      }

      if (state.smoking["light-smoker"]) {
        selectedFilters.push("filter.opt_smoking.light_smoker");
      }

      if (state.smoking["heavy-smoker"]) {
        selectedFilters.push("filter.opt_smoking.heavy_smoker");
      }

      if (state.drinking["non-drinker"]) {
        selectedFilters.push("filter.opt_drinking.non_drinker");
      }

      if (state.drinking["social-drinker"]) {
        selectedFilters.push("filter.opt_drinking.social_drinker");
      }

      if (state.drinking["heavy-drinker"]) {
        selectedFilters.push("filter.opt_drinking.heavy_drinker");
      }

      if (state.relationshipStatus.single) {
        selectedFilters.push("filter.opt_relationship.single");
      }

      if (state.relationshipStatus.divorced) {
        selectedFilters.push("filter.opt_relationship.divorced");
      }

      if (state.relationshipStatus.separated) {
        selectedFilters.push("filter.opt_relationship.separated");
      }

      if (state.relationshipStatus["married-but-looking"]) {
        selectedFilters.push("filter.opt_relationship.married_but_looking");
      }

      if (state.relationshipStatus.widowed) {
        selectedFilters.push("filter.opt_relationship.widowed");
      }

      if (state.education["high-school"]) {
        selectedFilters.push("filter.opt_education.high_school");
      }

      if (state.education["some-college"]) {
        selectedFilters.push("filter.opt_education.some_college");
      }

      if (state.education["associates-degree"]) {
        selectedFilters.push("filter.opt_education.associate_degree");
      }

      if (state.education["bachelors-degree"]) {
        selectedFilters.push("filter.opt_education.bachelors_degree");
      }

      if (state.education["graduate-degree"]) {
        selectedFilters.push("filter.opt_education.graduate_degree");
      }

      if (state.education["phd-or-post-doctoral"]) {
        selectedFilters.push("filter.opt_education.phd__post_doctoral");
      }

      state.selectedFilters = selectedFilters;
    },
    updateTriggerSearchFilteredPassthrough: (state) => {
      state.triggerSearchFilteredPassthrough = {};
    },
  },
});

export const {
  // General Functions
  updateKeyword,
  updateLocationFormattedAddress,
  updateLocationLatitude,
  updateLocationLongitude,
  updateLocationCity,
  updateLocationState,
  updateLocationCountry,
  updateLocationCountryIso,
  updateLocation,
  updateLocationData,
  updatePremiumOnly,
  updatePhotos,
  updateViewed,
  updateUnviewed,
  updateFavorited,
  updateFavoritedMe,
  updateMinAge,
  updateMaxAge,
  updateMinHeight,
  updateMaxHeight,
  updateLifestyleNegotiable,
  updateLifestyleMinimal,
  updateLifestylePractical,
  updateLifestyleModerate,
  updateLifestyleSubstantial,
  updateLifestyleHigh,
  updateBodyTypeSlim,
  updateBodyTypeAthletic,
  updateBodyTypeAverage,
  updateBodyTypeAFewExtraPounds,
  updateBodyTypeOverweight,
  updateBodyTypeOther,
  updateEthnicityAsian,
  updateEthnicityBlackAfrican,
  updateEthnicityLatinHispanic,
  updateEthnicityEastIndian,
  updateEthnicityMiddleEastern,
  updateEthnicityMixed,
  updateEthnicityNativeAmerican,
  updateEthnicityPacificIslander,
  updateEthnicityWhiteCaucasian,
  updateEthnicityOther,
  updateEyeColorBlack,
  updateEyeColorBlue,
  updateEyeColorBrown,
  updateEyeColorGrey,
  updateEyeColorGreen,
  updateEyeColorHazel,
  updateEyeColorOther,
  updateHairColorAuburn,
  updateHairColorBlack,
  updateHairColorBlonde,
  updateHairColorLightBrown,
  updateHairColorDarkBrown,
  updateHairColorGrey,
  updateHairColorRed,
  updateHairColorWhite,
  updateHairColorOther,
  updateSmokingNonSmoker,
  updateSmokingLightSmoker,
  updateSmokingHeavySmoker,
  updateDrinkingNonDrinker,
  updateDrinkingSocialDrinker,
  updateDrinkingHeavyDrinker,
  updateRelationshipStatusSingle,
  updateRelationshipStatusDivorced,
  updateRelationshipStatusSeparated,
  updateRelationshipMarriedButLooking,
  updateRelationshipStatusWidowed,
  updateEducationHighSchool,
  updateEducationSomeCollege,
  updateEducationAssociateDegree,
  updateEducationBachelorsDegree,
  updateEducationGraduateDegree,
  updateEducationPostGraduateDegree,
  updateTor,

  // Utility Functions
  resetSearchFilterStore,
  resetSearchFilter,
  updateLifestyleSelectedCount,
  updateBodyTypeSelectedCount,
  updateEthnicitySelectedCount,
  updateEyeColorSelectedCount,
  updateHairColorSelectedCount,
  updateSmokingSelectedCount,
  updateDrinkingSelectedCount,
  updateRelationshipStatusSelectedCount,
  updateEducationSelectedCount,
  updateIsFiltered,
  composeSelectedFilters,
  updateTriggerSearchFilteredPassthrough,
} = searchFilterSlice.actions;

export default searchFilterSlice.reducer;
